import Alert from '@storeblocks/alert';
import React from 'react';

import { useFms } from '@/hooks/useFms';

export const PostFailedAlert: React.FC = () => {
  const texts = useFms();

  return (
    <Alert
      title={texts.orders.form.errors.postError.title}
      variant="error"
      data-test="post-order-error-message"
    >
      {texts.orders.form.errors.postError.message}{' '}
      <a href={`mailto:${texts.error.siosEmail}`}>{texts.error.siosEmail}</a>
    </Alert>
  );
};
