import Breadcrumbs from '@storeblocks/breadcrumbs';
import Stepper, { Step } from '@storeblocks/stepper';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useCurrentUserInfo } from '@/auth/hooks';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { Pending } from '@/components/Pending';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { useExistingOrder } from './hooks/useExistingOrder';
import { useOrderPageTitle } from './hooks/useOrderPageTitle';
import { Signed } from './steps/Signed';
import { getOrderPagePath } from './utils/getOrderPagePath';
import { verifyCorrectOrderType } from './utils/verifyCorrectOrderType';

interface Props {
  orderType: OrderType;
}

export const OrderSignedPage: React.FC<Props> = ({ orderType }) => {
  const organizationCmId = useOrganizationCmId();
  const texts = useFms();
  const userInfo = useCurrentUserInfo();

  const pageTitle = useOrderPageTitle(orderType);
  const pagePath = getOrderPagePath(orderType);

  const { existingOrder, existingOrderIsPending } = useExistingOrder();

  if (existingOrderIsPending) {
    return <Pending center />;
  }

  // Verify that the order type is correct for the current URL since we are
  // using the same page for both subscription and redemption.
  verifyCorrectOrderType(existingOrder, orderType);

  const isOwner = existingOrder?.userId === userInfo.userCmId;
  const steps = isOwner ? 3 : 2;

  return (
    <WithGap>
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/trading`}>
          {texts.menu.links.trading.trading}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/trading/${pagePath}`}>
          {pageTitle}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={pageTitle} />

        <Stepper active={steps}>
          {/* Only the owner of the order can edit the order lines. */}
          {isOwner && (
            <Step
              title={texts.orders.form.steps.orderDetails.stepperTitle}
              legendProps={{
                'data-test': 'order-step-details',
              }}
            />
          )}

          <Step
            title={texts.orders.form.steps.confirm.stepperTitle}
            legendProps={{
              'data-test': 'order-step-confirm',
            }}
          />

          <Step
            title={texts.orders.form.steps.signing.stepperTitle}
            legendProps={{
              'data-test': 'order-step-signing',
            }}
          />

          <Step
            title={texts.orders.form.steps.finished.stepperTitle}
            legendProps={{
              'data-test': 'order-step-completed',
            }}
          >
            <Signed
              orderId={existingOrder?.id}
              isOwner={isOwner}
              hasCosigners={existingOrder?.signatories.length > 1}
            />
          </Step>
        </Stepper>
      </MainContent>
    </WithGap>
  );
};
