import * as Yup from 'yup';

import { SubscriptionFundState } from '../../types/SubscriptionFundState';
import { baseFundStateValidationSchema } from './baseFundStateValidationSchema';

export const baseSubscriptionFundStateValidationSchema: Yup.SchemaOf<SubscriptionFundState> =
  baseFundStateValidationSchema.concat(
    Yup.object({
      minimumSubscriptionAmount: Yup.number().required(),
    }),
  );
