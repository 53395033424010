import { Report, ReportSource } from '../types/ReportModel';

export const filterByReportSource = (
  reports: Report[],
  reportSource: ReportSource[],
): Report[] => {
  if (reportSource.length === 0) {
    return reports;
  }

  return reports.filter((report) => reportSource.includes(report.source));
};
