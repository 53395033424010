import React, { useState } from 'react';

import { DatePicker } from '@/components/DatePicker';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { usePresentationContext } from '../PresentationContext';
import { Filter } from './Filter';

const dateOfToday = new Date();

export const DateFilter: React.FC = () => {
  const texts = useFms();
  const locale = useUserLocale();

  const { globalFilter, setGlobalFilter } = usePresentationContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleDateOnChange = (_, selectedDate: Date): void => {
    // Check if the selected date is empty or invalid and set the
    // error message accordingly.
    if (!selectedDate || isNaN(selectedDate.getTime())) {
      setErrorMessage(texts.pages.presentation.filters.date.error);
    } else {
      setGlobalFilter({
        ...globalFilter,
        date: selectedDate,
      });
      setErrorMessage('');
    }
  };

  const getTrackId = useGetTrackId();
  const trackId = getTrackId('select-date', TrackingElement.FilterChip);

  return (
    <Filter label={texts.pages.presentation.filters.date.label}>
      <DatePicker
        id="presentation-filter-date"
        name="presentation-filter-date"
        locale={locale}
        max={dateOfToday}
        data-trackid={trackId}
        error={errorMessage}
        data-trackid-source="presentation-filter"
        data-trackid-type="date-picker"
        value={globalFilter.date}
        onChange={handleDateOnChange}
      />
    </Filter>
  );
};
