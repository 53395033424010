import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { FMSTexts } from '@/types/fms';

export const getPaymentTitleFromAccountHolder = (
  accountHolder: PaymentAccountHolder,
  texts: FMSTexts,
): string => {
  switch (accountHolder) {
    case PaymentAccountHolder.SAM:
      return texts.orders.form.payment.subscription.settlementAccount.info.title
        .sam;

    case PaymentAccountHolder.SPP:
      return texts.orders.form.payment.subscription.settlementAccount.info.title
        .spp;

    default:
      throw new Error(
        `Missing translation for account entity [${accountHolder}] in subscription payment.`,
      );
  }
};
