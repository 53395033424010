import React, { useCallback, useEffect } from 'react';
import { generatePath, Outlet, useNavigate } from 'react-router';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { MainContent } from '@/components/MainContent';
import { useCurrentRoute } from '@/routes/hooks';

import { CenteredPageContent } from './components/CenteredPageContent';
import { NavigationPad } from './components/NavigationPad';
import { usePresentationContext } from './PresentationContext';

export const PresentationPage: React.FC = () => {
  const currentRoute = useCurrentRoute();
  const organizationCmId = useOrganizationCmId();
  const navigate = useNavigate();

  const { selectedScreens } = usePresentationContext();

  const currentRouteIndex = selectedScreens.findIndex(
    (route) => route.path === currentRoute.path,
  );

  if (currentRouteIndex === -1) {
    throw new Error('Could not find current route.');
  }

  const nextRoute = selectedScreens[currentRouteIndex + 1];
  const nextPath =
    nextRoute &&
    generatePath(nextRoute.path, {
      organizationCmId,
    });

  const previousRoute = selectedScreens[currentRouteIndex - 1];
  const previousPath =
    previousRoute &&
    generatePath(previousRoute.path, {
      organizationCmId,
    });

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (nextPath && ['m', 'M'].includes(event.key)) {
        navigate(nextPath);
      }

      if (previousPath && ['n', 'N'].includes(event.key)) {
        navigate(previousPath);
      }
    },
    [nextPath, previousPath, navigate],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <MainContent>
      <Container>
        <NavigationPadWrapper>
          {previousRoute && (
            <NavigationPad direction="left" to={previousPath} />
          )}
        </NavigationPadWrapper>

        <CenteredPageContent data-test="presentation-page">
          <Outlet />
        </CenteredPageContent>

        <NavigationPadWrapper>
          {nextRoute && <NavigationPad direction="right" to={nextPath} />}
        </NavigationPadWrapper>
      </Container>
    </MainContent>
  );
};

const NavigationPadWrapper = styled.div`
  min-width: 96px;
  display: flex;
  justify-content: center;
  // Set the minimum height to the height of the viewport minus the height of the navbar
  min-height: calc(100vh - 75px);
`;

const Container = styled.div`
  display: flex;
  flex: 1;
`;
