import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import { LoadingIndicator } from '@storeblocks/loading-indicator';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import {
  useEditOrganizationMutation,
  useOrganizationsQuery,
} from '@/api/organizations/organizationsApi';
import {
  useAddIHubDirectoryToOrganizationMutation,
  useDeleteIHubDirectoryFromOrganizationMutation,
  useIHubDirectoriesForOrganizationQuery,
  useIHubDirectoriesQuery,
} from '@/api/reports/reportsApi';
import { FormInput } from '@/components/Form/FormInput';
import { FormRow } from '@/components/Form/FormRow';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { FMSTexts } from '@/types/fms';

import { DirectorySelector } from './DirectorySelector';
import {
  EditOrganizationFormValues,
  getInitialValues,
  getValidationSchema,
} from './EditOrganizationFormSchema';

interface Props {
  closeModal: () => void;
  organizationId: string;
}

export const EditOrganizationModal: FC<Props> = ({
  closeModal,
  organizationId,
}) => {
  const [submitError, setSubmitError] = useState(false);
  const texts: FMSTexts = useFms();
  const getTrackId = useGetTrackId();

  const iHubDirectoriesQuery = useIHubDirectoriesQuery();

  const iHubDirectoriesForOrganizationQuery =
    useIHubDirectoriesForOrganizationQuery(organizationId);

  const [addIHubDirectoryToOrganization] =
    useAddIHubDirectoryToOrganizationMutation();

  const [deleteIHubDirectoryFromOrganization] =
    useDeleteIHubDirectoryFromOrganizationMutation();

  const { organizationName, organizationsIsPending } =
    useOrganizationName(organizationId);

  const organizationsQuery = useOrganizationsQuery();

  const [editOrganization] = useEditOrganizationMutation();

  const isPending =
    iHubDirectoriesForOrganizationQuery.isLoading ||
    iHubDirectoriesQuery.isLoading ||
    organizationsIsPending;

  const handleSubmit = (
    values: EditOrganizationFormValues,
    helpers: FormikHelpers<EditOrganizationFormValues>,
  ): void => {
    const promises: Array<Promise<unknown>> = [];

    if (organizationName !== values.orgName) {
      promises.push(
        editOrganization({ organizationId, organizationName: values.orgName }),
      );
    }

    if (
      !iHubDirectoriesForOrganizationQuery.isError &&
      !iHubDirectoriesQuery.isError
    ) {
      const customerIhubDirectoriesIds =
        iHubDirectoriesForOrganizationQuery.data?.map((dir) => dir.id) ?? [];

      const directoriesToAdd = values.directories.filter(
        (newDir) => !customerIhubDirectoriesIds.includes(newDir),
      );

      const directoriesToRemove = customerIhubDirectoriesIds.filter(
        (currentDir) => !values.directories.includes(currentDir),
      );

      directoriesToAdd.forEach((directoryId) => {
        promises.push(
          addIHubDirectoryToOrganization({
            organizationCmId: organizationId,
            directoryId,
          }),
        );
      });

      directoriesToRemove.forEach((directoryId) => {
        promises.push(
          deleteIHubDirectoryFromOrganization({
            organizationCmId: organizationId,
            directoryId,
          }),
        );
      });
    }

    Promise.all(promises)
      .then(() => {
        organizationsQuery.refetch();
        closeModal();
      })
      .catch(() => {
        setSubmitError(true);
        helpers.setSubmitting(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(
        organizationName,
        iHubDirectoriesForOrganizationQuery.data ?? [],
      )}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(texts)}
    >
      {({
        isSubmitting,
        dirty,
        errors,
        touched,
      }: FormikProps<EditOrganizationFormValues>) => (
        <Form id="edit-organization-form">
          <Modal trigger={undefined} open onClose={closeModal}>
            <Heading>{texts.adminModal.editOrganization.title}</Heading>
            <Content>
              <Show when={!isPending}>
                <FormRow>
                  <StyledFormInput
                    name="orgName"
                    label={texts.adminModal.orgName}
                    large
                    error={touched.orgName && errors.orgName}
                  />
                </FormRow>
                <FormRow>
                  <DirectorySelector cmId={organizationId} />
                </FormRow>
                <Show when={submitError}>
                  <FormRow>
                    <Alert
                      variant="error"
                      title={texts.admin.organization.couldNotSave}
                    />
                  </FormRow>
                </Show>
              </Show>
              <Show when={isPending}>
                <LoadingIndicator />
              </Show>
            </Content>
            <Footer>
              <ButtonContainer key="modal-button-container">
                <Button
                  type="submit"
                  form="edit-organization-form"
                  disabled={
                    isSubmitting || !dirty || Object.keys(errors).length > 0
                  }
                  data-trackid={getTrackId(
                    'save:edit-organization-modal',
                    TrackingElement.Button,
                  )}
                >
                  {isSubmitting ? (
                    <LoadingIndicator />
                  ) : (
                    texts.adminModal.saveButton
                  )}
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="outlined"
                  onClick={closeModal}
                  data-trackid={getTrackId(
                    'cancel:edit-organization-modal',
                    TrackingElement.Button,
                  )}
                >
                  {texts.adminModal.cancelButton}
                </Button>
              </ButtonContainer>
            </Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormInput = styled(FormInput)`
  margin: 8px 0px;
`;

const ButtonContainer = styled.div`
  > button {
    margin-right: 8px;
  }
`;
