import { AssetPerformanceData } from '@/api/performanceData/models';

export const getAssetProducts = (
  node: AssetPerformanceData,
): Array<AssetPerformanceData> => {
  if (node.nodeDepth === 0) {
    return [node];
  }

  return node.assets.flatMap((childNode) => getAssetProducts(childNode));
};
