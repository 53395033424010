import Button from '@storeblocks/button';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { ContactFormSuccess } from './ContactFormSuccess';
import { Form } from './Form';

interface Props {
  source: Source;
}

export const ContactForm: React.FC<Props> = ({ source }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <WithGap gap="8">
      <div>
        {/* Button to open and close the form */}
        <Button
          variant="outlined"
          iconRight={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen((state) => !state)}
          data-test="toggle-contact-us-form"
          data-trackid={getTrackId(
            'toggle-contact-us-form',
            TrackingElement.Button,
          )}
        >
          {texts.pages.contactUs.needHelp.title}
        </Button>
      </div>

      {isOpen && (
        <Background data-test="contact-form-content">
          {isSubmitted ? (
            // The contact form has been successfully submitted.
            // Render a success message.
            <ContactFormSuccess
              onClose={() => setIsOpen(false)}
              onReset={() => setIsSubmitted(false)}
            />
          ) : (
            // The contact form has not been submitted yet.
            // Render the contact us form.
            <Form
              source={source}
              onClose={() => setIsOpen(false)}
              onSuccessfulSubmit={() => setIsSubmitted(true)}
            />
          )}
        </Background>
      )}
    </WithGap>
  );
};

const Background = styled.div`
  padding: 24px;
  background: var(--color-surface-variant);
`;
