import { unflatten } from 'flat';

import { Language } from '@/i18n/Language';
import { FMSTexts } from '@/types/fms';

import { getFmsLanguage } from './getFmsLanguage';

export const getBackupFmsTexts = async (
  language: Language,
): Promise<FMSTexts> => {
  const fmsLanguage = getFmsLanguage(language);

  const texts = await import(
    `../../../assets/fmsBackupTexts_${fmsLanguage}.json`
  );

  return unflatten({ ...texts });
};
