import parseISO from 'date-fns/parseISO';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import {
  HoldingsDto,
  MoneyDto,
  PortfolioGroupHoldingsReply,
  PortfolioHoldingsDto,
} from '../dtos/PortfolioGroupHoldingsReply';
import { Holdings } from '../models/Holdings';
import { HoldingsCalculationType } from '../models/HoldingsCalculationType';
import { Money } from '../models/Money';
import { PortfolioGroupHoldings } from '../models/PortfolioGroupHoldings';
import { PortfolioHoldings } from '../models/PortfolioHoldings';
import { getPortfolioGroupHoldingsSummary } from './getPortfolioGroupHoldingsSummary';
import { getPortfolioHoldingsSummary } from './getPortfolioHoldingsSummary';

export const transformPortfolioHoldingsResponse = (
  portfolioGroupId: string,
  response: PortfolioGroupHoldingsReply,
): PortfolioGroupHoldings => {
  const holdings = response.map(transformPortfolioHoldingsDtoToModel);

  return {
    portfolioGroupId,
    holdings,
    summary: getPortfolioGroupHoldingsSummary(holdings),
  };
};

const transformPortfolioHoldingsDtoToModel = (
  dto: PortfolioHoldingsDto,
): PortfolioHoldings => {
  const holdings = transformHoldingsDtosToModels(dto.holdings);

  return {
    portfolioId: dto.portfolioId,
    portfolioGroupId: dto.portfolioGroupId,
    calculationType: convertStringToEnum(
      dto.calculationType,
      HoldingsCalculationType,
    ),
    fromDate: parseISO(dto.fromDate),
    toDate: parseISO(dto.toDate),
    holdings,
    summary: getPortfolioHoldingsSummary(holdings),
  };
};

const transformHoldingsDtosToModels = (
  holdings: Array<HoldingsDto>,
): Array<Holdings> => {
  return holdings.map((holdingsDto) => ({
    nominal: holdingsDto.nominal,
    cleanPrice: holdingsDto.cleanPrice
      ? mapMoneyDtoToModel(holdingsDto.cleanPrice)
      : null,
    cleanValue: holdingsDto.cleanValue
      ? mapMoneyDtoToModel(holdingsDto.cleanValue)
      : null,
    dirtyValue: holdingsDto.dirtyValue
      ? mapMoneyDtoToModel(holdingsDto.dirtyValue)
      : null,
    dirtyValueInclPayRec: holdingsDto.dirtyValueInclPayRec
      ? mapMoneyDtoToModel(holdingsDto.dirtyValueInclPayRec)
      : null,
    bookValue: holdingsDto.bookValue
      ? mapMoneyDtoToModel(holdingsDto.bookValue)
      : null,
    accruedInterest: holdingsDto.accruedInterest
      ? mapMoneyDtoToModel(holdingsDto.accruedInterest)
      : null,
    unrealizedPLValue: holdingsDto.unrealizedPLValue
      ? mapMoneyDtoToModel(holdingsDto.unrealizedPLValue)
      : null,
    security: {
      name: holdingsDto.security.name,
      shortName: holdingsDto.security.shortName,
      instrumentType: holdingsDto.security.instrumentType,
      type: holdingsDto.security.type,
      group: holdingsDto.security.group,
      isin: holdingsDto.security.isin,
      currency: convertStringToEnum(
        holdingsDto.security.currency,
        CurrencyCode,
      ),
    },
  }));
};

const mapMoneyDtoToModel = (dto: MoneyDto): Money => ({
  amount: dto.amount,
  currency: convertStringToEnum(dto.currency, CurrencyCode),
});
