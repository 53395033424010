import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useOrganizationEsgQuery } from '@/api/esg/esgApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { WithRowGap } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

import { EsgTaxonomyDescriptionWidget } from './widgets/EsgTaxonomyDescriptionWidget';
import { EsgTaxonomyOverviewWidget } from './widgets/EsgTaxonomyOverviewWidget';

export const EsgTaxonomyPage: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useFms();
  const esgQuery = useOrganizationEsgQuery();

  if (isNotReady(esgQuery)) {
    return (
      <CenteredPageContent>
        <QueryState
          query={esgQuery}
          customTexts={{ noData: { title: texts.pages.esg.noData } }}
        />
      </CenteredPageContent>
    );
  }

  return (
    <CenteredPageContent data-test="reports-esg-taxonomy-page">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/reports`}>
          {texts.menu.links.reports.reports}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability/esg`}>
          {texts.menu.links.reports.esg}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability/esg/taxonomy`}>
          {texts.menu.links.reports.esgTaxonomy}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.reports.esgTaxonomy} />

        <WithRowGap>
          <EsgTaxonomyOverviewWidget
            euTaxonomyEligibility={esgQuery.data.euTaxonomyEligibility}
            euTaxonomyAlignment={esgQuery.data.euTaxonomyAlignment}
          />
          <EsgTaxonomyDescriptionWidget />
        </WithRowGap>
      </MainContent>
    </CenteredPageContent>
  );
};
