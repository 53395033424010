import Decimal from 'decimal.js';

/**
 * Round the value to the nearest specified fraction digits using
 * the ROUND_HALF_EVEN (Bankers rounding) algorithm.
 */
export const roundHalfEven = (value: number, decimalPlaces = 4): number => {
  return new Decimal(value)
    .toDecimalPlaces(decimalPlaces, Decimal.ROUND_HALF_EVEN)
    .toNumber();
};
