/**
 * Check if the user is in the process of getting access to a new organization.
 *
 * Users who are invited to access a new organization will first
 * have to go through an onboarding process (the new user page)
 * to accept the invitation.
 */
export const isOnboardingUserInProgress = (): boolean => {
  return location.pathname.includes('newuser');
};
