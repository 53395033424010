import { LargeCard } from '@storeblocks/card';
import React, { FC } from 'react';
import styled from 'styled-components';

import { NewsArticle } from '@/api/newsArticles/models/newsArticlesModels';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  newsArticle: NewsArticle;
}

/**
 * Card component for displaying a news article.
 * This component renders a smaller version of the news article card for use in a grid layout.
 *
 * The card is designed to handle single line headers and descriptions.
 * Since the Norwegian and English news articles have shorter headers and descriptions.
 */
export const NewsArticleCardSmall: FC<Props> = ({ newsArticle }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <StyledCard
      title={newsArticle.header}
      image={newsArticle.image}
      // Set the alternative text for the image in the card.
      // The images used are essentially illustration images and should
      // be hidden from the screen readers by setting the alt text empty.
      alt=""
      icon={!newsArticle.image ? 'magazine' : undefined}
      description={newsArticle.description.join(' ')}
      id={newsArticle.link}
      actions={[
        {
          href: newsArticle.link,
          text: texts.news.articles.readArticleLink,
          target: '_BLANK',
          'data-trackid': getTrackId('go-to-article', TrackingElement.Card),
          'data-trackid-article-title': newsArticle.header,
          'aria-label': `${texts.news.articles.readArticleLink} ${newsArticle.header}`,
        },
      ]}
    />
  );
};

const StyledCard = styled(LargeCard)`
  height: 100%;
`;
