import React from 'react';

import { Holdings } from '@/api/portfolioGroupHoldings/models/Holdings';
import { Locale } from '@/i18n/locale';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

import { CellWithTitle } from '../components/CellWithTitle';
import { MoneyCell } from '../components/MoneyCell';
import { HoldingsRow } from '../types/HoldingsRow';

export const mapToHoldingsRow = (
  holdings: Holdings,
  locale: Locale,
): HoldingsRow => {
  return {
    name: {
      sortContent: '',
      displayContent: <CellWithTitle value={holdings.security.name} />,
    },
    shortName: {
      sortContent: '',
      displayContent: <CellWithTitle value={holdings.security.shortName} />,
    },
    instrumentType: {
      sortContent: '',
      displayContent: (
        <CellWithTitle value={holdings.security.instrumentType} />
      ),
    },
    nominal:
      holdings.nominal && formatNumber(roundHalfEven(holdings.nominal), locale),
    cleanPrice: {
      sortContent: '',
      displayContent: <MoneyCell money={holdings.cleanPrice} />,
    },
    bookValue: {
      sortContent: '',
      displayContent: <MoneyCell money={holdings.bookValue} />,
    },
    cleanValue: {
      sortContent: '',
      displayContent: <MoneyCell money={holdings.cleanValue} />,
    },
    accruedInterest: {
      sortContent: '',
      displayContent: <MoneyCell money={holdings.accruedInterest} />,
    },
    unrealizedPLValue: {
      sortContent: '',
      displayContent: <MoneyCell money={holdings.unrealizedPLValue} />,
    },
  };
};
