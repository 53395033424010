import Table from '@storeblocks/table';
import styled from 'styled-components';

interface Props {
  displayVerticalScrollbar?: boolean;
}

export const AssetTable = styled(Table)<Props>`
  /*
    Set max height to allow vertical scrolling of the table
    if it grows larger than the defined max height.
  */
  max-height: ${(props) => (props.displayVerticalScrollbar ? '800px' : 'none')};

  /*
    Right align all columns with number value.
  */
  & th:not(:nth-of-type(1)),
  & td:not(:nth-of-type(1)) {
    text-align: right;
  }

  & table {
    border-collapse: separate;
    table-layout: auto;
    font-size: 16px;
    min-width: unset;
  }

  /*
    Disable wrapping.
  */
  th,
  td {
    white-space: nowrap;
  }

  /*
    Set cell padding to be a scalable unit for print resizing.
  */
  td {
    padding: 1em;
  }

  /*
    Make numbers in the header columns be displayed in superscript, except in the first column.
  */
  & th:not(:nth-of-type(1)) {
    font-variant-numeric: diagonal-fractions;
  }

  /*
    Set all header columns sticky except the first column.
  */
  & th:not(:first-child) {
    position: sticky;
    top: 0;
  }

  /*
    Set the first header column sticky.
  */
  & th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    border-right: 1px solid var(--color-border);
    z-index: 1;
  }

  /*
    Make the first column sticky excluding the last row (summary).
  */
  & tr:not(:last-child) > td:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--color-background);
    border-right: 1px solid var(--color-border);
    position: sticky;
    left: 0;
  }

  /*
    Make the first column of the last row sticky.
  */
  & tr:last-child > td:first-child {
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: var(--color-background);
    border-top: 1px solid var(--color-border);
    border-right: 1px solid var(--color-border);
    z-index: 1;
  }

  /*
    Make the last row (summary) sticky excluding the first column.
  */
  & tr:last-child > td:not(:first-child) {
    position: sticky;
    bottom: 0;
    background-color: var(--color-background);
    border-top: 1px solid var(--color-border);
  }

  /*
    Make the last row bold.
  */
  & tr:last-child {
    font-weight: 600;
  }

  /*
    Hide the numbers on the parent row when the row is expanded
    to make it easier to view the data in the expanded rows.
  */
  & [aria-expanded='true'] {
    td:not(:first-of-type) {
      color: transparent;
    }
  }

  /*
    Scale down font size when printing to avoid horizontal scroll when printing.
  */
  @media print {
    table {
      font-size: 12px;
    }

    // Unset the max height to avoid vertical scroll when printing.
    max-height: unset;
  }
`;
