import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { appConfig } from '@/constants/config';
import { useCurrentRoute } from '@/routes/hooks';

import { WithGap } from './WithGap';

type Props = PropsWithChildren & React.HTMLAttributes<HTMLDivElement>;

export const CenteredPageContent: React.FC<Props> = ({ children, ...rest }) => {
  const currentRoute = useCurrentRoute();

  return (
    <Center
      data-test={rest['data-test'] ? rest['data-test'] : currentRoute.id}
      {...rest}
    >
      <StyledWithGap>{children}</StyledWithGap>
    </Center>
  );
};

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const StyledWithGap = styled(WithGap)`
  padding: 0 24px;
`;
