import { max, min, parseISO } from 'date-fns';

import { Unit } from '@/types/Unit';

import { PortfolioPerformanceDto } from '../dtos';
import { PortfolioPerformance, PortfolioPerformanceDataPoint } from '../models';

export const transformPortfolioPerformanceResponse = (
  dtos: Array<PortfolioPerformanceDto>,
): PortfolioPerformance => {
  const dailyReturnAccumulatedPercent: Array<PortfolioPerformanceDataPoint> =
    dtos.map((dto) => [new Date(dto.date).getTime(), dto.dailyReturnAccPct]);

  const dailyReturnAccumulatedAmount: Array<PortfolioPerformanceDataPoint> =
    dtos.map((dto) => [new Date(dto.date).getTime(), dto.dailyReturnAccAmount]);

  const benchmarkReturnAccumulatedPercent: Array<PortfolioPerformanceDataPoint> =
    dtos.map((dto) => [
      new Date(dto.date).getTime(),
      dto.benchmarkReturnAccPct,
    ]);

  const benchmarkReturnAccumulatedAmount: Array<PortfolioPerformanceDataPoint> =
    dtos.map((dto) => [
      new Date(dto.date).getTime(),
      dto.benchmarkReturnAccAmount,
    ]);

  const excessReturnPercent: Array<PortfolioPerformanceDataPoint> = dtos.map(
    (dto) => [new Date(dto.date).getTime(), dto.excessReturnAcc],
  );

  const excessReturnAmount: Array<PortfolioPerformanceDataPoint> = dtos.map(
    (dto) => [new Date(dto.date).getTime(), dto.excessReturnAccAmount],
  );

  const dates = dtos.map((performance) => parseISO(performance.date));

  const firstPointDate = min(dates);
  const lastPointDate = max(dates);

  return {
    performanceMap: {
      [Unit.Amount]: {
        dailyReturnAccumulated: dailyReturnAccumulatedAmount,
        benchmarkReturnAccumulated: benchmarkReturnAccumulatedAmount,
        excessReturn: excessReturnAmount,
      },
      [Unit.Percent]: {
        dailyReturnAccumulated: dailyReturnAccumulatedPercent,
        benchmarkReturnAccumulated: benchmarkReturnAccumulatedPercent,
        excessReturn: excessReturnPercent,
      },
    },
    firstPointDate,
    lastPointDate,
  };
};
