/**
 * Remove diacritics, also known as accents, from a string.
 *
 * @example 'Prästlönetillgångar i strängnäs stift' -> 'Prastlonetillgangar i strangnas stift'
 */
export const removeDiacritics = (value: string): string =>
  value
    // Separate characters from diacritics.
    // This will convert 'ä' to 'a' and '¨'.
    .normalize('NFD')
    // Remove diacritics characters, e.g. '¨'.
    .replace(/\p{Diacritic}/gu, '');
