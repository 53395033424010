export enum FundDocumentType {
  MonthlyReport = 'MONTHLY_FUND_REPORT',
  QuarterlyReport = 'QUARTERLY_REPORT',
  SemiAnnualReport = 'SEMI_ANNUAL_REPORT',
  AnnualReport = 'ANNUAL_REPORT',
  Prospectus = 'PROSPECTUS',
  SimplifiedProspectus = 'SIMPLIFIED_PROSPECTUS',
  SupplementToProspectus = 'SUPPLEMENT_TO_PROSPECTUS',
  Kiid = 'KIID',
  FactSheet = 'FACT_SHEET',
  FactSheetInstitutional = 'FACTSHEET_INSTITUTIONAL',
  RuleBook = 'RULEBOOK',
  FinancialLeaflet = 'FINANCIAL_LEAFLET',
  Usd = 'USD',
  UnknownType02 = 'UNKNOWN_TYPE_02',
  PackagedRetailInvestmentAndInsuranceProduct = 'PRIIPS_KIID',
}
