import ChipGroup from '@storeblocks/chip/lib/group';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';

import { useDateFilterOptions } from '@/components/DateFilter/hooks/useDateFilterOptions';
import { DateFilterType } from '@/components/DateFilter/types/DateFilterType';

interface Props {
  /**
   * An ID to be used as a tracking source to know what data it filters.
   */
  id: string;
  checked: DateFilterType;
  onChange(selectedDate: DateFilterType): void;
}

export const DateFilterChips: React.FC<Props> = ({ id, checked, onChange }) => {
  // A page can have multiple of this component,
  // so we need to generate a unique ID for each one.
  const [componentId] = useState(nanoid());

  const options = useDateFilterOptions(id);
  const filteredOptions = options.filter((option) =>
    [
      DateFilterType.ThisYear,
      DateFilterType.OneMonth,
      DateFilterType.ThreeMonths,
      DateFilterType.SixMonths,
      DateFilterType.OneYear,
      DateFilterType.ThreeYears,
      DateFilterType.SinceInception,
    ].includes(option.value),
  );

  return (
    <ChipGroup
      id={componentId}
      type="choice"
      options={filteredOptions}
      checked={[checked]}
      onChange={(values) => onChange(values[0] as DateFilterType)}
    />
  );
};
