import { createApi } from '@reduxjs/toolkit/query/react';

import { authorizationApiUrl } from '@/api/utils/authorizationApiUrl';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { AddUserDto } from './dtos/AddUserDto';
import { UsersReply } from './dtos/UsersReply';
import { User } from './models/User';
import { transformUsersReply } from './utils/transformUsersReply';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    users: builder.query<Array<User>, void>({
      query: () => ({
        url: `${authorizationApiUrl()}/users/v2`,
      }),
      transformResponse: (dto: UsersReply) => {
        return transformUsersReply(dto);
      },
    }),

    addUser: builder.mutation<void, AddUserDto>({
      query: (user) => ({
        url: `${authorizationApiUrl()}/organizations/users/external`,
        method: 'PUT',
        data: user,
      }),
    }),

    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `${authorizationApiUrl()}/users/${userId}`,
        method: 'DELETE',
      }),
    }),

    addUserToOrganizations: builder.mutation<
      void,
      {
        userId: string;
        organizations: {
          organizationIds: string[];
          organizationRoles: string[];
        };
      }
    >({
      query: ({ userId, organizations }) => ({
        url: `${authorizationApiUrl()}/organizations/users/${userId}`,
        method: 'PUT',
        data: organizations,
      }),
    }),

    /**
     * Validate the invitation ID of a new user.
     * The API will return a 200 OK if the invitation ID is valid.
     * Otherwise, it will return a 400 BAD REQUEST.
     */
    validateInvitation: builder.mutation<void, { invitationId: string }>({
      query: ({ invitationId }) => ({
        url: `${authorizationApiUrl()}/organizations/users/validate/v2`,
        method: 'PUT',
        data: {
          invitationId,
        },
      }),
    }),
  }),
});

export const {
  useUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useAddUserToOrganizationsMutation,
  useValidateInvitationMutation,
  util,
} = usersApi;
