import Table from '@storeblocks/table';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import { useDocumentFilesQuery } from '@/api/documentArchive/documentArchiveApi';
import { Pending } from '@/components/Pending';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { useUserDateFormat } from '@/i18n/hooks';

import {
  ReactPaginateWrapper,
  StyledAlert,
} from '../archivePagesStyledComponents';
import { useDocumentsArchiveContext } from './DocumentsArchiveContext';
import { prepareDocumentsForDisplay } from './documentsArchiveLogic';
import { PreparedDocumentFile } from './PreparedDocumentFile';

const reportsPerPage = 100;

export const DocumentsArchiveTable: FC = () => {
  const texts = useFms();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const { uncheckAllDocumentFiles, documentFilesParams: documentFileArgs } =
    useDocumentsArchiveContext();

  const documentFilesQuery = useDocumentFilesQuery(documentFileArgs);

  const dateFormat = useUserDateFormat();

  const numberOfPages = useMemo<number>(() => {
    if (!documentFilesQuery.data) {
      return 0;
    }

    return Math.ceil(documentFilesQuery.data.length / reportsPerPage);
  }, [documentFilesQuery.data]);

  const documentsOnCurrentPage = useMemo<Array<PreparedDocumentFile>>(() => {
    if (!documentFilesQuery.data) {
      return [];
    }

    const documentsSlice = documentFilesQuery.data.slice(
      currentPageNumber * reportsPerPage,
      (currentPageNumber + 1) * reportsPerPage,
    );

    return prepareDocumentsForDisplay(documentsSlice, texts, dateFormat);
  }, [numberOfPages, currentPageNumber, documentFilesQuery.data]);

  useEffect(() => {
    uncheckAllDocumentFiles();
  }, [documentFilesQuery.data]);

  if (documentFilesQuery.isLoading) {
    return <Pending center />;
  }

  if (documentFilesQuery.isError) {
    return (
      <StyledAlert
        variant="error"
        title={texts.reportsArchivePage.serverError}
      />
    );
  }

  if (!documentFilesQuery.data || documentFilesQuery.data.length === 0) {
    return (
      <StyledAlert variant="info" title={texts.searchResults.noResultsFound} />
    );
  }

  return (
    <>
      <StyledTable
        columns={[
          {
            key: 'reportDate',
            name: texts.documentsArchivePage.datePublished,
            dataType: 'other',
            renderType: 'jsx',
          },
          {
            key: 'name',
            name: texts.reportsArchivePage.tableHeaders.name,
            dataType: 'other',
            renderType: 'jsx',
          },
          {
            key: 'category',
            name: texts.category.text,
            dataType: 'text',
          },
          {
            key: 'toolbar',
            name: '',
            dataType: 'other',
            renderType: 'jsx',
          },
        ]}
        data={documentsOnCurrentPage}
      />
      <Show when={numberOfPages > 1}>
        <ReactPaginateWrapper>
          <ReactPaginate
            pageCount={numberOfPages}
            onPageChange={(pageNumber) =>
              setCurrentPageNumber(pageNumber.selected)
            }
            pageRangeDisplayed={0}
            marginPagesDisplayed={0}
          />
        </ReactPaginateWrapper>
      </Show>
    </>
  );
};

const StyledTable = styled(Table)`
  // Published date
  & th:nth-of-type(1) {
    width: 15%;
    max-width: 15%;
  }

  // Name
  & th:nth-of-type(2) {
    width: 33%;
    max-width: 33%;
  }

  // Name cell
  & tr td:nth-of-type(2) {
    padding: 0;
  }

  // Category
  & th:nth-of-type(3) {
    width: 27%;
    max-width: 27%;
  }

  // Icons
  & th:nth-of-type(4) {
    width: 11%;
    max-width: 11%;
  }
`;
