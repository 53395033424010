import { appConfig } from '@/constants/config';

export function getFilenameWithoutPathAndExtension(filename: string): string {
  return filename ? filename.split('/').pop().split('.')[0] : '';
}

export function getFileExtension(filename: string): string {
  return filename ? filename.split('.').pop() : '';
}

export const isValidUploadFile = (file: File): FileValidationResult => {
  let valid = false;
  let error: FileValidationError = null;
  if (isInvalidFileSize(file)) {
    error = FileValidationError.FILE_SIZE_ERROR;
  } else if (isInvalidFileNameLength(file)) {
    error = FileValidationError.FILE_NAME_ERROR;
  } else if (isInvalidFileType(file)) {
    error = FileValidationError.FILE_TYPE_ERROR;
  } else {
    valid = true;
  }

  return { valid, error };
};

const isInvalidFileSize = (file: File): boolean => {
  const fileSize = file.size / 1024 / 1024; // in MB
  return fileSize > appConfig.fileUpload.maxFileSizeInMb;
};

const isInvalidFileNameLength = (file: File): boolean => {
  const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
  return (
    fileNameWithoutExtension.length >
    appConfig.inputMaxLengthValidation.nameAndEmail
  );
};

const isInvalidFileType = (file: File): boolean =>
  !appConfig.fileUpload.fileType.includes(file.type);

export enum FileValidationError {
  FILE_SIZE_ERROR,
  FILE_NAME_ERROR,
  FILE_TYPE_ERROR,
}

interface FileValidationResult {
  valid: boolean;
  error: FileValidationError;
}
