import Radio, { Option } from '@storeblocks/radio';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';

interface Props {
  title: string;
  options: Option[];
  paymentIndex: number;
  onChange?: (paymentType: OrderPaymentType) => void;
}

export const PlacementRadio: React.FC<Props> = ({
  title,
  options,
  paymentIndex,
  onChange,
}) => {
  const [field, meta, helpers] = useField<string>(
    `payments[${paymentIndex}].paymentPlacement`,
  );

  const optionsWithCustomId = options.map((option) => {
    return {
      ...option,
      inputProps: {
        ...option.inputProps,
        // Create our own ID instead of the one provided
        // by Storeblocks. This will make it easier to use
        // the IDs in the Cypress tests.
        id: `payment-placement-${option.id}`,
      },
      labelProps: {
        ...option.labelProps,
        // Since the ID of the input is now different, we
        // need to make sure the label is associated with
        // the correct input.
        htmlFor: `payment-placement-${option.id}`,
      },
    };
  });

  const handleChange = async (paymentType: OrderPaymentType): Promise<void> => {
    // Wait for Formik to set the value before calling the onChange.
    // Otherwise, we end up with wrong state in the UI due to
    // rendering issues. It renders the radio component in an error
    // state even though the value is set. The error state is removed
    // when the user interacts with the browser the next time. I.e.
    // on the next render.
    await helpers.setValue(paymentType);

    if (onChange) {
      onChange(paymentType);
    }
  };

  return (
    <div>
      <StyledRadio
        id={field.name}
        label={title}
        options={optionsWithCustomId}
        error={(meta.touched && meta.error) || undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        onChange={(event) =>
          handleChange(event.target.value as OrderPaymentType)
        }
      />
    </div>
  );
};

const StyledRadio = styled(Radio)`
  max-width: unset;
`;
