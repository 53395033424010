interface ErrorArgs {
  pageUrl: string;
  sessionId: string;
}

/**
 * HTTP error when doing a POST to the Connect log endpoint.
 */
export class PostLogError extends Error {
  public pageUrl: string;
  public sessionId: string;

  constructor(args: ErrorArgs, cause?: unknown) {
    let message = `HTTP POST failed to the Connect log endpoint, sessionID: [${args.sessionId}], page URL: [${args.pageUrl}]`;

    if ((cause as Error)?.message) {
      message = message + `, message: [${(cause as Error).message}]`;
    }

    super(message, { cause });

    this.pageUrl = args.pageUrl;
    this.sessionId = args.sessionId;

    // Maintains proper stack trace for where our error was thrown (only available on V8).
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Error.captureStackTrace) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Error.captureStackTrace(this, PostLogError);
    }

    this.name = 'Log Error';
  }
}
