import { getBasename } from '@/routes/utils/getBasename';

const pensionServicesWriteRole = 'PensionServices.write';

export const appConfig = {
  keyCodes: {
    ENTER_KEY: 13,
    ESCAPE_KEY: 27,
    SPACE_KEY: 32,
    ARROW_UP_KEY: 38,
    ARROW_DOWN_KEY: 40,
  },
  constants: {
    MAX_POLL_COUNT: 3,
    POLL_KEY: 'pollCount',
    POLL_INTERVAL: 1000,
    NEW_USER_KEY: 'newUser',
  },
  dateFormat: 'DD.MM.YYYY',
  webIdentifier: getBasename(),
  url: {
    logout: 'logout',
  },
  fileUpload: {
    fileType: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel',
      'application/pdf',
      'image/png',
      'image/jpg',
      'image/jpeg',
      '.jpeg',
    ],
    maxFileSizeInMb: 29,
  },
  search: {
    dateFormat: 'YYYY-MM-DDTHH:mm:ss',
    numberOfFilesPerSearch: 50,
  },
  componentAccessLevel: {
    documentUploadPopover: [pensionServicesWriteRole],
    documentDescriptionUpdate: [pensionServicesWriteRole],
    RadioGroupiHub: [
      'iHub.read',
      'iHub.write',
      'iHub.Customers.read',
      'iHub.Customers.write',
    ],
    deleteDocument: [pensionServicesWriteRole],
    updateMetadata: [pensionServicesWriteRole],
    showKeyPortfolioFigure: [
      'iHub.read',
      'iHub.write',
      'iHub.Customers.read',
      'iHub.Customers.write',
    ],
  },
  useGoogleAnalytics: true,
  inputMaxLengthValidation: {
    nameAndEmail: 100,
    searchUser: 200,
    searchIhubBoardDocs: 255,
    descriptionFields: 1024,
    allowedMaxFileSize: 31457280,
  },
  dayLabels: [...new Array(30).keys()].map((x) => `${x + 1}`),
  headerMenuBreakPoint: 976,
  context: {
    PERFORMANCE: 'Performance',
    ORDERHISTORY: 'OrderHistory',
    ORDERADMIN: 'OrderAdmin',
    FUNDS_SUSTAINABILITY: 'FundsSustainability',
    ADMIN: 'Admin',
    IHUB: 'iHub',
    PENSIONSERVICES: 'PensionServices',
    NEWS: 'News',
  },
  charts: {
    width: {
      default: 1090,
    },
    height: {
      default: 500,
    },
    legends: {
      portfolio: {
        name: 'portfolio',
      },
      benchmark: {
        name: 'benchmark',
      },
    },
  },
  orderTypes: {
    buy: {
      fund: 'BUY_FUND',
      discretionary: 'BUY_DISCRETIONARY',
    },
    sell: {
      fund: 'SELL_FUND',
      discretionary: 'SELL_DISCRETIONARY',
    },
  },
  maxPageWidth: '1128px',
};
