import styled from 'styled-components';

import { padding } from '@/styles/variables';

interface FormRowProps {
  grow?: boolean;
}

export const FormRow = styled.div<FormRowProps>`
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  max-width: 100%;

  > * {
    margin: ${padding.base * 2}px 0;
    flex-grow: ${(props: FormRowProps) => (props.grow ? 1 : 0)};
  }

  > div[role='radiogroup'] {
    min-height: 60px;
  }
`;
