import { skipToken } from '@reduxjs/toolkit/dist/query';

import { PendingOrder } from '@/api/order/models/PendingOrder';
import { usePendingOrdersQuery } from '@/api/order/orderApi';
import { Portfolio, usePortfoliosQuery } from '@/api/portfolios';
import { isInternalUser } from '@/config/utils';

interface Data {
  data?: {
    pendingOrders: PendingOrder[];
    portfolios: ReadonlyMap<string, Portfolio>;
  };
  isSuccess: boolean;
}

export const useOrderToSignData = (): Data => {
  const pendingOrdersQuery = usePendingOrdersQuery(
    // Only external users have access to order functionality.
    // The HTTP request will fail for internal users.
    // Thus skip the request for internal users.
    isInternalUser() ? skipToken : undefined,
  );

  const portfoliosQuery = usePortfoliosQuery();

  if (
    [pendingOrdersQuery, portfoliosQuery].some(
      // The request has not started, is loading or has failed.
      (request) => !request.isSuccess,
    )
  ) {
    return { isSuccess: false };
  }

  return {
    isSuccess: true,
    data: {
      pendingOrders: pendingOrdersQuery.data,
      portfolios: portfoliosQuery.data.portfolioById,
    },
  };
};
