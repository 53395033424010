import React from 'react';

import { TextShortBold } from '@/components/Typography';

interface Props {
  percent: number;
}

export const ReturnInPercentText: React.FC<Props> = ({ percent }) => {
  return <TextShortBold>{`${percent.toFixed(2)} %`}</TextShortBold>;
};
