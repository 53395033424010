import { Order } from '@/api/order/models/Order';
import { useGetOrderByIdQuery } from '@/api/order/orderApi';
import { useConsumeQueryParameter } from '@/util/useConsumeQueryParameter';

interface Response {
  existingOrder: Order | undefined;
  signUrl: string | undefined;
  existingOrderIsPending: boolean;
}

/** Fetch existing order from orderId if provided as query parameter. */
export const useExistingOrder = (): Response => {
  const orderId = useConsumeQueryParameter('orderId');

  const { data, isError, isFetching } = useGetOrderByIdQuery(
    { orderId },
    { skip: !orderId },
  );

  if (isError) {
    throw new Error(`Could not fetch order with ID [${orderId}].`);
  }

  return {
    existingOrder: data?.order,
    signUrl: data?.signUrl,
    existingOrderIsPending: isFetching,
  };
};
