import without from 'lodash/without';
import { useState } from 'react';

export const useCheckedFiles = (): [
  string[],
  (fileId: string, add: boolean) => void,
] => {
  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);

  const addRemoveFile = (fileId: string, add: boolean): void => {
    if (add) {
      setCheckedFiles([...checkedFiles, fileId]);
    } else {
      // Remove the specified file ID from the array.
      const withoutFile = without(checkedFiles, fileId);
      setCheckedFiles([...withoutFile]);
    }
  };

  return [checkedFiles, addRemoveFile];
};
