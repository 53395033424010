import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { DonutChart, DonutChartData } from '@/components/Charts';
import { Paragraph } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Unit } from '@/types/Unit';

interface Props {
  greenBond: EsgCategory;
  solutionCompanies: EsgCategory;
  solutionCompaniesEqualOpportunities: EsgCategory | undefined;
  solutionCompaniesRenewableEnergy: EsgCategory | undefined;
  solutionCompaniesCircularEconomy: EsgCategory | undefined;
  solutionCompaniesSmartCities: EsgCategory | undefined;
  positionLegendBottom?: boolean;
}

export const EsgSolutionsChart: React.FC<Props> = ({
  greenBond,
  solutionCompanies,
  solutionCompaniesEqualOpportunities,
  solutionCompaniesRenewableEnergy,
  solutionCompaniesCircularEconomy,
  solutionCompaniesSmartCities,
  positionLegendBottom,
}) => {
  const texts = useFms();

  const donutChartData: DonutChartData = [];

  if (solutionCompaniesEqualOpportunities) {
    donutChartData.push([
      texts.pages.esg.solutions.companyEqualOpportunities,
      solutionCompaniesEqualOpportunities.kpi.value,
    ]);
  }

  if (solutionCompaniesRenewableEnergy) {
    donutChartData.push([
      texts.pages.esg.solutions.companyRenewableEnergy,
      solutionCompaniesRenewableEnergy.kpi.value,
    ]);
  }

  if (solutionCompaniesCircularEconomy) {
    donutChartData.push([
      texts.pages.esg.solutions.companyCircularEconomy,
      solutionCompaniesCircularEconomy.kpi.value,
    ]);
  }

  if (solutionCompaniesSmartCities) {
    donutChartData.push([
      texts.pages.esg.solutions.companySmartCities,
      solutionCompaniesSmartCities.kpi.value,
    ]);
  }
  return (
    <>
      <Paragraph>
        {fmsWithTemplate(texts.pages.esg.solutions.overview, {
          sustainablePercentage: solutionCompanies.kpi.value.toString(),
          greenPercentage: greenBond.kpi.value.toString(),
        })}
      </Paragraph>

      <DonutChart
        data={donutChartData}
        size={200}
        unit={Unit.Percent}
        positionLegendBottom={positionLegendBottom}
      />
    </>
  );
};
