import React from 'react';

import { Permission } from '@/api/permissions/models/Permission';
import { PresentationLayout } from '@/pages/Presentation/PresentationLayout';
import { PresentationMenuPage } from '@/pages/Presentation/PresentationMenuPage';
import { PresentationPage } from '@/pages/Presentation/PresentationPage';
import { ReportPrintPage } from '@/pages/Presentation/ReportPrintPage';

import { presentationScreenRoutes } from './presentationScreenRoutes';
import { ConnectRoute } from './types/ConnectRoute';

export const presentationPageRoute: ConnectRoute = {
  id: 'presentation-page',
  element: <PresentationLayout />,
  path: '/:organizationCmId/reports/presentation',
  permissions: [Permission.ConnectRead, Permission.ReportsRead],
  children: [
    {
      element: <PresentationMenuPage />,
      index: true,
    },
    {
      element: <ReportPrintPage />,
      path: '/:organizationCmId/reports/presentation/print',
    },
    {
      element: <PresentationPage />,
      id: 'screens',
      path: '/:organizationCmId/reports/presentation/screen',
      children: presentationScreenRoutes,
    },
  ],
};
