import Checkbox, { Option } from '@storeblocks/checkbox';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useAddFundsModalContext } from '../AddFundsModalContext';

interface Props {
  isin: string;
}

export const CheckboxCell: React.FC<Props> = ({ isin }) => {
  const { selectedIsins } = useAddFundsModalContext();

  const [value, setValue] = useState(selectedIsins.has(isin));

  const handleChanged = (checked: boolean): void => {
    setValue(checked);

    if (selectedIsins.has(isin)) {
      selectedIsins.delete(isin);
    } else {
      selectedIsins.add(isin);
    }
  };

  const options: Option[] = [
    {
      id: `select-fund-option-${isin}`,
      label: '',
      value: 'selected',
    },
  ];

  const id = `select-fund-${isin}`;

  return (
    // The checkbox does not properly align horizontally
    // since it has display inline-flex. Therefore, we wrap it
    // in a div that has display flex to make it align properly.
    <Flex>
      <Checkbox
        id={id}
        name={id}
        options={options}
        value={value}
        onChange={(event) => handleChanged(event.target.checked)}
      />
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
`;
