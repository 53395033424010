import groupBy from 'lodash/groupBy';

import { CurrencyCode } from '@/types/CurrencyCode';

import { FundHoldingsDto } from '../dtos';
import { FundHoldings, FundHoldingsMap } from '../models';
import { Holdings } from '../models/Holdings';
import { byFundKey } from './byFundKey';
import { flattenHoldings } from './flattenHoldings';

export const transformFundHoldingsResponse = (
  dtos: Array<FundHoldingsDto>,
): Holdings => {
  const byPortfolioId: FundHoldingsMap = dtos.reduce(
    (accumulatedPortfolios, portfolio) => {
      const holdings: FundHoldings[] = flattenHoldings(portfolio);

      // Create a map where the key is a combination of account ID and ISIN.
      // This is due to that an ISIN can be present in multiple accounts.
      const byFund = holdings.reduce((acc, h) => {
        acc[byFundKey(h.accountId, h.isin)] = h;
        return acc;
      }, {});

      const byCurrency = groupBy(
        holdings,
        (h) => h.currency,
      ) as unknown as Record<CurrencyCode, FundHoldings[]>;

      const hasMultipleCurrencies = Object.keys(byCurrency).length > 1;

      const map: FundHoldingsMap = {
        ...accumulatedPortfolios,
        [portfolio.portfolioShortName]: {
          portfolioShortName: portfolio.portfolioShortName,
          holdings,
          byFund,
          byCurrency,
          hasMultipleCurrencies,
        },
      };

      return map;
    },
    {},
  );

  const all = dtos.flatMap((dto) => flattenHoldings(dto));

  return {
    byPortfolioId,
    all,
  };
};
