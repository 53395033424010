import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { EsgInsufficientDataAlert } from '../../components/EsgInsufficientDataAlert';
import { EsgScoreGauge } from './EsgScoreGauge';

interface Props {
  esgScore: EsgCategory | undefined;
}

export const EsgScore: React.FC<Props> = ({ esgScore }) => {
  const texts = useFms();

  if (!esgScore) {
    return <EsgInsufficientDataAlert />;
  }

  return (
    <WithGap>
      <Paragraph>{texts.pages.esg.esgScore.description}</Paragraph>

      <EsgScoreGauge esgScore={esgScore}></EsgScoreGauge>
    </WithGap>
  );
};
