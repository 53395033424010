import { AxiosInstance } from 'axios';

import { apiClientCreator } from '@/api/axios/apiClientCreator';
import { fileDownload } from '@/util/FileDownload';

export async function downloadFiles(
  url: string,
  fileIds: Array<string>,
): Promise<void | AxiosInstance> {
  const params = {
    fileIds,
    lang: 'en',
  };

  return apiClientCreator({ useKeycloakToken: true, customTimeout: 50000 })
    .post(url, params, {
      responseType: 'blob',
    })
    .then((response) =>
      fileDownload(response.data, response.headers['file-name']),
    );
}

export const documentsDownloadUrl = (): string =>
  `${window.config.connectBaseApiUrl}/api/documents/download`;
