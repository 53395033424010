import { IHubDirectoryDto } from '../dtos/IHubDirectoryDto';
import { IHubDirectory } from '../models/IHubDirectory';
import { getFriendlyName } from './getFriendlyName';

export const transformIHubDirectoriesForOrganizationReply = (
  reply: Array<IHubDirectoryDto>,
): Array<IHubDirectory> => {
  return reply.map((dto) => {
    return {
      id: dto.id,
      name: dto.name,
      friendlyName: getFriendlyName(dto.name),
    };
  });
};
