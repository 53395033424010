import Fuse from 'fuse.js';

import { Fund } from '@/api/funds/models';

/**
 * Regex used to match if a string looks like an ISIN.
 *
 * The [a-zA-Z]{2} matches exactly two alphabetic characters (uppercase or lowercase).
 * The \d+ matches one or more digits.
 *
 * Note that we don't match for exact length of the ISIN, as we only want to know if
 * the string looks like an ISIN.
 */
const isIsinRegex = /^[a-zA-Z]{2}\d+$/;

export const fuzzySearchFunds = (query: string, funds: Fund[]): string[] => {
  // Has the user entered a string that looks like an ISIN?
  const isIsinQuery = isIsinRegex.test(query);

  const fuse = new Fuse(funds, {
    keys: ['name', 'isin'],

    // Make the most relevant result be on top.
    shouldSort: true,

    // Make the search more restrictive as not to show results that is not
    // relevant.
    threshold: 0.3,

    // Enables the use of unix-like search commands.
    useExtendedSearch: isIsinQuery,
  });

  const results = isIsinQuery
    ? // When searching for ISINs, we want to match the beginning of the string
      // to make it easier for the user to find the correct fund.
      fuse.search(`^${query}`) // Prefix-exact-match search.
    : // Use fuzzy search for all other search terms.
      fuse.search(query);

  return results.map((result) => result.item.isin);
};
