import { format, parseISO } from 'date-fns';
import React from 'react';

import { Esg } from '@/api/esg/models/Esg';
import { H1, H2, Hint, Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { EsgCoverageChart } from '@/pages/Esg/widgets/EsgCoverage/EsgCoverageChart';
import { WithPortfolioGroupEsgData } from '@/pages/Esg/WithPortfolioGroupEsgData';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { AvoidPageBreaksInside } from '../components/AvoidPageBreakInside';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { usePresentationContext } from '../PresentationContext';

export const EsgCoverageScreen: React.FC = () => {
  const texts = useFms();
  const dateFormat = useUserDateFormat();

  const { globalFilter } = usePresentationContext();

  return (
    <A4WidthForPrint>
      <WithGap data-test="esg-coverage-screen">
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.esg.coverage}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>

        <Paragraph>{texts.pages.esg.coverage.description}</Paragraph>

        <WithGap gap="48">
          {globalFilter.portfolioGroups.map((portfolioGroup) => (
            <AvoidPageBreaksInside key={portfolioGroup.id}>
              <WithGap gap="16">
                <H2>{`${portfolioGroup.id} - ${portfolioGroup.name}`}</H2>

                <WithPortfolioGroupEsgData
                  portfolioGroupId={portfolioGroup.id}
                  key={portfolioGroup.id}
                  render={(esgData: Esg) => (
                    <>
                      <Hint>
                        {fmsWithTemplate(texts.common.lastUpdated, {
                          date: format(
                            parseISO(esgData.updatedDate),
                            dateFormat,
                          ),
                        })}
                      </Hint>

                      <EsgCoverageChart
                        carbonIntensities={esgData.carbonIntensities}
                        esgScore={esgData.esgScore}
                      />
                    </>
                  )}
                />
              </WithGap>
            </AvoidPageBreaksInside>
          ))}
        </WithGap>
      </WithGap>
    </A4WidthForPrint>
  );
};
