import LinkList from '@storeblocks/link-list';
import React, { FC, ReactElement } from 'react';
import { LinkProps, Navigate } from 'react-router-dom';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useCurrentUserInfo } from '@/auth/hooks';
import { hasAccessToPath } from '@/routes/utils/hasAccessToPath';

interface Props {
  links: Array<ReactElement<LinkProps>>;
}

export const InternalLinkList: FC<Props> = ({ links }) => {
  const userInfo = useCurrentUserInfo();
  const organizationCmId = useOrganizationCmId();

  const visibleLinks = links.filter((link) =>
    hasAccessToPath(link.props.to as string, userInfo),
  );

  if (visibleLinks.length === 0) {
    return <Navigate to={`/${organizationCmId}/not-found`} />;
  }

  return <LinkList>{visibleLinks.map((link) => link)}</LinkList>;
};
