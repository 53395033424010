import cookies from 'js-cookie';

import { unsafeConvertStringToEnumWithWarning } from '@/util/unsafeConvertStringToEnumWithWarning';

import { UserType } from '../types';

export const getUserTypeFromCookie = (): UserType | undefined => {
  const cookieUserType = cookies.get('development-user-type');
  return unsafeConvertStringToEnumWithWarning(cookieUserType, UserType);
};
