import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import { styled } from 'styled-components';

import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import StorebrandLogo from '@/assets/logo/storebrand.svg';
import { Divider } from '@/components/Divider';
import { FullPageLoadingIndicator } from '@/components/FullPageLoadingIndicator';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { appConfig } from '@/constants/config';
import { Language } from '@/i18n/Language';

import { getAuth } from './Auth';

/**
 * Page to display if the user fails or cancels the authentication.
 * This allows the user to re-trigger the authentication process or
 * contact support if they are experiencing issues.
 */
export const NotAuthenticatedPage: React.FC = () => {
  // We are unable to fetch the language for the user as they are not authenticated.
  // We therefore fetch the texts for the page in the default language.
  const { data: texts } = useFmsTextsQuery(Language.Norwegian);

  if (!texts) {
    return <FullPageLoadingIndicator />;
  }

  return (
    <Container data-test="not-authenticated-page">
      <Center>
        <LogoContainer>
          <StorebrandLogo height={20} />
        </LogoContainer>

        <MainContent>
          <PageHeading header={texts.pages.notAuthenticated.title} />
          <Divider margin="8" />

          <ContentRow>
            <Column $span={6}>
              <WithGap gap="48">
                <Ingress>
                  {texts.pages.notAuthenticated.contactSupport.part1}{' '}
                  <a href={`mailto:${texts.error.siosEmail}`}>
                    {texts.error.siosEmail}
                  </a>{' '}
                  {texts.pages.notAuthenticated.contactSupport.part2}
                </Ingress>

                <WithGap direction="row">
                  <Button
                    as="a"
                    href="https://www.storebrand.no"
                    iconLeft="arrow-left"
                    variant="text"
                  >
                    {texts.pages.notAuthenticated.backToStorebrand}
                  </Button>
                  <Button
                    onClick={() => getAuth().initialize()}
                    iconLeft="login"
                  >
                    {texts.pages.notAuthenticated.login}
                  </Button>
                </WithGap>
              </WithGap>
            </Column>

            <IllustrationColumn $span={6}>
              <Illustration name="profitable-sustainability" height={300} />
            </IllustrationColumn>
          </ContentRow>
        </MainContent>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--color-background);
  border-top: 3px solid var(--color-brand);
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  padding: 26px 0;
`;

const Ingress = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

const ContentRow = styled(Row)`
  margin-top: 8px;
`;

const IllustrationColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;
