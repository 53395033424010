import parseISO from 'date-fns/parseISO';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { OrderDto } from './dtos/OrderListReply';
import { Order, OrderStatus, OrderType, ValueType } from './models/Order';

export const transformOrderListReply = (dto: OrderDto): Order => {
  const order: Order = {
    orderId: dto.orderId.toString(),
    createdBy: dto.createdBy,
    status: convertStringToEnum(dto.status, OrderStatus),
    type: convertStringToEnum(dto.type, OrderType),
    createdDate: parseISO(dto.createdDate),
    documentReference: dto.documentReference,
    portfolioId: dto.portfolioId,
    portfolioName: dto.portfolioName,

    orderLines: dto.orderLines.map((orderLine) => ({
      isin: orderLine.isin,
      fundName: orderLine.fundName,
      value: orderLine.value,
      valueType: convertStringToEnum(orderLine.valueType, ValueType),
      type: convertStringToEnum(orderLine.type, OrderType),
      currency: convertStringToEnum(orderLine.currency, CurrencyCode),
      status: convertStringToEnum(orderLine.status, OrderStatus),
    })),

    signatories: dto.signatories.map((signatory) => ({
      name: signatory.userName,
      signedDate: signatory.signedDate
        ? parseISO(signatory.signedDate)
        : undefined,
      signUrl: signatory.signUrl,
    })),
  };

  return order;
};
