import React, { FC } from 'react';

import { ContactForm } from '@/components/ContactForm/ContactForm';
import { Column, Row } from '@/components/Grid';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { useContactFormSource } from '../../hooks/useContactFormSource';
import { OrderForm } from './components/OrderForm';

export const OrderDetails: FC = () => {
  const { orders } = useFms();
  const contactFormSource = useContactFormSource();

  return (
    <WithGap>
      <WithGap gap="24">
        <Row>
          <Column $span={9}>
            <ContactForm source={contactFormSource} />
          </Column>
        </Row>
        <H2 data-test="order-details-title">
          {orders.form.steps.orderDetails.title}
        </H2>
      </WithGap>

      <OrderForm />
    </WithGap>
  );
};
