import { useMemo } from 'react';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { Portfolio, usePortfoliosQuery } from '@/api/portfolios';

export const useFundPortfolios = (orderType: OrderType): Array<Portfolio> => {
  const portfoliosQuery = usePortfoliosQuery();
  const fundHoldingsQuery = useFetchFundHoldingsQuery();

  return useMemo(() => {
    const fundPortfolios =
      portfoliosQuery.data?.portfolios.filter(
        (portfolio) => portfolio.isFundPortfolio,
      ) || [];

    const portfolioIdsWithHolding = Object.values(
      fundHoldingsQuery.data?.byPortfolioId || [],
    )
      .filter((portfolio) => portfolio.holdings.length > 0)
      .map((portfolio) => portfolio.portfolioShortName);

    switch (orderType) {
      case OrderType.Redemption:
        return fundPortfolios.filter((portfolio) =>
          // Redemption can only be done on portfolios where the user has funds to sell.
          portfolioIdsWithHolding.includes(portfolio.id),
        );

      case OrderType.Subscription:
        // Subscriptions can be done on all portfolios that is a fund portfolio.
        return fundPortfolios;

      case OrderType.Switch:
        return fundPortfolios.filter((portfolio) =>
          // Switch can only be done on portfolios where the user has funds to reinvest.
          portfolioIdsWithHolding.includes(portfolio.id),
        );

      default:
        throw new Error(
          `Unable to filter portfolios for order type ${orderType}.`,
        );
    }
  }, [orderType, portfoliosQuery.data, fundHoldingsQuery.data?.byPortfolioId]);
};
