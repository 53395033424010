import * as Yup from 'yup';

import { FMSTexts } from '@/types/fms';

import { ConfirmFormValues } from '../types/ConfirmFormValues';

export const validationSchema = (
  texts: FMSTexts,
): Yup.SchemaOf<ConfirmFormValues> =>
  Yup.object({
    confirmInformationIsCorrect: Yup.boolean()
      .isTrue(texts.orders.form.errors.missingConfirmation)
      .required(),
  });
