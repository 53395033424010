import Button from '@storeblocks/button';
import InlineAlert from '@storeblocks/inline-alert';
import React from 'react';

import { useFms } from '@/hooks/useFms';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { downloadFile } from '@/util/downloadFile';

interface Props {
  orderId: string;
  disabled: boolean;
}

export const DownloadOrderDocumentButton: React.FC<Props> = ({
  orderId,
  disabled,
}) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const startDownload = async (): Promise<void> => {
    const url = `${window.config.connectBaseApiUrl}/api/order-list/document/${orderId}`;
    return await downloadFile(url);
  };

  const [handleDownloadClick, isDownloading, isError] =
    useHandleAsyncOperation(startDownload);

  return (
    <div>
      <div>
        <Button
          variant="text"
          iconLeft="pdf"
          onClick={handleDownloadClick}
          disabled={disabled || isDownloading}
          loading={isDownloading}
          data-trackid={getTrackId(
            'download-order-document',
            TrackingElement.Button,
          )}
          data-test="download-order-document"
        >
          {texts.pages.orderList.downloadOrderDocument}
        </Button>
      </div>

      {isError && (
        <InlineAlert
          variant="error"
          title={texts.error.description.unexpected}
          data-test="download-order-document-error"
        />
      )}
    </div>
  );
};
