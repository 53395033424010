import Button from '@storeblocks/button';
import Table, { IColumn, IRow } from '@storeblocks/table';
import format from 'date-fns/format';
import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useOrderListQuery } from '@/api/orderList/orderListApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { InternalLink } from '@/components/InternalLink';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { defaultDateFormat } from '@/i18n/hooks/useUserDateFormat';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { orderStatusToFmsText } from './utils/orderStatusToFmsText';
import { orderTypeToFmsText } from './utils/orderTypeToFmsText';

interface Props {
  portfolioIds: string[];
}

export const OngoingOrdersWidget: React.FC<Props> = ({ portfolioIds }) => {
  const organizationCmId = useOrganizationCmId();
  const orderListQuery = useOrderListQuery();
  const getTrackId = useGetTrackId();
  const texts = useFms();

  if (isNotReady(orderListQuery, { skipNoData: true })) {
    return <QueryState query={orderListQuery} />;
  }

  const orders = orderListQuery.data.filter((order) =>
    portfolioIds.includes(order.portfolioId),
  );

  if (orders.length === 0) {
    // Don't show the widget if there are no orders.
    return null;
  }

  const rows: IRow[] = orders.map((order) => {
    return {
      date: format(order.createdDate, defaultDateFormat),
      createdBy: order.createdBy,
      orderType: orderTypeToFmsText(order.type, texts),
      status: orderStatusToFmsText(order.status, texts),
      reactKey: order.orderId,
    };
  });

  const columns: IColumn[] = [
    {
      key: 'date',
      name: texts.pages.orderList.table.date,
      dataType: 'text',
      style: {
        width: '100px',
      },
    },
    {
      key: 'createdBy',
      name: texts.pages.orderList.table.createdBy,
      dataType: 'text',
    },
    {
      key: 'orderType',
      name: texts.pages.orderList.table.orderType,
      dataType: 'text',
    },
    {
      key: 'status',
      name: texts.pages.orderList.table.status,
      dataType: 'text',
    },
  ];

  return (
    <WithGap gap="32" data-test="latest-orders-widget">
      <H2>{texts.pages.orderList.pendingOrder.title}</H2>
      <Table columns={columns} data={rows} />
      <div>
        <Button
          variant="text"
          iconRight="arrow-right"
          as={InternalLink}
          to={`/${organizationCmId}/trading/orders`}
          data-trackid={getTrackId(
            'go-to-pending-orders',
            TrackingElement.Link,
          )}
        >
          {texts.pages.orderList.goToOrderOverview.title}
        </Button>
      </div>
    </WithGap>
  );
};
