import { EsgScore } from '@/api/esg/models/EsgScore';

/** Calculate the reference percentage of a ESG category as compared to the kpi. */
export const calculateEsgReferenceKpiPercentage = (
  kpi: EsgScore,
  referenceKpi: EsgScore,
): number => {
  return referenceKpi.value < kpi.value
    ? (100 * referenceKpi.value) / kpi.value
    : 100;
};
