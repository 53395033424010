import Button from '@storeblocks/button';
import React, { FC } from 'react';
import styled from 'styled-components';

import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  disabled?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  alignRight?: boolean;
  actionContext: string;
  id?: string;
}

export const EditAndDeleteButtons: FC<Props> = ({
  disabled = false,
  onEdit,
  onDelete,
  alignRight = false,
  actionContext,
  id,
}) => {
  const getTrackId = useGetTrackId();

  return (
    <Wrapper alignRight={alignRight}>
      <StyledButton
        variant="icon"
        disabled={disabled}
        onClick={disabled ? undefined : onEdit}
        iconLeft="edit"
        m="12px"
        data-trackid={getTrackId(
          `edit-${actionContext}`,
          TrackingElement.Button,
        )}
        data-test={`edit-button-${id}`}
      />
      <StyledButton
        disabled={disabled}
        variant="icon"
        iconLeft="delete"
        onClick={disabled ? undefined : onDelete}
        m="12px"
        data-trackid={getTrackId(
          `delete-${actionContext}`,
          TrackingElement.Button,
        )}
        data-test={`delete-button-${id}`}
      />
    </Wrapper>
  );
};

interface WrapperProps {
  alignRight: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-flow: row;
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
  margin: -12px 0px;
`;

const StyledButton = styled(Button)`
  color: var(--color-on-background);
`;
