import Alert from '@storeblocks/alert';
import { Option } from '@storeblocks/checkbox';
import InlineAlert from '@storeblocks/inline-alert';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';

import { useAssignableRolesQuery } from '@/api/assignableRoles/assignableRolesApi';
import { FormCheckbox } from '@/components/Form/FormCheckbox';
import { FormRow } from '@/components/Form/FormRow';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { padding } from '@/styles/variables';
import { FMSTexts } from '@/types/fms';

const CheckboxGroup: FC<PropsWithChildren> = ({ children }) => {
  const texts: FMSTexts = useFms();

  return (
    <Wrapper>
      <Label htmlFor="roles">{texts.roles}</Label>
      <Content>{children}</Content>
    </Wrapper>
  );
};

interface RolesSelectionProps {
  error: string;
  displayDisclaimer?: boolean;
}

export const RolesSelection: FC<RolesSelectionProps> = ({
  error,
  displayDisclaimer = false,
}) => {
  const assignableRolesQuery = useAssignableRolesQuery();

  const texts: FMSTexts = useFms();

  const roles = useMemo<Array<Option>>(() => {
    if (!assignableRolesQuery.data) {
      return [];
    }

    return assignableRolesQuery.data.map((role) => ({
      id: role,
      value: role,
      label: role,
    }));
  }, [assignableRolesQuery.data]);

  if (assignableRolesQuery.isError) {
    return (
      <CheckboxGroup>
        <Alert variant="error" title={texts.admin.user.roles.fetchError} />
      </CheckboxGroup>
    );
  }

  if (error) {
    return (
      <CheckboxGroup>
        <InlineAlert title={error} variant="error" />
      </CheckboxGroup>
    );
  }

  return (
    <CheckboxGroup>
      <Show when={displayDisclaimer}>
        <Disclaimer>{texts.admin.user.roles.disclaimer}</Disclaimer>
      </Show>

      <FormRow>
        <FormCheckbox name="roles" options={roles} />
      </FormRow>
    </CheckboxGroup>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  margin: ${padding.base}px;
`;

const Content = styled.div`
  margin-top: 8px;
`;

const Label = styled.label`
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
`;

const Disclaimer = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
`;
