import React, { FC } from 'react';

import { DocumentFile } from '@/api/documentArchive/models/DocumentFile';
import { EditAndDeleteButtons } from '@/components/EditAndDeleteButtons';

import { useDocumentsArchiveContext } from './DocumentsArchiveContext';

interface Props {
  document: DocumentFile;
}

export const FileOperationsColumn: FC<Props> = ({ document }) => {
  const { setDocumentFileToEdit, setDocumentFileToDelete } =
    useDocumentsArchiveContext();

  return (
    <EditAndDeleteButtons
      onEdit={() => setDocumentFileToEdit(document)}
      onDelete={() => setDocumentFileToDelete(document)}
      alignRight
      actionContext="file"
    />
  );
};
