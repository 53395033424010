import { IRow } from '@storeblocks/table';
import React from 'react';

import { Fund } from '@/api/funds/models';

import { CheckboxCell } from '../components/CheckboxCell';
import { FundCell } from '../components/FundCell';
import { MinimumSubscriptionAmountCell } from '../components/MinimumSubscriptionAmountCell';

export const createFundListRow = (fund: Fund, hasHoldings: boolean): IRow => {
  // Use the initial minimum subscription amount if the fund does not have any holdings.
  const minimumSubscriptionAmount = hasHoldings
    ? fund.minimumSubsequentSubscriptionAmount
    : fund.minimumInitialSubscriptionAmount;

  return {
    select: {
      displayContent: <CheckboxCell isin={fund.isin} />,
      sortContent: fund.isin,
    },
    fund: {
      displayContent: <FundCell fundName={fund.name} isin={fund.isin} />,
      sortContent: fund.isin,
    },
    minimumSubscriptionAmount: {
      displayContent: (
        <MinimumSubscriptionAmountCell
          amount={minimumSubscriptionAmount}
          currency={fund.baseCurrency}
        />
      ),
      sortContent: minimumSubscriptionAmount,
    },
  };
};
