import { Order } from '@/api/order/models/Order';
import { OrderType } from '@/api/order/models/OrderType';

export const verifyCorrectOrderType = (
  order: Order,
  orderType: OrderType,
): void => {
  if (order.orderType !== orderType) {
    throw new Error(
      `The order is not of the type it should be. The order is [${order.orderType}] but should be [${orderType}].`,
    );
  }
};
