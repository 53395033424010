import { getPermissionsFromStore } from '@/api/permissions/utils/getPermissionsFromStore';
import { UserInfo } from '@/auth/types/UserInfo';
import { ConnectRoute } from '@/routes/types/ConnectRoute';
import { hasPermission } from '@/util/hasPermission';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';

/** Returns true if the user has access to the route. */
export const hasAccessToRoute = (
  route: ConnectRoute,
  userInfo: UserInfo,
): boolean => {
  if (route.isBeta && !isBetaFeaturesOn()) {
    return false;
  }

  if (route.userType && route.userType !== userInfo.userType) {
    return false;
  }

  if (!route.permissions || route.permissions.length === 0) {
    return true;
  }

  const userPermissions = getPermissionsFromStore();

  return hasPermission(
    { mustHave: 'all', permissions: route.permissions },
    userPermissions,
  );
};
