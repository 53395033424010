import Button from '@storeblocks/button';
import { Icon } from '@storeblocks/icon';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Column, Row } from '@/components/Grid';
import { H2 } from '@/components/Typography/H2';
import { SurfaceWidget } from '@/components/Widget';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const FundsSustainabilityBannerWidget: FC = () => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <SurfaceWidget>
      <Row>
        <Column $span={7}>
          <Content>
            <H2>{texts.news.assets.sustainability.title}</H2>

            <Button
              as="a"
              variant="outlined"
              href={texts.news.assets.sustainability.link}
              rel="noreferrer"
              target="_blank"
              data-trackid={getTrackId(
                'go-to-news-assets-sustainability',
                TrackingElement.Link,
              )}
            >
              {texts.news.assets.sustainability.buttonLabel}
            </Button>
          </Content>
        </Column>

        <Column $span={5}>
          <Illustration name="sustainability" />
        </Column>
      </Row>
    </SurfaceWidget>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 40px;
`;

const Illustration = styled(Icon)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & svg {
    width: 115px;
    height: 115px;
  }
`;
