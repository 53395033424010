import type { Option } from '@storeblocks/select';
import { useMemo } from 'react';

import { useFetchCountriesQuery } from '@/api/countries/countriesApi';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';

/**
 * Stable reference to an empty array if it is used in the
 * dependency array to other hooks.
 */
const emptyArray: Option[] = [];

/**
 * Create select options with country names based on the user's
 * language. The value of the option is the country's Alpha-3 country code.
 *
 * @returns An array of countries as options for
 * the Storeblocks select component.
 */
export const useCountriesAsSelectOption = (): Option[] => {
  const { data: countries } = useFetchCountriesQuery();
  const { language } = useUserSettings();

  return useMemo(() => {
    if (!countries) {
      return emptyArray;
    }

    return countries
      .map((country) => ({
        value: country.alpha3,
        label: country.nameByLanguage[language],
      }))
      .sort((a, b) => a.label.localeCompare(b.label, language));
  }, [countries, language]);
};
