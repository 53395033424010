import * as Sentry from '@sentry/react';
import { AxiosInstance } from 'axios';

import { getAuth } from '@/auth/Auth';

import { AuthorizationTokenError } from './AuthorizationTokenError';

export const addKeycloakTokenInterceptor = (
  axiosInstance: AxiosInstance,
): void => {
  axiosInstance.interceptors.request.use(async (config) => {
    try {
      const token = await getAuth().getToken();

      config.headers.set('Authorization', `Bearer ${token}`);

      return config;
    } catch (error) {
      // Something went wrong getting the authorization token (JWT bearer token).
      // Most likely the token has expired.

      const message = tryParseErrorMessage(error);

      const tokenError = new AuthorizationTokenError(
        { exceptionMessage: message },
        error,
      );

      // Log to console for developers to see more easily.
      console.error(tokenError);

      // Log to Sentry to keep tally of how often this happens.
      Sentry.captureException(tokenError);

      // Return a promise that waits for 4 seconds before throwing the error.
      // Most likely, the token has expired. In that case, the Keycloak library
      // will automatically redirect the user to the Keycloak login page. Thus, give
      // it time to redirect to the login page before doing anything else.
      // But if it doesn't redirect, we set a timeout to throw the error
      // to notify the user that something has gone wrong.
      return new Promise(() => {
        setTimeout(() => {
          throw tokenError;
        }, 4000);
      });
    }
  });
};

function tryParseErrorMessage(error: unknown): string {
  // Using try/catch to avoid throwing an error in the error handler.
  try {
    return (error as Error)?.message ?? JSON.stringify(error);
  } catch (error) {
    return 'Failed to parse the error message';
  }
}
