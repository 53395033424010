import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderPlacementType } from '@/api/order/models/OrderPlacementType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { getValueFromPercent } from '@/pages/Order/utils/getValueFromPercent';

import { RedemptionPayment } from '../types/Payment';
import { RedemptionFundState } from '../types/RedemptionFundState';
import { findPaymentForRedemptionFund } from './findPaymentForRedemptionFund';

export const mapRedemptionFundStatesToOrderPlacements = (
  funds: Array<RedemptionFundState>,
  payments: Array<RedemptionPayment>,
): Array<OrderPlacement> => {
  return funds
    .filter((fund) => fund.value > 0)
    .map((fund) => {
      // When in the context of switch, there will be no payments.
      const payment: RedemptionPayment | undefined =
        payments.length > 0
          ? findPaymentForRedemptionFund(fund.currency, payments)
          : undefined;

      const orderPlacement = {
        accountId: fund.accountId,
        isin: fund.isin,
        fundName: fund.name,
        currency: fund.currency,
        placementType: OrderPlacementType.Sell,

        // When the user has selected to sell all their holdings then the
        // sell value must be in units.
        value: getFundValue(fund),
        valueType: getFundValueType(fund),

        payment: undefined,
        externalAccountNumber: undefined,
      };

      // When in the context of switch, there will be no payments.
      // Thus check if there is a payment before setting the payment.
      if (payment) {
        orderPlacement.payment = payment.paymentPlacement;

        orderPlacement.externalAccountNumber =
          payment.paymentPlacement === OrderPaymentType.BankAccount
            ? payment.bankAccountNumber
            : payment.portfolioBankAccountNumber;
      }

      return orderPlacement;
    });
};

const isSellAll = (fund: RedemptionFundState): boolean => {
  return (
    fund.sellAll ||
    (fund.valueType === OrderValueType.Percent && fund.value === 100)
  );
};

const getFundValue = (fund: RedemptionFundState): number => {
  if (isSellAll(fund)) {
    return fund.units;
  }

  if (fund.valueType === OrderValueType.Percent) {
    return getValueFromPercent(fund.value, fund.units);
  }

  return fund.value;
};

const getFundValueType = (fund: RedemptionFundState): OrderValueType => {
  if (isSellAll(fund) || fund.valueType === OrderValueType.Percent) {
    return OrderValueType.Units;
  }

  return fund.valueType;
};
