import { useMemo } from 'react';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { usePaymentOptionsQuery } from '@/api/paymentOptions/paymentOptionsApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { useSignatoriesForOrganizationQuery } from '@/api/signing-right/signingRightApi';
import { ApiError } from '@/api/types/ApiError';
export const useFetchRequiredFormData = (): boolean => {
  const portfoliosQuery = usePortfoliosQuery();

  const { isSuccess: fundListIsSuccess } = useFundListQuery();

  const { isSuccess: fundHoldingsIsSuccess, error: fundHoldingsError } =
    useFetchFundHoldingsQuery();

  const signatoriesQuery = useSignatoriesForOrganizationQuery();

  const paymentOptionsQuery = usePaymentOptionsQuery();

  // Throw error if some required data was not successfully fetched.
  // The order form must have this data to before it can be used.
  // This will be caught in the error boundary and an error message will
  // be displayed to the user.

  if (portfoliosQuery.isError) {
    throw new Error(
      `Failed to fetch portfolios: ${
        (portfoliosQuery.error as ApiError).message
      }`,
    );
  }

  if (fundHoldingsError) {
    throw new Error(
      `Failed to fetch fund holdings: ${
        (fundHoldingsError as ApiError).message
      }`,
    );
  }

  if (signatoriesQuery.isError) {
    throw new Error(
      `Failed to fetch signatories: ${
        (signatoriesQuery.error as ApiError).message
      }`,
    );
  }

  if (paymentOptionsQuery.isError) {
    throw new Error(
      `Failed to fetch payment options: ${(paymentOptionsQuery.error as ApiError).message}`,
    );
  }

  return useMemo(
    () =>
      fundListIsSuccess &&
      fundHoldingsIsSuccess &&
      portfoliosQuery.isSuccess &&
      signatoriesQuery.isSuccess &&
      paymentOptionsQuery.isSuccess,
    [
      fundListIsSuccess,
      fundHoldingsIsSuccess,
      portfoliosQuery.isSuccess,
      signatoriesQuery.isSuccess,
      paymentOptionsQuery.isSuccess,
    ],
  );
};
