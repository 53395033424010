import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { WithGap } from '@/components/WithGap';

interface Props extends PropsWithChildren {
  label: string;
}

export const Filter: FC<Props> = ({ label, children }) => {
  return (
    <WithGap gap="16">
      <Label>{label}</Label>
      {children}
    </WithGap>
  );
};

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;
