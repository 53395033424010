import { OrderType } from '@/api/order/models/OrderType';

const orderTypeMap = {
  [OrderType.Subscription]: 'subscription',
  [OrderType.Redemption]: 'redemption',
  [OrderType.Switch]: 'switch',
};

export const createLinkToOrder = (
  orderId: number,
  organizationCmId: string,
  orderType: OrderType,
): string =>
  `/${organizationCmId}/trading/${orderTypeMap[orderType]}?orderId=${orderId}`;
