import { EsgReply } from '../dtos/EsgReply';
import { Esg } from '../models/Esg';
import {
  transformEsgCategoryDto,
  transformEsgCurrencyCategoryDto,
} from './transformEsgDto';

export const transformOrganizationEsgReply = (dto: EsgReply): Esg => {
  return {
    customerId: dto.customerId,
    reportDate: dto.reportDate,
    carbonIntensities: dto.carbonIntensities.map((score) =>
      transformEsgCurrencyCategoryDto(score),
    ),
    esgScore: transformEsgCategoryDto(dto.esgScore),
    solutionCompanies: transformEsgCategoryDto(dto.solutionCompanies),
    solutionCompaniesCircularEconomy: transformEsgCategoryDto(
      dto.solutionCompaniesCircularEconomy,
    ),
    solutionCompaniesEqualOpportunities: transformEsgCategoryDto(
      dto.solutionCompaniesEqualOpportunities,
    ),
    solutionCompaniesRenewableEnergy: transformEsgCategoryDto(
      dto.solutionCompaniesRenewableEnergy,
    ),
    solutionCompaniesSmartCities: transformEsgCategoryDto(
      dto.solutionCompaniesSmartCities,
    ),
    euTaxonomyAlignment:
      dto.euTaxonomyAlignment &&
      transformEsgCategoryDto(dto.euTaxonomyAlignment),
    euTaxonomyEligibility:
      dto.euTaxonomyEligibility &&
      transformEsgCategoryDto(dto.euTaxonomyEligibility),
    greenBond: transformEsgCategoryDto(dto.greenBond),
    traceId: dto.traceId,
    updatedDate: dto.updatedDate,
  };
};
