import styled, { css } from 'styled-components';

interface Props {
  noBorder?: boolean;
}

export const TableRowCell = styled.td<Props>`
  padding: 16px 0;
  vertical-align: text-top;
  line-height: 24px;

  border-bottom: ${({ noBorder }) =>
    noBorder ? 'none' : '1px solid var(--color-border)'};

  ${({ noBorder }) =>
    noBorder &&
    css`
      padding-bottom: 0;
    `}
`;
