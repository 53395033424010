import { Option } from '@storeblocks/chip/lib/group';
import uniqBy from 'lodash/uniqBy';

import { Fund } from '@/api/funds/models';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { CustomFilterValue } from '../CustomFilterValue';
import { findLabelForBrand } from '../utils/findLabelForBrand';

export const useGetBrandsOptions = (funds: Fund[]): Option[] => {
  const texts = useFms();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId('select-brand', TrackingElement.FilterChip);

  const options: Option[] = uniqBy(funds, (fund) => fund.brand).map((fund) => ({
    id: `brand-${fund.brand}-chip`,
    label: findLabelForBrand(fund.brand, texts),
    value: fund.brand,
    inputProps: {
      'data-trackid': trackId,
      'data-trackid-selected-filter': fund.brand,
      'data-trackid-source': 'funds-brand',
      'data-trackid-type': 'filter-chips',
    },
  }));

  // Add an "All" option to be the first filter option.
  options.unshift({
    id: 'brand-all-chip',
    label: texts.orders.form.addFundModal.filter.brand.option.all,
    value: CustomFilterValue.All,
    inputProps: {
      'data-trackid': trackId,
      'data-trackid-selected-filter': CustomFilterValue.All,
      'data-trackid-source': 'funds-brand',
      'data-trackid-type': 'filter-chips',
    },
  });

  return options;
};
