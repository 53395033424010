import React, { PropsWithChildren } from 'react';

import { usePermissionsQuery } from '@/api/permissions/permissionsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';

import { FullPageLoadingIndicator } from '../FullPageLoadingIndicator';

/**
 * Fetch permissions from the backend.
 */
export const Permissions: React.FC<PropsWithChildren> = ({ children }) => {
  const organizationCmId = useOrganizationCmId();
  const { isLoading, error } = usePermissionsQuery(undefined, {
    skip: !organizationCmId,
  });

  if (isLoading) {
    return <FullPageLoadingIndicator />;
  }

  if (error) {
    throw new Error('Could not fetch permissions.', error);
  }

  return <>{children}</>;
};
