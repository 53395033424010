import React, { useEffect } from 'react';

import { useConfirmOrderSignedMutation } from '@/api/order/orderApi';

import { OrderCompleted } from '../../components/OrderCompleted';
import { useLogOrderSigned } from '../../utils/useLogOrderSigned';

interface Props {
  orderId: string;
  isOwner: boolean;
  hasCosigners: boolean;
}

export const Signed: React.FC<Props> = ({ orderId, isOwner, hasCosigners }) => {
  useLogOrderSigned({
    orderId,
    hasCosigners,
    signedBy: isOwner ? 'owner' : 'cosigner',
  });

  const [confirmOrderSigned] = useConfirmOrderSignedMutation();

  useEffect(() => {
    confirmOrderSigned({ orderId });
  }, [orderId]);

  return <OrderCompleted isOwner={isOwner} hasCosigners={hasCosigners} />;
};
