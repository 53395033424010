import { HoldingsSummary } from '../models/HoldingsSummary';
import { PortfolioGroupHoldings } from '../models/PortfolioGroupHoldings';
import { mergeSumsByCurrency } from './mergeSumsByCurrency';

/**
 * Summarize the given multiple portfolio group holdings.
 * The summary contains summarized amounts mapped by currency.
 */
export const getMultiPortfolioGroupHoldingsSummary = (
  multiPortfolioGroupHoldings: Array<PortfolioGroupHoldings>,
): HoldingsSummary => {
  return {
    cleanValue: mergeSumsByCurrency(
      multiPortfolioGroupHoldings.map(
        (holdings) => holdings.summary.cleanValue,
      ),
    ),
    dirtyValue: mergeSumsByCurrency(
      multiPortfolioGroupHoldings.map(
        (holdings) => holdings.summary.dirtyValue,
      ),
    ),
    dirtyValueInclPayRec: mergeSumsByCurrency(
      multiPortfolioGroupHoldings.map(
        (holdings) => holdings.summary.dirtyValueInclPayRec,
      ),
    ),
    bookValue: mergeSumsByCurrency(
      multiPortfolioGroupHoldings.map((holdings) => holdings.summary.bookValue),
    ),
    accruedInterest: mergeSumsByCurrency(
      multiPortfolioGroupHoldings.map(
        (holdings) => holdings.summary.accruedInterest,
      ),
    ),
    unrealizedPLValue: mergeSumsByCurrency(
      multiPortfolioGroupHoldings.map(
        (holdings) => holdings.summary.unrealizedPLValue,
      ),
    ),
  };
};
