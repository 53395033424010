import React from 'react';
import styled from 'styled-components';

import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { h4Style } from '@/components/Typography';
import { appConfig } from '@/constants/config';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

export const OrganizationNameBanner: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const { organizationName } = useOrganizationName(organizationCmId);
  const organizationsQuery = useOrganizationsQuery();

  if (!organizationsQuery.data || organizationsQuery.data.length === 1) {
    // Don't show the banner if the user has only one organization.
    return null;
  }

  return (
    <Banner>
      <Center data-test="organization-name-banner">
        <Text>{prettifyOrganizationName(organizationName)}</Text>
      </Center>
    </Banner>
  );
};

const Banner = styled.div`
  background-color: var(--color-surface);
  padding: 4px 0;
  margin-bottom: 12px;
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Text = styled.div`
  ${h4Style}
  padding: 0 24px;
`;
