/**
 * Replace templates in FMS with actual values.
 *
 * @param text FMS texts.
 * @param args An object where the key is the template variable and the value
 * is the text to replace the template. The templates are written as
 * `{{myVariable}}`.
 * @returns Text with the templates replaced with the actual values.
 *
 * @example
 * From FMS -> "Total value in {{currency}}"
 * fmsWithTemplate(texts.redemption.total, { currency: 'NOK' })
 */
export function fmsWithTemplate(
  text: string,
  args: Record<string, string>,
): string {
  return Object.keys(args).reduce((translated, key) => {
    return translated.replace(`{{${key}}}`, args[key]);
  }, text);
}
