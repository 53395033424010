import { getOrganizationCmIdFromUrl } from './getOrganizationCmIdFromUrl';

/**
 * Try to find the organization CMID from the URL.
 *
 * The URL does not contain the organization CMID when the user is not logged in to a specific organization.
 *
 * @returns The organization CMID from the URL, if found. Otherwise, undefined.
 */
export const findOrganizationCmIdFromUrl = (): string | undefined => {
  try {
    return getOrganizationCmIdFromUrl();
  } catch (error) {
    return undefined;
  }
};
