import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Pending } from '@/components/Pending';
import { Show } from '@/components/Show';
import { FundOrder } from '@/pages/Order/types/FundOrder';

import { useExistingOrder } from './hooks/useExistingOrder';
import { useFetchRequiredFormData } from './hooks/useFetchRequiredFormData';
import { getFundOrderFromOrder } from './utils/getFundOrderFromOrder';

interface OrderPageState {
  orderType: OrderType;
  currentOrder: FundOrder | undefined;
  setCurrentOrder: (fundOrder: FundOrder | undefined) => void;
  /** Order is already existing, only needs signing. */
  orderForSigning: { id: string; signUrl: string } | undefined;
  cancel: () => void;
  reset: () => void;
}

const OrderPageContext = React.createContext<OrderPageState | null>(null);

interface Props extends PropsWithChildren {
  orderType: OrderType;
}

export const OrderPageProvider: FC<Props> = ({ orderType, children }) => {
  const [currentOrder, setCurrentOrder] = useState<FundOrder | undefined>();

  const { existingOrder, signUrl, existingOrderIsPending } = useExistingOrder();

  const organizationCmId = useOrganizationCmId();

  const navigate = useNavigate();

  const hasRequiredData = useFetchRequiredFormData();
  const { data: fundList } = useFundListQuery();

  const reset = (): void => {
    setCurrentOrder(undefined);
  };

  const cancel = (): void => {
    navigate(`/${organizationCmId}`);
  };

  useEffect(() => {
    reset();
  }, [orderType]);

  // Set the fund order if there is an existing order.
  useEffect(() => {
    if (existingOrder && fundList) {
      setCurrentOrder(getFundOrderFromOrder(existingOrder, fundList));
    }
  }, [existingOrder, fundList]);

  const contextData = useMemo<OrderPageState>(() => {
    return {
      orderType,
      currentOrder,
      setCurrentOrder,
      orderForSigning: existingOrder && { id: existingOrder.id, signUrl },
      cancel,
      reset,
    };
  }, [orderType, currentOrder, existingOrder, signUrl]);

  return (
    <>
      <Show when={existingOrderIsPending || !hasRequiredData}>
        <Pending center />
      </Show>
      <Show when={hasRequiredData} animate>
        <OrderPageContext.Provider value={contextData}>
          {children}
        </OrderPageContext.Provider>
      </Show>
    </>
  );
};

export const useOrderPageContext = (): OrderPageState => {
  const context = useContext(OrderPageContext);

  if (!context) {
    throw new Error(
      `${useOrderPageContext.name} must be called within a ${OrderPageProvider.name}.`,
    );
  }

  return context;
};
