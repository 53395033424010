import Surface from '@storeblocks/surface';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const DebugSurface: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Surface variant="border">
      <Container>{children}</Container>
    </Surface>
  );
};

const Container = styled.div`
  padding: 16px;
`;
