import { createApi } from '@reduxjs/toolkit/query/react';

import { authorizationApiUrl } from '@/api/utils/authorizationApiUrl';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { AddOrganizationUserDto } from './dtos/AddOrganizationUserDto';
import { EditOrganizationUserDto } from './dtos/EditOrganizationUserDto';
import { OrganizationUserRolesReply } from './dtos/OrganizationUserRolesReply';
import { OrganizationUsersReply } from './dtos/OrganizationUsersReply';
import { OrganizationUser } from './models/OrganizationUser';
import { OrganizationUserRoles } from './models/OrganizationUserRoles';
import { transformOrganizationUsersReply } from './utils/transformOrganizationUsersReply';

export const organizationUsersApi = createApi({
  reducerPath: 'organizationUsersApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    organizationUsers: builder.query<Array<OrganizationUser>, void>({
      query: () => ({
        url: `${authorizationApiUrl()}/organizations/users/v2`,
      }),
      transformResponse: (dto: OrganizationUsersReply) => {
        return transformOrganizationUsersReply(dto);
      },
    }),

    organizationUserRoles: builder.query<OrganizationUserRoles, string>({
      query: (userId) => ({
        url: `${authorizationApiUrl()}/organizations/users/${userId}/roles/v2`,
      }),
      transformResponse: (dto: OrganizationUserRolesReply) => {
        return dto.roles;
      },
    }),

    addOrganizationUser: builder.mutation<void, AddOrganizationUserDto>({
      query: (user) => ({
        url: `${authorizationApiUrl()}/organizations/users`,
        method: 'PUT',
        data: user,
      }),
    }),

    editOrganizationUser: builder.mutation<void, EditOrganizationUserDto>({
      query: (user) => ({
        url: `${authorizationApiUrl()}/organizations/users/edit`,
        method: 'PUT',
        data: user,
      }),
    }),

    deleteOrganizationUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `${authorizationApiUrl()}/organizations/users/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useOrganizationUsersQuery,
  useOrganizationUserRolesQuery,
  useAddOrganizationUserMutation,
  useEditOrganizationUserMutation,
  useDeleteOrganizationUserMutation,
  util,
} = organizationUsersApi;
