import { AssetReturnPeriod } from '@/components/AssetReturnChart';
import { FMSTexts } from '@/types/fms';

export const getAssetReturnPeriodText = (
  period: AssetReturnPeriod,
  texts: FMSTexts,
): string => {
  switch (period) {
    case AssetReturnPeriod.YTD:
      return texts.pages.presentation.filters.assetReturnPeriod.ytd;
    case AssetReturnPeriod.QTD:
      return texts.pages.presentation.filters.assetReturnPeriod.qtd;
    case AssetReturnPeriod.MTD:
      return texts.pages.presentation.filters.assetReturnPeriod.mtd;
    default:
      throw new Error(
        `Unable to find filter text for asset return period [${period}]`,
      );
  }
};
