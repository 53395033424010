import { createApi } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';

import { axiosBaseQuery, RtkBaseQueryResponse } from '../axios/axiosBaseQuery';
import { RequestConfig } from '../axios/axiosRequest';
import { AssetPerformanceDto, PortfolioPerformanceDto } from './dtos';
import { MultiPortfolioReturns } from './models';
import { MultiAssetPerformance } from './models/MultiAssetPerformance';
import {
  mapMultiPortfolioReturns,
  mapPortfolioReturns,
  transformAssetPerformanceResponse,
} from './utils';
import { mapMultiAssetPerformance } from './utils/mapMultiAssetPerformance';

interface PortfolioPerformance {
  portfolioGroups: string[];
  from: string;
  to: string;
}

interface AssetPerformanceArguments {
  portfolioGroups: string[];
  to: string;
}

const portfolioPerformanceUrl = (
  portfolioGroup: string,
  from: string,
  to: string,
): string =>
  `${window.config.connectBaseApiUrl}/api/performancedata/portfolio/search/${portfolioGroup}/${from}/${to}`;

const assetPerformanceUrl = (portfolioGroup: string, to: string): string =>
  `${window.config.connectBaseApiUrl}/api/performancedata/asset/search/${portfolioGroup}/${to}`;

export const multiPortfolioReturnsApi = createApi({
  reducerPath: 'multiPerformanceDataApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    multiplePortfolioPerformance: builder.query<
      MultiPortfolioReturns,
      PortfolioPerformance
    >({
      async queryFn(
        arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ: (
          args: RequestConfig,
        ) => Promise<RtkBaseQueryResponse<PortfolioPerformanceDto[]>>,
      ) {
        const requests = arg.portfolioGroups.map(
          async (portfolioGroupShortName) => {
            const response = await fetchWithBQ({
              url: portfolioPerformanceUrl(
                portfolioGroupShortName,
                arg.from,
                arg.to,
              ),
            });

            if (response.error) {
              return {
                error: response.error,
              };
            }

            if (isEmpty(response.data)) {
              return {
                data: undefined,
              };
            }

            return {
              data: mapPortfolioReturns(portfolioGroupShortName, response.data),
            };
          },
        );

        const responses = await Promise.all(requests);

        const responseWithError = responses.find((response) => response.error);

        if (responseWithError) {
          return {
            error: responseWithError.error,
          };
        }

        const returns = responses
          .filter((response) => !!response.data)
          .map((response) => response.data);

        if (returns.length === 0) {
          return {
            data: undefined,
          };
        }

        return {
          data: mapMultiPortfolioReturns(returns),
        };
      },
    }),
    multipleAssetPerformance: builder.query<
      MultiAssetPerformance | undefined,
      AssetPerformanceArguments
    >({
      async queryFn(
        arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ: (
          args: RequestConfig,
        ) => Promise<RtkBaseQueryResponse<AssetPerformanceDto>>,
      ) {
        const requests = arg.portfolioGroups.map(
          async (portfolioGroupShortName) => {
            const response = await fetchWithBQ({
              url: assetPerformanceUrl(portfolioGroupShortName, arg.to),
            });

            if (response.error) {
              return {
                error: response.error,
              };
            }

            if (isEmpty(response.data)) {
              return {
                data: undefined,
              };
            }

            return {
              data: transformAssetPerformanceResponse(response.data),
            };
          },
        );

        const responses = await Promise.all(requests);

        const responseWithError = responses.find((response) => response.error);

        if (responseWithError) {
          return {
            error: responseWithError.error,
          };
        }

        const assetPerformances = responses
          .filter((response) => !!response.data)
          .map((response) => response.data);

        if (assetPerformances.length === 0) {
          return {
            data: undefined,
          };
        }

        return {
          data: mapMultiAssetPerformance(assetPerformances),
        };
      },
    }),
  }),
});

export const {
  useMultiplePortfolioPerformanceQuery,
  useMultipleAssetPerformanceQuery,
} = multiPortfolioReturnsApi;
