import { useField } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Organization } from '@/api/organizations/models/Organization';
import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { AddSingleItemTable, OnChange } from '@/components/AddSingleItemTable';
import { Item } from '@/components/AddSingleItemTable/Item';
import { FormRow } from '@/components/Form/FormRow';
import { useFms } from '@/hooks/useFms';
import { padding } from '@/styles/variables';
import { FMSTexts } from '@/types/fms';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

export const OrganizationFormSelector: FC = () => {
  const texts: FMSTexts = useFms();
  const [, meta, helpers] = useField<Organization[]>('organizations');

  const [existingItems, setExistingItems] = useState<Item[]>([]);
  const [selectListItems, setSelectListItems] = useState<Item[]>([]);

  const organizationsQuery = useOrganizationsQuery();

  useEffect(() => {
    if (!meta.initialValue) {
      return;
    }

    const existingDirectories = meta.initialValue.map((item) => ({
      id: item.organizationId,
      label: item.organizationName,
    }));

    const selectableDirectories = organizationsQuery.data.map((item) => ({
      id: item.organizationId,
      label: prettifyOrganizationName(item.organizationName),
    }));

    setExistingItems(existingDirectories);
    setSelectListItems(selectableDirectories);
  }, [organizationsQuery.data, meta.initialValue]);

  const handleChange = (items: OnChange): void => {
    const organizations = items.all.map((item) =>
      organizationsQuery.data.find((org) => org.organizationId === item.id),
    );

    helpers.setValue(organizations);
  };

  return (
    <SelectGroup>
      <FormRow>
        <AddSingleItemTable
          title={texts.adminModal.organizations}
          placeholderText={texts.admin.user.chooseOrganization}
          addNewItemButtonText={texts.admin.user.addToOrg}
          existingItems={existingItems}
          selectListItems={selectListItems}
          onChange={handleChange}
          // Todo: SAMIK-1071 - Implement when we have a backend.
          disableRemoveButton
        />
      </FormRow>
    </SelectGroup>
  );
};

const SelectGroup = styled.section`
  margin: ${padding.base}px;
`;
