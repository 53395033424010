import React from 'react';

import { AddFundsButton } from '../../AddFundsModal';
import { TotalSubscriptionCell } from '../components/TotalSubscriptionCell';
import { SubscriptionFundRow } from '../types/SubscriptionFundRow';

export const createSubscriptionSummaryRow = (
  hideHoldings: boolean,
): SubscriptionFundRow => {
  return {
    fund: {
      displayContent: <AddFundsButton />,
      sortContent: '',
    },
    ...(!hideHoldings && {
      holdings: {
        displayContent: null,
        sortContent: '',
      },
    }),
    amount: {
      displayContent: <TotalSubscriptionCell />,
      sortContent: '',
    },
  };
};
