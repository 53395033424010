import { SystemIcon } from '@storeblocks/icon';
import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from 'styled-components';

import { InternalLink } from '@/components/InternalLink';
import { TextShort } from '@/components/Typography';
import { TrackingElement } from '@/tracking';

import { menuItemStyle } from '../styles/menuItemStyle';

interface Props extends ComponentPropsWithoutRef<typeof InternalLink> {
  routeId: string;
  text: string;
}

export const MenuItemLink = forwardRef<HTMLAnchorElement, Props>(
  ({ routeId, text, to, onClick, ...rest }, ref) => {
    return (
      <li>
        <StyledLink
          to={to}
          onClick={onClick}
          data-test={`menu-item-${routeId}`}
          // We can't use `getTrackId` here because it adds the page name to the
          // track-id. We don't want the track-ids in the menu to change for
          // every page change, therefor we're using `menu` to describe placement.
          data-trackid={`${TrackingElement.Link}_go-to-${routeId}_menu`}
          ref={ref}
          {...rest}
        >
          <TextShort>{text}</TextShort>
          <StyledSystemIcon name="arrow-right" aria-hidden="true" />
        </StyledLink>
      </li>
    );
  },
);

const StyledSystemIcon = styled(SystemIcon)`
  font-size: 18px;
  color: var(--color-on-background);
`;

const StyledLink = styled(InternalLink)`
  ${menuItemStyle}
`;

MenuItemLink.displayName = MenuItemLink.name;
