import { useCurrentRoute } from '@/routes/hooks';

import { TrackingElement } from '../types/TrackingElement';

export const useGetTrackId = (): ((
  action: string,
  element: TrackingElement,
) => string) => {
  const currentRoute = useCurrentRoute();

  return (action: string, element: TrackingElement): string => {
    return `${element}_${action}_${currentRoute.id}`;
  };
};
