/**
 * The Sustainable Finance Disclosure Regulation (SFDR) imposes
 * mandatory ESG disclosure obligations for asset managers and
 * other financial markets participants with substantive
 * provisions of the regulation effective from 10 March 2021.
 */
export enum SfdrArticle {
  Article6 = 'ARTICLE_6',
  Article8 = 'ARTICLE_8',
  Article9 = 'ARTICLE_9',

  /**
   * Funds are currently classified as Article 6, 8 or 9.
   * Use this value if the fund is not yet classified.
   */
  Unknown = 'UNKNOWN',
}
