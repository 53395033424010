import React, { PropsWithChildren } from 'react';

import { QueryState } from '@/api/components/QueryState';
import { usePortfoliosQuery } from '@/api/portfolios';
import { isNotReady } from '@/api/utils/isNotReady';

export const WaitForPortfolioQuery: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const portfoliosQuery = usePortfoliosQuery();

  if (isNotReady(portfoliosQuery)) {
    return <QueryState query={portfoliosQuery} />;
  }

  return <>{children}</>;
};
