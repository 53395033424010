import HighchartsReact from 'highcharts-react-official';
import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';

import { useHighcharts } from '../highcharts';
import { useGaugeOptions } from './useGaugeOptions';

interface Props {
  percent: number;
  description?: string;
  size?: number;
  trackId?: string;
}

/**
 * Highcharts `solidgauge` implementation for displaying a visual representation of a ranged number in a gauge (circular) format.
 * @param {number} [percent] - The value in percent.
 * @param {string} [description] - Renders a descriptive text below the gauge if provided.
 * @param {number} [size=250] - The size of the chart in pixels. Defaults to `250`.
 * @param {string} [trackId] - GTM id for interaction tracking.
 */
export const Gauge: FC<Props> = memo(
  ({ percent, description, size = 250, trackId }: Props) => {
    const highcharts = useHighcharts();

    const options = useGaugeOptions(percent, size);

    return (
      <Container>
        <HighchartsReact
          highcharts={highcharts}
          options={options}
          containerProps={{ 'data-chartid': trackId }}
          immutable
        />
        {description && <DescriptionText>{description}</DescriptionText>}
      </Container>
    );
  },
);

Gauge.displayName = Gauge.name;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DescriptionText = styled(TextShort)`
  color: var(--color-inactive);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
  text-align: center;
`;
