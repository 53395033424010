import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { FMSTexts } from '@/types/fms';

/**
 * Get the text description for the contact us form.
 */
export const getDescriptionFromSource = (
  source: Source,
  texts: FMSTexts,
): string => {
  switch (source) {
    case Source.PortfolioReports:
      return texts.pages.contactUs.header.description.portfolioReports;
    case Source.FundReports:
      return texts.pages.contactUs.header.description.fundReports;
    case Source.OrderSubscription:
      return texts.pages.contactUs.header.description.orderSubscription;
    case Source.OrderRedemption:
      return texts.pages.contactUs.header.description.orderRedemption;
    case Source.OrderSwitch:
      return texts.pages.contactUs.header.description.orderSwitch;
    case Source.SigningRight:
      return texts.pages.contactUs.header.description.signingRight;
    default:
      throw new Error(
        `Missing translation. Could not get description from source: ${source}`,
      );
  }
};
