import { unsafeConvertStringToEnumWithWarning } from '@/util/unsafeConvertStringToEnumWithWarning';

import { FundEsgReply } from '../dtos/FundEsgReply';
import { FundEsg } from '../models/FundEsg';
import { SfdrArticle } from '../models/SfdrArticle';
import {
  transformEsgCategoryDto,
  transformEsgCurrencyCategoryDto,
} from './transformEsgDto';

export const transformFundEsgReply = (dto: FundEsgReply): FundEsg => {
  return {
    isin: dto.isin,
    fundName: dto.fundName,
    updatedDate: dto.updatedDate,
    benchmarkName: dto.benchmarkName,
    sfdr:
      unsafeConvertStringToEnumWithWarning(dto.sfdr, SfdrArticle) ||
      SfdrArticle.Unknown,

    esgScore: dto.esgScore && transformEsgCategoryDto(dto.esgScore),

    solutionCompanies:
      dto.solutionCompanies && transformEsgCategoryDto(dto.solutionCompanies),

    solutionCompaniesCircularEconomy:
      dto.solutionCompaniesCircularEconomy &&
      transformEsgCategoryDto(dto.solutionCompaniesCircularEconomy),

    solutionCompaniesEqualOpportunities:
      dto.solutionCompaniesEqualOpportunities &&
      transformEsgCategoryDto(dto.solutionCompaniesEqualOpportunities),

    solutionCompaniesRenewableEnergy:
      dto.solutionCompaniesRenewableEnergy &&
      transformEsgCategoryDto(dto.solutionCompaniesRenewableEnergy),

    solutionCompaniesSmartCities:
      dto.solutionCompaniesSmartCities &&
      transformEsgCategoryDto(dto.solutionCompaniesSmartCities),

    solutionCompaniesCrossCutting:
      dto.solutionCompaniesCrossCutting &&
      transformEsgCategoryDto(dto.solutionCompaniesCrossCutting),

    greenBond: dto.greenBond && transformEsgCategoryDto(dto.greenBond),

    socialBond: dto.socialBond && transformEsgCategoryDto(dto.socialBond),

    scienceBasedTargetSet:
      dto.scienceBasedTargetSet &&
      transformEsgCategoryDto(dto.scienceBasedTargetSet),

    sustainabilityBond:
      dto.sustainabilityBond && transformEsgCategoryDto(dto.sustainabilityBond),

    sustainabilityLinkedBond:
      dto.sustainabilityLinkedBond &&
      transformEsgCategoryDto(dto.sustainabilityLinkedBond),

    carbonIntensities: dto.carbonIntensities.map((score) =>
      transformEsgCurrencyCategoryDto(score),
    ),
  };
};
