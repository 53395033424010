import React, { FC, PropsWithChildren, useContext, useState } from 'react';

interface AddFundsModalContextType {
  selectedIsins: Set<string>;
  resetContextData(): void;
}

const AddFundsModalContext =
  React.createContext<AddFundsModalContextType | null>(null);

export const AddFundsModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedIsins] = useState<Set<string>>(new Set());

  const [contextData] = useState<AddFundsModalContextType>({
    selectedIsins,
    resetContextData: () => {
      selectedIsins.clear();
    },
  });

  return (
    <AddFundsModalContext.Provider value={contextData}>
      {children}
    </AddFundsModalContext.Provider>
  );
};

export const useAddFundsModalContext = (): AddFundsModalContextType => {
  const context = useContext(AddFundsModalContext);

  if (!context) {
    throw new Error(
      `${useAddFundsModalContext.name} must be called within a ${AddFundsModalProvider.name}.`,
    );
  }

  return context;
};
