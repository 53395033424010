import React from 'react';
import styled from 'styled-components';

import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderPlacementType } from '@/api/order/models/OrderPlacementType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';

import { FundRow } from './FundRow';
import { MainHeaderRow } from './MainHeaderRow';
import { PortfolioRow } from './PortfolioRow';
import { TotalAmountRows } from './TotalAmountRows';

interface Props {
  portfolioShortName: string;
  transferToPortfolioNumber: string;
  orderPlacements: Array<OrderPlacement>;
}

export const SwitchOrderDetailsTables: React.FC<Props> = ({
  portfolioShortName,
  transferToPortfolioNumber,
  orderPlacements,
}) => {
  const texts = useFms();

  const fromPlacements = orderPlacements.filter(
    (placement) => placement.placementType === OrderPlacementType.Sell,
  );

  const toPlacements = orderPlacements.filter(
    (placement) => placement.placementType === OrderPlacementType.Buy,
  );

  const showFromTotal = fromPlacements.every(
    (orderPlacement) => orderPlacement.valueType === OrderValueType.Amount,
  );

  const showToTotal = toPlacements.every(
    (orderPlacement) => orderPlacement.valueType === OrderValueType.Amount,
  );

  return (
    <>
      <StyledTable
        cellSpacing="0"
        cellPadding="0"
        data-test="switch-redemption"
      >
        <thead>
          <MainHeaderRow title={texts.orders.form.switch.from.title} />
        </thead>
        <tbody>
          <PortfolioRow portfolioShortName={portfolioShortName} />
          <FundRow
            portfolioShortName={portfolioShortName}
            orderPlacements={fromPlacements}
          />
          <Show when={showFromTotal}>
            <TotalAmountRows orderPlacements={fromPlacements} />
          </Show>
        </tbody>
      </StyledTable>

      <StyledTable
        cellSpacing="0"
        cellPadding="0"
        data-test="switch-subscription"
      >
        <thead>
          <MainHeaderRow title={texts.orders.form.switch.to.title} />
        </thead>
        <tbody>
          <PortfolioRow
            portfolioShortName={transferToPortfolioNumber ?? portfolioShortName}
          />
          <FundRow
            orderPlacements={toPlacements}
            portfolioShortName={portfolioShortName}
          />
          <Show when={showToTotal}>
            <TotalAmountRows orderPlacements={toPlacements} />
          </Show>
        </tbody>
      </StyledTable>
    </>
  );
};

const StyledTable = styled.table`
  line-height: 16px;
  width: 100%;
`;
