import Checkbox from '@storeblocks/checkbox';
import InlineAlert from '@storeblocks/inline-alert';
import LoadingIndicator from '@storeblocks/loading-indicator';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { LinkButton } from '@/components/shared/LinkButton';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import { FileIconWrapper } from '@/pages/Archives/archivePagesStyledComponents';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { stripFilename } from '@/util/stripFilename';

import { FileTypeIcon } from '../FileTypeIcon/FileTypeIcon';

interface Props {
  filename: string;
  fileId: string;
  fileExtension: string;
  checked: boolean;
  onCheck: (fileId: string, checked: boolean) => void;
  onDownload: (fileId: string) => Promise<unknown>;
}

export const FileNameColumnWithCheckbox: React.FC<Props> = ({
  filename,
  fileId,
  fileExtension,
  checked,
  onCheck,
  onDownload,
}: Props) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const handleCheck = (event: ChangeEvent<HTMLInputElement>): void => {
    onCheck(fileId, event.target.checked);
  };

  const startDownload = async (): Promise<void> => {
    await onDownload(fileId);
  };

  const [handleDownloadClick, isDownloading, isError] =
    useHandleAsyncOperation(startDownload);

  const checkboxOption = {
    id: `file-${fileId}`,
    value: fileId,
    label: '',
  };

  return (
    <Wrapper>
      <Checkbox
        id={`file-download-checkbox-${fileId}`}
        name={`file-download-checkbox-${fileId}`}
        value={checked}
        options={[checkboxOption]}
        onChange={handleCheck}
      />

      <FileIconWrapper key="icon">
        <FileTypeIcon fileExtension={fileExtension} />
      </FileIconWrapper>

      <NameWrapper>
        <Show when={!isDownloading}>
          <StyledLinkButton
            key="anchor"
            onClick={handleDownloadClick}
            data-trackid={getTrackId(
              'download-clicked-file',
              TrackingElement.Link,
            )}
            data-trackid-downloaded-file={stripFilename(filename)}
          >
            {filename}
          </StyledLinkButton>
        </Show>

        <Show when={isError}>
          <StyledInlineAlert
            variant="error"
            title={texts.download.singleFileError}
          />
        </Show>
      </NameWrapper>

      <Show when={isDownloading}>
        <SpinnerWrapper>
          <LoadingIndicator />
        </SpinnerWrapper>
      </Show>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInlineAlert = styled(InlineAlert)`
  padding-bottom: 0;
  padding-left: 0;
  align-items: center;

  & p {
    line-height: 1;
  }

  & svg {
    height: 20px;
    width: 20px;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  margin-left: 0;
  word-break: break-all;
`;

const NameWrapper = styled.div`
  padding: 16px;
`;

const SpinnerWrapper = styled.div`
  justify-content: flex-start;
  align-self: center;
`;
