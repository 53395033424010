import { isEqual, parseISO } from 'date-fns';
import Fuse from 'fuse.js';
import isEmpty from 'lodash/isEmpty';

import { DailyTradeReport } from '@/api/reports/models/DailyTradeReports';

import { SearchParams } from '../components/Toolbar';

/**
 * Filter reports by the specified search parameters.
 *
 * @returns A new array with the filtered result.
 */
export const filterBySearchParams = (
  reports: DailyTradeReport[],
  searchParams: SearchParams,
): DailyTradeReport[] => {
  let searchResults: DailyTradeReport[] = reports;

  // Search after report name.
  if (!isEmpty(searchParams.query)) {
    const fuse = new Fuse(searchResults, {
      keys: ['displayName'],
    });

    // Use fuzzy search on the report name.
    searchResults = fuse
      .search(searchParams.query)
      .map((result) => result.item);
  }

  // Search after report date.
  if (!isEmpty(searchParams.dateISO)) {
    const dateResults = searchResults.filter((report) =>
      isEqual(parseISO(report.reportDateISO), parseISO(searchParams.dateISO)),
    );

    searchResults = dateResults;
  }

  return searchResults;
};
