import Checkbox from '@storeblocks/checkbox';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Divider } from '@/components/Divider';
import { useFms } from '@/hooks/useFms';
import { presentationScreenRoutes } from '@/routes/presentationScreenRoutes';

import {
  selectAllOptionValue,
  useScreenOptions,
} from './hooks/useScreenOptions';
import { usePresentationContext } from './PresentationContext';

export const ScreenCheckboxes: React.FC = () => {
  const texts = useFms();

  const options = useScreenOptions();

  const { selectedScreens, setSelectedScreens } = usePresentationContext();
  const [selectAll, setSelectAll] = useState<boolean>(
    presentationScreenRoutes.every((route) =>
      selectedScreens.map((screen) => screen.id).includes(route.id),
    ),
  );

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const selectedScreenIds = event.target.checked
      ? options.map((option) => option.value)
      : [];

    // Set all the selected screen routes.
    setSelectedScreens(
      presentationScreenRoutes.filter((route) =>
        selectedScreenIds.includes(route.id),
      ),
    );

    setSelectAll(event.target.checked);
  };

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    values: Array<string>,
  ): void => {
    const selectedScreenIds = values.filter(
      (option) => option !== selectAllOptionValue,
    );

    // Set all the selected screen routes.
    setSelectedScreens(
      presentationScreenRoutes.filter((route) =>
        selectedScreenIds.includes(route.id),
      ),
    );

    // Set select all to true if all screens are selected
    // or to false if not all screens are selected.
    setSelectAll(
      presentationScreenRoutes.every((route) =>
        selectedScreenIds.includes(route.id),
      ),
    );
  };

  return (
    <>
      <StyledCheckbox
        id="select-all-screens"
        name="select-all-screens"
        onChange={handleSelectAllChange}
        options={[
          {
            id: 'select-all-screens',
            value: selectAllOptionValue,
            label: texts.pages.presentation.selectAll,
          },
        ]}
        value={selectAll}
      />
      <Divider margin="8" />
      <Checkbox
        id="choose-screens"
        hint={texts.pages.presentation.chooseElements.description}
        name="choose-screens"
        onChange={handleChange}
        options={options}
        value={selectedScreens.map((screen) => screen.id)}
      />
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  font-weight: 600;
`;
