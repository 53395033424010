import sortBy from 'lodash/sortBy';

import { FundHoldings } from '@/api/fundHoldings/models';
import { Fund } from '@/api/funds/models';
import { OrderValueType } from '@/api/order/models/OrderValueType';

import { RedemptionFundState } from '../types/RedemptionFundState';
import { value95percent } from './value95percent';

export const mapHoldingsToRedemptionFundState = (
  fundsByIsin: Record<string, Fund | undefined>,
  fundHoldings: Array<FundHoldings>,
  valueType: OrderValueType,
): RedemptionFundState[] => {
  // Map funds that has holdings data.
  const fundStates = fundHoldings.map((holdings) => {
    const fund = fundsByIsin[holdings.isin];

    if (!fund) {
      throw new Error(
        `Customer has holdings in [${holdings.isin}], but the fund is not present in the fund list.`,
      );
    }

    const fundState: RedemptionFundState = {
      accountId: holdings.accountId,
      isin: holdings.isin,
      name: holdings.name,
      currency: holdings.currency,
      brand: fund.brand,
      isClosed: fund.closedForRedemption,

      units: holdings.units,
      nav: holdings.nav,
      // Remove decimals from the market value as they are not needed in the UI.
      marketValue: Math.trunc(holdings.marketValue),
      marketValue95percent: value95percent(holdings.marketValue),

      value: 0,
      valueType,

      minimumRedemptionAmount: fund.minimumRedemptionAmount,

      sellAll: false,
    };

    return fundState;
  });

  return sortBy(fundStates, (fund) => fund.name);
};
