import React from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';

import { Navbar } from './components/Navbar';
import { WaitForPortfolioDetails } from './components/WaitForPortfolioDetails';
import { PresentationProvider } from './PresentationContext';

export const PresentationLayout: React.FC = () => {
  return (
    <Container>
      <Navbar />
      <WaitForPortfolioDetails>
        <PresentationProvider>
          <Outlet />
        </PresentationProvider>
      </WaitForPortfolioDetails>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
