import React from 'react';

import { PortfolioGroupHoldings } from '@/api/portfolioGroupHoldings/models/PortfolioGroupHoldings';
import { Locale } from '@/i18n/locale';

import { CellWithTitle } from '../components/CellWithTitle';
import { SumByCurrencyCell } from '../components/SumByCurrencyCell';
import { HoldingsRow } from '../types/HoldingsRow';
import { mapToPortfolioHoldingsRow } from './mapToPortfolioHoldingsRow';

export const mapToPortfolioGroupHoldingsRow = (
  portfolioGroupHoldings: PortfolioGroupHoldings,
  locale: Locale,
): HoldingsRow => {
  return {
    name: {
      sortContent: '',
      displayContent: (
        <CellWithTitle value={portfolioGroupHoldings.portfolioGroupId} />
      ),
    },
    shortName: {
      sortContent: '',
      displayContent: null,
    },
    instrumentType: {
      sortContent: '',
      displayContent: null,
    },
    nominal: '',
    cleanPrice: {
      sortContent: '',
      displayContent: null,
    },
    bookValue: {
      sortContent: portfolioGroupHoldings.portfolioGroupId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioGroupHoldings.summary.bookValue}
        />
      ),
    },
    cleanValue: {
      sortContent: portfolioGroupHoldings.portfolioGroupId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioGroupHoldings.summary.cleanValue}
        />
      ),
    },
    accruedInterest: {
      sortContent: portfolioGroupHoldings.portfolioGroupId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioGroupHoldings.summary.accruedInterest}
        />
      ),
    },
    unrealizedPLValue: {
      sortContent: portfolioGroupHoldings.portfolioGroupId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioGroupHoldings.summary.unrealizedPLValue}
        />
      ),
    },
    subRows: portfolioGroupHoldings.holdings.map((portfolioGroupHoldings) =>
      mapToPortfolioHoldingsRow(portfolioGroupHoldings, locale),
    ),
  };
};
