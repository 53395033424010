import { format } from 'date-fns';
import React from 'react';

import { TextShort } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';
import { useUserDateFnsLocale, useUserDateFormat } from '@/i18n/hooks';

interface Props {
  fromDate: Date;
  toDate: Date;
}

export const DateIntervalText: React.FC<Props> = ({ fromDate, toDate }) => {
  const texts = useFms();

  const dateFnsLocale = useUserDateFnsLocale();
  const dateFormat = useUserDateFormat('short');

  const fromDateFormatted = format(fromDate, dateFormat, {
    locale: dateFnsLocale,
  });
  const toDateFormatted = format(toDate, dateFormat, {
    locale: dateFnsLocale,
  });

  return (
    <TextShort>{`${texts.widgets.portfolio.summary.title} ${fromDateFormatted} - ${toDateFormatted}`}</TextShort>
  );
};
