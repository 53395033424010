import * as Yup from 'yup';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { Brand } from '@/types/Brand';
import { CurrencyCode } from '@/types/CurrencyCode';

import { FundState } from '../../types/FundState';

export const baseFundStateValidationSchema: Yup.SchemaOf<FundState> =
  Yup.object({
    name: Yup.string().required(),
    isin: Yup.string().required(),
    marketValue: Yup.number().required(),
    currency: Yup.mixed<CurrencyCode>()
      .oneOf(Object.values(CurrencyCode))
      .required(),
    brand: Yup.mixed<Brand>().oneOf(Object.values(Brand)).required(),
    accountId: Yup.string().required(),
    units: Yup.number().required(),
    nav: Yup.number().required(),
    isClosed: Yup.boolean().required(),
    valueType: Yup.mixed<OrderValueType>()
      .oneOf(Object.values(OrderValueType))
      .required(),
    value: Yup.number().required(),
  });
