import Button from '@storeblocks/button';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const GoToDashboardButton: React.FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();

  const getTrackId = useGetTrackId();

  return (
    <Button
      variant="text"
      iconRight="arrow-right"
      type="button"
      to={`/${organizationCmId}`}
      as={InternalLink}
      data-trackid={getTrackId('go-to-dashboard', TrackingElement.Link)}
    >
      {texts.orders.signatureRight.goToDashboard}
    </Button>
  );
};
