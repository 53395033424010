import React from 'react';

import { FundName } from '../../FundName';
import { Isin } from '../../Isin';

interface Props {
  fundName: string;
  isin: string;
}

export const FundCell: React.FC<Props> = ({ fundName, isin }) => {
  return (
    <>
      <FundName data-test="fund-name">{fundName}</FundName>
      <Isin data-test="isin">{isin}</Isin>
    </>
  );
};
