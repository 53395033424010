import * as Yup from 'yup';
import { SchemaLike } from 'yup/lib/types';

import { FMSTexts } from '@/types/fms';
import { nameof } from '@/util/nameof';

import { FormValues } from '../types/FormValues';
import { PoliticallyExposedPerson } from '../types/PoliticallyExposedPerson';

// No need for translation on this error message as it should only
// happen under development.
const notAlpha3CodeErrorMessage = 'Value is not an ISO 3166 Alpha-3 code.';

const whenPepIsTrue = (schema: SchemaLike): Yup.StringSchema =>
  Yup.string().when(nameof<FormValues>('pep'), {
    is: PoliticallyExposedPerson.Yes,
    then: schema,
  });

export const validationSchema = (texts: FMSTexts): Yup.SchemaOf<FormValues> =>
  Yup.object({
    pep: Yup.mixed<PoliticallyExposedPerson>()
      .oneOf(
        [PoliticallyExposedPerson.Yes, PoliticallyExposedPerson.No],
        texts.form.emptyMessage,
      )
      .required(texts.form.emptyMessage),

    pepReason: whenPepIsTrue(
      Yup.string().required(texts.form.emptyMessage),
    ).optional(),

    citizenship: Yup.string()
      /* Verify that the string behaves as an ISO Alpha-3 country code. */
      .min(3, notAlpha3CodeErrorMessage)
      .max(3, notAlpha3CodeErrorMessage)
      .required(texts.form.emptyMessage),

    dualCitizenship: Yup.string()
      /* Verify that the string behaves as an ISO Alpha-3 country code. */
      .min(3, notAlpha3CodeErrorMessage)
      .max(3, notAlpha3CodeErrorMessage)
      .notOneOf(
        // Make sure that the dual citizenship is not the same as the primary citizenship.
        [Yup.ref(nameof<FormValues>('citizenship'))],
        texts.orders.signatureRight.pep.dualCitizenship
          .identicalToPrimaryCitizenshipError,
      )
      .optional(),

    confirmed: Yup.boolean().isTrue(texts.form.emptyMessage).required(),
  });
