import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';

import { CenteredPageContent } from '../CenteredPageContent';
import { TextShort } from '../Typography';
import { CopyrightSymbol } from './CopyrightSymbol';
import { FooterBanner } from './FooterBanner';

export const Footer: React.FC = () => {
  const texts = useFms();
  return (
    <>
      <FooterBanner />
      <StyledCenteredPageContent>
        <Link to={texts.footer.privacyLink.url}>
          {texts.footer.privacyLink.text}
        </Link>
        <div>
          <CopyrightSymbol /> <TextShort>Storebrand</TextShort>
        </div>
      </StyledCenteredPageContent>
    </>
  );
};

const StyledCenteredPageContent = styled(CenteredPageContent)`
  padding: 24px;
`;
