import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import { LoadingIndicator } from '@storeblocks/loading-indicator';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';

import { useDeleteDocumentFileMutation } from '@/api/documentArchive/documentArchiveApi';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useDocumentsArchiveContext } from '@/pages/Archives/DocumentsArchive/DocumentsArchiveContext';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const DeleteFileModal: FC = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const texts = useFms();
  const getTrackId = useGetTrackId();

  const [deleteDocumentFile, deleteDocumentFileRequest] =
    useDeleteDocumentFileMutation();

  const { documentFileToDelete, setDocumentFileToDelete } =
    useDocumentsArchiveContext();

  useEffect(() => setShowLoader(false), [documentFileToDelete?.id]);

  useKeyPress(() => {
    onCancelClick();
  }, ['Escape']);

  useKeyPress(
    () => {
      onDeleteClick();
    },
    ['Enter'],
    [documentFileToDelete?.id],
  );

  const onDeleteClick = async (): Promise<void> => {
    if (documentFileToDelete) {
      deleteDocumentFileRequest.reset();
      setShowLoader(true);
      await deleteDocumentFile(documentFileToDelete.id);
      setDocumentFileToDelete(null);
    }
  };

  const onCancelClick = (): void => {
    setDocumentFileToDelete(null);
  };

  useEffect(() => {
    if (documentFileToDelete) {
      deleteDocumentFileRequest.reset();
    }
  }, [documentFileToDelete?.id]);

  return (
    <Modal
      trigger={undefined}
      open={!!documentFileToDelete}
      onClose={onCancelClick}
    >
      <Heading>
        {`${texts.deleteFileModal.confirmDelete} "${documentFileToDelete?.name}"?`}
      </Heading>
      <Content data-test="delete-file-modal">
        {texts.uploadFileModal.cannotBeUndone}
        {deleteDocumentFileRequest.isError && (
          <Alert
            variant="error"
            title={(deleteDocumentFileRequest.error as AxiosError)?.message}
          />
        )}
      </Content>
      <Footer>
        <Button
          onClick={onDeleteClick}
          disabled={showLoader}
          id="delete-file-button"
          key="delete-file-button"
          mr="8px"
          data-trackid={getTrackId(
            'delete:confirm-delete-modal',
            TrackingElement.Button,
          )}
        >
          <Show when={!showLoader}>{texts.iHub.delete.button}</Show>

          <Show when={showLoader}>
            <LoadingIndicator />
          </Show>
        </Button>
        <Button
          variant="outlined"
          onClick={onCancelClick}
          disabled={showLoader}
          key="cancel-modal"
          data-trackid={getTrackId(
            'cancel:confirm-delete-modal',
            TrackingElement.Button,
          )}
        >
          {texts.admin.cancel}
        </Button>
      </Footer>
    </Modal>
  );
};
