import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';

/**
 * Source of the contact us form.
 *
 * This enum is also used in the backend, thus the values must be kept in sync.
 */
export enum Source {
  PortfolioReports = 'PORTFOLIO_REPORTS',
  FundReports = 'FUND_REPORTS',
  OrderSubscription = 'ORDER_SUBSCRIPTION',
  OrderRedemption = 'ORDER_REDEMPTION',
  OrderSwitch = 'ORDER_SWITCH',
  SigningRight = 'SIGNING_RIGHT',
}

export const contactUsFormApi = createApi({
  reducerPath: 'contactUsFormApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    contactUsForm: builder.mutation<
      void,
      {
        phoneNumber: string;
        email: string;
        message: string;
        source: Source;
      }
    >({
      query: (data) => ({
        url: `${window.config.connectBaseApiUrl}/api/contact-us`,
        method: 'post',
        data,
      }),
    }),
  }),
});

export const { useContactUsFormMutation } = contactUsFormApi;
