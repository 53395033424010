import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { InternalLinkList } from '@/components/InternalLinkList';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

export const IntermediateSustainabilityPage: FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();

  const links = [
    <InternalLink
      key={`/${organizationCmId}/sustainability/esg`}
      to={`/${organizationCmId}/sustainability/esg`}
      data-test="sustainability-esg-link"
    >
      {texts.menu.links.reports.esg}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/sustainability/engagement`}
      to={`/${organizationCmId}/sustainability/engagement`}
      data-test="sustainability-engagement-link"
    >
      {texts.menu.links.engagement}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/sustainability/voting`}
      to={`/${organizationCmId}/sustainability/voting`}
      data-test="sustainability-voting-link"
    >
      {texts.menu.links.voting}
    </InternalLink>,
  ];

  return (
    <CenteredPageContent data-test="sustainability-page">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability`}>
          {texts.menu.links.sustainability}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.sustainability} />
        <InternalLinkList links={links} />
      </MainContent>
    </CenteredPageContent>
  );
};
