import kebabCase from 'lodash/kebabCase';

/**
 * Make a string URL friendly to be used as a slug in the URL.
 * Converts a string to kebab-case (lowercase with hyphens)
 * and removes all non-alphanumeric characters.
 *
 * @example "Storebrand Global ESG Plus Valutasikret A" -> "storebrand-global-esg-plus-valutasikret-a"
 */
export const toUrlFriendlySlug = (value: string): string =>
  kebabCase(value.replace(/[^a-zA-Z0-9 ]/g, ''));
