import React from 'react';

import { MultiPortfolioGroupHoldings } from '@/api/portfolioGroupHoldings/models/MultiPortfolioGroupHoldings';
import { FMSTexts } from '@/types/fms';

import { CellWithTitle } from '../components/CellWithTitle';
import { SumByCurrencyCell } from '../components/SumByCurrencyCell';
import { HoldingsRow } from '../types/HoldingsRow';

export const mapToPortfolioGroupHoldingsSummaryRow = (
  multiPortfolioGroupHoldings: MultiPortfolioGroupHoldings,
  texts: FMSTexts,
): HoldingsRow => {
  return {
    name: {
      sortContent: '',
      displayContent: (
        <CellWithTitle
          value={texts.components.portfolioHoldingsTable.summary}
        />
      ),
    },
    shortName: {
      sortContent: '',
      displayContent: null,
    },
    instrumentType: {
      sortContent: '',
      displayContent: null,
    },
    nominal: '',
    cleanPrice: {
      sortContent: '',
      displayContent: null,
    },
    bookValue: {
      sortContent: '',
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={multiPortfolioGroupHoldings.summary.bookValue}
        />
      ),
    },
    cleanValue: {
      sortContent: '',
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={multiPortfolioGroupHoldings.summary.cleanValue}
        />
      ),
    },
    accruedInterest: {
      sortContent: '',
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={multiPortfolioGroupHoldings.summary.accruedInterest}
        />
      ),
    },
    unrealizedPLValue: {
      sortContent: '',
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={multiPortfolioGroupHoldings.summary.unrealizedPLValue}
        />
      ),
    },
  };
};
