interface ErrorArgs {
  message: string;
  status: number;
  method: string;
  url: string;
  code?: string;
}

/**
 * HTTP errors from the APIs we use in the frontend.
 */
export class ApiError extends Error {
  public status: number;
  public code?: string;
  public method: string;
  public url: string;
  public originalMessage: string;

  constructor(error: ErrorArgs, cause?: Error) {
    super(
      `${error.message}, code: [${error.code}], status: [${error.status}], HTTP verb: [${error.method}] URL: [${error.url}]`,
      { cause },
    );

    this.originalMessage = error.message;
    this.code = error.code;
    this.status = error.status;
    this.method = error.method;
    this.url = error.url;

    // Maintains proper stack trace for where our error was thrown (only available on V8).
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Error.captureStackTrace) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'API Error';
  }
}
