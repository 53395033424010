import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createLogEvent } from '@/logging/createLogEvent';
import { addLogEvent } from '@/logging/logSlice';
import { AppDispatch } from '@/store';

interface Args {
  orderId: string;
  signedBy: 'owner' | 'cosigner';
  hasCosigners: boolean;
}

export const useLogOrderSigned = (args: Args): void => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(
    () => {
      dispatch(
        addLogEvent(
          createLogEvent({
            level: 'info',
            type: 'http',
            description: 'Order signed successfully.',
            data: {
              // Use toString() on the orderId to avoid the JSON deserializer on
              // the backend converting it to a number with decimals, e.g. "394.0".
              orderId: args.orderId.toString(),
              signedBy: args.signedBy,
              orderHasCosigners: args.hasCosigners,
            },
          }),
        ),
      );
    },
    [
      /** Only execute once. */
    ],
  );
};
