import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { getAuth } from './auth/Auth';
import { Authenticated } from './auth/Authenticated';
import { App } from './components/App';
import { HighchartsProvider } from './components/Charts/highcharts';
import { HasUserAccessToConnect } from './components/HasUserAccessToConnect';
import { Permissions } from './components/Permissions/Permissions';
import { ScrollToTopOnNavigation } from './components/ScrollToTopOnNavigation';
import { UnexpectedError } from './components/UnexpectedError';
import { WaitForRequiredAppData } from './components/WaitForRequiredAppData';
import {
  initializeSentry,
  initializeServerConfig,
  initializeStoreblocksAssets,
} from './config';
import { initializeCookieConsent } from './config/cookieConsent/initializeCookieConsent';
import { isCypress } from './config/utils';
import { LocationChangedLogger } from './logging/components/LocationChangedLogger';
import { TrackIdClickedLogger } from './logging/components/TrackIdClickedLogger';
import { initializeSessionId } from './logging/sessionId';
import { getBasename } from './routes/utils/getBasename';
import { store } from './store';
import { GlobalStyle } from './styles/globalStyles';
import { initializeFmsWithBackupTexts } from './testUtils/initializeFmsWithBackupTexts';

export const bootstrap = async (): Promise<void> => {
  await initializeServerConfig();
  initializeSessionId();
  initializeStoreblocksAssets();
  initializeSentry();

  await getAuth().initialize();

  // Initialize the cookie consent popup after the user has been authenticated.
  // Otherwise the popup will be visible for a split second before the user
  // is redirected to the login page.
  initializeCookieConsent();

  // Initialize backup texts if running in Cypress.
  if (isCypress()) {
    initializeFmsWithBackupTexts(store);
  }

  const containerElement = document.querySelector('#app');
  const rootElement = createRoot(containerElement);

  rootElement.render(
    <Provider store={store}>
      <BrowserRouter basename={getBasename()}>
        <Sentry.ErrorBoundary fallback={<UnexpectedError />}>
          <Authenticated>
            <Permissions>
              <LocationChangedLogger />
              <TrackIdClickedLogger />

              <WaitForRequiredAppData>
                <HighchartsProvider>
                  <GlobalStyle />

                  <HasUserAccessToConnect>
                    <ScrollToTopOnNavigation />
                    <App />
                  </HasUserAccessToConnect>
                </HighchartsProvider>
              </WaitForRequiredAppData>
            </Permissions>
          </Authenticated>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </Provider>,
  );
};
