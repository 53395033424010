import { Locale as DateFnsLocale } from 'date-fns';

import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';

import { getDateFnsLocaleFromLanguage } from '../utils/getDateFnsLocaleFromLanguage';

/**
 * Hook to get the date-fns locale for the signed in user.
 */
export const useUserDateFnsLocale = (): DateFnsLocale => {
  const { language } = useUserSettings();

  return getDateFnsLocaleFromLanguage(language);
};
