import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';
import { ForceUpdateInformationSlug } from '@/routes/connectRoutes';

/**
 * Force the user to update their settings if they are out of date.
 *
 * This will make the user settings page the only page available to the user
 * until they update their settings.
 */
export const NeedToUpdateUserSettings: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { updateNeeded } = useUserSettings();
  const organizationCmId = useOrganizationCmId();
  const navigate = useNavigate();
  const location = useLocation();

  const forceUpdateOfUserSettings =
    organizationCmId &&
    updateNeeded &&
    !location.pathname.includes(ForceUpdateInformationSlug);

  useEffect(() => {
    if (forceUpdateOfUserSettings) {
      const redirectUrl = encodeURIComponent(location.pathname);

      // Navigate the user to the update user settings page.
      // The `navigate` must be in a `useEffect`, this is per the React Router docs.
      navigate(
        `/${organizationCmId}/${ForceUpdateInformationSlug}?redirectUrl=${redirectUrl}`,
        {
          replace: true,
        },
      );
    }
  }, [forceUpdateOfUserSettings, location.pathname]);

  if (forceUpdateOfUserSettings) {
    // Don't render anything while we're redirecting.
    return null;
  }

  // Render Connect as normal.
  return <>{children}</>;
};
