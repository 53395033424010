import Button from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { InternalLink } from '@/components/InternalLink';

interface Props {
  to: string;
  direction: 'left' | 'right';
}

export const NavigationPad: React.FC<Props> = ({ to, direction }) => {
  const icon = direction === 'left' ? 'chevron-left' : 'chevron-right';

  return (
    <ButtonStyle>
      <Button as={InternalLink} to={to} variant="text" iconLeft={icon} />
    </ButtonStyle>
  );
};

const ButtonStyle = styled.div`
  height: 100%;
  width: 100%;

  & > a {
    width: 100%;
    height: 100%;

    /*
      Remove content that sticks out of its own container.
      Otherwise we get horizontal scrollbars due to the extra height.
    */
    ::after {
      content: none;
    }
  }
`;
