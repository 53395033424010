import { motion, useAnimationControls } from 'framer-motion';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';

interface Props extends PropsWithChildren {
  isOpen: boolean;
}

export const AnimatedMenuContainer: FC<Props> = ({ isOpen, children }) => {
  const animationControls = useAnimationControls();

  useEffect(() => {
    if (isOpen) {
      animationControls.start({ flex: 1, display: 'flex' });
    } else {
      animationControls.start({ flex: 0, display: 'none' });
    }
  }, [isOpen]);

  return (
    <Container
      animate={animationControls}
      initial={
        isOpen ? { flex: 1, display: 'flex' } : { flex: 0, display: 'none' }
      }
      transition={{ ease: 'easeInOut' }}
    >
      {children}
    </Container>
  );
};

const Container = styled(motion.div)`
  flex: 1;
  overflow: hidden;
  display: flex;
`;
