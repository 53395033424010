export enum SigningRightStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Unconfirmed = 'UNCONFIRMED',
  Expired = 'EXPIRED',
  Completed = 'COMPLETED',
}

export interface SigningRight {
  status: SigningRightStatus;
  signingUrl: string;
}
