import groupBy from 'lodash/groupBy';

import { FundList } from '@/api/funds/models';
import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { SubscriptionPayment } from '../../../types/Payment';

export const mapOrderPlacementToPayments = (
  orderPlacements: OrderPlacement[],
  fundList: FundList,
): SubscriptionPayment[] => {
  const paymentByCurrency = groupBy(
    orderPlacements,
    (placement) => placement.currency + placement.externalAccountNumber,
  );

  const payments: SubscriptionPayment[] = Object.keys(paymentByCurrency).map(
    (key) => {
      const placement = paymentByCurrency[key][0];
      const fund = fundList.byIsin[placement.isin];

      return {
        type: 'SUBSCRIPTION_PAYMENT',

        paymentPlacement: convertStringToEnum(
          placement.payment,
          OrderPaymentType,
        ),

        portfolioBankAccountNumber: placement.externalAccountNumber,

        fundPaymentInformation: {
          accountHolder: fund.paymentInformation.accountHolder,
          currency: placement.currency,
          bankAccountNumber: fund.paymentInformation.bankAccountNumber,
          iban: fund.paymentInformation.iban,
          swift: fund.paymentInformation.swift,
        },

        forIsins: paymentByCurrency[key].map((p) => p.isin),
      };
    },
  );

  return payments;
};
