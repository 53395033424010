import { Table as StoreblocksTable } from '@storeblocks/table';
import React from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';

import { useMapReportsToTableRows } from '../hooks/useMapReportsToTableRows';
import { Report } from '../types/ReportModel';
import { TableColumn } from './TableModels';

interface Props {
  reports: Report[];
}

export const ReportsTable: React.FC<Props> = ({ reports }) => {
  const texts = useFms();

  const rows = useMapReportsToTableRows(reports);

  const reportTableColumns: TableColumn[] = [
    {
      key: 'reportDate',
      name: texts.reportsArchivePage.tableHeaders.reportDate,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
    {
      key: 'name',
      name: texts.reportsArchivePage.tableHeaders.name,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
    {
      key: 'selectFile',
      name: texts.reportsArchivePage.tableHeaders.selectFile,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
  ];

  return <StyledTable columns={reportTableColumns} data={rows} />;
};

const StyledTable = styled(StoreblocksTable)`
  table {
    table-layout: unset;
    white-space: nowrap;
  }

  & tr td:nth-of-type(2) {
    width: 100%;
  }
`;
