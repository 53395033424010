export enum DateFilterType {
  ThisMonth = 'this-month',
  LastMonth = 'last-month',
  LastThreeMonths = 'last-three-months',
  ThisYear = 'this-year',
  SinceInception = 'since-inception',
  OneMonth = 'one-month',
  ThreeMonths = 'three-months',
  SixMonths = 'six-months',
  OneYear = 'one-year',
  ThreeYears = 'three-years',
  Custom = 'custom',
}
