import Alert from '@storeblocks/alert';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import difference from 'lodash/difference';
import React, { FC, useEffect, useState } from 'react';

import { Organization } from '@/api/organizations/models/Organization';
import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { User } from '@/api/users/models/User';
import {
  useAddUserToOrganizationsMutation,
  useUsersQuery,
} from '@/api/users/usersApi';
import { FormRow } from '@/components/Form/FormRow';
import { useFms } from '@/hooks/useFms';
import { FMSTexts } from '@/types/fms';

import { OrganizationFormSelector } from '../OrganizationFormSelector';
import { UserAdminForm } from './UserAdminForm';
import {
  getValidationSchemaEditAllUsers,
  OrganizationUserFormValues,
} from './UserFormSchema';

const removeUndefined = <S,>(value: S | undefined): value is S =>
  value !== undefined;

interface Props {
  onClose: () => void;
  userToEdit: User;
}

export const EditAllUsers: FC<Props> = ({ onClose, userToEdit }) => {
  const texts: FMSTexts = useFms();

  const [addUserToOrganizations] = useAddUserToOrganizationsMutation();
  const usersQuery = useUsersQuery();

  const [submitFailed, setSubmitFailed] = useState(false);
  const [usersOrganizations, setUsersOrganizations] = useState<Organization[]>(
    [],
  );
  const [initialFormValues, setInitialFormValues] =
    useState<OrganizationUserFormValues>({
      givenName: '',
      familyName: '',
      email: '',
      roles: [],
      organizations: [],
    });

  const organizationsQuery = useOrganizationsQuery();

  const handleSubmit = async (
    data: OrganizationUserFormValues,
    helpers: FormikHelpers<OrganizationUserFormValues>,
  ): Promise<void> => {
    const organizations = data.organizations || [];

    try {
      await Promise.all([
        handleAddedUserToOrganizations(organizations),
        handleRemovedUserFromOrganizations(organizations),
      ]);
      usersQuery.refetch();
      handleOnClose();
    } catch {
      helpers.setSubmitting(false);
      setSubmitFailed(true);
    }
  };

  const handleAddedUserToOrganizations = async (
    organizations: Organization[],
  ): Promise<void> => {
    const addedOrganizations = organizations
      .filter(
        (org) =>
          !userToEdit.allowedOrganizationsByName.includes(org.organizationName),
      )
      .map((org) => org.organizationId);

    if (addedOrganizations.length > 0) {
      return addUserToOrganizations({
        userId: userToEdit.userId,
        organizations: {
          organizationIds: addedOrganizations,
          organizationRoles: ['ReadReports'],
        },
      }).unwrap();
    }

    return Promise.resolve();
  };

  const handleRemovedUserFromOrganizations = (
    organizations: Organization[],
  ): Promise<unknown> => {
    const removedOrganizations = difference(
      userToEdit.allowedOrganizationsByName,
      organizations.map((org) => org.organizationName),
    );

    if (removedOrganizations.length > 0) {
      // TODO add support when endpoint is ready - SAMIK-1071
      return Promise.resolve();
    }

    return Promise.resolve();
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmit,
    validationSchema: getValidationSchemaEditAllUsers(texts),
    enableReinitialize: true,
  });

  const handleOnClose = (): void => {
    // Reset state as this component is a singleton triggered by the `show` boolean.
    formik.resetForm();
    onClose();
  };

  // Set initial form values.
  useEffect(() => {
    setInitialFormValues({
      givenName: userToEdit.givenName || '',
      familyName: userToEdit.familyName || '',
      email: userToEdit.email || '',
      roles: [],
      organizations: usersOrganizations,
    });
  }, [userToEdit, usersOrganizations]);

  // Find the organizations the user has access to.
  useEffect(() => {
    const organizations = (userToEdit.allowedOrganizationsByName || [])
      .map((userOrg) =>
        organizationsQuery.data.find(
          (allOrg) => userOrg === allOrg.organizationName,
        ),
      )
      .filter(removeUndefined);

    setUsersOrganizations(organizations);
  }, [userToEdit, organizationsQuery.data]);

  return (
    <FormikProvider value={formik}>
      <UserAdminForm
        show
        onClose={handleOnClose}
        header={texts.adminModal.editUser}
        isSubmitting={formik.isSubmitting}
        submitFailed={submitFailed}
        currentMode="editAll"
        touched={formik.touched}
        errors={formik.errors}
        dirty={formik.dirty}
        trackingModal="edit-all-user"
      >
        <OrganizationFormSelector />
        <FormRow>
          <Alert title={texts.admin.user.access.disclaimer} fluid />
        </FormRow>
      </UserAdminForm>
    </FormikProvider>
  );
};
