import { DocumentCategory } from '@/api/documentArchive/models/DocumentCategory';
import { FMSTexts } from '@/types/fms';

export const getDocumentCategoryTextFromEnum = (
  documentCategory: DocumentCategory,
  texts: FMSTexts,
): string => {
  const categoryTexts = texts.documentsArchivePage.documentCategories;

  switch (documentCategory) {
    case DocumentCategory.Accounting:
      return categoryTexts.accounting;

    case DocumentCategory.AgreementsAndSignedContracts:
      return categoryTexts.agreementsAndSignedContracts;

    case DocumentCategory.AssetManagement:
      return categoryTexts.assetManagement;

    case DocumentCategory.BoardDocuments:
      return categoryTexts.boardDocuments;

    case DocumentCategory.BoardMeetingMinutes:
      return categoryTexts.boardMeetingMinutes;

    case DocumentCategory.Correspondence:
      return categoryTexts.correspondence;

    case DocumentCategory.EquityOrSubordinatedLoan:
      return categoryTexts.equityOrSubordinatedLoan;

    case DocumentCategory.FinancialSupervisoryAdvisory:
      return categoryTexts.financialSupervisoryAdvisory;

    case DocumentCategory.Instructions:
      return categoryTexts.instructions;

    case DocumentCategory.InternalControl:
      return categoryTexts.internalControl;

    case DocumentCategory.MeetingSchedule:
      return categoryTexts.meetingSchedule;

    case DocumentCategory.MiscellaneousDocuments:
      return categoryTexts.miscellaneousDocuments;

    case DocumentCategory.Presentations:
      return categoryTexts.presentations;

    case DocumentCategory.Reinsurance:
      return categoryTexts.reinsurance;

    case DocumentCategory.StrategyAndPolicyDocs:
      return categoryTexts.strategyAndPolicyDocs;

    default:
      throw new Error(
        `Category [${documentCategory}] is not a valid document category.`,
      );
  }
};
