import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderValueType } from '@/api/order/models/OrderValueType';

export const getValueTypeFromOrderPlacements = (
  orderPlacements: Array<OrderPlacement>,
): OrderValueType => {
  if (
    orderPlacements.some(
      (orderPlacement) => orderPlacement.valueType === OrderValueType.Amount,
    )
  ) {
    return OrderValueType.Amount;
  }
  if (
    orderPlacements.some(
      (orderPlacement) => orderPlacement.valueType === OrderValueType.Percent,
    )
  ) {
    return OrderValueType.Percent;
  }

  return OrderValueType.Units;
};
