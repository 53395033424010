import React, { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { GoToMainContent } from '../GoToMainContent';

interface Props extends PropsWithChildren {
  className?: string;
}

export const Nav = forwardRef<HTMLDivElement, Props>(
  ({ children, className }, ref) => {
    return (
      <>
        <GoToMainContent />
        <Wrapper className={className} ref={ref} data-test="navbar">
          <>{children}</>
        </Wrapper>
      </>
    );
  },
);

const Wrapper = styled.nav`
  background-color: var(--color-background);
  border-top: 3px solid var(--color-brand);
  height: 72px;
  min-height: 72px;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

Nav.displayName = Nav.name;
