import Button from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  loading: boolean;
}

export const SubmitButton: React.FC<Props> = ({ loading }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <RightAlign>
      <Button
        type="submit"
        loading={loading}
        data-trackid={getTrackId(
          'submit-signing-right',
          TrackingElement.Button,
        )}
      >
        {texts.orders.signatureRight.goToSigning}
      </Button>
    </RightAlign>
  );
};

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;
