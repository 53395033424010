import sortBy from 'lodash/sortBy';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';

import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';
import { SignatoryOption } from '../../../types/SignatoryOption';

export const createInitialRedemptionValues = (
  fundStates: RedemptionFundState[],
  portfolioShortName: string,
  fromValueType: OrderValueType,
  signatoryOption: SignatoryOption,
): RedemptionOrderFormValues => {
  const sortedFundStates = sortBy(fundStates, (fund) =>
    fund.name.toLowerCase(),
  );

  return {
    fromPortfolioShortName: portfolioShortName,
    fromFunds: sortedFundStates,
    payments: [],
    fromValueType,
    signer: null,
    cosigner: undefined,
    signatoryOption,
    signingProvider: undefined,
  };
};
