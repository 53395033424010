import { Permission } from '@/api/permissions/models/Permission';
import { usePermissionsQuery } from '@/api/permissions/permissionsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { ComplexPermission, hasPermission } from '@/util/hasPermission';

export interface HasPermission {
  (permission: Permission | ComplexPermission): boolean;
}

/**
 * This hook will make the components rerender when the permissions change.
 * Thus making the components to always have the latest permissions.
 *
 * @returns A function that checks if the user has the specified permission.
 */
export const useHasPermission = (): HasPermission => {
  const organizationCmId = useOrganizationCmId();
  const { data } = usePermissionsQuery(undefined, { skip: !organizationCmId });

  return (permission: Permission | ComplexPermission) =>
    hasPermission(permission, data);
};
