import * as Yup from 'yup';

import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';

import { baseRedemptionFundStateValidationSchema } from './baseRedemptionFundStateValidationSchema';

interface ErrorMessages {
  maximumUnitsMessage: string;
}

/** Validate redemption in Units */
export const redemptionInUnitsFundStateValidationSchema = ({
  maximumUnitsMessage,
}: ErrorMessages): Yup.SchemaOf<RedemptionFundState> =>
  baseRedemptionFundStateValidationSchema.concat(
    Yup.object({
      value: Yup.number()
        .test(function validateRedemptionUnits(value) {
          // Don't show validation message when there isn't any amount.
          if (!value) {
            return true;
          }
          // Show validation message if sell all is set but the units value is not equal to the total units.
          if (this.parent.sellAll && value !== this.parent.units) {
            return this.createError({
              message:
                'sellAll is true but the units value is not equal to the total fund units',
            });
          }

          // Show validation message if the value is negative.
          if (value < 0) {
            return this.createError({
              message: 'Value cannot be negative',
            });
          }

          // Show validation message if the value exceeds the total fund holdings units.
          if (value > this.parent.units) {
            return this.createError({
              message: maximumUnitsMessage,
            });
          }

          return true;
        })
        .required(),
    }),
  );
