import sortBy from 'lodash/sortBy';

import { OrganizationUserDto } from '../dtos/OrganizationUserDto';
import { OrganizationUsersReply } from '../dtos/OrganizationUsersReply';
import { OrganizationUser } from '../models/OrganizationUser';

export const transformOrganizationUsersReply = (
  dto: OrganizationUsersReply,
): Array<OrganizationUser> => {
  return sortBy(dto.users.map(mapUserDtoToModel), (d) => d.familyName);
};

const mapUserDtoToModel = (dto: OrganizationUserDto): OrganizationUser => ({
  userId: dto.userId,
  givenName: dto.givenName,
  familyName: dto.familyName,
  email: dto.email,
  isActiveUser: dto.isActiveUser,
});
