import React from 'react';
import styled from 'styled-components';

import { TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

interface Props {
  fundValue: number;
  benchmarkValue: number;
}

/**
 * Displays two bars, one for the fund and one for the benchmark.
 *
 * The values are normalized. The largest value is 100% and the smaller
 * is a percentage of the larger value. Thus, if the fund value is 26 tons
 * and the benchmark is 225 tons, the fund bar will be 11.55% of the width and
 * the benchmark will be 100% width. This will also work if the fund is
 * larger than the benchmark value. The fund value will be 100% width and the
 * benchmark will be a percentage of the fund value.
 */
export const CarbonIntensityChart: React.FC<Props> = ({
  fundValue,
  benchmarkValue,
}) => {
  const texts = useFms();

  const normalizedValue =
    (Math.min(fundValue, benchmarkValue) /
      Math.max(fundValue, benchmarkValue)) *
    100;

  const isBenchmarkLarger = benchmarkValue > fundValue;

  return (
    <WithGap gap="24">
      <WithGapStyled gap="16" direction="row">
        <ValueText>
          {fundValue} {texts.pages.esg.carbonFootprint.tonnage}
        </ValueText>

        <WithGapStyled gap="8">
          <TextShortBold>{texts.pages.fundEsg.fund.title}</TextShortBold>

          <SingleBar
            width={isBenchmarkLarger ? normalizedValue : 100}
            color="var(--color-blue-50)"
          />
        </WithGapStyled>
      </WithGapStyled>

      <WithGapStyled gap="16" direction="row">
        <ValueText>
          {benchmarkValue} {texts.pages.esg.carbonFootprint.tonnage}
        </ValueText>

        <WithGapStyled gap="8">
          <TextShortBold>{texts.pages.fundEsg.benchmark.title}</TextShortBold>

          <SingleBar
            width={isBenchmarkLarger ? 100 : normalizedValue}
            color="var(--color-blue-20)"
          />
        </WithGapStyled>
      </WithGapStyled>
    </WithGap>
  );
};

interface SingleBarProps {
  width: number;
  color: string;
}

const SingleBar = styled.div<SingleBarProps>`
  width: ${({ width }) => width}%;
  height: 32px;
  background-color: ${({ color }) => color};
  border: 1px solid var(--color-on-background);
`;

const WithGapStyled = styled(WithGap)`
  flex: 1;
`;

const ValueText = styled(TextShortBold)`
  /**
   * Make sure that both the fund and benchmark values have the same
   * width, so that the bars are properly vertically aligned.
   * This width should be good enough for up to four digits.
   */
  min-width: 104px;

  text-align: right;
  margin-bottom: 4px;
  align-self: flex-end;
`;
