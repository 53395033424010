import groupBy from 'lodash/groupBy';
import React from 'react';
import styled from 'styled-components';

import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Locale } from '@/i18n/locale';
import { CurrencyCode } from '@/types/CurrencyCode';
import { formatNumber } from '@/util/formatNumber';

import { isLastRow } from '../utils/isLastRow';
import { TableRowCell } from './TableRowCell';
import { TableRowHeader } from './TableRowHeader';

interface Props {
  orderPlacements: Array<OrderPlacement>;
}

export const TotalAmountRows: React.FC<Props> = ({ orderPlacements }) => {
  const { orders } = useFms();

  const orderPlacementsWithAmount = orderPlacements.filter(
    (orderPlacement) => orderPlacement.valueType === OrderValueType.Amount,
  );

  const orderPlacementsGroupedByCurrency = groupBy(
    orderPlacementsWithAmount,
    (orderPlacement) => orderPlacement.currency,
  );

  const amounts = Object.keys(orderPlacementsGroupedByCurrency)
    .map((currency) => ({
      currency: CurrencyCode[currency],
      amount: orderPlacementsGroupedByCurrency[currency].reduce(
        (total, orderPlacement) => {
          return total + orderPlacement.value;
        },
        0,
      ),
    }))
    .filter((currency) => currency.amount !== 0);

  const hasMultipleCurrencies = Object.keys(amounts).length > 1;

  return (
    <>
      {amounts.map(({ amount, currency }, index, array) => (
        <tr key={currency} data-test={`sum-row-${currency}`}>
          <TableRowHeader noBorder={!isLastRow(index, array)}>
            {hasMultipleCurrencies
              ? fmsWithTemplate(orders.form.table.totalAmountWithCurrency, {
                  currency,
                })
              : orders.form.table.totalAmount}
          </TableRowHeader>
          <TableRowCell colSpan={2} noBorder={!isLastRow(index, array)}>
            <Sum>{formatNumber(amount, Locale.Norwegian, currency)}</Sum>
          </TableRowCell>
        </tr>
      ))}
    </>
  );
};

const Sum = styled.div`
  text-align: right;
  font-weight: 600;
`;
