import { FundHoldings } from '@/api/fundHoldings/models';
import { byFundKey } from '@/api/fundHoldings/utils';
import { Fund } from '@/api/funds/models';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { groupByBoolean } from '@/util/groupByBoolean';

import { RedemptionFundState } from '../../../types/RedemptionFundState';
import { value95percent } from '../../../utils/value95percent';

export const mapOrderPlacementToRedemptionFundState = (
  orderPlacements: OrderPlacement[],
  holdingsByFund: Record<string, FundHoldings>,
  fundByIsin: Record<string, Fund>,
): RedemptionFundState[] => {
  const orderPlacementsHasHoldings = groupByBoolean(
    orderPlacements,
    (placement) =>
      !!holdingsByFund[byFundKey(placement.accountId, placement.isin)],
  );

  const orderPlacementFundStates = orderPlacementsHasHoldings.truthy.map(
    (placement) => {
      const holdings =
        holdingsByFund[byFundKey(placement.accountId, placement.isin)];
      const fund = fundByIsin[placement.isin];

      const sellAll = isSellAll(placement, holdings);

      const fundState: RedemptionFundState = {
        accountId: placement.accountId,
        isin: placement.isin,
        name: placement.fundName,
        currency: placement.currency,
        brand: fund.brand,

        units: holdings.units,
        nav: holdings.nav,
        // Remove decimals from the market value as they are not needed in the UI.
        marketValue: Math.trunc(holdings.marketValue),
        marketValue95percent: value95percent(holdings.marketValue),

        minimumRedemptionAmount: fund.minimumRedemptionAmount,

        isClosed: fund.closedForRedemption,
        sellAll,

        value: getPlacementValue(placement, sellAll),
        valueType: placement.valueType,
      };

      return fundState;
    },
  );

  if (orderPlacementsHasHoldings.falsy.length > 0) {
    const isins = orderPlacementsHasHoldings.falsy.map(
      (placement) => placement.isin,
    );

    throw new Error(
      `Cannot do redemption on funds without holdings, ISIN [${isins.toString()}].`,
    );
  }

  return orderPlacementFundStates;
};

const isSellAll = (
  placement: OrderPlacement,
  holdings: FundHoldings,
): boolean => {
  return (
    placement.valueType === OrderValueType.Units &&
    placement.value === holdings.units
  );
};

const getPlacementValue = (
  placement: OrderPlacement,
  sellAll: boolean,
): number => {
  if (sellAll && placement.valueType === OrderValueType.Percent) {
    return 100;
  }

  return placement.value;
};
