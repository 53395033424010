import { matchPath, useLocation } from 'react-router';

import { connectRoutes } from '../connectRoutes';
import { ConnectRoute } from '../types/ConnectRoute';

/**
 * Returns the current connect route.
 */
export const useCurrentRoute = (): ConnectRoute => {
  const location = useLocation();

  return matchRoute(connectRoutes(), location.pathname);
};

/**
 * Recursively find the route matching the pathname.
 */
const matchRoute = (
  routes: Array<ConnectRoute>,
  pathname: string,
): ConnectRoute => {
  for (let i = 0; i < routes.length; i += 1) {
    const route = routes[i];

    if (route.path) {
      const match = matchPath(route.path, pathname);

      if (match) {
        return route;
      }

      if (route.children) {
        const matchedChild = matchRoute(route.children, pathname);

        if (matchedChild) {
          return matchedChild;
        }
      }
    }
  }

  return undefined;
};
