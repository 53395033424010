/**
 * Removes spaces, file extensions, numbers, hyphens between numbers (e.g. 2022-12-01),
 * parantheses and the content inside the parantheses from the given filename.
 *
 * E.g.:
 * Regnskapspakke (682).XLSX -> Regnskapspakke
 * Transaksjonsbekreftelse 2023-06-30 (231305968936) -> Transaksjonsbekreftelse
 */
export const stripFilename = (filename: string): string => {
  const regexPattern = /\s+|\(.*?\)|\d+|-\d+-|\..*$/g;
  return filename.replace(regexPattern, '');
};
