import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { OrderAssetInfoDto } from '../dtos/OrderAssetInfoDto';
import { OrderDocumentDto } from '../dtos/OrderDocumentDto';
import { OrderDto } from '../dtos/OrderDto';
import { OrderPlacementDto } from '../dtos/OrderPlacementDto';
import { OrderStatusDto } from '../dtos/OrderStatusDto';
import { Order } from '../models/Order';
import { OrderAssetInfo } from '../models/OrderAssetInfo';
import { OrderDocument } from '../models/OrderDocument';
import { OrderPaymentType } from '../models/OrderPaymentType';
import { OrderPlacement } from '../models/OrderPlacement';
import { OrderPlacementType } from '../models/OrderPlacementType';
import { OrderStatus } from '../models/OrderStatus';
import { OrderStatusState } from '../models/OrderStatusState';
import { OrderValueType } from '../models/OrderValueType';
import { mapOrderTypeDtoToEnum } from './mapOrderTypeDtoToEnum';

export function mapOrderDtoToModel(order: OrderDto): Order {
  return {
    id: order.id,
    created: order.created,
    statuses: order.statuses?.map(mapOrderStatusDtoToModel) ?? [],
    specifications: order.specifications,
    amountIsAvailable: order.amountIsAvailable,
    orderPlacements: order.orderPlacements?.map(mapOrderPlacementDtoToModel),
    payorAccountNumber: order.payorAccountNumber,
    portfolioNumber: order.portfolioNumber,
    portfolioName: order.portfolioName,
    orderType: mapOrderTypeDtoToEnum(order.orderType),
    userId: order.userId,
    userName: order.userName,
    organizationCmId: order.organizationCmID,
    organizationName: order.organizationName,
    organizationNumber: order.organizationNumber,
    documents: order.documents?.map(mapOrderDocumentDtoToModel) ?? [],
    value: order.value,
    signatories: order.signatories,
    assetInfo: mapOrderAssetInfoDtoToModel(order.assetInfo),
  };
}

const mapOrderDocumentDtoToModel = (dto: OrderDocumentDto): OrderDocument => {
  return {
    id: dto.id,
    onBaseDocumentId: dto.onBaseDocumentId,
    onBaseDocumentName: dto.onBaseDocumentName,
    onBaseDocumentType: dto.onBaseDocumentType,
  };
};

const mapOrderStatusDtoToModel = (dto: OrderStatusDto): OrderStatus => {
  return {
    state: convertStringToEnum(dto.state, OrderStatusState),
    created: dto.created,
    id: dto.id,
    userId: dto.userId,
    reason: dto.reason,
  };
};

const mapOrderAssetInfoDtoToModel = (
  dto?: OrderAssetInfoDto,
): OrderAssetInfo | undefined => {
  if (!dto) {
    return undefined;
  }

  return {
    investmentObjective: dto.investmentObjective,
    hasHighRankingPosition: dto.hasHighRankingPosition,
    highRankingPositionDescription: dto.highRankingPositionDescription,
    assetOrigin: dto.assetOrigin,
  };
};

const mapOrderPlacementDtoToModel = (
  dto: OrderPlacementDto,
): OrderPlacement => {
  return {
    fundIsin: dto.isin,
    hash: dto.hash,
    valueType: convertStringToEnum(dto.valueType, OrderValueType),
    accountId: dto.accountId,
    // Set default currency value to `NOK` for old orders missing currency.
    currency: convertStringToEnum(dto.currency || 'NOK', CurrencyCode),
    externalAccountNumber: dto.externalAccountNumber,
    fundName: dto.fundName,
    id: dto.id,
    isin: dto.isin,
    payment: convertStringToEnum(dto.payment, OrderPaymentType),
    placementType: convertStringToEnum(dto.placementType, OrderPlacementType),
    value: dto.value,
  };
};
