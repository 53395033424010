import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { Keyframes } from 'styled-components/dist/types';

import { TextShort } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks';

interface Props {
  label: string;
  value: number;
  percentage: number;
  unit: string;
}

/** Renders a percentage bar for displaying a value as percentage for comparisons.
 * @param {string} label - The name text to display under the bar.
 * @param {number} percentage - The percentage used for the visual comparison.
 * @param {number} value - The total value to display under the bar.
 * @param {string} unit - The unit of the value.
 */
export const EsgPercentageBar: FC<Props> = ({
  label,
  percentage,
  value,
  unit,
}) => {
  const locale = useUserLocale();

  return (
    <Container>
      <BarContainer>
        <BackgroundBar />
        <PrimaryBar percentage={percentage} />
      </BarContainer>

      <TextContainer>
        <TextShort>{label}</TextShort>
        <ValueText>{`${value.toLocaleString(locale)} ${unit}`}</ValueText>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const BarContainer = styled.div`
  height: 200px;
  position: relative;
`;

interface BarProps {
  percentage: number;
}

const PrimaryBar = styled.div<BarProps>`
  height: 0;
  position: absolute;
  bottom: 0;
  min-width: 24px;
  background-color: var(--color-blue-50);
  animation: ${({ percentage }) => growAnimation(percentage)} 1s forwards;
  animation-delay: 0.5s;
`;

const BackgroundBar = styled.div`
  height: 100%;
  min-width: 24px;
  background-color: var(--color-blue-20);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ValueText = styled(TextShort)`
  color: var(--color-blue-50);
  font-weight: 600;
`;

const growAnimation = (percentage: number): Keyframes => keyframes`
    from {
      height : 0%
    }
    to {
      height : ${percentage}%
    }
`;
