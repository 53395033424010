import { useMemo } from 'react';
import * as Yup from 'yup';

import { useCurrentUserInfo } from '@/auth/hooks';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';

import { RedemptionOrderFormValues } from '../../types/RedemptionOrderFormValues';
import { createRedemptionValidationSchema } from '../createRedemptionValidationSchema';

export const useCreateRedemptionValidationSchema =
  (): Yup.SchemaOf<RedemptionOrderFormValues> => {
    const texts = useFms();
    const errorTexts = texts.orders.form.errors;

    const locale = useUserLocale();

    const { userType } = useCurrentUserInfo();

    return useMemo(
      () =>
        createRedemptionValidationSchema({
          locale,
          userType,
          errorMessages: {
            portfolioRequired: errorTexts.portfolioRequired,
            mustHaveAtLeastOneFund:
              errorTexts.redemption.atLeastOneFundRequired,
            paymentPlacementRequired: errorTexts.redemption.paymentRequired,
            bankAccountNumberRequired: errorTexts.bankAccountRequired,
            signatoryOptionRequired: errorTexts.signatoryOptionRequired,
            signerRequired: errorTexts.signerRequired,
            cosignerRequired: errorTexts.cosignerRequired,
            signingProviderRequired: errorTexts.signingProviderRequired,
            minimumRedemptionAmount: errorTexts.redemption.minimumAmount,
            maximumPercent: errorTexts.maximumPercent,
            maximumUnits: errorTexts.redemption.maximumUnits,
            holdingsNotAbove95percent:
              errorTexts.valueIsOver95percentOfHoldings,
            portfolioBankAccountNumberRequired:
              texts.orders.form.payment.options.portfolioSelect.error,
          },
        }),
      [locale, errorTexts],
    );
  };
