import { customAlphabet } from 'nanoid/non-secure';

// Alphabet and the size of the ID is taken from matssockets.
// https://github.com/centiservice/matssocket/blob/main/matssocket-client-javascript/client/lib/MatsSocket.js#L1253
const nanoid = customAlphabet(
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  6,
);

const storageKey = 'connect-session-id';

export const initializeSessionId = (): void => {
  const sessionId = sessionStorage.getItem(storageKey);

  if (!sessionId) {
    sessionStorage.setItem(storageKey, nanoid());
  }
};

export const getSessionId = (): string => {
  const sessionId = sessionStorage.getItem(storageKey);

  if (sessionId) {
    return sessionId;
  }

  throw new Error('Connect session ID is not set.');
};
