import { store } from '@/store';

import { Permission } from '../models/Permission';
import { endpoints } from '../permissionsApi';

/**
 * Get the user permissions from the store.
 *
 * This is not a hook, thus it will not trigger
 * a re-render when the permissions change.
 */
export const getPermissionsFromStore = (): Permission[] => {
  const permissionsApiState = endpoints.permissions.select()(store.getState());
  const userPermissions = permissionsApiState.data;

  return userPermissions ?? [];
};
