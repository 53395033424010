import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { addLogEvent } from '@/logging/logSlice';
import { findCurrentPath } from '@/routes/utils/findCurrentPath';
import { AppDispatch } from '@/store';

import { createLogEvent } from '../createLogEvent';

export const LocationChangedLogger: React.FC = () => {
  const [fromLocation, setFromLocation] = useState<string | undefined>();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const currentPath = findCurrentPath();

    dispatch(
      addLogEvent(
        createLogEvent({
          type: 'location-changed',
          level: 'info',

          description: !fromLocation ? 'Initial page load' : undefined,

          data: {
            from: fromLocation,
            to: currentPath,
          },
        }),
      ),
    );

    setFromLocation(currentPath);
  }, [location.pathname]);

  return null;
};
