/**
 * Regex used to match a string that consists entirely of one or more digits.
 *
 * The \d is a shorthand character class that represents any digit (0-9).
 * The + indicates one or more occurrences.
 */
const onlyDigitsRegex = /^\d+$/;

/**
 * Regex used to match all whitespace characters, not just spaces.
 *
 * The \s represents any whitespace character (spaces, tabs, line breaks).
 * The + matches one or more occurrences of the preceding whitespace character.
 */
const matchWhitespaceRegex = /\s+/g;

/**
 * Regex used to match all dash characters.
 *
 * The [-—–] represents a character class that
 * includes the standard hyphen-minus (-), em dash (—), and en dash (–).
 * The + matches one or more occurrences of any character in the character class.
 */
const matchDashRegex = /[-—–]+/g;

/**
 * Regex used to match all parentheses.
 *
 * The ( matches an opening parenthesis "(".
 * The | is the pipe symbol, which acts as a logical OR.
 * The ) matches a closing parenthesis ")".
 */
const matchParenthesesRegex = /\(|\)/g;

/**
 * Check if the specified string is a valid phone number format.
 * It does not consider the length of the phone number, only that it
 * consists of digits, spaces, dashes, and country code.
 *
 * The following formats are considered valid:
 * - 123
 * - 12345678
 * - 123 45 678
 * - 123-4567890
 * - 123-456 7890
 * - 123-456-7890
 * - (123) 456 7890
 * - Includes country code for all the above examples.
 * - E.g. +1234567890
 *
 * @returns `true` if the phone number is valid, `false` otherwise.
 */
export const isPhoneNumberFormatValid = (value: string): boolean => {
  let parsedValue = value.trim();

  // Remove the plus sign if the phone number starts with a country code in the plus format (+47).
  // Country codes are allowed, thus the plus sign can be omitted from the rest of the validation.
  if (parsedValue.startsWith('+')) {
    parsedValue = parsedValue.slice(1);
  }

  // It is normal for users to add spaces to the phone number to format it, e.g. "111 11 111".
  // Thus we allow it, but remove it from the rest of the validation.
  parsedValue = parsedValue.replace(matchWhitespaceRegex, '');

  // Swedish users use dash in their phone numbers, e.g. "111-111 11 11".
  // Thus we allow it, but remove it from the rest of the validation.
  parsedValue = parsedValue.replace(matchDashRegex, '');

  // Some users may add parentheses to the phone number.
  // Thus we allow it, but remove it from the rest of the validation.
  parsedValue = parsedValue.replace(matchParenthesesRegex, '');

  // The rest of the phone number should only contain digits.
  // If not, it is not a valid phone number.
  const isOnlyDigits = onlyDigitsRegex.test(parsedValue);

  return isOnlyDigits;
};
