import Breadcrumbs from '@storeblocks/breadcrumbs';
import { Tab, Tabs } from '@storeblocks/tabs';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';
import { FMSTexts } from '@/types/fms';

import { CountryTab } from './CountryTab';

export const ContactUsPage: FC = () => {
  const texts: FMSTexts = useFms();
  const navigate = useNavigate();

  const { organizationCmId, tabId } = useParams();

  const rootPath = organizationCmId ? `/${organizationCmId}` : '/';

  const handleChange = (_, newTabId: string): void => {
    if (organizationCmId) {
      navigate(`/${organizationCmId}/contact-us/${newTabId}`);
    } else {
      navigate(`/contact-us/${newTabId}`);
    }
  };

  useEffect(() => {
    if (!tabId) {
      navigate('./norway', { replace: true });
    }
  }, [tabId]);

  return (
    <CenteredPageContent data-test="contact-us-page">
      <AnnouncementWarning />

      <Breadcrumbs>
        <InternalLink to={rootPath}>{texts.menu.links.home}</InternalLink>
        <InternalLink to={`${rootPath}/contact-us/${tabId}`}>
          {texts.menu.links.contactUs}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.contactUs} />

        <Tabs
          activeTabId={tabId}
          description="Country tabs"
          onChange={handleChange}
        >
          <Tab id="norway" label={texts.country.norway}>
            <CountryTab country="norway" />
          </Tab>
          <Tab id="sweden" label={texts.country.sweden}>
            <CountryTab country="sweden" />
          </Tab>
        </Tabs>
      </MainContent>
    </CenteredPageContent>
  );
};
