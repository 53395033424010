import React, { FC } from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

export const EsgExclusionsList: FC = () => {
  const texts = useFms();
  const { criteria } = texts.pages.esg.exclusions;

  const exclusions = Object.values(criteria).sort();

  return (
    <List>
      {exclusions.map((exclusion) => (
        <ListItem key={exclusion}>
          <TextShort>{exclusion}</TextShort>
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.li`
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border);
`;
