import * as Yup from 'yup';

import { nameof } from '@/util/nameof';

import { OrderFormValues } from '../../types/OrderFormValues';
import { SignatoryOption } from '../../types/SignatoryOption';

interface ErrorMessages {
  cosignerRequired: string;
}

/**
 * Validate that a cosigner is selected if the signatory option is Cosigner.
 */
export const cosignerFieldValidationSchema = (
  errorMessages: ErrorMessages,
): Yup.StringSchema =>
  Yup.string().when(nameof<OrderFormValues>('signatoryOption'), {
    is: SignatoryOption.Cosigner,
    then: Yup.string().required(errorMessages.cosignerRequired),
  });
