import { Portfolio } from '@/api/portfolios/models/Portfolio';
import { PortfolioBankAccount } from '@/api/portfolios/models/PortfolioBankAccount';

export const getPortfolioBankAccounts = (
  portfolioId: string,
  portfolios: Portfolio[],
): PortfolioBankAccount[] => {
  const portfolio = portfolios.find(
    (portfolio) => portfolio.id === portfolioId,
  );

  if (!portfolio) {
    return [];
  }

  return portfolio.portfolioBankAccounts;
};
