import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { Transaction } from './domain/Transaction';
import { TransactionsReply } from './dtos/TransactionsReplyDto';

export const transformTransactionsReply = (
  dtos: TransactionsReply,
): Array<Transaction> => {
  return dtos.transactions.map((dto) => ({
    portfolioGroupId: dto.portfolioGroupId,
    portfolioGroupName: dto.portfolioGroupName,
    portfolioId: dto.portfolioId,
    portfolioName: dto.portfolioName,
    securityName: dto.securityName,
    isin: dto.isin,
    instrumentType: dto.instrumentType,
    transactionNumber: dto.transactionNumber,
    transactionType: dto.transactionType,
    paymentCode: dto.paymentCode,
    orderNumber: dto.orderNumber,
    currency: convertStringToEnum(dto.currency, CurrencyCode),
    createdDate: new Date(dto.createdDate),
    tradeDate: dto.tradeDate ? new Date(dto.tradeDate) : undefined,
    paymentDate: dto.paymentDate ? new Date(dto.paymentDate) : undefined,
    nominal: dto.nominal,
    price: dto.price,
    exchangeRate: dto.exchangeRate,
    accruedInterest: dto.accruedInterest,
    currentValue: dto.currentValue,
    bookValue: dto.bookValue,
    profitLossSecurity: dto.profitLossSecurity,
    profitLossCurrency: dto.profitLossCurrency,
    counterPartyId: dto.counterPartyId,
    status: dto.status,
  }));
};
