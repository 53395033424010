import ChipGroup, { Option } from '@storeblocks/chip/lib/group';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { ReportSource } from '../types/ReportModel';

interface Props {
  onChange(reportSources: ReportSource[]): void;
}

export const ReportSourceFilter: React.FC<Props> = ({ onChange }) => {
  const [checked, setChecked] = useState<ReportSource[]>([]);
  const texts = useFms();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-report-source',
    TrackingElement.FilterChip,
  );

  const options: Option[] = [
    {
      value: ReportSource.Crossroads,
      id: 'yearly-reports',
      label: texts.reportsArchivePage.yearlyReports,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': 'yearly-reports',
        'data-trackid-source': 'reports-archive',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      value: ReportSource.IHubOrganization,
      id: 'monthly-reports',
      label: texts.reportsArchivePage.monthlyReports,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': 'monthly-reports',
        'data-trackid-source': 'reports-archive',
        'data-trackid-type': 'filter-chips',
      },
    },
  ];

  const handleChanged = (values: ReportSource[]): void => {
    onChange(values);

    setChecked(values);
  };

  return (
    <div>
      <Label>{texts.reportsArchivePage.sourceFilter.label}</Label>
      <ChipGroup
        id="reports-archive-facets"
        type="filter"
        checked={checked}
        options={options}
        onChange={handleChanged}
      />
    </div>
  );
};

const Label = styled.label`
  height: 24px;
  font-weight: 600;
  overflow-wrap: break-word;
  margin-bottom: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;
