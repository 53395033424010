import { Locale } from '@/i18n/locale';

/**
 * Convert a number to the specified locale.
 *
 * @example
 * Without suffix:
 * 2109068 -> '2 109 068'
 *
 * With suffix:
 * 2109068 -> '2 109 068 NOK'
 */
export const formatNumber = (
  value: number,
  locale: Locale,
  suffix?: string,
): string => {
  const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: 4 });

  if (suffix) {
    return `${formatter.format(value)} ${suffix}`;
  }

  return `${formatter.format(value)}`;
};
