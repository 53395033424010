import {
  endOfMonth,
  endOfToday,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';

import { DateFilterType } from '../types/DateFilterType';

interface DateInterval {
  from: Date;
  to: Date;
}

// Must memoize the interval values, i.e. they cannot be created on the fly
// due to unit tests and faking the Date object.
const dateIntervals = new Map<DateFilterType, DateInterval>();

export const getDateIntervals = (): ReadonlyMap<
  DateFilterType,
  DateInterval
> => {
  if (dateIntervals.size > 0) {
    // Return memoized value.
    return dateIntervals;
  }

  const today = endOfToday();
  const beginningOfTime = new Date(0);

  dateIntervals.set(DateFilterType.ThisMonth, {
    from: startOfMonth(today),
    to: today,
  });

  dateIntervals.set(DateFilterType.LastMonth, {
    from: startOfMonth(subMonths(today, 1)),
    to: endOfMonth(subMonths(today, 1)),
  });

  dateIntervals.set(DateFilterType.LastThreeMonths, {
    from: startOfMonth(subMonths(today, 3)),
    to: endOfMonth(subMonths(today, 1)),
  });

  dateIntervals.set(DateFilterType.ThisYear, {
    from: startOfYear(today),
    to: today,
  });

  dateIntervals.set(DateFilterType.SinceInception, {
    from: beginningOfTime,
    to: today,
  });

  dateIntervals.set(DateFilterType.OneMonth, {
    from: subMonths(today, 1),
    to: today,
  });

  dateIntervals.set(DateFilterType.ThreeMonths, {
    from: subMonths(today, 3),
    to: today,
  });

  dateIntervals.set(DateFilterType.SixMonths, {
    from: subMonths(today, 6),
    to: today,
  });

  dateIntervals.set(DateFilterType.OneYear, {
    from: subYears(today, 1),
    to: today,
  });

  dateIntervals.set(DateFilterType.ThreeYears, {
    from: subYears(today, 3),
    to: today,
  });

  return dateIntervals;
};
