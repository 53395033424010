import Surface from '@storeblocks/surface';
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Divider } from '@/components/Divider';
import { useFms } from '@/hooks/useFms';
import { useFilterMenuItemsByPermission } from '@/routes/hooks/useFilterMenuItemsByPermission';

import { Dropdown } from '../Dropdown';
import { AnimatedMenuContainer, MenuItemList } from './components';
import { useCommonMenuItems } from './hooks/useCommonMenuItems';
import { useMenuItems } from './hooks/useMenuItems';
import { MenuItem } from './types/MenuItem';

interface Props {
  widget?: JSX.Element;
  isOpen: boolean;
  isSubMenuOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsSubMenuOpen?: (isOpen: boolean) => void;
  onToggle: (isOpen: boolean) => void;
}

const emptyArray: Array<MenuItem> = [];

export const Menu = forwardRef<HTMLDivElement, Props>(
  (
    { widget, isOpen, isSubMenuOpen, setIsOpen, setIsSubMenuOpen, onToggle },
    ref,
  ) => {
    const texts = useFms();

    const [selectedMenuItem, setSelectedMenuItem] = useState<
      MenuItem | undefined
    >();
    const filterMenuItemsByPermission = useFilterMenuItemsByPermission();
    const menuItems = useMenuItems();
    const visibleMenuItems = filterMenuItemsByPermission(menuItems);

    const commonMenuItems = useCommonMenuItems();
    const visibleCommonMenuItems = filterMenuItemsByPermission(commonMenuItems);

    const toggleIsOpen = (): void => {
      setIsOpen(!isOpen);
    };

    const closeDropdown = (): void => {
      setIsOpen(false);
    };

    useEffect(() => {
      onToggle(isOpen);
    }, [isOpen]);

    useEffect(() => {
      setIsSubMenuOpen(!!selectedMenuItem?.children);
    }, [selectedMenuItem?.children, setIsSubMenuOpen]);

    useEffect(() => {
      if (!isSubMenuOpen) {
        setSelectedMenuItem(undefined);
      }
    }, [isSubMenuOpen, setSelectedMenuItem]);

    return (
      <Dropdown
        title={texts.application.menu}
        onClickOutside={closeDropdown}
        toggleIsOpen={toggleIsOpen}
        isOpen={isOpen}
        testId="menu"
      >
        <Container ref={ref}>
          <LeftContainer>
            <MenuItemList
              menuItems={visibleMenuItems}
              selectedMenuItem={selectedMenuItem}
              onSelected={setSelectedMenuItem}
              toggleIsOpen={toggleIsOpen}
              deactivateTabFocus={isSubMenuOpen}
            />

            <DividerContainer>
              <Divider margin="8" />
            </DividerContainer>
            <MenuItemList
              menuItems={visibleCommonMenuItems}
              selectedMenuItem={selectedMenuItem}
              onSelected={setSelectedMenuItem}
              toggleIsOpen={toggleIsOpen}
              deactivateTabFocus={isSubMenuOpen}
            />
          </LeftContainer>
          <RightContainer>
            <AnimatedMenuContainer isOpen={isSubMenuOpen}>
              <MenuItemList
                menuItems={selectedMenuItem?.children ?? emptyArray}
                toggleIsOpen={toggleIsOpen}
                deactivateTabFocus={!isSubMenuOpen}
              />
            </AnimatedMenuContainer>

            <AnimatedMenuContainer isOpen={!isSubMenuOpen}>
              {widget}
            </AnimatedMenuContainer>
          </RightContainer>
        </Container>
      </Dropdown>
    );
  },
);

const Container = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  flex: 1;
  max-width: 50%;
`;

const RightContainer = styled(Surface)`
  flex: 1;
  max-width: 50%;
  display: flex;
`;

const DividerContainer = styled.div`
  padding: 0 16px;
`;

Menu.displayName = Menu.name;
