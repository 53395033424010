import HighchartsReact from 'highcharts-react-official';
import React, { memo } from 'react';
import styled from 'styled-components';

import { Unit } from '@/types/Unit';

import { useHighstocks } from '../highcharts';
import { useLineChartOptions } from './hooks/useLineChartOptions';
import { LineChartData } from './types/LineChartData';

interface Props {
  data: LineChartData;
  unit?: Unit;
  height?: number;
  trackId?: string;
  className?: string;
}

/**
 * Highcharts `line` chart implementation.
 * @param {LineChartData} data - Data to be rendered in the chart.
 * @param {Unit} [unit=Unit.Amount] - Used for number formatting. Defaults to `Amount`.
 * @param {number} [height=400] - The height of the chart in pixels. Defaults to `400`.
 * @param {string} [trackId] - GTM id for interaction tracking.
 * @param {string} [className] - Used for styling `styled-components`.
 */
const Chart: React.FC<Props> = memo(
  ({ data, unit = Unit.Amount, height = 400, trackId, className }: Props) => {
    const chartOptions = useLineChartOptions(data, unit, height);

    const highstock = useHighstocks();

    return (
      <HighchartsReact
        highcharts={highstock}
        options={chartOptions}
        constructorType="stockChart"
        containerProps={{ className, 'data-chartid': trackId }}
        immutable
      />
    );
  },
);

Chart.displayName = Chart.name;

// Must apply the styles to the component that returns the Highcharts component
// since it is that component that takes in the 'className' and forwards it.
// https://stackoverflow.com/a/62090785
export const LineChart = styled(Chart)`
  /* Custom styling for Highcharts. */

  .highcharts-legend-entry {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .highcharts-tooltip {
    > span {
      padding: 8px 16px !important;
      font-family: 'Open Sans';
      line-height: 24px;
    }
  }
`;
