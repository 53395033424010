import Alert from '@storeblocks/alert';
import { FormikErrors } from 'formik';
import React from 'react';

interface Props {
  title: string;
  errors: FormikErrors<unknown>;
}

export const FormValidationSummary: React.FC<Props> = ({ title, errors }) => {
  const stringErrors = Object.values(errors).filter(
    (error) => typeof error === 'string',
  );

  const arrayErrors = Object.values(errors)
    .filter(Array.isArray)
    .flat()
    .filter(Boolean)
    .flatMap(Object.values);

  const errorMessages = [...arrayErrors, ...stringErrors];

  return (
    <Alert
      title={title}
      variant="error"
      fluid
      data-test="form-validation-summary"
    >
      <ul>
        {errorMessages.map((error) => (
          // We don't assign a key since error messages are not unique and
          // can occur multiple times in the array.
          // E.g.: Error message that the fund amount is too low or
          // too large. This will be displayed for each fund that has this
          // condition.
          // eslint-disable-next-line react/jsx-key
          <li>{error}</li>
        ))}
      </ul>
    </Alert>
  );
};
