import React, { FC } from 'react';

import { Column, Row } from '@/components/Grid';
import { Widget, WidgetHeader } from '@/components/Widget';
import { useFms } from '@/hooks/useFms';
import { Country } from '@/types/country';

import { PersonCard } from './PersonCard';

interface Props {
  country: Country;
}

export const PersonContactsWidget: FC<Props> = ({ country }) => {
  const texts = useFms();
  const contactsEntries = texts.contacts[country];

  return (
    <>
      {contactsEntries.map((content) => {
        const title = texts.contacts.title[content.titleLookup];
        const contactList = content.list;

        return (
          <Widget key={title || ''}>
            <WidgetHeader title={title} />

            <Row>
              {contactList.map((contact) => {
                const position =
                  texts.contacts.position[contact.positionLookup];
                return (
                  <Column $span={3} key={contact.name}>
                    <PersonCard
                      name={contact.name}
                      position={position}
                      image={contact.image}
                      email={contact.mail}
                      phone={contact.phone}
                    />
                  </Column>
                );
              })}
            </Row>
          </Widget>
        );
      })}
    </>
  );
};
