import React from 'react';

import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { MainContent } from '@/components/MainContent';

import { ContactForm } from './ContactForm';

export const ContactFormPage: React.FC = () => {
  return (
    <CenteredPageContent data-test="reports-portfolio-contact-us-page">
      <MainContent>
        <ContactForm source={Source.PortfolioReports} />
      </MainContent>
    </CenteredPageContent>
  );
};
