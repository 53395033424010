import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Mask: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledMask
      key="mask"
      transition={{ ease: 'easeInOut' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </StyledMask>
  );
};

const StyledMask = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: var(--color-scrim);
`;
