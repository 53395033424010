import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createLogEvent } from '@/logging/createLogEvent';
import { addLogEvent } from '@/logging/logSlice';
import { findLogDataInClickEvent } from '@/logging/utils/findLogDataInClickEvent';
import { AppDispatch } from '@/store';

export const TrackIdClickedLogger: React.FC<PropsWithChildren> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClicked = useCallback(
    (event: MouseEvent): void => {
      const logData = findLogDataInClickEvent(event);

      if (!logData) {
        return;
      }

      dispatch(
        addLogEvent(
          createLogEvent({
            level: 'info',
            type: 'click',
            data: {
              trackId: logData.trackId,
              ...logData.extraData,
            },
          }),
        ),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('click', handleClicked);

    return () => window.removeEventListener('click', handleClicked);
  }, [handleClicked]);

  return null;
};
