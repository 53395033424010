import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';
import { styled } from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

export const EngagementPage: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useFms();

  return (
    <CenteredPageContent data-test="engagement-page">
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability`}>
          {texts.menu.links.sustainability}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability/engagement`}>
          {texts.menu.links.engagement}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.pages.engagement.title} />
        <StyledIframe src="https://share.esgaia.com/?id=PJzjFEfGHkhwl5ayNtlf" />
      </MainContent>
    </CenteredPageContent>
  );
};

const StyledIframe = styled.iframe`
  border: none;
  height: 7000px;
`;
