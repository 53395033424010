import Checkbox, { Option } from '@storeblocks/checkbox';
import { useField } from 'formik';
import React from 'react';

interface FormCheckboxProps {
  name: string;
  options: Option[];
  disabled?: boolean;
  error?: string;
}

export function FormCheckbox({
  name,
  options,
  disabled,
  error,
}: FormCheckboxProps): JSX.Element {
  const [field, , helpers] = useField<string[]>(name);

  return (
    <Checkbox
      id={field.name}
      options={options}
      value={field.value}
      disabled={disabled}
      onChange={(e, values) => {
        helpers.setValue(values);
      }}
      name={name}
      error={error || undefined}
    />
  );
}
