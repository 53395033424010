import * as Sentry from '@sentry/react';
import { useState } from 'react';

/**
 * Hook to handle async operations that may throw an error.
 *
 * @param initiate Callback to initiate the specified async operation.
 *
 * @returns A function that will initiate the async operation, a boolean that indicates loading state and
 * a boolean that indicates if there was an error.
 */
export const useHandleAsyncOperation = (
  initiate: () => Promise<void>,
): [() => Promise<void>, boolean, boolean] => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const initiateOperation = async (): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      await initiate();
      setIsError(false);
    } catch (error) {
      setIsError(true);
      console.error(error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [initiateOperation, isLoading, isError];
};
