import type { Option } from '@storeblocks/radio';
import { useFormikContext } from 'formik';
import React from 'react';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { RedemptionPayment } from '@/pages/Order/steps/OrderDetails/types/Payment';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';
import { PlacementRadio } from '../../PlacementRadio';

interface Props {
  payment: RedemptionPayment;
  displayCurrencyInTitle: boolean;
  paymentIndex: number;
}

export const RedemptionPaymentPlacementRadio: React.FC<Props> = ({
  payment,
  displayCurrencyInTitle,
  paymentIndex,
}) => {
  const texts = useFms();

  const formContext = useFormikContext<RedemptionOrderFormValues>();

  const portfolios = useFundPortfolios(OrderType.Redemption);

  const portfolioBankAccounts = getPortfolioBankAccounts(
    formContext.values.fromPortfolioShortName,
    portfolios,
  );

  const hasMultiplePortfolioBankAccounts = portfolioBankAccounts.length > 1;

  const options: Option[] = [
    {
      id: `${OrderPaymentType.Portfolio}-${payment.currency}`,
      value: OrderPaymentType.Portfolio,
      label: texts.orders.form.payment.options.portfolioAccount,
    },
    {
      id: `${OrderPaymentType.BankAccount}-${payment.currency}`,
      value: OrderPaymentType.BankAccount,
      label: texts.orders.form.payment.options.externalAccount,
    },
  ];

  const title = displayCurrencyInTitle
    ? fmsWithTemplate(texts.orders.form.payment.titleWithCurrency, {
        currency: payment.currency,
      })
    : texts.orders.form.payment.title;

  /**
   * Explicitly handle the change of payment type when the portfolio has
   * only one portfolio bank account.
   */
  const handlePaymentTypeChanged = async (
    paymentType: OrderPaymentType,
  ): Promise<void> => {
    if (hasMultiplePortfolioBankAccounts) {
      // Nothing to do when the portfolio has multiple bank accounts.
      return;
    }
    // -> The portfolio has only one bank account.
    if (paymentType === OrderPaymentType.Portfolio) {
      // When the portfolio has only one bank account, we can set the value
      // directly without the user having to choose through a select list.
      await formContext.setFieldValue(
        `payments[${paymentIndex}].portfolioBankAccountNumber`,
        portfolioBankAccounts[0].accountNumber,
      );

      // Reset the bank account number when the user chooses the portfolio bank account.
      await formContext.setFieldValue(
        `payments[${paymentIndex}].bankAccountNumber`,
        '',
      );
    } else {
      // Reset the value when the user chooses another payment type.
      formContext.setFieldValue(
        `payments[${paymentIndex}].portfolioBankAccountNumber`,
        '',
      );
    }
  };

  return (
    <PlacementRadio
      title={title}
      options={options}
      paymentIndex={paymentIndex}
      onChange={handlePaymentTypeChanged}
    />
  );
};
