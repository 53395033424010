import type { Option } from '@storeblocks/radio';

import { FMSTexts } from '@/types/fms';

export enum CitizenshipHighlightedCountries {
  Norway = 'NOR',
  Sweden = 'SWE',
  Other = 'OTHER',
}

export const citizenshipHighlightedCountriesOptions = (
  texts: FMSTexts,
): Option[] => [
  {
    id: CitizenshipHighlightedCountries.Norway,
    value: CitizenshipHighlightedCountries.Norway,
    label: texts.orders.signatureRight.citizenship.highlightedCountries.nor,
  },
  {
    id: CitizenshipHighlightedCountries.Sweden,
    value: CitizenshipHighlightedCountries.Sweden,
    label: texts.orders.signatureRight.citizenship.highlightedCountries.swe,
  },
  {
    id: CitizenshipHighlightedCountries.Other,
    value: CitizenshipHighlightedCountries.Other,
    label: texts.orders.signatureRight.citizenship.highlightedCountries.other,
  },
];
