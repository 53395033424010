import { useEffect, useState } from 'react';

import { useYearlyReportsQuery } from '@/api/reports/reportsApi';

import { Report } from '../types/ReportModel';
import { ReportsFilter } from '../types/ReportsFilter';
import { filterByDate } from '../utils/filterByDate';
import { filterByName } from '../utils/filterByName';
import { mapReportFileToReport } from '../utils/mapReportFileToReport';

interface Response {
  yearlyReports: Report[];
  yearlyReportsIsSuccess: boolean;
  yearlyReportsIsError: boolean;
}

export const useYearlyReports = (filter: ReportsFilter): Response => {
  const yearlyReportsQuery = useYearlyReportsQuery();

  const [response, setResponse] = useState<Response>({
    yearlyReports: [],
    yearlyReportsIsSuccess: false,
    yearlyReportsIsError: false,
  });

  // Important! Must use useEffect and not useMemo or any other synchronous
  // solutions. This is due to that this hook is used in conjunction with
  // another hook that does HTTP calls. If this hook is synchronous it will
  // deliver data before the HTTP hook can initiate a state change to say that
  // it will begin fetching. This makes the UI table render twice, once for
  // when this hook is finished (instantly) and a second time when we receive
  // data from the HTTP hook. To avoid this we use useEffect since this will
  // put the code to run in the event loop. Thus the state change in the HTTP
  // hook will be registered at the same time as this code runs. Making the
  // UI table only render once.
  useEffect(() => {
    let wolfReports = yearlyReportsQuery.data?.map(mapReportFileToReport) ?? [];

    wolfReports = filterByDate(wolfReports, filter.startDate, filter.endDate);
    wolfReports = filterByName(wolfReports, filter.name);

    setResponse({
      yearlyReports: wolfReports,
      yearlyReportsIsSuccess: yearlyReportsQuery.isSuccess,
      yearlyReportsIsError: yearlyReportsQuery.isError,
    });
  }, [
    yearlyReportsQuery.data,
    yearlyReportsQuery.isSuccess,
    yearlyReportsQuery.isError,
    filter,
  ]);

  return response;
};
