import { downloadFile } from '@/util/downloadFile';
import { downloadFilesFromIhub } from '@/util/downloadFilesFromIHub';

import { Report, ReportSource } from '../types/ReportModel';

export function downloadReports(files: Array<Report>): Promise<void[]> {
  const crossroadsFiles = files.filter(
    (file) => file.source === ReportSource.Crossroads,
  );

  const downloadsFromCrossroads = crossroadsFiles.map(async (file) => {
    const url = `${window.config.connectBaseApiUrl}/api/reports/wolf/${file.id}`;
    return downloadFile(url);
  });

  const ihubSharedFiles = files.filter(
    (file) => file.source === ReportSource.IHubShared,
  );
  const downloadsFromIhubShared = downloadFilesFromIhub(
    ihubSharedFiles.map((file) => file.id),
    ReportSource.IHubShared,
  );

  const ihubOrganizationFiles = files.filter(
    (file) => file.source === ReportSource.IHubOrganization,
  );
  const downloadsFromIhubOrganization = downloadFilesFromIhub(
    ihubOrganizationFiles.map((file) => file.id),
    ReportSource.IHubOrganization,
  );

  return Promise.all([
    ...downloadsFromCrossroads,
    downloadsFromIhubShared,
    downloadsFromIhubOrganization,
  ]);
}
