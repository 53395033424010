import Table, { IColumn } from '@storeblocks/table';
import React from 'react';
import styled from 'styled-components';

import { Fund } from '@/api/funds/models';
import { useFms } from '@/hooks/useFms';

import { createFundListRow } from '../utils/createFundListRow';

interface Props {
  funds: Array<Fund>;
  fundsWithHoldings: Array<string>;
}

export const FundList: React.FC<Props> = ({ funds, fundsWithHoldings }) => {
  const texts = useFms();

  const columns: IColumn[] = [
    {
      name: texts.orders.form.addFundModal.table.columnTitle.selectFund,
      key: 'select',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.orders.form.addFundModal.table.columnTitle.fundName,
      key: 'fund',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.orders.form.addFundModal.table.columnTitle
        .minimumSubscriptionAmount,
      key: 'minimumSubscriptionAmount',
      renderType: 'jsx',
      dataType: 'other',
    },
  ];

  const rows = funds.map((fund) =>
    createFundListRow(fund, fundsWithHoldings.includes(fund.isin)),
  );

  return (
    <TableStyled data-test="modal-fund-list" columns={columns} data={rows} />
  );
};

const TableStyled = styled(Table)`
  & table {
    table-layout: auto;
  }

  /*
    Make the second column take all the remaining space
    to make the other columns only be as wide as its content.
  */
  & th:nth-of-type(2) {
    width: 100%;
  }

  & th:last-child,
  td:last-child {
    white-space: nowrap;
    text-align: right;
  }
`;
