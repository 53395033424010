import parseISO from 'date-fns/parseISO';

import { AssetPerformanceDataDto, AssetPerformanceDto } from '../dtos';
import { AssetPerformance, AssetPerformanceData } from '../models';

export const transformAssetPerformanceResponse = (
  dto: AssetPerformanceDto,
): AssetPerformance => {
  return {
    date: parseISO(dto.date),
    clientName: dto.clientName,
    portfolioGroup: dto.portfolioGroup,
    assets: dto.assets.map(mapAssetPerformanceDataDtoToModel),
  };
};

const mapAssetPerformanceDataDtoToModel = (
  dto: AssetPerformanceDataDto,
): AssetPerformanceData => {
  return {
    assetRatioPercent: dto.assetRatioPercent,
    assetRatioOfTotalPercent: dto.assetRatioOfTotalPercent,
    assets: dto.assets.map(mapAssetPerformanceDataDtoToModel),
    benchmarkMonthToDateAmount: dto.benchmarkMonthToDateAmount,
    benchmarkMonthToDateFactor: dto.benchmarkMonthToDateFactor,
    benchmarkMonthToDatePercent: dto.benchmarkMonthToDatePercent,
    benchmarkQuarterToDateAmount: dto.benchmarkQuarterToDateAmount,
    benchmarkQuarterToDateFactor: dto.benchmarkQuarterToDateFactor,
    benchmarkQuarterToDatePercent: dto.benchmarkQuarterToDatePercent,
    benchmarkYearToDateAmount: dto.benchmarkYearToDateAmount,
    benchmarkYearToDateFactor: dto.benchmarkYearToDateFactor,
    benchmarkYearToDatePercent: dto.benchmarkYearToDatePercent,
    marketValue: dto.marketValue,
    monthToDateAmount: dto.monthToDateAmount,
    monthToDateExcessReturnAmount: dto.monthToDateExcessReturnAmount,
    monthToDateExcessReturnFactor: dto.monthToDateExcessReturnFactor,
    monthToDateExcessReturnPercent: dto.monthToDateExcessReturnPercent,
    monthToDateFactor: dto.monthToDateFactor,
    monthToDatePercent: dto.monthToDatePercent,
    name: dto.name,
    nodeDepth: dto.nodeDepth,
    nodeLevel: dto.nodeLevel,
    quarterToDateAmount: dto.quarterToDateAmount,
    quarterToDateExcessReturnAmount: dto.quarterToDateExcessReturnAmount,
    quarterToDateExcessReturnFactor: dto.quarterToDateExcessReturnFactor,
    quarterToDateExcessReturnPercent: dto.quarterToDateExcessReturnPercent,
    quarterToDateFactor: dto.quarterToDateFactor,
    quarterToDatePercent: dto.quarterToDatePercent,
    yearToDateAmount: dto.yearToDateAmount,
    yearToDateExcessReturnAmount: dto.yearToDateExcessReturnAmount,
    yearToDateExcessReturnFactor: dto.yearToDateExcessReturnFactor,
    yearToDateExcessReturnPercent: dto.yearToDateExcessReturnPercent,
    yearToDateFactor: dto.yearToDateFactor,
    yearToDatePercent: dto.yearToDatePercent,
  };
};
