import { Permission } from '@/api/permissions/models/Permission';
import { HasPermission } from '@/components/Permissions/useHasPermission';

import { SignatoryOption } from '../types/SignatoryOption';

/* Get the default signatory option based on the users permissions. */
export const getDefaultSignatoryOption = (
  hasPermission: HasPermission,
): SignatoryOption => {
  const hasOrdersSignPermission = hasPermission(Permission.OrdersSign);

  // Default signatory option to unknown to allow the user to make a choice
  // if the user has permission to sign orders.
  // Otherwise, default to other as the user must choose other signers.
  return hasOrdersSignPermission
    ? SignatoryOption.Unknown
    : SignatoryOption.Other;
};
