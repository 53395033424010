import Input from '@storeblocks/input';
import React from 'react';

import { useFms } from '@/hooks/useFms';

interface Props {
  value: string;
  onChange: (query: string) => void;
}

export const SearchField: React.FC<Props> = ({ value, onChange }) => {
  const texts = useFms();

  return (
    <Input
      id="fund-list-search-input"
      name="fund-list-search-input"
      label={texts.orders.form.addFundModal.table.columnTitle.fundName}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      icon="search"
      fluid
      inputProps={{
        autoComplete: 'off',
      }}
    />
  );
};
