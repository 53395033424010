import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useUserSettingsQuery } from '@/api/userSettings/userSettingsApi';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';
import { useSearchParams } from '@/hooks/useSearchParams';

import { UserSettingsForm } from './UserSettingsForm';

/**
 * Page for forcing the user to update their settings.
 */
export const ForceUpdateInformationPage: React.FC = () => {
  const { data: userSettings } = useUserSettingsQuery();

  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const [redirectUrl] = useState(
    decodeURIComponent(searchParams.get('redirectUrl')),
  );

  const texts = useFms();

  useEffect(() => {
    if (!userSettings.updateNeeded) {
      navigate(redirectUrl, { replace: true });
    }
  }, [userSettings.updateNeeded]);

  return (
    <CenteredPageContent data-test="force-update-information-page">
      <PageHeading
        header={texts.pages.forceUpdateInformation.title}
        ingress={texts.pages.forceUpdateInformation.description}
      />

      <UserSettingsForm forceUpdate />
    </CenteredPageContent>
  );
};
