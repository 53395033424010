import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { InternalLink } from '@/components/InternalLink';
import { appConfig } from '@/constants/config';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { Divider } from '../../components/Divider';
import { Column, Row } from '../../components/Grid';
import { PageHeading } from '../../components/PageHeading';
import { Ingress } from '../../components/Typography';
import { WithGap } from '../../components/WithGap';

export const InvitationalLinkErrorPage: React.FC = () => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <Center data-test="invitation-error-page">
      <Page>
        <PageHeading header={texts.error.description.invitation.title} />
        <Divider margin="8" />
        <StyledRow>
          <Column $span={6}>
            <StyledWithGap>
              <Ingress>
                {texts.error.description.invitation.part1}{' '}
                <a href={`mailto:${texts.error.siosEmail}`}>
                  {texts.error.siosEmail}
                </a>{' '}
                {texts.error.description.invitation.part2}
              </Ingress>
              <Button
                as={InternalLink}
                to="/"
                iconLeft="arrow-left"
                variant="text"
                data-trackid={getTrackId('go-to-connect', TrackingElement.Link)}
              >
                {texts.error.buttons.backToConnect}
              </Button>
            </StyledWithGap>
          </Column>
          <StyledColumn $span={6}>
            <Illustration name="something-went-wrong" height={500} />
          </StyledColumn>
        </StyledRow>
      </Page>
    </Center>
  );
};

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Page = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;

const StyledWithGap = styled(WithGap)`
  align-items: start;
`;
