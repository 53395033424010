import LinkList from '@storeblocks/link-list';
import Surface from '@storeblocks/surface';
import React, { FC } from 'react';
import styled from 'styled-components';

import { H2 } from '@/components/Typography/H2';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const AssetAllocationBannerWidget: FC = () => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const { heading, link1, link2 } = texts.news.tiles.largestAssetManager;

  const links = [
    link1.title &&
      link1.link && {
        ...link1,
        name: 'link1',
      },
    link2.title &&
      link2.link && {
        ...link2,
        name: 'link2',
      },
  ].filter(Boolean);

  return (
    <StyledSurface variant="border">
      <Heading>{heading}</Heading>
      <LinkList>
        {links.map((link) => (
          <a
            key={link.link}
            href={link.link}
            target="_blank"
            data-trackid={getTrackId(
              `go-to-asset-allocation-${link.name}`,
              TrackingElement.Link,
            )}
            rel="noreferrer"
          >
            {link.title}
          </a>
        ))}
      </LinkList>
    </StyledSurface>
  );
};

const StyledSurface = styled(Surface)`
  height: 100%;
  padding: 24px;
`;

const Heading = styled(H2)`
  margin-top: 0;
`;
