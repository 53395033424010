import { Locale } from '@/i18n/locale';

export const getNumberSeparators = (
  locale: Locale,
): {
  thousandSeparator: string;
  decimalSeparator: string;
} => {
  if (locale === Locale.English) {
    return { thousandSeparator: ',', decimalSeparator: '.' };
  }

  return { thousandSeparator: ' ', decimalSeparator: ',' };
};
