import { Options } from 'highcharts';

export const getDefaultGaugeOptions = (size: number): Options => ({
  chart: {
    type: 'solidgauge',
    backgroundColor: 'transparent',
    width: size,
    height: size / 2 + 2,
    spacing: [0, 0, 0, 0],
  },
  pane: {
    startAngle: -90,
    endAngle: 90,
    background: [],
    size: size,
    center: ['50%', '100%'],
  },
  plotOptions: {
    solidgauge: {
      // innerRadius is in percentage.
      // The Highcharts docs says it can be in pixels, but thats a lie.
      // And in addition it is not "radius", it is the diameter. The property
      // and documentation is lying there too :(
      innerRadius: `${100 - (48 / size) * 100}%`,
      linecap: 'round',
      stickyTracking: false,
      enableMouseTracking: false,
      borderWidth: 1,
      borderColor: 'var(--color-on-background)',
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickWidth: 0,
    minorTickWidth: 0,
    labels: {
      enabled: false,
    },
  },
  title: undefined,
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
});
