import * as Yup from 'yup';

import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { FMSTexts } from '@/types/fms';

import { ContactFormValues } from './contactFormValues';
import { isMessageValid } from './isMessageValid';
import { isPhoneNumberFormatValid } from './isPhoneNumberFormatValid';

export const contactFormValidation = (
  texts: FMSTexts,
): Yup.SchemaOf<ContactFormValues> =>
  Yup.object({
    phoneNumber: Yup.string()
      // Note that the minimum length validation is not added.
      // It is hard to determine the proper minimum length for a phone number.
      // Thus it is better to leave it out.
      // For context, here are some examples of phone number lengths:
      // Icelandic phone numbers are 7 digits long.
      // Swedish phone numbers are 7-13 digits long.
      // Norwegian phone numbers are 8 digits long.
      .test(
        'is-phone-number-format-valid',
        texts.pages.contactUs.form.invalidPhoneNumber,
        (value, context) => {
          if (!value) {
            // The phone number is required.
            // Thus it cannot be empty.
            return context.createError({
              message: texts.pages.contactUs.form.missingPhoneNumber,
            });
          }

          return isPhoneNumberFormatValid(value || '');
        },
      )
      .required(texts.pages.contactUs.form.missingPhoneNumber),

    email: Yup.string()
      .email(texts.pages.contactUs.form.invalidEmail)
      .required(texts.pages.contactUs.form.missingEmail),

    message: Yup.string()
      .test(
        'is-message-valid',
        texts.pages.contactUs.form.invalidCharacters,
        (value, context) => {
          if (!value) {
            // The message is required.
            // Thus it cannot be empty.
            return context.createError({
              message: texts.pages.contactUs.form.missingMessage,
            });
          }

          const validationResult = isMessageValid(value || '');

          if (validationResult.isValid) {
            // All good!
            // The message is valid.
            return true;
          }

          // The message is invalid.
          // Return an error with the invalid characters.
          return context.createError({
            message: fmsWithTemplate(
              texts.pages.contactUs.form.invalidCharacters,
              {
                characters: validationResult.invalidCharacters.join(' '),
              },
            ),
          });
        },
      )
      .required(texts.pages.contactUs.form.missingMessage),
  });
