import sortBy from 'lodash/sortBy';

import { OrganizationDto } from '../dtos/OrganizationDto';
import { OrganizationsReply } from '../dtos/OrganizationsReply';
import { Organization } from '../models/Organization';

export const transformOrganizationsReply = (
  dto: OrganizationsReply,
): Array<Organization> => {
  return sortBy(
    dto.organizations.map(mapOrganizationDtoToModel),
    (d) => d.organizationName,
  );
};

const mapOrganizationDtoToModel = (dto: OrganizationDto): Organization => ({
  organizationId: dto.organizationId,
  organizationName: dto.organizationName,
});
