import { apiClientCreator } from '@/api/axios/apiClientCreator';

import { fileDownload } from './FileDownload';

export const downloadFile = async (url: string): Promise<void> => {
  const response = await apiClientCreator({ useKeycloakToken: true }).get(url, {
    responseType: 'blob',
  });

  const filename = response.headers['content-disposition']
    ?.split('filename=')
    .pop();

  if (!filename) {
    throw new Error(
      `Failed to download the file. Missing filename in the response headers. Download URL [${url}]`,
    );
  }

  fileDownload(response.data, filename);
};
