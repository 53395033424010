import React from 'react';

import { HoldingsCalculationType } from '@/api/portfolioGroupHoldings/models/HoldingsCalculationType';
import { PortfolioHoldingsTable } from '@/components/PortfolioHoldingsTable/PortfolioHoldingsTable';
import { H1 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { CollapsibleFilterContainer } from '../filters/CollapsibleFilterContainer';
import { Filter } from '../filters/Filter';
import { HoldingsCalculationTypeChips } from '../filters/HoldingsCalculationTypeChips';
import { getHoldingsCalculationText } from '../filters/utils/getHoldingsCalculationText';
import {
  useFilterForScreen,
  usePresentationContext,
} from '../PresentationContext';

interface Filters {
  selectedHoldingsCalculationType: HoldingsCalculationType;
}

const screenKey = 'portfolio-holdings';

export const PortfolioHoldingsScreen: React.FC = () => {
  const texts = useFms();

  const { globalFilter, setFilter, isPrintPreviewPage } =
    usePresentationContext();

  const filters = useFilterForScreen<Filters>(screenKey, {
    selectedHoldingsCalculationType: HoldingsCalculationType.EOD,
  });

  const portfolioGroupIds = globalFilter.portfolioGroups.map(
    (portfolioGroup) => portfolioGroup.id,
  );

  const holdingsCalculationTexts = getHoldingsCalculationText(
    filters.selectedHoldingsCalculationType,
    texts,
  );

  return (
    <A4WidthForPrint>
      <WithGap data-test="portfolio-holdings-screen">
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.portfolioHoldings.title}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>
        <CollapsibleFilterContainer
          selectedFilterTexts={[holdingsCalculationTexts]}
        >
          <WithGap>
            <Filter
              label={
                texts.pages.presentation.filters.holdingsCalculationType.label
              }
            >
              <HoldingsCalculationTypeChips
                checked={filters.selectedHoldingsCalculationType}
                onChange={(selectedHoldingsCalculationType) =>
                  setFilter<Filters>('portfolio-holdings', {
                    selectedHoldingsCalculationType,
                  })
                }
              />
            </Filter>
          </WithGap>
        </CollapsibleFilterContainer>
        <PortfolioHoldingsTable
          portfolioGroupIds={portfolioGroupIds}
          calculationType={filters.selectedHoldingsCalculationType}
          expanded
          displayVerticalScrollbar={!isPrintPreviewPage}
        />
      </WithGap>
    </A4WidthForPrint>
  );
};
