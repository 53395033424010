import LoadingIndicator from '@storeblocks/loading-indicator';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  center?: boolean;
}

export const Pending: FC<Props> = ({ center }) => {
  return (
    <PendingContainer $center={center}>
      <LoadingIndicator />
    </PendingContainer>
  );
};

interface PendingContainerProps {
  $center?: boolean;
}

const PendingContainer = styled.div<PendingContainerProps>`
  height: 100%;
  margin: 16px 0;
  display: flex;
  flex: 1 0 auto;
  align-items: ${({ $center }) => ($center ? 'center' : 'unset')};
  justify-content: ${({ $center }) => ($center ? 'center' : 'unset')};
`;
