import Fuse from 'fuse.js';

import { Report } from '../types/ReportModel';

export const filterByName = (reports: Report[], name?: string): Report[] => {
  if (!name) {
    return reports;
  }

  const fuse = new Fuse(reports, {
    keys: ['name'],

    // Make the most relevant result be on top.
    shouldSort: true,

    // Make the search more restrictive as not to show results that is not
    // relevant.
    threshold: 0.3,
  });

  // Use fuzzy search.
  return fuse.search(name).map((result) => result.item);
};
