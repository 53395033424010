import max from 'date-fns/max';
import min from 'date-fns/min';

import { MultiPortfolioReturns } from '../models';
import { PortfolioReturns } from '../models/PortfolioReturns';

export const mapMultiPortfolioReturns = (
  returns: Array<PortfolioReturns>,
): MultiPortfolioReturns => {
  const firstPointDates = returns.map((returns) => returns.firstPointDate);

  const lastPointDates = returns.map((returns) => returns.lastPointDate);

  const firstPointDate = min(firstPointDates);
  const lastPointDate = max(lastPointDates);

  return {
    returns,
    firstPointDate,
    lastPointDate,
  };
};
