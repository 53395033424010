import { isCypressE2E } from '@/config/utils/isCypressE2E';

export const getBasename = (): string => {
  // When running the Cypress tests in e2e mode (Gradle/Jenkins)
  // the localhost basename is prefixed with `/connect/public`
  // and this must also be reflected in the routes.
  if (isCypressE2E()) {
    return '/connect/public';
  }

  return '';
};
