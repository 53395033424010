export interface Report {
  id: string;
  reportDateISO: string;
  name: string;
  size: number;
  fileType: string;
  source: ReportSource;
}

export enum ReportSource {
  IHubShared = 'ihub-shared',
  IHubOrganization = 'ihub-organization',
  Crossroads = 'crossroads',
}

export type IHubSource =
  | ReportSource.IHubShared
  | ReportSource.IHubOrganization;
