import React from 'react';

import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { CurrencyCode } from '@/types/CurrencyCode';

import { TableRowHeader } from './TableRowHeader';

interface Props {
  currency: CurrencyCode;
  displayCurrency: boolean;
  noBorder: boolean;
}

export const RedemptionPaymentRowHeadingCell: React.FC<Props> = ({
  currency,
  displayCurrency,
  noBorder,
}) => {
  const texts = useFms();

  const title = displayCurrency
    ? fmsWithTemplate(texts.orders.form.table.redemption.paymentWithCurrency, {
        currency,
      })
    : texts.orders.form.table.redemption.payment;

  return (
    <TableRowHeader colSpan={2} noBorder={noBorder}>
      {title}
    </TableRowHeader>
  );
};
