import { createApi } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';

import { axiosBaseQuery, RtkBaseQueryResponse } from '../axios/axiosBaseQuery';
import { RequestConfig } from '../axios/axiosRequest';
import { PortfolioGroupHoldingsReply } from './dtos/PortfolioGroupHoldingsReply';
import { HoldingsCalculationType } from './models/HoldingsCalculationType';
import { MultiPortfolioGroupHoldings } from './models/MultiPortfolioGroupHoldings';
import { getMultiPortfolioGroupHoldingsSummary } from './utils/getMultiPortfolioGroupHoldingsSummary';
import { transformPortfolioHoldingsResponse } from './utils/transformPortfolioHoldingsResponse';

interface PortfolioGroupHoldingsArguments {
  portfolioGroupIds: Array<string>;
  calculationType: HoldingsCalculationType;
}

export const multiPortfolioGroupHoldingsApi = createApi({
  reducerPath: 'multiPortfolioGroupHoldingsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    multiPortfolioGroupHoldings: builder.query<
      MultiPortfolioGroupHoldings,
      PortfolioGroupHoldingsArguments
    >({
      async queryFn(
        arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ: (
          args: RequestConfig,
        ) => Promise<RtkBaseQueryResponse<PortfolioGroupHoldingsReply>>,
      ) {
        const requests = arg.portfolioGroupIds.map(async (portfolioGroupId) => {
          const response = await fetchWithBQ({
            url: `${window.config.connectBaseApiUrl}/api/portfolioGroup/${portfolioGroupId}/holdings`,
            params: {
              calculationType: arg.calculationType,
            },
          });

          if (response.error) {
            return {
              error: response.error,
            };
          }

          if (isEmpty(response.data)) {
            return {
              data: undefined,
            };
          }

          return {
            data: transformPortfolioHoldingsResponse(
              portfolioGroupId,
              response.data,
            ),
          };
        });

        const responses = await Promise.all(requests);

        const responseWithError = responses.find((response) => response.error);

        if (responseWithError) {
          return {
            error: responseWithError.error,
          };
        }

        const multiPortfolioGroupHoldings = responses
          .filter((response) => !!response.data)
          .map((response) => response.data);

        if (multiPortfolioGroupHoldings.length === 0) {
          return {
            data: undefined,
          };
        }

        return {
          data: {
            holdings: multiPortfolioGroupHoldings,
            summary: getMultiPortfolioGroupHoldingsSummary(
              multiPortfolioGroupHoldings,
            ),
          },
        };
      },
    }),
  }),
});

export const { useMultiPortfolioGroupHoldingsQuery } =
  multiPortfolioGroupHoldingsApi;
