import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LogEvent } from '@/logging/types/LogEvent';

import { LogState } from './LogState';

const initialState: LogState = {
  events: [],
};

const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    addLogEvent(state, action: PayloadAction<LogEvent>) {
      state.events.push(action.payload);
    },
  },
});

export const logReducer = logSlice.reducer;

export const { addLogEvent } = logSlice.actions;
