export enum DocumentCategory {
  BoardDocuments = 'BOARD_DOCUMENTS',
  BoardMeetingMinutes = 'BOARD_MEETING_MINUTES',
  Accounting = 'ACCOUNTING',
  AgreementsAndSignedContracts = 'AGREEMENTS_AND_SIGNED_CONTRACTS',
  StrategyAndPolicyDocs = 'STRATEGY_AND_POLICYDOCS',
  FinancialSupervisoryAdvisory = 'FINANCIAL_SUPERVISORY_ADVISORY',
  Correspondence = 'CORRESPONDENCE',
  AssetManagement = 'ASSET_MANAGEMENT',
  Reinsurance = 'REINSURANCE',
  Presentations = 'PRESENTATIONS',
  InternalControl = 'INTERNAL_CONTROL',
  Instructions = 'INSTRUCTIONS',
  MeetingSchedule = 'MEETING_SCHEDULE',
  MiscellaneousDocuments = 'MISCELLANEOUS_DOCUMENTS',
  EquityOrSubordinatedLoan = 'EQUITY_OR_SUBORDINATED_LOAN',
}
