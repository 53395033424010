import * as Yup from 'yup';

import { Language } from '@/i18n/Language';
import { FMSTexts } from '@/types/fms';

import { UserSettingsFormData } from './UserSettingsFormData';

export const useUserSettingsValidationSchema = (
  texts: FMSTexts,
): Yup.SchemaOf<UserSettingsFormData> => {
  return Yup.object({
    email: Yup.string()
      .email(texts.userSettings.emailInvalid)
      .required(texts.userSettings.emailRequired),
    language: Yup.mixed<Language>()
      .oneOf(Object.values(Language))
      .required(texts.userSettings.languageRequired),
  });
};
