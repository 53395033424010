import { OrderType } from '@/api/orderList/models/Order';
import { FMSTexts } from '@/types/fms';

export const orderTypeToFmsText = (
  orderType: OrderType,
  texts: FMSTexts,
): string => {
  switch (orderType) {
    case OrderType.Subscription:
      return texts.pages.orderList.orderType.subscription;

    case OrderType.Redemption:
      return texts.pages.orderList.orderType.redemption;

    case OrderType.Switch:
      return texts.pages.orderList.orderType.switch;

    default:
      throw new Error(`Missing translation for order type [${orderType}].`);
  }
};
