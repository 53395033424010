import Alert from '@storeblocks/alert';
import React, { FC } from 'react';

import { QueryState } from '@/api/components/QueryState';
import { usePortfoliosQuery } from '@/api/portfolios';
import { isNotReady } from '@/api/utils/isNotReady';
import { MainContent } from '@/components/MainContent';
import { TransactionsTable } from '@/components/TransactionsTable/TransactionsTable';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

export const TransactionsPage: FC = () => {
  const texts = useFms();
  const portfoliosQuery = usePortfoliosQuery();
  const hasPortfolios = portfoliosQuery.data?.portfolioGroups.length > 0;

  if (isNotReady(portfoliosQuery)) {
    return <QueryState query={portfoliosQuery} />;
  }

  return (
    <WithGap>
      <MainContent>
        {hasPortfolios ? (
          <TransactionsTable />
        ) : (
          <Alert
            title={texts.transactions.table.alert.noPortfolios}
            data-test="no-portfolio-alert"
          />
        )}
      </MainContent>
    </WithGap>
  );
};
