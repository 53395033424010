import styled, { css } from 'styled-components';

export const ingressStyle = css`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;

export const Ingress = styled.span`
  ${ingressStyle}
  color: var(--color-on-background);
`;
