import { Draft, produce } from 'immer';
import isDeepEqual from 'lodash/isEqual';
import { useRef, useState } from 'react';

interface SearchParams<T> {
  params: T;
  update: (updater: (state: Draft<T>) => void) => void;
}

export const useSearchParams = <T>(
  onChange: (params: T) => void,
  initialState: T = {} as T,
): SearchParams<T> => {
  const [searchParams, setSearchParams] = useState<T>(initialState);

  // The value from the useSate does not have the updated value in closures,
  // it is always only the initial value. Thus use useRef to have this value
  // in the updater function closure.
  const searchParamsRef = useRef(searchParams);

  const updateSearchParams = (updater: (state: Draft<T>) => void): void => {
    const currentSearchParams = searchParamsRef.current;

    // Use immer to make immutable changes to the state.
    const updatedSearchParams = produce(currentSearchParams, updater);

    // Only update if the search params has truly changed.
    if (!isDeepEqual(currentSearchParams, updatedSearchParams)) {
      searchParamsRef.current = updatedSearchParams;
      setSearchParams(updatedSearchParams);
      onChange(updatedSearchParams);
    }
  };

  return { params: searchParams, update: updateSearchParams };
};
