import React, { FC } from 'react';
import styled from 'styled-components';

type DividerSize = '1' | '2' | '3';

type DividerMargin = '8' | '16' | '24' | '32' | '40' | '48';

interface Props {
  size?: DividerSize;
  margin?: DividerMargin;
  className?: string;
}

export const Divider: FC<Props> = ({
  size = '1',
  margin = '48',
  className,
}) => {
  return <StyledDivider size={size} margin={margin} className={className} />;
};

const StyledDivider = styled.hr<Props>`
  width: 100%;
  background: var(--color-border);
  height: ${(props) => props.size}px;
  margin-top: ${(props) => props.margin}px;
  margin-bottom: ${(props) => props.margin}px;
  border: none;
`;
