import { Options } from 'highcharts';

import { useUserLocale } from '@/i18n/hooks';
import { Unit } from '@/types/Unit';

import { getUnitNumberFormat } from '../utils/getUnitNumberFormat';
import { DonutChartData } from './types/DonutChartData';

export const useDonutChartOptions = (
  data: DonutChartData,
  size: number,
  unit: Unit,
  positionLegendBottom: boolean,
): Options => {
  const locale = useUserLocale();

  return {
    chart: {
      type: 'pie',
      height: size + 200,
      numberFormatter(value) {
        return getUnitNumberFormat(value, unit, locale);
      },
    },
    plotOptions: {
      pie: {
        size,
        innerSize: '70%',
        showInLegend: true,
        dataLabels: {
          formatter() {
            return `${this.point.name}<br/><b>${getUnitNumberFormat(
              this.y,
              unit,
              locale,
            )}</b>`;
          },
          connectorWidth: 0,
          style: {
            fontSize: '14px',
            fontWeight: '400',
          },
        },
        tooltip: {
          pointFormatter() {
            return `<b>${getUnitNumberFormat(this.y, unit, locale)}</b>`;
          },
        },
        borderColor: 'var(--color-blue-70)',
        borderWidth: 1,
      },
    },
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontSize: '14px',
      },
      itemDistance: 24,
      itemMarginTop: 8,
      symbolRadius: 0,
      symbolHeight: 12,
      symbolWidth: 12,
      ...(!positionLegendBottom && {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        width: 250,
      }),
    },
    colors: [
      'var(--color-blue-30)',
      'var(--color-blue-70)',
      'var(--color-blue-20)',
      'var(--color-blue-50)',
      'var(--color-green-10)',
      'var(--color-green-50)',
      'var(--color-green-70)',
      'var(--color-green-30)',
      'var(--color-beige-70)',
      'var(--color-beige-30)',
      'var(--color-beige-90)',
      'var(--color-beige-60)',
      'var(--color-brand)',
    ],
    series: [
      {
        type: 'pie',
        data,
      },
    ],
    credits: {
      enabled: false,
    },

    title: undefined,
  };
};
