import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import InlineAlert from '@storeblocks/inline-alert';
import { Input } from '@storeblocks/input';
import { Option, Radio } from '@storeblocks/radio';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import {
  useUpdateUserSettingsMutation,
  useUserSettingsQuery,
} from '@/api/userSettings/userSettingsApi';
import { getAuth } from '@/auth/Auth';
import { FormValidationSummary } from '@/components/Form/FormValidationSummary';
import { formHasErrors } from '@/components/Form/utils/formHasErrors';
import { isInternalUser } from '@/config/utils';
import { useFms } from '@/hooks/useFms';
import { Language } from '@/i18n/Language';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { UserSettingsFormData } from './UserSettingsFormData';
import { useUserSettingsValidationSchema } from './useUserSettingsValidationSchema';

interface Props {
  forceUpdate?: boolean;
}

export const UserSettingsForm: React.FC<Props> = ({ forceUpdate }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  // For internal users we already know their email address.
  // Prefill the email field with their '@storebrand' email
  const initialEmailAddress = isInternalUser()
    ? getAuth().getTokenParsed().email || ''
    : '';

  const validationSchema = useUserSettingsValidationSchema(texts);

  const { data: userSettings, refetch } = useUserSettingsQuery();
  const [patchUserSettings, userSettingsMutation] =
    useUpdateUserSettingsMutation();

  const [initialValues, setInitialValues] = useState<UserSettingsFormData>({
    email: userSettings.emailBusiness || initialEmailAddress,
    language: userSettings.language,
  });

  const languageOptions: Array<Option> = [
    {
      id: Language.Norwegian,
      label: texts.language.noLabel,
      value: Language.Norwegian,
    },
    {
      id: Language.English,
      label: texts.language.enLabel,
      value: Language.English,
    },
    {
      id: Language.Swedish,
      label: texts.language.svLabel,
      value: Language.Swedish,
    },
  ];

  const handleSubmit = async (values: UserSettingsFormData): Promise<void> => {
    await patchUserSettings({
      emailBusiness: values.email,
      language: values.language,
    });

    // Reset the form with new initial values after submission.
    setInitialValues(values);
  };

  // :: The user has updated their setting, we must therefore fetch them again to get the updated settings.
  useEffect(() => {
    if (userSettingsMutation.isSuccess) {
      refetch();
    }
  }, [userSettingsMutation.isSuccess]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        isSubmitting,
        errors,
        dirty,
        submitCount,
        submitForm,
        getFieldProps,
      }: FormikProps<UserSettingsFormData>) => (
        <>
          <Input
            id="email"
            type="email"
            label={texts.userSettings.changeEmail}
            hint={texts.userSettings.contactDetailsDescription}
            error={errors.email}
            large
            {...getFieldProps('email')}
          />
          <Radio
            id="language-selector"
            label={texts.pages.forceUpdateInformation.language.title}
            options={languageOptions}
            error={errors.language}
            {...getFieldProps('language')}
          />
          <div>
            <Button
              id="submit"
              type="submit"
              loading={isSubmitting}
              data-trackid={getTrackId(
                'save-user-settings',
                TrackingElement.Button,
              )}
              onClick={submitForm}
            >
              {texts.pages.userSettings.save}
            </Button>
          </div>

          {submitCount > 0 && formHasErrors(errors) && (
            <FormValidationSummary
              title={texts.pages.userSettings.validationSummary.title}
              errors={errors}
            />
          )}

          {userSettingsMutation.isError && (
            <Alert fluid variant="error" title={texts.genericError} />
          )}

          {!dirty && userSettingsMutation.isSuccess && (
            <InlineAlert
              data-test="success-alert"
              variant="success"
              title={
                forceUpdate
                  ? texts.pages.forceUpdateInformation.confirm
                  : texts.pages.userSettings.success
              }
            />
          )}
        </>
      )}
    </Formik>
  );
};
