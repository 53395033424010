import { FundHoldings } from '@/api/fundHoldings/models';
import { byFundKey } from '@/api/fundHoldings/utils';
import { Fund } from '@/api/funds/models';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { groupByBoolean } from '@/util/groupByBoolean';

interface FundStates {
  withHoldings: SubscriptionFundState[];
  withoutHoldings: SubscriptionFundState[];
}

export const mapOrderPlacementToSubscriptionFundStates = (
  orderPlacements: OrderPlacement[],
  holdingsByFund: Record<string, FundHoldings>,
  fundByIsin: Record<string, Fund>,
): FundStates => {
  const orderPlacementsHasHoldings = groupByBoolean(
    orderPlacements,
    (placement) =>
      !!holdingsByFund[byFundKey(placement.accountId, placement.isin)],
  );

  const fundStatesWithHoldings = orderPlacementsHasHoldings.truthy.map(
    (placement) => {
      const holdings =
        holdingsByFund[byFundKey(placement.accountId, placement.isin)];
      const fund = fundByIsin[placement.isin];

      const fundState: SubscriptionFundState = {
        accountId: placement.accountId,
        isin: placement.isin,
        name: placement.fundName,
        currency: placement.currency,
        brand: fund.brand,

        units: holdings.units,
        nav: holdings.nav,
        // Remove decimals from the market value as they are not needed in the UI.
        marketValue: Math.trunc(holdings.marketValue),

        minimumSubscriptionAmount: fund.minimumSubsequentSubscriptionAmount,

        isClosed: fund.closedForSubscription,

        value: placement.value,
        valueType: placement.valueType,
      };

      return fundState;
    },
  );

  const fundStateWithoutHoldings = orderPlacementsHasHoldings.falsy.map(
    (placement) => {
      const fund = fundByIsin[placement.isin];

      const fundState: SubscriptionFundState = {
        accountId: placement.accountId,
        isin: placement.isin,
        name: placement.fundName,
        currency: placement.currency,
        brand: fund.brand,
        minimumSubscriptionAmount: fund.minimumInitialSubscriptionAmount,
        units: 0,
        nav: 0,
        value: placement.value,
        valueType: placement.valueType,
        marketValue: 0,
        isClosed: fund.closedForSubscription,
      };

      return fundState;
    },
  );

  return {
    withHoldings: fundStatesWithHoldings,
    withoutHoldings: fundStateWithoutHoldings,
  };
};
