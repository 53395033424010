import round from 'lodash/round';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { EsgCategoryDto } from '../dtos/EsgCategoryDto';
import { EsgCurrencyCategoryDto } from '../dtos/EsgCurrencyCategoryDto';
import { EsgScoreDto } from '../dtos/EsgScoreDto';
import { EsgCategory } from '../models/EsgCategory';
import { EsgCurrencyCategory } from '../models/EsgCurrencyCategory';
import { EsgScore } from '../models/EsgScore';
import { EsgValueType } from '../models/EsgValueType';

export const transformEsgCurrencyCategoryDto = (
  dto: EsgCurrencyCategoryDto,
): EsgCurrencyCategory => ({
  currency: convertStringToEnum(dto.currency, CurrencyCode),
  ...transformEsgCategoryDto(dto),
});

export const transformEsgCategoryDto = (dto: EsgCategoryDto): EsgCategory => {
  return {
    kpi: transformEsgScoreDto(dto.kpi),
    benchmark: dto.benchmark?.value && transformEsgScoreDto(dto.benchmark),
    difference:
      dto.benchmark?.value &&
      calculateDifference(dto.kpi.value, dto.benchmark.value),
  };
};

const transformEsgScoreDto = (dto: EsgScoreDto): EsgScore => ({
  value: round(dto.value, 1),
  coverageInPercent: round(dto.coverageInPercent, 1),
  type: convertStringToEnum(dto.type, EsgValueType),
});

/**
 * Calculate the difference percentage between the kpi and reference kpi.
 */
const calculateDifference = (kpi: number, referenceKpi: number): number =>
  round(((kpi - referenceKpi) / referenceKpi) * 100, 1);
