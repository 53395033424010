import styled, { css } from 'styled-components';

export const hintStyle = css`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const Hint = styled.span`
  ${hintStyle}
  color: var(--color-on-background);
`;
