import Button from '@storeblocks/button';
import React, { useState } from 'react';

import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  numberOfFilesToDownload: number;
  'data-trackid-downloaded-files'?: Array<string>;
  onClick: () => Promise<void>;
}

export const DownloadButton: React.FC<Props> = ({
  numberOfFilesToDownload,
  'data-trackid-downloaded-files': downloadedFiles,
  onClick,
}) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const [downloading, setDownloading] = useState<boolean>(false);
  const downloadClick = async (): Promise<void> => {
    if (!downloading) {
      setDownloading(true);
      try {
        await onClick();
        setDownloading(false);
      } catch {
        setDownloading(false);
      }
    }
  };

  const getButtonLabel = (): string => {
    let label = texts.reportsArchivePage.download;

    if (numberOfFilesToDownload) {
      label += ` ${numberOfFilesToDownload} ${
        numberOfFilesToDownload > 1
          ? texts.reportsArchivePage.files
          : texts.reportsArchivePage.file
      }`;
    }

    return label;
  };

  return (
    <Button
      disabled={!numberOfFilesToDownload || downloading}
      onClick={downloadClick}
      id="download-files-button"
      data-trackid={getTrackId(
        'download-selected-files',
        TrackingElement.Button,
      )}
      data-trackid-number-of-files={numberOfFilesToDownload}
      data-trackid-downloaded-files={downloadedFiles}
      loading={downloading}
    >
      {getButtonLabel()}
    </Button>
  );
};
