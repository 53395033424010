import ChipGroup, { Option } from '@storeblocks/chip/lib/group';
import React from 'react';

import { TrackingElement, useGetTrackId } from '@/tracking';
import { CurrencyCode } from '@/types/CurrencyCode';

interface Props {
  currency: CurrencyCode;
  currencies: CurrencyCode[];
  onChange: (currency: CurrencyCode) => void;
}

export const CurrencyChips: React.FC<Props> = ({
  currency,
  currencies,
  onChange,
}) => {
  const getTrackId = useGetTrackId();
  const trackId = getTrackId('select-value-type', TrackingElement.FilterChip);

  const options: Array<Option> = currencies.map((currency) => ({
    id: currency,
    label: currency,
    value: currency,
    inputProps: {
      'data-trackid': trackId,
      'data-trackid-selected-filter': currency,
      'data-trackid-source': 'fund-esg',
      'data-trackid-type': 'filter-chips',
    },
  }));

  const handleOnChange = (checked: Array<string>): void => {
    onChange(checked[0] as CurrencyCode);
  };

  return (
    <ChipGroup
      type="choice"
      id="currency-selector-fund-esg"
      checked={[currency]}
      options={options}
      onChange={handleOnChange}
    />
  );
};
