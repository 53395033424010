import { Input } from '@storeblocks/input';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useState } from 'react';

interface Props {
  label: string;
  onChange: (query: string) => void;
  id?: string;

  /**
   * Initialize the search field with the specified query.
   */
  initialQuery?: string;
}

export const TextSearch: React.FC<Props> = ({
  label,
  onChange,
  id = 'search-by-text',
  initialQuery = '',
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>(initialQuery);

  const callOnChange = useCallback(
    // Delay update when the search text changes to avoid unnecessary updates on each keystroke.
    debounce((query: string) => {
      onChange(query);
    }, 300),
    [],
  );

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
    callOnChange(e.target.value);
  };

  return (
    <Input
      id={id}
      name={id}
      label={label}
      value={searchQuery}
      onChange={onQueryChange}
      inputProps={{
        autoComplete: 'off',
      }}
      large
    />
  );
};
