import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { Column, Row } from '@/components/Grid';
import { WithGap } from '@/components/WithGap';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';
import { useRedemptionPaymentOptions } from '../hooks/useRedemptionPaymentOptions';
import { RedemptionPaymentOptions } from './RedemptionPaymentOptions';
import { RedemptionPaymentPlacementRadio } from './RedemptionPaymentPlacementRadio';
import { RedemptionPortfolioBankAccount } from './RedemptionPortfolioBankAccount';

export const RedemptionPaymentPlacement: React.FC = () => {
  const { values } = useFormikContext<RedemptionOrderFormValues>();
  const portfolios = useFundPortfolios(OrderType.Redemption);

  const hasPortfolioBankAccounts =
    getPortfolioBankAccounts(values.fromPortfolioShortName, portfolios).length >
    0;

  useRedemptionPaymentOptions();

  if (values.payments.length === 0) {
    return null;
  }

  return (
    <Container>
      {values.payments.map((payment, paymentIndex) => (
        <div
          key={payment.currency}
          data-test={`redemption-payment-placement-${payment.currency}`}
        >
          {
            // When the user has portfolio bank accounts, let the user choose
            // where the withdrawal should be placed.
            // To the portfolio bank account (porteføljekonto) or to an
            // bank account of their specification.
            hasPortfolioBankAccounts ? (
              <WithGap>
                <RedemptionPaymentPlacementRadio
                  payment={payment}
                  // Display the currency in the title if there are two or more
                  // currencies in play.
                  displayCurrencyInTitle={values.payments.length > 1}
                  paymentIndex={paymentIndex}
                />

                {payment.paymentPlacement === OrderPaymentType.Portfolio && (
                  <Row>
                    <Column $span={5}>
                      <RedemptionPortfolioBankAccount
                        paymentIndex={paymentIndex}
                      />
                    </Column>
                  </Row>
                )}

                {payment.paymentPlacement === OrderPaymentType.BankAccount && (
                  <AlignWithRadio>
                    <RedemptionPaymentOptions
                      paymentIndex={paymentIndex}
                      paymentCurrency={payment.currency}
                    />
                  </AlignWithRadio>
                )}
              </WithGap>
            ) : (
              // The user has only the option to specify their own bank account.
              <RedemptionPaymentOptions
                paymentIndex={paymentIndex}
                paymentCurrency={payment.currency}
              />
            )
          }
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const AlignWithRadio = styled.div`
  /* Make the container line up vertically with the radio option text. */
  margin-left: 40px;
`;
