import React from 'react';

import { Item } from './Item';

export interface AddSingleItemContextType {
  selectableItems: Item[];
}

export const AddSingleItemContext =
  React.createContext<AddSingleItemContextType>({
    selectableItems: [],
  });
