import { LargeCard } from '@storeblocks/card';
import React, { FC } from 'react';
import styled from 'styled-components';

import { EmailLink } from '@/components/EmailLink';

interface Props {
  title: string;
  email: string;
}

export const GeneralContactCard: FC<Props> = ({ title, email }) => {
  return (
    <LargeCardStyled
      title={title}
      caption={<EmailLink email={email} />}
      actions={[]}
    />
  );
};

const LargeCardStyled = styled(LargeCard)`
  height: 100%;
`;
