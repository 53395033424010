import React, { useMemo } from 'react';

import { AssetPerformance } from '@/api/performanceData/models';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';

import { AssetTable } from '../components/AssetTable';
import { ColumnHints } from '../components/ColumnHints';
import { AssetAllocationViewMode } from '../types/AssetAllocationViewMode';
import { useAssetAllocationColumns } from './hooks/useAssetAllocationColumns';
import { AssetAllocationRow } from './types/AssetAllocationRow';
import { mapToAssetAllocationRow } from './utils/mapToAssetAllocationRow';
import { mapToAssetAllocationSummaryRow } from './utils/mapToAssetAllocationSummaryRow';

interface Props {
  assetPerformance: AssetPerformance;
  expanded?: boolean;
  viewMode: AssetAllocationViewMode;
}

export const AssetAllocationTable: React.FC<Props> = ({
  assetPerformance,
  expanded,
  viewMode,
}) => {
  const texts = useFms();

  const locale = useUserLocale();

  const columns = useAssetAllocationColumns(assetPerformance.date, viewMode);

  const rows = useMemo<AssetAllocationRow[]>(() => {
    if (assetPerformance.assets.length === 0) {
      return [];
    }

    const root = assetPerformance.assets[0];

    const holdingsRows = root.assets.map((asset) =>
      mapToAssetAllocationRow(asset, locale, viewMode),
    );

    const summaryRow = mapToAssetAllocationSummaryRow(
      root,
      locale,
      viewMode,
      texts,
    );

    holdingsRows.push(summaryRow);

    return holdingsRows;
  }, [locale, texts, assetPerformance.assets, viewMode]);

  return (
    <>
      <AssetTable
        columns={columns}
        data={rows}
        expandable
        expanded={expanded}
        displayVerticalScrollbar
      />
      <Show
        when={
          viewMode === AssetAllocationViewMode.Performance ||
          viewMode === AssetAllocationViewMode.All
        }
      >
        <ColumnHints />
      </Show>
    </>
  );
};
