import axios from 'axios';

import { Auth, TokenParsed } from './Auth';

interface MockToken {
  name: string;
  userCmId: string;
  signature?: string;
  currentOrgCmId: string;
  email: string;
}

let mockToken: MockToken | undefined;

async function initialize(): Promise<void> {
  const response = await axios.get<MockToken>(
    '/mock-api/sign-in-to-organization',
  );

  mockToken = response.data;

  return Promise.resolve();
}

function getToken(): Promise<string> {
  return Promise.resolve('<mocked token>');
}

function getTokenParsed(): TokenParsed {
  return {
    name: mockToken.name,
    cmId: mockToken.userCmId,
    signature: mockToken.signature,
    email: mockToken.email,

    behalfOf: {
      cmId: mockToken.currentOrgCmId,
    },
  };
}

function logout(): void {
  // Nothing to do when doing Cypress testing.
}

function isAuthenticated(): boolean {
  return !!mockToken;
}

export const cypressAuth: Auth = {
  initialize,
  getToken,
  getTokenParsed,
  logout,
  isAuthenticated,
};
