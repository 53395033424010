import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { InternalLinkList } from '@/components/InternalLinkList';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

export const IntermediateOrderPage: FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();

  const links = [
    <InternalLink
      key={`/${organizationCmId}/trading/subscription`}
      to={`/${organizationCmId}/trading/subscription`}
      data-test="trading-subscription-link"
    >
      {texts.menu.links.trading.subscription}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/trading/redemption`}
      to={`/${organizationCmId}/trading/redemption`}
      data-test="trading-redemption-link"
    >
      {texts.menu.links.trading.redemption}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/trading/switch`}
      to={`/${organizationCmId}/trading/switch`}
      data-test="trading-switch-link"
    >
      {texts.menu.links.trading.switch}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/trading/orders`}
      to={`/${organizationCmId}/trading/orders`}
      data-test="trading-history-link"
    >
      {texts.menu.links.trading.history}
    </InternalLink>,
  ];

  return (
    <WithGap>
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/trading`}>
          {texts.menu.links.trading.trading}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.trading.trading} />

        <InternalLinkList links={links} />
      </MainContent>
    </WithGap>
  );
};
