import { Language } from '@/i18n/Language';

export const getFmsLanguage = (language: Language): string => {
  switch (language) {
    case Language.Swedish:
      return 'se';

    case Language.Norwegian:
      return 'no';

    case Language.English:
      return 'en';

    default:
      throw new Error(
        `Could not format language [${language}] to FMS language.`,
      );
  }
};
