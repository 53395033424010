import { FMSTexts } from '@/types/fms';

import { AllocationType } from '../../types/AllocationType';

export const getAssetAllocationTypeText = (
  type: AllocationType,
  texts: FMSTexts,
): string => {
  switch (type) {
    case AllocationType.MainDistribution:
      return texts.pages.presentation.filters.allocationType.filterTexts
        .mainDistribution;
    case AllocationType.ProductGroup:
      return texts.pages.presentation.filters.allocationType.filterTexts
        .productGroups;
    case AllocationType.Product:
      return texts.pages.presentation.filters.allocationType.filterTexts
        .products;
    default:
      throw new Error(
        `Unable to find filter text for allocation type [${type}]`,
      );
  }
};
