import { css } from 'styled-components';

export const menuItemStyle = css`
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: var(--color-overlay-hover-darken);
    color: var(--color-primary);

    i {
      color: var(--color-primary);
    }
  }

  &.active {
    cursor: pointer;
    background-color: var(--color-surface);
    color: var(--color-on-surface);
  }
`;
