import * as Sentry from '@sentry/react';
import {
  AuthClient,
  AuthOptions,
  createAuthClient,
} from '@storebrand-digital/auth';

import { isInternalUser, isLocalhost } from '@/config/utils';
import { getBasename } from '@/routes/utils/getBasename';
import { findOrganizationCmIdFromUrl } from '@/util/findOrganizationCmIdFromUrl';

import { Auth, TokenParsed } from './Auth';
import { AuthError } from './types/AuthError';
import { getOrganizationCmIdFromToken } from './utils/getOrganizationCmIdFromToken';

let auth: AuthClient | undefined;

async function initialize(): Promise<void> {
  const organizationCmId = findOrganizationCmIdFromUrl();

  const scope = organizationCmId
    ? `storebrand.private on_behalf_of:cm_id:${organizationCmId}`
    : 'storebrand.private';

  const options: AuthOptions = {
    config: {
      realm: window.config.keycloakRealm,
      clientId: window.config.keycloakClientId,
      url: window.config.keycloakUrl,
    },
    init: {
      scope,
      silentCheckSsoRedirectUri: `${window.location.origin}/.well-known/openid-silent-check-sso.html`,
    },
    login: {
      scope,
      allowedACR: [
        'https://id.storebrand.no/authn/al3',
        'https://id.storebrand.no/authn/al4',
      ],
      allowedAMR: [
        'no:bankid:mobile',
        'no:bankid:regular',
        'pwd:mfa',
        'se:bankid:regular',
        'se:bankid:mobile',
      ],
      showExtraIdpList: true,
    },
  };

  auth = createAuthClient(options);

  // Try to initialize KeyCloak and trigger login.
  try {
    await auth.init();
    await auth.login();

    if (!auth.authenticated) {
      // When the user is not authenticated, the user will be redirected to the Keycloak login page.
      // Thus, wait for the user to be redirected.
      return new Promise(() => {
        // Never resolve.
      });
    }

    // Add a listener for when the user is no longer authenticated and re-initialize.
    auth.instance.onAuthRefreshError = async () => {
      initialize();
    };
  } catch (error) {
    const authError = error as AuthError;

    if (isLocalhost()) {
      // Something went wrong when initializing the authentication.
      // The user may have cancelled the authentication process or there was an error.
      // Only log the error in the console when running on localhost, otherwise it will
      // be logged in Sentry.
      console.error('Failed to initialize authentication', authError?.message);
    }

    // Log the error in Sentry only if the error code is not interaction_required
    // as this error code does not necessarily indicate that something went wrong but is returned if
    // the user cancels the authentication or if the user must restart the login process.
    if (authError.error !== 'interaction_required') {
      Sentry.captureException(authError);
    }
  }

  return Promise.resolve();
}

async function getToken(): Promise<string> {
  try {
    if (!auth.authenticated) {
      // The user will no longer be authenticated after a period of time (unknown time period).
      // Thus, authenticate the user again.
      await initialize();
    }

    return await auth.getToken();
  } catch (error) {
    await initialize();
  }
}

let parsedToken: TokenParsed | undefined;

function getTokenParsed(): TokenParsed {
  if (!auth.authenticated) {
    // The user will no longer be authenticated after a period of time (unknown time period).
    // Thus, authenticate the user again.
    // This is an async operation, but we can't return a promise here since it is used
    // in react hooks. Thus, we return the parsed token from the previous authentication.
    // The `initialize` function will force a re-authentication so it is safe
    // to return the previous token data for the few seconds before the user is
    // redirected to the login page.
    initialize();

    return parsedToken;
  }

  const hasBehalfOf = !!auth.instance.tokenParsed.on_behalf_of;

  // There is only a behalf of when the user is logged in to a specific organization.
  const behalfOf = hasBehalfOf
    ? {
        cmId: getOrganizationCmIdFromToken(auth.instance.tokenParsed),
      }
    : undefined;

  parsedToken = {
    name: auth.instance.tokenParsed.name,
    cmId: auth.instance.tokenParsed.cm_id,
    signature: auth.instance.tokenParsed.signature,

    // The email field is blank for external users.
    // Only internal users has their email exposed.
    email: auth.instance.tokenParsed.email,

    behalfOf,
  };

  return parsedToken;
}

function logout(): void {
  const webappRoot = `${window.location.protocol}//${
    window.location.host
  }${getBasename()}/`;

  auth.logout({
    redirectUri: isInternalUser()
      ? webappRoot
      : 'https://www.storebrand.no/logg-ut-connect',
  });
}

function isAuthenticated(): boolean {
  return auth?.authenticated ?? false;
}

/**
 * Storebrand authentication using Keycloak.
 */
export const storebrandAuth: Auth = {
  initialize,
  getToken,
  getTokenParsed,
  logout,
  isAuthenticated,
};
