import * as Yup from 'yup';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { UserType } from '@/config/types';
import { Locale } from '@/i18n/locale';
import { nameof } from '@/util/nameof';

import { RedemptionOrderFormValues } from '../types/RedemptionOrderFormValues';
import { baseRedemptionFundStateValidationSchema } from './rules/baseRedemptionFundStateValidationSchema';
import { cosignerFieldValidationSchema } from './rules/cosignerFieldValidationSchema';
import { portfolioNameFieldValidationSchema } from './rules/portfolioNameFieldValidationSchema';
import { redemptionInAmountFundStateValidationSchema } from './rules/redemptionInAmountFundStateValidationSchema';
import { redemptionInPercentFundStateValidationSchema } from './rules/redemptionInPercentFundStateValidationSchema';
import { redemptionInUnitsFundStateValidationSchema } from './rules/redemptionInUnitsFundStateValidationSchema';
import { redemptionPaymentValidationSchema } from './rules/redemptionPaymentValidationSchema';
import { signatoryOptionValidationSchema } from './rules/signatoryOptionValidationSchema';
import { signerFieldValidationSchema } from './rules/signerFieldValidationSchema';
import { signingProviderFieldValidationSchema } from './rules/signingProviderFieldValidationSchema';

interface ValidationSchema {
  errorMessages: ErrorMessages;
  locale: Locale;
  userType: UserType;
}

interface ErrorMessages {
  portfolioRequired: string;
  minimumRedemptionAmount: string;
  holdingsNotAbove95percent: string;
  mustHaveAtLeastOneFund: string;
  signatoryOptionRequired: string;
  signerRequired: string;
  cosignerRequired: string;
  signingProviderRequired: string;
  paymentPlacementRequired: string;
  bankAccountNumberRequired: string;
  maximumPercent: string;
  maximumUnits: string;
  portfolioBankAccountNumberRequired: string;
}

export const createRedemptionValidationSchema = ({
  errorMessages,
  locale,
  userType,
}: ValidationSchema): Yup.SchemaOf<RedemptionOrderFormValues> => {
  return Yup.object({
    fromPortfolioShortName: portfolioNameFieldValidationSchema({
      portfolioRequired: errorMessages.portfolioRequired,
    }),

    fromValueType: Yup.mixed<OrderValueType>()
      .oneOf(Object.values(OrderValueType))
      .required(),

    fromFunds: Yup.array()
      .of(baseRedemptionFundStateValidationSchema)
      .min(1, errorMessages.mustHaveAtLeastOneFund)
      .test({
        // Ensure that the user has input an amount to sell.
        message: errorMessages.mustHaveAtLeastOneFund,
        test: (funds) => funds.some((fund) => fund.value),
      })
      // Validate from funds when the value type is Amount.
      .when(nameof<RedemptionOrderFormValues>('fromValueType'), {
        is: OrderValueType.Amount,
        then: Yup.array().of(
          redemptionInAmountFundStateValidationSchema(locale, {
            minimumRedemptionAmountMessage:
              errorMessages.minimumRedemptionAmount,
            marketValueNotAbove95percentMessage:
              errorMessages.holdingsNotAbove95percent,
          }),
        ),
      })
      // Validate from funds when the value type is Percent.
      .when(nameof<RedemptionOrderFormValues>('fromValueType'), {
        is: OrderValueType.Percent,
        then: Yup.array().of(
          redemptionInPercentFundStateValidationSchema({
            maximumPercentMessage: errorMessages.maximumPercent,
          }),
        ),
      })
      // Validate from funds when the value type is Units.
      .when(nameof<RedemptionOrderFormValues>('fromValueType'), {
        is: OrderValueType.Units,
        then: Yup.array().of(
          redemptionInUnitsFundStateValidationSchema({
            maximumUnitsMessage: errorMessages.maximumUnits,
          }),
        ),
      })
      .required(),

    payments: redemptionPaymentValidationSchema({
      paymentPlacementRequired: errorMessages.paymentPlacementRequired,
      bankAccountNumberRequired: errorMessages.bankAccountNumberRequired,
      portfolioBankAccountNumberRequired:
        errorMessages.portfolioBankAccountNumberRequired,
    }),

    signatoryOption: signatoryOptionValidationSchema({
      signatoryOptionRequired: errorMessages.signatoryOptionRequired,
    }),

    signer: signerFieldValidationSchema({
      signerRequired: errorMessages.signerRequired,
    }),

    cosigner: cosignerFieldValidationSchema({
      cosignerRequired: errorMessages.cosignerRequired,
    }),

    signingProvider: signingProviderFieldValidationSchema(userType, {
      signingProviderRequired: errorMessages.signingProviderRequired,
    }),
  });
};
