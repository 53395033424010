import Button from '@storeblocks/button';
import React, { useState } from 'react';

import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { AddFundsModal } from '../AddFundsModal';

export const AddFundsButton: React.FC = () => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        iconLeft="add"
        variant="text"
        onClick={() => setIsModalOpen(true)}
        data-test="open-add-funds-modal-button"
        data-trackid={getTrackId(
          'open-add-funds-modal',
          TrackingElement.Button,
        )}
      >
        {texts.orders.form.addFundModal.button.open}
      </Button>

      <AddFundsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
