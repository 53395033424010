import React from 'react';

import { OrderCompleted } from '../../components/OrderCompleted';
import { useOrderSteps } from '../../components/OrderStepsProvider';

interface Props {
  isOwner: boolean;
  hasCosigners: boolean;
}

export const Completed: React.FC<Props> = ({ isOwner, hasCosigners }) => {
  const { setActiveStep } = useOrderSteps();

  const handleNewOrderClick = (): void => {
    setActiveStep(0);
  };

  return (
    <OrderCompleted
      isOwner={isOwner}
      hasCosigners={hasCosigners}
      newOrderOnClick={handleNewOrderClick}
    />
  );
};
