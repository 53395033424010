import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

type GridGap = '0' | '4' | '8' | '16' | '24' | '32' | '48' | '64';

interface Props extends PropsWithChildren {
  id?: string;
  gridGap?: GridGap;
  minColumnWidth?: number;
  disableFit?: boolean;
  className?: string;
}

/**
 * @deprecated Use `<Row><Column/></Row>` instead.
 *
 * Wrapper component to render a grid layout.
 * @param {string} [id] Id of the grid container div.
 * @param {number} [gridGap='24'] Gap between columns and rows in pixels. Defaults to `24`.
 * @param {number} [minColumnWidth=200] The minimum width of the columns in pixels. Defaults to `200`.
 * @param {boolean} [disableFit] Disables `auto-fit` for the columns to retain a static width for the columns.
 * @param {boolean} [className] Used for `styled-components` styling.
 */
export const Grid: FC<Props> = ({
  id,
  gridGap = '24',
  minColumnWidth = 200,
  disableFit,
  className,
  children,
}) => (
  <Container
    id={id}
    gridGap={gridGap}
    minColumnWidth={minColumnWidth}
    disableFit={disableFit}
    className={className}
  >
    {children}
  </Container>
);

const Container = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(
    ${({ disableFit }) => (disableFit ? 'auto-fill' : 'auto-fit')},
    ${({ minColumnWidth }) => `minmax(min(${minColumnWidth}px, 100%), 1fr)`}
  );

  grid-gap: ${({ gridGap }) => gridGap}px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;
