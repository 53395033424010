import { Button as StoreblocksButton } from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';

interface Props {
  onClick: () => void;
  disabled?: boolean;

  /**
   * To be used by Styled Components.
   */
  className?: string;
}

const Button: React.FC<Props> = ({ onClick, disabled, className }) => {
  const texts = useFms();

  return (
    <StoreblocksButton
      disabled={disabled}
      data-testid="remove-button"
      type="button"
      variant="text"
      onClick={onClick}
      className={className}
    >
      {texts.admin.remove}
    </StoreblocksButton>
  );
};

export const RemoveButton = styled(Button)`
  margin-left: 16px;
`;
