import { Fund } from '@/api/funds/models';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';

export const mapFundToFundState = (
  fund: Fund,
  valueType: OrderValueType,
): SubscriptionFundState => ({
  // The account ID represents the account were the holdings are stored.
  // Since these funds does not have holdings they don't have any account ID.
  accountId: 'NOT-AVAILABLE',
  isin: fund.isin,
  name: fund.name,
  currency: fund.baseCurrency,
  brand: fund.brand,
  minimumSubscriptionAmount: fund.minimumInitialSubscriptionAmount,
  units: 0,
  nav: 0,
  value: 0,
  valueType,
  marketValue: 0,

  // All subscribable funds are open.
  isClosed: false,
});
