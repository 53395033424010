import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { Show } from '@/components/Show';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';
import { nameof } from '@/util/nameof';

import { FundName } from '../../FundName';
import { Isin } from '../../Isin';
import { useArrayFieldByIsin } from '../hooks/useArrayFieldByIsin';
import { AmountError } from './AmountError';
import { ClosedForTradeInfo } from './ClosedForTradeInfo';

interface Props {
  fund: SubscriptionFundState;
}

export const SubscriptionFundCell: React.FC<Props> = ({ fund }) => {
  const [, { touched, error }] = useArrayFieldByIsin<number>(
    nameof<SubscriptionOrderFormValues>('toFunds'),
    fund.isin,
    nameof<SubscriptionFundState>('value'),
  );

  const hasErrorInAmount = touched && !!error;

  return (
    <>
      <FundName data-test="fund-name">{fund.name}</FundName>
      <Isin>{fund.isin}</Isin>

      <Show when={hasErrorInAmount} animate>
        <AmountError errorMessage={error} isin={fund.isin} />
      </Show>

      <Show when={fund.isClosed} animate>
        <ClosedForTradeInfo
          isin={fund.isin}
          orderType={OrderType.Subscription}
        />
      </Show>
    </>
  );
};
