import { SfdrArticle } from '@/api/esg/models/SfdrArticle';
import { FMSTexts } from '@/types/fms';

export const sfdrToFms = (
  sfdrArticle: SfdrArticle,
  texts: FMSTexts,
): {
  title: string;
  description: string;
} => {
  switch (sfdrArticle) {
    case SfdrArticle.Article6:
      return {
        title: texts.pages.fundesg.sfdr.article6.title,
        description: texts.pages.fundesg.sfdr.article6.description,
      };

    case SfdrArticle.Article8:
      return {
        title: texts.pages.fundesg.sfdr.article8.title,
        description: texts.pages.fundesg.sfdr.article8.description,
      };

    case SfdrArticle.Article9:
      return {
        title: texts.pages.fundesg.sfdr.article9.title,
        description: texts.pages.fundesg.sfdr.article9.description,
      };

    default:
      // There are currently only 3 SFDR articles.
      // Fallback in case there are more in the future.
      return {
        title: '',
        description: '',
      };
  }
};
