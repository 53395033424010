import { GroupedTableRow, TableRow } from '../Table/TableModels';

export function visuallyGroupByDate(rows: GroupedTableRow[]): TableRow[] {
  let prevKey = '';
  return rows.map((file, i) => {
    const key = file.groupKey;
    if (key === prevKey) {
      // Remove date on the current row since the row above has the same date.
      file.reportDate.displayContent = null;

      // Remove the bottom border from the row above as we want
      // them to visually be together.
      rows[i - 1].style = {
        borderBottom: 'none',
      };
    } else {
      prevKey = key;
    }

    return file;
  });
}
