import { DateRange, RangeDatePicker } from '@storeblocks/datepicker';
import React, { FC } from 'react';
import { styled } from 'styled-components';

import { usePortfoliosQuery } from '@/api/portfolios';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { Column } from '../Grid/Column';
import { Row } from '../Grid/Row';
import { PortfolioGroupSelect } from '../PortfolioGroupSelect';
import { WithGap } from '../WithGap';

interface Props {
  selectedPortfolioGroupId: string;
  dateRange: DateRange;
  onSelectedPortfolioChange: (portfolioGroupId: string) => void;
  onDateRangeChange: (date: DateRange) => void;
}

export const TransactionsTableFilter: FC<Props> = ({
  selectedPortfolioGroupId,
  dateRange,
  onSelectedPortfolioChange: onSelectedPortfolioGroupIdsChange,
  onDateRangeChange,
}) => {
  const locale = useUserLocale();
  const texts = useFms();
  const portfoliosQuery = usePortfoliosQuery();
  const getTrackId = useGetTrackId();

  return (
    <WithGap>
      {portfoliosQuery.data.portfolioGroups.length > 1 && (
        <Row>
          <Column $span={6}>
            <PortfolioGroupSelect
              onChange={onSelectedPortfolioGroupIdsChange}
              selectedPortfolioGroupId={selectedPortfolioGroupId}
            />
          </Column>
        </Row>
      )}

      <StyledRangeDatePicker
        id="transactions-table-date-range"
        name="date-range"
        label={texts.transactions.table.filters.date.label}
        locale={locale}
        data-trackid={getTrackId('pick-date-range', TrackingElement.DatePicker)}
        data-trackid-source="transactions-table"
        value={dateRange}
        onChange={(_, date) => {
          onDateRangeChange(date);
        }}
        clearable
        clearLabel={texts.transactions.table.filters.date.clearLabel}
      />
    </WithGap>
  );
};

const StyledRangeDatePicker = styled(RangeDatePicker)`
  // There is no option in the Storeblocks component to hide the hint.
  // Therefore we have to manually override the styles of RangeDatePicker in order to
  // remove the hint.
  .hint {
    display: none;
  }
`;
