import * as Yup from 'yup';

import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';

import { baseRedemptionFundStateValidationSchema } from './baseRedemptionFundStateValidationSchema';

interface ErrorMessages {
  maximumPercentMessage: string;
}

/** Validate redemption in Percent */
export const redemptionInPercentFundStateValidationSchema = ({
  maximumPercentMessage,
}: ErrorMessages): Yup.SchemaOf<RedemptionFundState> =>
  baseRedemptionFundStateValidationSchema.concat(
    Yup.object({
      value: Yup.number()
        .test(function validateRedemptionPercent(value) {
          // Don't show validation message when there isn't any amount.
          if (!value) {
            return true;
          }

          // Don't show validation message when selling all..
          if (this.parent.sellAll) {
            return true;
          }

          // Show validation message if the value is negative.
          if (value < 0) {
            return this.createError({
              message: 'Value cannot be negative',
            });
          }

          // Show validation message if the percentage exceeds 100%.
          if (value > 100) {
            return this.createError({
              message: maximumPercentMessage,
            });
          }

          return true;
        })
        .required(),
    }),
  );
