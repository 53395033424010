import sortBy from 'lodash/sortBy';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { CurrencyCode } from '@/types/CurrencyCode';

import { RedemptionFundState } from '../../../types/RedemptionFundState';
import { SignatoryOption } from '../../../types/SignatoryOption';
import { SwitchOrderFormValues } from '../../../types/SwitchOrderFormValues';

export const createInitialSwitchValues = (
  currency: CurrencyCode,
  fromPortfolioShortName: string,
  toPortfolioShortName: string,
  fromValueType: OrderValueType,
  toValueType: OrderValueType,
  redemptionFundStates: RedemptionFundState[],
  subscriptionFundStates: SubscriptionFundState[],
  signatoryOption: SignatoryOption,
): SwitchOrderFormValues => {
  const fromFunds = sortBy(redemptionFundStates, (fund) =>
    fund.name.toLowerCase(),
  );

  const toFunds = sortBy(subscriptionFundStates, (fund) =>
    fund.name.toLowerCase(),
  );

  return {
    currency,

    fromFunds,
    toFunds,

    fromPortfolioShortName,
    toPortfolioShortName,

    fromValueType,
    toValueType,

    signer: null,
    cosigner: undefined,
    signatoryOption,
    signingProvider: undefined,
  };
};
