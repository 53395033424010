import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scroll to the top of the page when the user navigates to a new page.
 *
 * The default behavior without this is that the browser keeps the current
 * scroll position on the new page. I.e. if the user has scrolled halfway
 * down on a page and then navigates to a new page it keep the scroll
 * position on the new page.
 *
 * This component is taken from the React router docs.
 */
export const ScrollToTopOnNavigation: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
