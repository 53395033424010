import { Language } from '@/i18n/Language';
import { unsafeConvertStringToEnumWithWarning } from '@/util/unsafeConvertStringToEnumWithWarning';

import { UserSettingsReply } from '../dtos/UserSettingsReply';
import { UserSettings } from '../models/UserSettings';

const fallbackLanguage = Language.English;

export const transformUserSettingsReply = (
  dto: UserSettingsReply,
): UserSettings => {
  return {
    emailBusiness: dto.emailBusiness ?? '',
    language:
      unsafeConvertStringToEnumWithWarning(dto.language, Language) ??
      fallbackLanguage,
    lastUpdated: dto.lastUpdated ? new Date(dto.lastUpdated) : undefined,
    updateNeeded: dto.updateNeeded,
  };
};
