import Alert from '@storeblocks/alert';
import styled from 'styled-components';

export const FileIconWrapper = styled.span`
  padding: 16px;
  padding-bottom: 0;
  color: var(--color-primary);
`;

export const StyledAlert = styled(Alert)`
  margin-top: 24px;
`;

export const ReactPaginateWrapper = styled.div`
  display: flex;
  margin-top: 16px;

  ul {
    display: flex;
    flex-flow: row;
    margin: auto;

    li {
      list-style: none;
      margin-right: 8px;

      a {
        display: block;
        cursor: pointer;
        min-width: 20px;
        min-height: 20px;
        text-align: center;
      }
      &.selected a {
        cursor: initial;
        background: red;
        border-radius: 20px;
      }

      &:not(.selected) {
        a:hover {
          font-weight: bold;
        }
      }
    }
  }
`;
