import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { OrderType } from '@/api/order/models/OrderType';

import { useOrderPageContext } from '../OrderPageContext';

export const useContactFormSource = (): Source => {
  const { orderType } = useOrderPageContext();

  switch (orderType) {
    case OrderType.Subscription:
      return Source.OrderSubscription;
    case OrderType.Redemption:
      return Source.OrderRedemption;
    case OrderType.Switch:
      return Source.OrderSwitch;
    default:
      throw new Error(
        `Invalid order type. Could not get contact form source from orderType: ${orderType}`,
      );
  }
};
