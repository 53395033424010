import LoadingIndicator from '@storeblocks/loading-indicator';
import React from 'react';
import styled from 'styled-components';

import { Center } from './Center';

/**
 * Display a loading indicator that is centered on the screen.
 * Nothing else should be displayed while the loading indicator is visible.
 */
export const FullPageLoadingIndicator: React.FC = () => {
  return (
    <Wrapper data-test="full-page-loading-indicator">
      <Center vertical horizontal>
        <LoadingIndicator />
      </Center>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
