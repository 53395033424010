import Popover from '@storeblocks/popover';
import React, { FC } from 'react';
import styled from 'styled-components';

import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  id: string;
  title?: string;
  description: string;
}

export const Helper: FC<Props> = ({ title, description, id }) => {
  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    `open-help-text-for-${id}`,
    TrackingElement.Button,
  );

  return (
    <StyledPopover
      headline={title}
      icon="information"
      size={24}
      buttonProps={{
        'data-trackid': trackId,
      }}
    >
      {description}
    </StyledPopover>
  );
};

const StyledPopover = styled(Popover)`
  height: unset;
  & > button {
    line-height: unset;
  }
`;
