import { Brand } from '@/types/Brand';
import { FMSTexts } from '@/types/fms';

export const findLabelForBrand = (brand: Brand, fmsTexts: FMSTexts): string => {
  switch (brand) {
    case Brand.STOREBRAND:
      return fmsTexts.orders.form.addFundModal.filter.brand.option.storebrand;

    case Brand.SKAGEN:
      return fmsTexts.orders.form.addFundModal.filter.brand.option.skagen;

    case Brand.SPP:
      return fmsTexts.orders.form.addFundModal.filter.brand.option.spp;

    case Brand.DELPHI:
      return fmsTexts.orders.form.addFundModal.filter.brand.option.delphi;

    case Brand.KRON:
      return fmsTexts.orders.form.addFundModal.filter.brand.option.kron;

    default:
      throw new Error(`Could not find label for brand [${brand}].`);
  }
};
