import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';

import { ApiError } from '@/api/types/ApiError';

import { ApiMeta } from '../types/ApiMeta';
import { UnknownApiError } from '../types/UnknownApiError';
import { axiosRequest, RequestConfig } from './axiosRequest';

/**
 * Type for the return value from RTK Query's baseQuery.
 * The base query in a query function is not typed properly, thus
 * we have this type that corresponds with what the RTK base query returns.
 *
 * In the createApi() call, the fetchWithBQ argument is not typed, thus provide
 * the type here.
 *
 * @example
 *  async queryFn(
 *      arg,
 *      _queryApi,
 *      _extraOptions,
 *      fetchWithBQ: (args: RequestConfig) => Promise<RtkBaseQueryResponse>,
 *      // Add the types to the fetchWithBQ argument -------^
 *    ) {
 * ...
 * }
 */
export type RtkBaseQueryResponse<TData = unknown> =
  // Successful response.
  | {
      data: TData;
      meta: ApiMeta;
      error?: never;
    }
  // Error response.
  | {
      data?: never;
      meta?: never;
      error: ApiError;
    };

export function axiosBaseQuery<ResponseData = unknown>(): BaseQueryFn<
  RequestConfig,
  ResponseData,
  ApiError | UnknownApiError,
  unknown /* Extra options. */,
  ApiMeta
> {
  return async (requestConfig) => {
    const query = await axiosRequest<ResponseData>(requestConfig);

    if (query.error) {
      return {
        error: query.error,
      };
    }

    return {
      data: query.response?.data,
      meta: {
        statusCode: query.response?.status,
      },
    };
  };
}
