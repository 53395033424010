import React, { FC } from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { EsgCurrencyCategory } from '@/api/esg/models/EsgCurrencyCategory';
import { Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { EsgCoverageChart } from './EsgCoverageChart';

interface Props {
  carbonIntensities: EsgCurrencyCategory[];
  esgScore: EsgCategory;
  // euTaxonomyAlignment: EsgCategory;
  // euTaxonomyEligibility: EsgCategory;
}

export const EsgCoverage: FC<Props> = ({
  carbonIntensities,
  esgScore,
  // euTaxonomyAlignment,
  // euTaxonomyEligibility,
}) => {
  const texts = useFms();

  /** @todo: Temporary taxonomy data need to be hidden, see SAMIK-1799 */
  /*
  if (euTaxonomyEligibility) {
    columnChartData.push({
      title: texts.pages.esg.taxonomy.eligibilityTitle,
      dataPoints: [
        {
          name: texts.pages.esg.taxonomy.eligibilityTitle,
          y: euTaxonomyEligibility.kpi.coverageInPercent,
        },
      ],
    });
  }

  if (euTaxonomyAlignment) {
        columnChartData.push({
      title: texts.pages.esg.taxonomy.alignmentTitle,
      dataPoints: [
        {
          name: texts.pages.esg.taxonomy.alignmentTitle,
          y: euTaxonomyAlignment.kpi.coverageInPercent,
        },
      ],
    });
  }
  */

  return (
    <WithGap>
      <Paragraph>{texts.pages.esg.coverage.description}</Paragraph>
      <EsgCoverageChart
        carbonIntensities={carbonIntensities}
        esgScore={esgScore}
      />
    </WithGap>
  );
};
