import React, { FC } from 'react';
import styled from 'styled-components';

import { Helper } from '@/components/Helper';
import { Show } from '@/components/Show';
import { H3, Hint } from '@/components/Typography';

interface HelperProps {
  id: string;
  title?: string;
  description: string;
}

interface Props {
  title: string;
  hint?: string;
  helperProps?: HelperProps;
}

export const WidgetHeader: FC<Props> = ({ title, hint, helperProps }) => {
  // Check that either title or description is not empty, as they are normally
  // fetched from FMS and we don't want to render an empty popover when text is
  // missing in FMS.
  const showHelperIcon = !!helperProps?.title || !!helperProps?.description;

  return (
    <Container>
      <StyledH3>{title}</StyledH3>
      {showHelperIcon && (
        <Helper
          id={helperProps.id}
          title={helperProps.title}
          description={helperProps.description}
        />
      )}
      <Show when={!!hint}>
        <StyledHint>{hint}</StyledHint>
      </Show>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledH3 = styled(H3)`
  margin: 0;
`;

const StyledHint = styled(Hint)`
  margin-left: auto;
`;
