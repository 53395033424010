/**
 * These permissions are calculated on the backend.
 *
 * The enum value is made of "resource:privilege", e.g.: "ORDERS:WRITE".
 */
export enum Permission {
  ConnectRead = 'CONNECT:READ',

  OrdersRead = 'ORDERS:READ',
  OrdersWrite = 'ORDERS:WRITE',

  /**
   * The user has orders:write permission in the token, but
   * the organization is missing some properties, e.g. correct
   * accounts, to be able to write orders.
   */
  OrdersWriteNotEligible = 'ORDERS:WRITE_NOT_ELIGIBLE',

  OrdersSign = 'ORDERS:SIGN',

  /**
   * The user has orders:sign permission in the token, but needs
   * to go through the signing right process first to be able to
   * sign orders.
   */
  OrdersSignMissingSigningRight = 'ORDERS:SIGN_MISSING_SIGNING_RIGHT',

  ReportsRead = 'REPORTS:READ',
  ReportsWrite = 'REPORTS:WRITE',

  DocumentStorageRead = 'DOCUMENT_STORAGE:READ',
  DocumentStorageWrite = 'DOCUMENT_STORAGE:WRITE',

  UserAdminRead = 'USER_ADMIN:READ',
  UserAdminWrite = 'USER_ADMIN:WRITE',

  CustomerAdminRead = 'CUSTOMER_ADMIN:READ',
  CustomerAdminWrite = 'CUSTOMER_ADMIN:WRITE',
}
