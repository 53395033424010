import { max, min, parseISO } from 'date-fns';

import { Unit } from '@/types/Unit';

import { PortfolioPerformanceDto } from '../dtos';
import { PortfolioPerformanceDataPoint } from '../models';
import { PortfolioReturns } from '../models/PortfolioReturns';

export const mapPortfolioReturns = (
  portfolioGroupShortName: string,
  performances: Array<PortfolioPerformanceDto>,
): PortfolioReturns => {
  const dailyReturnAccumulatedPercent: Array<PortfolioPerformanceDataPoint> =
    performances.map((dto) => [
      new Date(dto.date).getTime(),
      dto.dailyReturnAccPct,
    ]);

  const dailyReturnAccumulatedAmount: Array<PortfolioPerformanceDataPoint> =
    performances.map((dto) => [
      new Date(dto.date).getTime(),
      dto.dailyReturnAccAmount,
    ]);

  const dates = performances.map((performance) => parseISO(performance.date));

  const firstPointDate = min(dates);
  const lastPointDate = max(dates);

  return {
    portfolioGroupShortName,
    forUnit: {
      [Unit.Amount]: dailyReturnAccumulatedAmount,
      [Unit.Percent]: dailyReturnAccumulatedPercent,
    },
    firstPointDate,
    lastPointDate,
  };
};
