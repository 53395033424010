import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props extends PropsWithChildren {
  className?: string;
  'data-test'?: string;
}

export const CenteredPageContent: React.FC<Props> = ({
  children,
  className,
  'data-test': dataTest,
}) => {
  return (
    <Center className={className} data-test={dataTest}>
      <>{children}</>
    </Center>
  );
};

const Center = styled.div`
  padding: 0 24px;
  margin: 0 auto;
  flex: 1;
  max-width: 1728px;

  // Remove max width when printing to allow native print scaling.
  @media print {
    max-width: unset;
  }
`;
