import React, { PropsWithChildren } from 'react';

import { WithGap } from './WithGap';

interface Props extends PropsWithChildren {
  className?: string;
  'data-test'?: string;
}

export const MainContent: React.FC<Props> = ({
  className,
  'data-test': dataTest,
  children,
}) => {
  return (
    // Setting tabIndex to -1 makes the element focusable with code, but not with keyboard navigation.
    <div
      className={className}
      id="main-content"
      tabIndex={-1}
      data-test={dataTest}
    >
      <WithGap gap="24">{children}</WithGap>
    </div>
  );
};
