import styled, { css } from 'styled-components';

export const h2Style = css`
  font-family: 'SignaWeb-ColumnBook';
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  color: var(--color-on-background);
`;

export const H2 = styled.h2`
  ${h2Style}
`;
