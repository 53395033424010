export enum TrackingElement {
  // Elements that are automatically tracked in GA.
  Button = 'button',
  Card = 'card',
  Clickbox = 'clickbox',
  Faq = 'faq',
  Image = 'image',
  Lens = 'lens',
  Link = 'link',

  // Elements with custom events for GA.
  FilterChip = 'filter-chip',
  DatePicker = 'date-picker',
}
