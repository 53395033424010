import Button from '@storeblocks/button';
import React from 'react';

import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  onClick: () => void;
}

export const RemoveDualCitizenshipButton: React.FC<Props> = ({ onClick }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <div>
      <Button
        variant="text"
        iconLeft="close"
        onClick={onClick}
        data-trackid={getTrackId(
          'remove-dual-citizenship',
          TrackingElement.Button,
        )}
      >
        {texts.orders.signatureRight.pep.citizenship.removeDual}
      </Button>
    </div>
  );
};
