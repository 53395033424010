import React from 'react';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { byFundKey } from '@/api/fundHoldings/utils';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { useFms } from '@/hooks/useFms';

import { isLastRow } from '../utils/isLastRow';
import { OrderPlacementValue } from './OrderPlacementValue';
import { TableRowCell } from './TableRowCell';
import { TableRowHeader } from './TableRowHeader';

interface Props {
  orderPlacements: Array<OrderPlacement>;
  portfolioShortName: string;
}

export const FundRow: React.FC<Props> = ({
  orderPlacements,
  portfolioShortName,
}) => {
  const { orders } = useFms();

  const { data: fundHoldings } = useFetchFundHoldingsQuery();

  const portfolioFundHoldings = fundHoldings.byPortfolioId[portfolioShortName];

  const orderPlacementsWithValue = orderPlacements.filter((orderPlacement) =>
    Boolean(orderPlacement.value),
  );

  return (
    <>
      {orderPlacementsWithValue.map((orderPlacement, index, array) => (
        <tr key={orderPlacement.isin}>
          {index === 0 ? (
            <TableRowHeader noBorder={!isLastRow(index, array)}>
              <div>{orders.form.table.fundAndAmount}</div>
            </TableRowHeader>
          ) : (
            <TableRowCell noBorder={!isLastRow(index, array)} />
          )}

          <TableRowCell noBorder={!isLastRow(index, array)}>
            {orderPlacement.isin} - {orderPlacement.fundName}
          </TableRowCell>

          <TableRowCell noBorder={!isLastRow(index, array)}>
            <OrderPlacementValue
              orderPlacement={orderPlacement}
              fundHoldings={
                portfolioFundHoldings?.byFund[
                  byFundKey(orderPlacement.accountId, orderPlacement.isin)
                ]
              }
            />
          </TableRowCell>
        </tr>
      ))}
    </>
  );
};
