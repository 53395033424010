import Button from '@storeblocks/button';
import Table from '@storeblocks/table';
import differenceBy from 'lodash/differenceBy';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useFirstRender } from '@/hooks/useFirstRender';
import { JSXTableCell, TableColumn, TableRow } from '@/types/tables';

import { AddSingleItemContext, AddSingleItemContextType } from './context';
import { ExistingItemRow } from './ExistingItemRow';
import { Item } from './Item';
import { SelectListRow } from './SelectListRow';

type Row = TableRow<{
  item: JSXTableCell;
}>;

/**
 * Create row with a select list to add new items.
 */
const createSelectRow = (
  rowId: string,
  placeholderText: string,

  /**
   * @param previousItemId
   * The value is `undefined` for the initial item.
   */
  onSelectChange: (newItemId: Item, previousItemId: string | undefined) => void,

  onRemoveClick: (rowId: string, selectedItemId: string | undefined) => void,
): Row => ({
  item: {
    displayContent: (
      <SelectListRow
        placeholderText={placeholderText}
        onChange={onSelectChange}
        onRemoveClick={(selectedItemId) => onRemoveClick(rowId, selectedItemId)}
      />
    ),
    // Sort content is used as React key for the table row entry and it must be unique.
    sortContent: rowId,
  },
});

/**
 * Create row with a label for existing items.
 */
const createLabelRow = (
  item: Item,
  disableRemoveButton: boolean,
  onRemoveClick: () => void,
): Row => ({
  item: {
    displayContent: (
      <ExistingItemRow
        label={item.label}
        onRemoveClick={onRemoveClick}
        disableRemoveButton={disableRemoveButton}
      />
    ),
    // Sort content is used as React key for the table row entry and it must be unique.
    sortContent: item.id,
  },
});

const columns = (title: string): TableColumn<Row>[] => [
  {
    key: 'item',
    name: title,
    dataType: 'other',
    renderType: 'jsx',
  },
];

export interface OnChange {
  added: Item[];
  removed: Item[];
  all: Item[];
}

interface Props {
  title: string;
  placeholderText: string;
  addNewItemButtonText: string;
  existingItems: Item[];
  selectListItems: Item[];
  onChange: (items: OnChange) => void;
  disableRemoveButton?: boolean;
}

export const AddSingleItemTable: React.FC<Props> = ({
  title,
  placeholderText,
  addNewItemButtonText,
  existingItems,
  selectListItems,
  onChange,
  disableRemoveButton,
}) => {
  const isFirstRender = useFirstRender();
  const [rows, setRows] = useState(new Map<string, Row>());
  const [removedItems, setRemovedItems] = useState<Item[]>([]);
  const [addedItems, setAddedItems] = useState<Item[]>([]);
  const [contextData, setContextData] = useState<AddSingleItemContextType>({
    selectableItems: [],
  });

  /**
   * useEffect for on mount logic.
   */
  useEffect(() => {
    // Remove the items that we already have selected from the select list.
    const nonselectedSelectListItems = differenceBy(
      selectListItems,
      existingItems,
      (item) => item.id,
    );

    setContextData({
      selectableItems: nonselectedSelectListItems,
    });

    setRows((state) => {
      // Create table rows.
      existingItems.forEach((item) => {
        const rowId = item.id;

        const row = createLabelRow(item, !!disableRemoveButton, () => {
          if (!disableRemoveButton) {
            onRemoveClick(rowId, item.id);
          }
        });

        state.set(rowId, row);
      });

      return new Map<string, Row>([...state]);
    });
  }, [existingItems, selectListItems, disableRemoveButton]);

  /**
   * useEffect for when table items changes.
   */
  useEffect(() => {
    const itemsAlreadyInTheList = differenceBy(
      existingItems,
      removedItems,
      (item) => item.id,
    );
    const nonselectedSelectListItems = differenceBy(
      selectListItems,
      itemsAlreadyInTheList.concat(addedItems),
      (item) => item.id,
    );

    setContextData((state) => ({
      ...state,
      selectableItems: [...nonselectedSelectListItems],
    }));

    // Don't fire 'onChange' on first render, only fire it when the added or removed items
    // array changes.
    if (!isFirstRender) {
      const allItemsInTheList = itemsAlreadyInTheList.concat(addedItems);

      onChange({
        added: addedItems,
        removed: removedItems,
        all: allItemsInTheList,
      });
    }
  }, [addedItems, removedItems]);

  const onRemoveClick = (
    rowId: string,
    selectedItemId: string | undefined,
  ): void => {
    const existingItem = existingItems.find(
      (item) => item.id === selectedItemId,
    );

    // ?: Was the item originally in the list, i.e. the user did not add it manually.
    if (existingItem) {
      // -> Yes, it is an original item.
      // Add it to the removed items list.
      setRemovedItems((state) => [...state, existingItem]);
    } else {
      // -> No, the item was previously added by the user.
      // Remove it from the added items list.
      setAddedItems((state) => [
        ...state.filter((item) => item.id !== selectedItemId),
      ]);
    }

    setRows((state) => {
      state.delete(rowId);
      return new Map<string, Row>([...state]);
    });
  };

  const onSelectChange = (
    newItem: Item,
    previousItemId: string | undefined,
  ): void => {
    const existingItem = existingItems.find((item) => item.id === newItem.id);

    // ?: Is the new item an existing item that has been removed and now been added back again?
    if (existingItem) {
      // -> Yes.
      // The original item is in the removed list, we can remove it from that list
      // since it has been added back again.
      setRemovedItems((state) => [
        ...state.filter((item) => item.id !== existingItem.id),
      ]);
      // Stop processing as this does not add a new item, only reinstates a removed item
      // from the original list.
      return;
    }

    // ?: Did the select element have a value before the new value was selected?
    if (previousItemId) {
      // -> Yes, must remove the previous value and add the new value.
      setAddedItems((state) => [
        ...state.filter((item) => item.id !== previousItemId),
        newItem,
      ]);
    } else {
      // -> No, need only to add the new value.
      setAddedItems((state) => [...state, newItem]);
    }
  };

  const onAddNewRow = (): void => {
    setRows((state) => {
      const rowId = nanoid();
      const newRow = createSelectRow(
        rowId,
        placeholderText,
        onSelectChange,
        onRemoveClick,
      );

      state.set(rowId, newRow);

      return new Map<string, Row>([...state]);
    });
  };

  return (
    <div>
      <AddSingleItemContext.Provider value={contextData}>
        <StyledTable columns={columns(title)} data={[...rows.values()]} />

        <StyledButton
          data-testid="add-item"
          type="button"
          variant="text"
          onClick={onAddNewRow}
        >
          {addNewItemButtonText}
        </StyledButton>
      </AddSingleItemContext.Provider>
    </div>
  );
};

const StyledTable = styled(Table)`
  white-space: nowrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;
