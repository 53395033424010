import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { PatchUserSettingsDto } from './dtos/PatchUserSettingsDto';
import { UserSettingsReply } from './dtos/UserSettingsReply';
import { UserSettings } from './models/UserSettings';
import { transformUserSettingsReply } from './utils/transformUserSettingsReply';

export const userSettingsApi = createApi({
  reducerPath: 'userSettingsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    userSettings: builder.query<UserSettings, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/usersettings/me`,
      }),
      transformResponse: (dto: UserSettingsReply) => {
        return transformUserSettingsReply(dto);
      },
    }),

    updateUserSettings: builder.mutation<void, PatchUserSettingsDto>({
      query: (settings) => ({
        url: `${window.config.connectBaseApiUrl}/api/usersettings/me`,
        method: 'PATCH',
        data: settings,
      }),
    }),
  }),
});

export const { useUserSettingsQuery, useUpdateUserSettingsMutation, util } =
  userSettingsApi;
