import React from 'react';

import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';

import { HoldingsCell } from '../components/HoldingsCell';
import { SubscriptionFundCell } from '../components/SubscriptionFundCell';
import { SubscriptionInputCell } from '../components/SubscriptionInputCell';
import { SubscriptionFundRow } from '../types/SubscriptionFundRow';

export const mapFundStateToSubscriptionFundRow = (
  fund: SubscriptionFundState,
  hideHoldings: boolean | undefined,
): SubscriptionFundRow => ({
  fund: {
    displayContent: <SubscriptionFundCell fund={fund} />,
    sortContent: fund.isin,
  },
  ...(!hideHoldings && {
    holdings: {
      displayContent: <HoldingsCell fund={fund} />,
      sortContent: fund.isin,
    },
  }),
  amount: {
    displayContent: <SubscriptionInputCell fund={fund} />,
    sortContent: fund.isin,
  },
});
