import { useUserDateFnsLocale } from '@/i18n/hooks/useUserDateFnsLocale';

export const defaultDateFormat = 'dd.MM.yy';

type DateFormatWidth = 'short' | 'medium' | 'long';

type DateFormat = string | undefined;

/**
 * Hook to return the correct date format for the current users locale
 *
 * @example Example for `short` date format:
 * const dateFormat = useUserDateFormat('short') => `23.02.22`.
 * @example Example for `medium` date format:
 * const dateFormat = useUserDateFormat('medium') => `23. feb. 2022`.
 * @example Example for `long` date format:
 * const dateFormat = useUserDateFormat('long') => `23. februar 2022`.
 *
 * @param width {DateFormatWidth} Specifies the date format length to be used. Defaults to short.
 * @returns Date format string
 */
export const useUserDateFormat = (
  width: DateFormatWidth = 'short',
): DateFormat => {
  const language = useUserDateFnsLocale();

  return width === 'short'
    ? defaultDateFormat
    : language.formatLong?.date({ width });
};
