import Highcharts from 'highcharts';

type HighchartsModule = (highcharts: typeof Highcharts) => void;

/**
 * Activate additional modules for a Highcharts instance.
 */
export const useModules = (
  instance: typeof Highcharts,
  modules?: Array<HighchartsModule>,
): void => {
  // Activate additional modules
  modules?.forEach((module) => {
    module(instance);
  });
};
