import Alert from '@storeblocks/alert';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { usePermissionsQuery } from '@/api/permissions/permissionsApi';
import { SigningRightStatus } from '@/api/signing-right/models/SigningRight';
import { useSigningRightForUserQuery } from '@/api/signing-right/signingRightApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Pending } from '@/components/Pending';
import { H1 } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

export const WaitForSigningConfirmation: React.FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState(false);
  const [isError, setIsError] = useState(false);

  // Refetch the signing right every second until error or eventual expiry.
  const signingRightQuery = useSigningRightForUserQuery(undefined, {
    pollingInterval: 1000,
    skip: isExpired || isError,
  });

  const permissionsQuery = usePermissionsQuery();

  useEffect(() => {
    // Redirect to the completed page when the user receives the orders sign permission.
    if (signingRightQuery.data?.status === SigningRightStatus.Completed) {
      // Refetch the permissions to ensure the user gets the updated orders sign permission.
      permissionsQuery.refetch();

      // Replace the current history to avoid the user going back to the signed/unconfirmed pages.
      navigate(`/${organizationCmId}/trading/signing-right/completed`, {
        replace: true,
      });
    }

    // Set timed out to true if the signing right has expired.
    if (signingRightQuery.data?.status === SigningRightStatus.Expired) {
      setIsExpired(true);
    }
  }, [signingRightQuery.data?.status]);

  // Set the error flag if the signing right query failed to stop the polling on error.
  useEffect(() => {
    if (signingRightQuery.isError) {
      setIsError(true);
    }
  }, [signingRightQuery.isError]);

  return (
    <div data-test="signing-right-pending-confirmation">
      <H1>{texts.orders.signatureRight.unconfirmed.title}</H1>

      {/* Show error message if the signing right query failed. */}
      {isError ? (
        <Alert
          variant="error"
          title={texts.orders.signatureRight.confirmation.error.title}
          description={
            texts.orders.signatureRight.confirmation.error.description
          }
          data-test="signing-right-confirmation-error"
        />
      ) : (
        <>
          {/* Wait for signing confirmation from ESS/Signicat until eventual expiry. */}
          {!isExpired ? (
            <Pending />
          ) : (
            // The signing right confirmation expired, show an error message.
            <Alert
              variant="error"
              title={texts.orders.signatureRight.confirmation.timeout.title}
              description={
                texts.orders.signatureRight.confirmation.timeout.description
              }
              data-test="signing-right-expired"
            />
          )}
        </>
      )}
    </div>
  );
};
