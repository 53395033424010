import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { Show } from '@/components/Show';
import { DateSearch } from '@/components/Toolbar/DateSearch';
import { DownloadButton } from '@/components/Toolbar/DownloadButton';
import { DownloadErrorAlert } from '@/components/Toolbar/DownloadErrorAlert';
import { useSearchParams } from '@/components/Toolbar/hooks/useSearchParams';
import { TextSearch } from '@/components/Toolbar/TextSearch';
import { useFms } from '@/hooks/useFms';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import { media } from '@/styles/variables';
import { stripFilename } from '@/util/stripFilename';

import { useReportsContext } from '../context/ReportsProvider';
import { downloadReports } from '../utils/downloadReports';

export interface SearchParams {
  date?: string;
  query?: string;
}

interface Props {
  onSearchParamsChange: (params: SearchParams) => void;

  /**
   * Initialize the search field with the specified query.
   */
  initialQuery?: string;
}

export const FundReportsToolbar: React.FC<Props> = ({
  onSearchParamsChange,
  initialQuery,
}) => {
  const texts = useFms();
  const { selectedReports } = useReportsContext();

  const searchParams = useSearchParams<SearchParams>(onSearchParamsChange, {});

  const [handleDownloadOnClick, , downloadError] = useHandleAsyncOperation(
    async () => {
      await downloadReports([...selectedReports.values()]);
    },
  );

  const handleTextSearchChanged = (query: string): void => {
    searchParams.update((state) => {
      state.query = query;
    });
  };

  const handleDateSearchChanged = (date: Date | undefined): void => {
    const dateString = date ? format(date, 'yyyy-MM-dd') : '';

    searchParams.update((state) => {
      state.date = dateString;
    });
  };

  return (
    <>
      <Wrapper>
        <TextSearch
          onChange={handleTextSearchChanged}
          label={texts.reportsArchivePage.search}
          initialQuery={initialQuery}
        />

        <DateSearch
          onChange={handleDateSearchChanged}
          label={texts.reportsArchivePage.tableHeaders.reportDate}
        />

        <DownloadButton
          onClick={handleDownloadOnClick}
          numberOfFilesToDownload={selectedReports.size}
          data-trackid-downloaded-files={[...selectedReports.values()].map(
            (report) => stripFilename(report.name),
          )}
        />
      </Wrapper>

      <Show when={downloadError}>
        <DownloadErrorAlert />
      </Show>
    </>
  );
};

const Wrapper = styled.div`
  height: 136px;
  display: flex;

  & > div + div {
    margin-left: 25px;
  }

  & > button {
    /* Make the download button align to the right. */
    margin-left: auto;
    margin-top: 40px;
  }

  @media ${media.tabletAndBelow} {
    flex-flow: column;
    align-items: flex-start;

    & > div + div {
      margin-left: 0;
      margin-top: 16px;
    }

    & > button {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;
