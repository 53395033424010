import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';

import { fromLanguageToLocale, Locale } from '../locale';

/**
 * Get the locale for the signed in user.
 */
export const useUserLocale = (): Locale => {
  const { language } = useUserSettings();
  return fromLanguageToLocale(language);
};
