import Select, { Option } from '@storeblocks/select';
import { Field, FieldProps } from 'formik';
import React, { FunctionComponent } from 'react';

interface Props {
  name: string;
  label: string;
  hint?: string;
  options: Option[];
  error?: string;
  defaultOptionLabel?: string;
  fluid?: boolean;
}

export const FormSelect: FunctionComponent<Props> = ({
  name,
  label,
  hint,
  options,
  error = '',
  ...rest
}): JSX.Element => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        id={name}
        label={label}
        hint={hint}
        options={options}
        onChange={(event) => form.setFieldValue(name, event.target.value)}
        error={error}
        value={field.value}
        large
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    )}
  </Field>
);
