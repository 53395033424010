import Breadcrumbs from '@storeblocks/breadcrumbs';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

import { UserSettingsForm } from './UserSettingsForm';

/**
 * Page for the user to update their settings.
 */
export const UpdateInformationPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();

  const texts = useFms();

  return (
    <CenteredPageContent data-test="user-settings-page">
      <AnnouncementWarning />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/user-settings`}>
          {texts.menu.links.profileAndLanguage}
        </InternalLink>
      </Breadcrumbs>

      <PageHeading
        header={texts.pages.userSettings.title}
        ingress={texts.pages.userSettings.description}
      />

      <UserSettingsForm />
    </CenteredPageContent>
  );
};
