import { IHubReportFileDto } from '../dtos/IHubReportFileDto';
import { IHubReportsReply } from '../dtos/IHubReportsReply';
import { IHubReport } from '../models/IHubReport';

export const transformIHubReportsReply = (
  reply: IHubReportsReply,
): Array<IHubReport> => reply.files.map(mapIHubReportDtoToModel);

export const mapIHubReportDtoToModel = (
  dto: IHubReportFileDto,
): IHubReport => ({
  name: dto.name,
  id: dto.id,
  size: dto.size,
  fileType: dto.fileType,
  reportDateISO: dto.sortDate,
});
