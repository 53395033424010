import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 0px;
    font-family: Open Sans, sans-serif;
    overflow-y: scroll;
  }

  // Animations used with react-transition-group
  .item-enter {
    opacity: 0;
    position: relative;
    bottom: -100px;
    max-height: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms, bottom 500ms, max-height 500ms, height 500ms;
    bottom: 0px;
    max-height: 300px;
  }
  .item-exit {
    opacity: 1;
    top: 0px;
    max-height: 300px;
    position: relative;
  }
  .item-exit-active {
    display: block;
    opacity: 0;
    top: -100px;
    max-height: 0;
    transition: opacity 500ms, top 500ms, max-height 500ms, height 500ms;
  }

  // Remove the forced margin added to components after a hr from Storeblocks assets.
  hr + * {
    margin-top: unset;
  }
`;
