import Button from '@storeblocks/button';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { H1 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';

export const SigningRightCancelledSuccessfullyPage: React.FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();

  return (
    <WithGap data-test="signing-right-cancelled-successfully">
      <SigningRightBreadcrumb />

      <Stepper activeStep={2} />

      <H1>{texts.orders.signatureRight.aborted.title}</H1>

      <Row>
        {/*
         * Force the text to wrap to make it easier to read,
         * rather than to have it on one long line.
         */}
        <Column $span={6}>
          <p>{texts.orders.signatureRight.aborted.description}</p>
        </Column>
      </Row>

      <WithGap gap="24" direction="row">
        <Button
          variant="text"
          iconLeft="arrow-left"
          type="button"
          to={`/${organizationCmId}`}
          as={InternalLink}
          data-trackid={getTrackId('go-to-dashboard', TrackingElement.Link)}
        >
          {texts.orders.signatureRight.backToDashboard}
        </Button>

        <Button
          type="button"
          to={`/${organizationCmId}/trading/signing-right`}
          as={InternalLink}
          data-trackid={getTrackId('go-to-signing-right', TrackingElement.Link)}
        >
          {texts.orders.signatureRight.aborted.startAgain}
        </Button>
      </WithGap>
    </WithGap>
  );
};
