import React, { FC } from 'react';
import styled from 'styled-components';

import { padding } from '@/styles/variables';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

interface Props {
  organizations: string[];
}

export const OrganizationCell: FC<Props> = ({ organizations }) => {
  const formattedNames = organizations.map((orgName) =>
    prettifyOrganizationName(orgName),
  );

  return (
    <Container>
      {formattedNames.map((orgName) => (
        <OrgWrapper key={orgName}>{orgName}</OrgWrapper>
      ))}
    </Container>
  );
};

const OrgWrapper = styled.div`
  margin: ${padding.base}px 0px;
`;

const Container = styled.div`
  margin: -${padding.base}px 0px;
`;
