import React from 'react';

import { PortfolioHoldings } from '@/api/portfolioGroupHoldings/models/PortfolioHoldings';
import { Locale } from '@/i18n/locale';

import { CellWithTitle } from '../components/CellWithTitle';
import { SumByCurrencyCell } from '../components/SumByCurrencyCell';
import { HoldingsRow } from '../types/HoldingsRow';
import { mapToHoldingsRow } from './mapToHoldingsRow';

export const mapToPortfolioHoldingsRow = (
  portfolioHoldings: PortfolioHoldings,
  locale: Locale,
): HoldingsRow => {
  return {
    name: {
      sortContent: '',
      displayContent: <CellWithTitle value={portfolioHoldings.portfolioId} />,
    },
    shortName: {
      sortContent: '',
      displayContent: null,
    },
    instrumentType: {
      sortContent: '',
      displayContent: null,
    },
    nominal: '',
    cleanPrice: {
      sortContent: '',
      displayContent: null,
    },
    bookValue: {
      sortContent: portfolioHoldings.portfolioId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioHoldings.summary.bookValue}
        />
      ),
    },
    cleanValue: {
      sortContent: portfolioHoldings.portfolioId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioHoldings.summary.cleanValue}
        />
      ),
    },
    accruedInterest: {
      sortContent: portfolioHoldings.portfolioId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioHoldings.summary.accruedInterest}
        />
      ),
    },
    unrealizedPLValue: {
      sortContent: portfolioHoldings.portfolioId,
      displayContent: (
        <SumByCurrencyCell
          sumByCurrency={portfolioHoldings.summary.unrealizedPLValue}
        />
      ),
    },
    subRows: portfolioHoldings.holdings.map((holdings) =>
      mapToHoldingsRow(holdings, locale),
    ),
  };
};
