import React, { FC } from 'react';
import styled from 'styled-components';

import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { MainContent } from '@/components/MainContent';
import { NewsArticles } from '@/components/NewsArticles';
import { NewsLinks } from '@/components/NewsLinks';
import { FundsSustainabilityBannerWidget } from '@/components/Widgets/FundsSustainability/FundsSustainabilityBanner';
import { WithGap } from '@/components/WithGap';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';

import { HeaderSection } from './HeaderSection';

export const HomePage: FC = () => {
  return (
    <MainContent>
      <WithGap data-test="home-page">
        <Background>
          <CenteredPageContent>
            <AnnouncementWarning />

            <HeaderSection />
          </CenteredPageContent>
        </Background>

        <CenteredPageContent>
          {isBetaFeaturesOn() && (
            <>
              <NewsLinks />
              <NewsArticles />
              <FundsSustainabilityBannerWidget />
            </>
          )}
        </CenteredPageContent>
      </WithGap>
    </MainContent>
  );
};

const Background = styled.div`
  background-color: var(--color-surface);
  padding: 36px 0;
`;
