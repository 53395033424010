import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import type { PortfolioReply } from './dtos/PortfolioReply';
import type { Portfolios } from './models/Portfolios';
import { transformPortfolioGroupDtoToModel } from './utils/transformPortfolioGroupDtoToModel';

/**
 * Fetch all portfolio groups for the current organization.
 */
export const portfoliosApi = createApi({
  reducerPath: 'portfoliosApi',
  baseQuery: axiosBaseQuery<PortfolioReply>(),
  endpoints: (builder) => ({
    portfolios: builder.query<Portfolios, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/portfolios`,
      }),
      transformResponse: (dto: PortfolioReply) => {
        const portfolioGroups = dto.portfolioGroups.map(
          transformPortfolioGroupDtoToModel,
        );

        const portfolios = portfolioGroups.flatMap(
          (portfolioGroup) => portfolioGroup.portfolios,
        );

        return {
          portfolioGroups,
          portfolios,

          portfolioGroupById: new Map(
            portfolioGroups.map((portfolioGroup) => [
              portfolioGroup.id,
              portfolioGroup,
            ]),
          ),

          portfolioById: new Map(
            portfolios.map((portfolioGroup) => [
              portfolioGroup.id,
              portfolioGroup,
            ]),
          ),
        };
      },
    }),
  }),
});

export const { usePortfoliosQuery } = portfoliosApi;
