import React, {
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { presentationScreenRoutes } from '@/routes/presentationScreenRoutes';
import { ConnectRoute } from '@/routes/types/ConnectRoute';

import { useGlobalFilter } from './hooks/useGlobalFilter';
import { GlobalFilter } from './types/GlobalFilter';

interface PresentationContextType {
  selectedScreens: ConnectRoute[];
  globalFilter: GlobalFilter;
  filtersByScreen: ReadonlyMap<string, unknown>;
  isPrintPreviewPage: boolean;
  setSelectedScreens: (screens: ConnectRoute[]) => void;
  setGlobalFilter: (globalFilters: GlobalFilter) => void;
  setFilter: <T>(screenId: string, filter: T) => void;
  setIsPrintPreviewPage: (reportView: boolean) => void;
}

const PresentationContext = React.createContext<PresentationContextType | null>(
  null,
);

export const PresentationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedScreens, setSelectedScreens] = useState<ConnectRoute[]>(
    presentationScreenRoutes,
  );

  const [globalFilter, setGlobalFilter] = useGlobalFilter();

  const [filtersByScreen, setFiltersByScreen] = useState(
    new Map<string, unknown>(),
  );

  const [isPrintPreviewPage, setIsPrintPreviewPage] = useState(false);

  const contextData: PresentationContextType = useMemo(
    () => ({
      selectedScreens,
      globalFilter,
      filtersByScreen,
      isPrintPreviewPage,
      setSelectedScreens,
      setGlobalFilter,
      setFilter: (screenId, filter) => {
        filtersByScreen.set(screenId, filter);
        setFiltersByScreen(new Map(filtersByScreen));
      },
      setIsPrintPreviewPage,
    }),
    [selectedScreens, globalFilter, filtersByScreen, isPrintPreviewPage],
  );

  return (
    <PresentationContext.Provider value={contextData}>
      {children}
    </PresentationContext.Provider>
  );
};

export const usePresentationContext = (): PresentationContextType => {
  const context = useContext(PresentationContext);

  if (!context) {
    throw new Error(
      `${usePresentationContext.name} must be called within a ${PresentationProvider.name}.`,
    );
  }

  return context;
};

export function useFilterForScreen<T>(screenId: string, defaultFilter: T): T {
  const context = usePresentationContext();

  const filter = context.filtersByScreen.get(screenId) as T | undefined;

  return filter || defaultFilter;
}
