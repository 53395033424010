import React, { FC } from 'react';
import styled from 'styled-components';

import { Hint } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';

interface Props {
  percentage: number;
}

/**
 * Renders a styled text used for displaying a ESG coverage percentage.
 *
 * @param percentage The coverage percentage.
 */
export const EsgCoverageText: FC<Props> = ({ percentage }) => {
  const locale = useUserLocale();
  const texts = useFms();
  const coverageText = `${
    texts.pages.esg.coverageText
  } ${percentage.toLocaleString(locale)}%`;

  return <HintStyled>{coverageText}</HintStyled>;
};

const HintStyled = styled(Hint)`
  color: var(--color-inactive);
`;
