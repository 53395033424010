import { useFormikContext } from 'formik';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { Show } from '@/components/Show';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';

import { TotalSubscriptionAmountCell } from './TotalSubscriptionAmountCell';
import { TotalSwitchRedemptionPercentCell } from './TotalSwitchRedemptionPercentCell';

export const TotalSubscriptionCell: React.FC = () => {
  const { orderType } = useOrderPageContext();
  const { values } = useFormikContext<SubscriptionOrderFormValues>();

  // Return a total percent cell only if order type is switch
  // and value to type is percent.
  if (
    orderType === OrderType.Switch &&
    values.toValueType === OrderValueType.Percent
  ) {
    return <TotalSwitchRedemptionPercentCell />;
  }

  return (
    <Show when={values.toValueType === OrderValueType.Amount}>
      <TotalSubscriptionAmountCell />
    </Show>
  );
};
