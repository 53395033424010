import round from 'lodash/round';

import { AssetPerformanceData } from '@/api/performanceData/models';
import { DonutChartData } from '@/components/Charts';
import { Unit } from '@/types/Unit';
import { roundHalfEven } from '@/util/roundHalfEven';

export const getDonutChartDataByUnit = (
  assets: Array<AssetPerformanceData>,
  unit: Unit,
): DonutChartData => {
  switch (unit) {
    case Unit.Amount:
      return assets
        .filter((asset: AssetPerformanceData) => asset.marketValue > 0)
        .sort((a, b) => b.marketValue - a.marketValue)
        .map((asset: AssetPerformanceData) => {
          return [asset.name, roundHalfEven(asset.marketValue)];
        });

    case Unit.Percent:
      return assets
        .filter(
          (asset: AssetPerformanceData) => asset.assetRatioOfTotalPercent > 0,
        )
        .sort((a, b) => b.assetRatioOfTotalPercent - a.assetRatioOfTotalPercent)
        .map((asset: AssetPerformanceData) => [
          asset.name,
          round(asset.assetRatioOfTotalPercent, 2),
        ]);

    default:
      throw new Error(`Invalid unit for asset performance data [${unit}]`);
  }
};
