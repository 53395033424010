import dompurify from 'dompurify';
import parse from 'html-react-parser';
import React from 'react';

interface Props {
  html: string;
}

const domPurifier = dompurify(window);

/**
 * Sanitizes and render pure HTML.
 * @returns The specified HTML string as JSX.
 */
export const RenderHtml: React.FC<Props> = ({ html }) => {
  // The html-react-parser does not sanitize the input.
  // Thus we have to sanitize it before rendering to
  // avoid XSS.
  const sanitizedHtml = domPurifier.sanitize(html);

  return <span>{parse(sanitizedHtml)}</span>;
};
