import { Brand } from '@/types/Brand';
import { CurrencyCode } from '@/types/CurrencyCode';

import { FundPaymentInformation } from '../models/FundPaymentInformation';
import { PaymentAccountHolder } from '../models/PaymentAccountHolder';

const storebrandPaymentInformationNOK: FundPaymentInformation = {
  currency: CurrencyCode.NOK,
  bankAccountNumber: '6003 05 40576',
  iban: 'NO2260030540576',
  swift: 'NDEANOKK',
  accountHolder: PaymentAccountHolder.SAM,
};

const sppPaymentInformationSEK: FundPaymentInformation = {
  currency: CurrencyCode.SEK,
  bankAccountNumber: '(Plusgirot Bank/Nordea) 9960 3415979412',
  iban: 'SE199500009960341597941',
  swift: 'NDEASESS',
  accountHolder: PaymentAccountHolder.SPP,
};

/**
 * Get the payment information for a given brand and currency.
 */
export const getPaymentInformation = (
  brand: Brand,
  currency: CurrencyCode,
): FundPaymentInformation | undefined => {
  switch (brand + currency) {
    case Brand.STOREBRAND + CurrencyCode.NOK:
      return storebrandPaymentInformationNOK;
    case Brand.DELPHI + CurrencyCode.NOK:
      return storebrandPaymentInformationNOK;
    case Brand.SKAGEN + CurrencyCode.NOK:
      return storebrandPaymentInformationNOK;
    case Brand.SPP + CurrencyCode.SEK:
      return sppPaymentInformationSEK;
    default:
      return undefined;
  }
};
