import ChipGroup from '@storeblocks/chip/lib/group';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';

import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { Unit } from '@/types/Unit';

interface Props {
  /**
   * An ID to be used as a tracking source to know what data it filters.
   */
  id: string;
  checked: Unit;
  onChange(selectedUnit: Unit): void;
}

export const UnitChips: React.FC<Props> = ({ id, checked, onChange }) => {
  const texts = useFms();

  // A page can have multiple of this component,
  // so we need to generate a unique ID for each one.
  const [componentId] = useState(nanoid());

  const getTrackId = useGetTrackId();
  const trackId = getTrackId('select-unit', TrackingElement.FilterChip);

  return (
    <ChipGroup
      id={`unit-chips-${componentId}`}
      type="choice"
      options={[
        {
          id: `percent-${componentId}`,
          label: texts.charts.filters.units.percent,
          value: 'percent',
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'percent',
            'data-trackid-source': id,
            'data-trackid-type': 'filter-chips',
          },
        },
        {
          id: `amount-${componentId}`,
          label: texts.charts.filters.units.amount,
          value: 'amount',
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'amount',
            'data-trackid-source': id,
            'data-trackid-type': 'filter-chips',
          },
        },
      ]}
      checked={[checked]}
      onChange={(value) => onChange(value[0] as Unit)}
    />
  );
};
