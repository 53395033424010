import { addDays, isAfter, isBefore, parseISO, subDays } from 'date-fns';

import { Report } from '../types/ReportModel';

export const filterByDate = (
  reports: Report[],
  startDate?: string,
  endDate?: string,
): Report[] => {
  let filteredReports = reports;

  if (startDate) {
    filteredReports = filteredReports.filter((report) =>
      isAfter(parseISO(report.reportDateISO), subDays(parseISO(startDate), 1)),
    );
  }

  if (endDate) {
    filteredReports = filteredReports.filter((report) =>
      isBefore(parseISO(report.reportDateISO), addDays(parseISO(endDate), 1)),
    );
  }

  return filteredReports;
};
