import axios, { AxiosInstance } from 'axios';

import { isDemoOrganization } from '@/demoMode/isDemoOrganization';

import { addDemoModeUrlRewriteInterceptor } from './interceptors/addDemoModeUrlRewriteInterceptor';
import { addKeycloakTokenInterceptor } from './interceptors/addKeycloakTokenInterceptor';
import { addXRequestIdInterceptor } from './interceptors/addXRequestIdInterceptor';

interface ApiClientConfiguration {
  useKeycloakToken: boolean;
  customTimeout?: number;
}

export const apiClientCreator = ({
  useKeycloakToken,
  customTimeout,
}: ApiClientConfiguration): AxiosInstance => {
  const apiClient = axios.create({
    timeout: customTimeout || 30000,
    headers: {
      clientId: 'icwc',
    },
  });

  // Request interceptors:
  addXRequestIdInterceptor(apiClient);

  if (useKeycloakToken) {
    addKeycloakTokenInterceptor(apiClient);
  }

  if (isDemoOrganization()) {
    addDemoModeUrlRewriteInterceptor(apiClient);
  }

  return apiClient;
};
