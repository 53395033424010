import StoreblocksDatepicker from '@storeblocks/datepicker/lib/Datepicker';
import styled from 'styled-components';

export const DatePicker = styled(StoreblocksDatepicker)`
  // We need to set the width to prevent the error message from setting the width
  // wider and displacing all the adjacent elements.
  width: 148px;

  // Hide the inbuilt hint text saying: Format dd.MM.yyyy
  .hint {
    display: none;
  }
`;
