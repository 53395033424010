import { useField } from 'formik';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import React from 'react';
import styled from 'styled-components';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';
import { RedemptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/RedemptionOrderFormValues';
import { nameof } from '@/util/nameof';

import { TotalAmountCell } from './TotalAmountCell';

export const TotalRedemptionAmountCell: React.FC = () => {
  const [field] = useField<RedemptionFundState[]>(
    nameof<RedemptionOrderFormValues>('fromFunds'),
  );

  const fundsWithAmount = field.value
    .filter((fund) => fund.valueType === OrderValueType.Amount)
    .filter((fund) => fund.value > 0);

  const fundsGroupedByCurrency = groupBy(
    fundsWithAmount,
    (fund) => fund.currency,
  );

  return (
    <Container>
      {Object.keys(fundsGroupedByCurrency).map((currency) => {
        const total = sumBy(
          fundsGroupedByCurrency[currency],
          (fund) => fund.value,
        );
        return (
          <TotalAmountCell
            key={`amount-${currency}`}
            amount={total}
            suffix={currency}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
