import React, { FC } from 'react';
import { styled } from 'styled-components';

import { useFms } from '@/hooks/useFms';

export const TableHeader: FC = () => {
  const texts = useFms();

  return (
    <TableHead>
      <HeaderRow>
        <ChevronHeaderCell />
        <HeaderCell $columnWidth="10%">
          {texts.transactions.table.header.date}
        </HeaderCell>
        <HeaderCell $columnWidth="25%">
          {texts.transactions.table.header.name}
        </HeaderCell>
        <HeaderCell $columnWidth="25%">
          {texts.transactions.table.header.type}
        </HeaderCell>
        <HeaderCell $columnWidth="10%">
          {texts.transactions.table.header.amount}
        </HeaderCell>
        <HeaderCell $columnWidth="10%">
          {texts.transactions.table.header.price}
        </HeaderCell>
        <HeaderCell $columnWidth="20%">
          {texts.transactions.table.header.turnover}
        </HeaderCell>
      </HeaderRow>
    </TableHead>
  );
};

const TableHead = styled.thead`
  height: 48px;
`;

export const HeaderRow = styled.tr`
  background-color: var(--color-surface);
  border-top: 1px solid rgba(40, 40, 40, 0.12);
  border-bottom: 1px solid rgba(40, 40, 40, 0.12);
`;

const ChevronHeaderCell = styled.th`
  width: 48px;
`;

interface HeaderCellProps {
  $columnWidth?: string;
}

const HeaderCell = styled.th<HeaderCellProps>`
  padding: 11px 16px;
  line-height: 24px;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;

  ${(props: HeaderCellProps) =>
    props.$columnWidth && `width: ${props.$columnWidth};`}
`;
