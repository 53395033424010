import React, { FC } from 'react';

import { Permission } from '@/api/permissions/models/Permission';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { LatestMonthlyReportsWidget } from '@/components/Widgets/Reports';
import { WithGap } from '@/components/WithGap';

import { NewsArticlesWidget } from './NewsArticlesWidget';

export const NoPortfoliosDashboard: FC = () => {
  const hasPermission = useHasPermission();

  return (
    <div data-test="no-portfolios-dashboard">
      <WithGap gap="48">
        {hasPermission(Permission.ReportsRead) && (
          <LatestMonthlyReportsWidget />
        )}

        <NewsArticlesWidget />
      </WithGap>
    </div>
  );
};
