import take from 'lodash/take';

interface LogData {
  trackId: string;
  extraData: Record<string, string>;
}

export function findLogDataInClickEvent(
  event: MouseEvent,
): LogData | undefined {
  // ?: Is the click target an HTML element?
  if (!(event.target instanceof HTMLElement)) {
    // -> No, nothing to do.
    return undefined;
  }
  // -> Yes, it is an HTML element.

  // If the element has a track ID use it.
  if (event.target.dataset.trackid) {
    return {
      trackId: event.target.dataset.trackid,
      extraData: findExtraData(event.target.dataset),
    };
  }

  // If not, try to walk the click path to find it.
  // This can be if e.g. a button element wraps a h4 element.
  // The click target will be the h4, but it's the button that has
  // the onClick handler and a track ID.
  const elementWithTrackId = findElementWithTrackIdInClickPath(event);

  if (elementWithTrackId) {
    return {
      trackId: elementWithTrackId.dataset.trackid,
      extraData: findExtraData(elementWithTrackId.dataset),
    };
  }

  return undefined;
}

function findElementWithTrackIdInClickPath(
  event: MouseEvent,
): HTMLElement | undefined {
  const eventTargets = event.composedPath();

  // Restrict the path to 4 elements, the track ID should be in one of those.
  // Otherwise we can safely conclude that the click is on an element without
  // track ID.
  const htmlElement = take(eventTargets, 4).find((eventTarget) => {
    return eventTarget instanceof HTMLElement && eventTarget.dataset.trackid;
  }) as HTMLElement | undefined;

  return htmlElement;
}

/*
 * The dataset contains the data-* attributes formatted without the dashes.
 * E.g.: "data-trackid-has-holdings-in-fund" will be "trackidHasHoldingsInFund".
 */
function findExtraData(
  dataset: DOMStringMap,
): Record<string, string> | undefined {
  const extraDataKeys = Object.keys(dataset)
    // Don't take the exact "data-trackid" value.
    .filter((key) => key !== 'trackid')
    // Only take the extra data, e.g.: "data-trackid-isin".
    .filter((key) => key.includes('trackid'));

  if (extraDataKeys.length === 0) {
    return undefined;
  }

  return extraDataKeys.reduce((extraData, key) => {
    extraData[omitTrackidPrefix(key)] = dataset[key];

    return extraData;
  }, {});
}

function omitTrackidPrefix(key: string): string {
  const withoutPrefix = key.replace('trackid', '');
  return withoutPrefix.charAt(0).toLowerCase() + withoutPrefix.slice(1);
}
