import { matchPath } from 'react-router';

import { connectRoutes } from '../connectRoutes';
import { ConnectRoute } from '../types/ConnectRoute';
import { getBasename } from './getBasename';

// Find all route paths recursively and flatten the array.
const getAllPaths = (routes: ConnectRoute[] = []): string[] =>
  routes.flatMap((route) =>
    route.path
      ? [route.path, ...getAllPaths(route.children)]
      : getAllPaths(route.children),
  );

// Memoize the result since it is frequently used.
let flattenedRoutes: string[] = [];

const getAllRoutePathsFlattened = (): string[] => {
  if (flattenedRoutes.length === 0) {
    flattenedRoutes = getAllPaths(connectRoutes());
  }

  return flattenedRoutes;
};

export const findCurrentPath = (): string | undefined => {
  return getAllRoutePathsFlattened().find((path) =>
    matchPath(path, window.location.pathname.replace(`${getBasename()}`, '')),
  );
};
