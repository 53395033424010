import React, { FC } from 'react';
import styled from 'styled-components';

import {
  GeneralContactsWidget,
  PersonContactsWidget,
} from '@/components/Widgets/Contacts';
import { Country } from '@/types/country';

interface Props {
  country: Country;
}

export const CountryTab: FC<Props> = ({ country }) => {
  return (
    <Container>
      <GeneralContactsWidget country={country} />
      <PersonContactsWidget country={country} />
    </Container>
  );
};

const Container = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
