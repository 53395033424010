import Button from '@storeblocks/button';
import { SystemIcon } from '@storeblocks/icon';
import React, { useState } from 'react';
import styled from 'styled-components';

import { getAuth } from '@/auth/Auth';
import { Show } from '@/components/Show';

import { DebugSurface } from './DebugSurface';

export const CopyBearerToken: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <DebugSurface>
      <p>Copy the bearer authentication token to your clipboard.</p>
      <Container>
        <Button
          onClick={async () => {
            setLoading(true);
            const token = await getAuth().getToken();
            await navigator.clipboard.writeText(token);
            setLoading(false);

            setSuccess(true);
            setTimeout(() => setSuccess(false), 10_000);
          }}
          loading={loading}
          disabled={loading}
        >
          Copy to clipboard
        </Button>

        <Show when={success}>
          <SuccessIcon name="check-circle-filled" />
          <span>Copied!</span>
        </Show>
      </Container>
    </DebugSurface>
  );
};

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SuccessIcon = styled(SystemIcon)`
  color: var(--color-success-accent);
`;
