import InlineAlert from '@storeblocks/inline-alert';
import React from 'react';

import { FundInfo } from './FundInfo';

interface Props {
  errorMessage: string;
  isin: string;
}

export const AmountError: React.FC<Props> = ({ errorMessage, isin }) => {
  return (
    <FundInfo>
      <InlineAlert
        data-test={`amount-error-${isin}`}
        title={errorMessage}
        variant="error"
      />
    </FundInfo>
  );
};
