import { IHubReport } from '@/api/reports/models/IHubReport';

import { IHubSource, Report } from '../types/ReportModel';

export const mapIHubReportToReport = (
  ihubReport: IHubReport,
  source: IHubSource,
): Report => ({
  source,
  id: ihubReport.id,
  name: ihubReport.name,
  size: ihubReport.size,
  fileType: ihubReport.fileType,
  reportDateISO: ihubReport.reportDateISO,
});
