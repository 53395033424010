import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { OrderType } from '@/api/order/models/OrderType';
import { Show } from '@/components/Show';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';

import { FundsTable } from './components/FundsTable';
import { MultipleCurrenciesAlert } from './components/MultipleCurrenciesAlert';
import { useCreateSubscriptionFundTableColumns } from './hooks/useCreateSubscriptionFundTableColumns';
import { createNoFundsAddedRow } from './utils/createNoFundsAddedRow';
import { createSubscriptionSummaryRow } from './utils/createSubscriptionSummaryRow';
import { hasFundsMultipleCurrencies } from './utils/hasFundsMultipleCurrencies';
import { mapFundStateToSubscriptionFundRow } from './utils/mapFundStateToSubscriptionFundRow';

interface Props {
  hideHoldings?: boolean;
}

export const SubscriptionFundsTable: React.FC<Props> = ({ hideHoldings }) => {
  const { values } = useFormikContext<SubscriptionOrderFormValues>();

  const columns = useCreateSubscriptionFundTableColumns(
    values.toValueType,
    hideHoldings,
  );

  const hasMultipleCurrencies = hasFundsMultipleCurrencies(values.toFunds);

  const summaryRow = createSubscriptionSummaryRow(hideHoldings);
  const noFundsAddedRow = createNoFundsAddedRow();

  let fundRows = values.toFunds.map((fund) =>
    mapFundStateToSubscriptionFundRow(fund, hideHoldings),
  );

  if (fundRows.length === 0) {
    fundRows = [noFundsAddedRow, summaryRow];
  } else {
    fundRows = fundRows.concat(summaryRow);
  }

  return (
    <Container data-test="subscription-input-table">
      <FundsTable columns={columns} rows={fundRows} />
      <Show when={hasMultipleCurrencies} animate>
        <MultipleCurrenciesAlert orderType={OrderType.Subscription} />
      </Show>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
