/**
 * Represents the brand of a fund.
 * Copied from backend Brand enum and should be kept in sync.
 */
export enum Brand {
  SKAGEN = 'SKAGEN',
  STOREBRAND = 'STOREBRAND',
  DELPHI = 'DELPHI',
  SPP = 'SPP',
  KRON = 'KRON',
}
