import { CurrencyCode } from '@/types/CurrencyCode';

export interface Order {
  orderId: string;
  createdBy: string;
  status: OrderStatus;
  type: OrderType;
  createdDate: Date;
  documentReference: string;
  portfolioId: string;
  portfolioName?: string;

  orderLines: OrderLine[];

  signatories: Signatory[];
}

export interface OrderLine {
  isin: string;
  fundName: string;
  value: number;
  valueType: ValueType;
  type: OrderType;
  currency: CurrencyCode;
  status: OrderStatus;
}

export enum ValueType {
  Percent = 'PERCENT',
  Amount = 'AMOUNT',
  Units = 'UNITS',
}

export enum OrderType {
  Subscription = 'BUY',
  Redemption = 'SELL',
  Switch = 'SWITCH',
}

interface Signatory {
  name: string;
  signedDate: Date | undefined;
  signUrl: string | undefined;
}

/**
 * Represents the state of an order.
 */
export enum OrderStatus {
  /**
   * Created and awaiting signing.
   */
  PendingSigning = 'PENDING_SIGNING',

  /**
   * Sent to K3S, pending FA approval.
   */
  PendingApproval = 'PENDING_APPROVAL',

  /**
   * Only valid for subscriptions.
   */
  PendingCash = 'PENDING_CASH',

  /**
   * Waiting for settlement.
   */
  PendingSettlement = 'PENDING_SETTLEMENT',

  /**
   * Order is completed.
   */
  Settled = 'SETTLED',
}
