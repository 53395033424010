import { useMemo } from 'react';

import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';

interface Response {
  organizationName: string;
  organizationsIsPending: boolean;
  organizationsIsError: boolean;
  organizationsError: unknown;
}

export const useOrganizationName = (cmId: string): Response => {
  const organizationsQuery = useOrganizationsQuery();

  /**
   * Find organization for a given cmId and return the name.
   */
  return useMemo<Response>(() => {
    const organizationName =
      organizationsQuery.data.find(
        (organization) => organization.organizationId === cmId,
      )?.organizationName ?? '';

    return {
      organizationName,
      organizationsIsPending: organizationsQuery.isLoading,
      organizationsIsError: organizationsQuery.isError,
      organizationsError: organizationsQuery.error,
    };
  }, [
    organizationsQuery.data,
    organizationsQuery.isLoading,
    organizationsQuery.isError,
    organizationsQuery.error,
  ]);
};
