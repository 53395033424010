import React, { PropsWithChildren } from 'react';

import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { useCurrentRoute } from '@/routes/hooks';
import { getBasename } from '@/routes/utils/getBasename';

/**
 * Take the user to their organization if they only have access to one organization.
 * Otherwise continue rendering the children.
 *
 * The navigation is a full page reload to get the correct JWT token for the organization.
 */
export const OneOrganizationNavigation: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const currentRoute = useCurrentRoute();
  const organizationsQuery = useOrganizationsQuery();

  // Take the user to their organization if they only have access to one organization.
  if (currentRoute.path === '/' && organizationsQuery.data?.length === 1) {
    // Full page redirect to their only organization.
    window.location.replace(
      `${getBasename()}/${organizationsQuery.data[0].organizationId}`,
    );

    // Don't render anything while redirecting.
    return null;
  }

  return <>{children}</>;
};
