import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import LoadingIndicator from '@storeblocks/loading-indicator';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import { Form, FormikErrors, FormikTouched } from 'formik';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { FormInput } from '@/components/Form/FormInput';
import { FormRow } from '@/components/Form/FormRow';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { textInputSettings } from './UserFormSchema';

interface Props<T> extends PropsWithChildren {
  show: boolean;
  onClose: () => void;
  header: string;
  isSubmitting: boolean;
  submitFailed: boolean;
  currentMode: string;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
  dirty: boolean;
  trackingModal: string;
}

export const UserAdminForm = <T,>({
  show,
  onClose,
  header,
  isSubmitting,
  submitFailed,
  currentMode,
  touched,
  errors,
  dirty,
  children,
  trackingModal,
}: Props<T>): JSX.Element => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const alertRef = useRef(null);

  useEffect(() => {
    if (submitFailed && alertRef.current) {
      alertRef.current.scrollIntoView();
    }
  }, [submitFailed, alertRef]);

  return (
    <Form id={`${currentMode}-form`}>
      <Modal trigger={undefined} open={show} onClose={onClose}>
        <Heading>{header}</Heading>
        <Content>
          {textInputSettings.map(({ field, disabledRules }) => (
            <FormRow key={field}>
              <StyledFormInput
                name={field}
                disabled={disabledRules[currentMode]}
                label={texts[field]}
                large
                error={touched[field] ? errors[field] : undefined}
              />
            </FormRow>
          ))}
          {children}
          <Show when={submitFailed}>
            <FormRow ref={alertRef}>
              <Alert variant="error" title={texts.admin.user.couldNotSave} />
            </FormRow>
          </Show>
        </Content>
        <Footer>
          <ButtonContainer key="modal-button-container">
            <Button
              type="submit"
              disabled={
                isSubmitting || !dirty || Object.keys(errors).length > 0
              }
              form={`${currentMode}-form`}
              data-trackid={getTrackId(
                `save-changes:${trackingModal}-modal`,
                TrackingElement.Button,
              )}
            >
              {isSubmitting ? (
                <LoadingIndicator color="onPrimary" />
              ) : (
                texts.adminModal.saveButton
              )}
            </Button>
            <Button
              type="button"
              disabled={isSubmitting}
              variant="outlined"
              onClick={onClose}
              data-trackid={getTrackId(
                `cancel:${trackingModal}-modal`,
                TrackingElement.Button,
              )}
            >
              {texts.admin.cancel}
            </Button>
          </ButtonContainer>
        </Footer>
      </Modal>
    </Form>
  );
};

const StyledFormInput = styled(FormInput)`
  margin: 8px;
`;

const ButtonContainer = styled.div`
  > button {
    margin-right: 8px;
  }
`;
