import sortBy from 'lodash/sortBy';
import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/orderList/models/Order';
import { useOrderListQuery } from '@/api/orderList/orderListApi';
import { isNotReady } from '@/api/utils/isNotReady';
import { Column, Row } from '@/components/Grid';
import { TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { Heading } from './components/Heading';
import { Line } from './components/Line';
import { OrderLinesTable } from './components/OrderLinesTable';
import { PaymentInformation } from './components/PaymentInformation';
import { SignatoriesTable } from './components/SignatoriesTable';

export const OrderList: React.FC = () => {
  const texts = useFms();

  const orderListQuery = useOrderListQuery();
  const fundListQuery = useFundListQuery();

  if (isNotReady([orderListQuery, fundListQuery])) {
    return <QueryState query={[orderListQuery, fundListQuery]} />;
  }

  const ordersSorted = sortBy(
    orderListQuery.data,
    (order) => order.createdDate,
  ).reverse();

  return (
    <WithGap gap="64">
      {ordersSorted.map((order, index) => (
        <div key={order.orderId}>
          <WithGap gap="24">
            {/* Only display the line between orders. */}
            {index > 0 && <Line />}

            <Heading order={order} />

            <Row>
              <Column $span={6}>
                <SignatoriesTable order={order} />
              </Column>
              <Column $span={6}>
                {order.type === OrderType.Subscription && (
                  <PaymentInformation order={order} />
                )}
              </Column>
            </Row>

            {order.type === OrderType.Switch ? (
              <>
                <WithGap gap="48">
                  <WithGap gap="16">
                    <TextShortBold>
                      {texts.pages.orderList.fundTable.title.switchFrom}
                    </TextShortBold>
                    <OrderLinesTable
                      data-test="switch-from"
                      orderLines={order.orderLines.filter(
                        (orderPlacement) =>
                          orderPlacement.type === OrderType.Redemption,
                      )}
                    />
                  </WithGap>

                  <WithGap gap="16">
                    <TextShortBold>
                      {texts.pages.orderList.fundTable.title.switchTo}
                    </TextShortBold>
                    <OrderLinesTable
                      data-test="switch-to"
                      orderLines={order.orderLines.filter(
                        (orderPlacement) =>
                          orderPlacement.type === OrderType.Subscription,
                      )}
                    />
                  </WithGap>
                </WithGap>
              </>
            ) : (
              <OrderLinesTable
                data-test="order-lines-table"
                orderLines={order.orderLines}
              />
            )}
          </WithGap>
        </div>
      ))}
    </WithGap>
  );
};
