import React, { FC } from 'react';
import styled from 'styled-components';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { Grid } from '@/components/Grid';
import { TextShort, TextShortBold } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

import { EsgCoverageText } from '../../components/EsgCoverageText';
import { EsgInsufficientDataAlert } from '../../components/EsgInsufficientDataAlert';
import { EsgPercentageBar } from '../../components/EsgPercentageBar';
import { EsgPercentageText } from '../../components/EsgPercentageText';
import { calculateEsgKpiPercentage } from '../../utils/calculateEsgKpiPercentage';
import { calculateEsgReferenceKpiPercentage } from '../../utils/calculateEsgReferenceKpiPercentage';

interface Props {
  data: EsgCategory | undefined;
}

export const EsgCarbonFootprintComparison: FC<Props> = ({ data }) => {
  const texts = useFms();
  const {
    higher,
    lower,
    comparedToIndex,
    portfolioHas,
    reference,
    portfolio,
    tonnage,
  } = texts.pages.esg.carbonFootprint;

  if (!data) {
    return <EsgInsufficientDataAlert />;
  }

  return (
    <Grid>
      <OverviewContainer>
        <TextShort>{portfolioHas}</TextShort>
        <EsgPercentageText percentage={data.difference} />
        <TextShort>
          <TextShortBold>{`${
            data.difference > 0 ? higher : lower
          } `}</TextShortBold>
          {comparedToIndex}
        </TextShort>
        <EsgCoverageText percentage={data.kpi.coverageInPercent} />
      </OverviewContainer>
      <ComparisonContainer>
        <EsgPercentageBar
          label={reference}
          percentage={calculateEsgReferenceKpiPercentage(
            data.kpi,
            data.benchmark,
          )}
          value={data.benchmark.value}
          unit={tonnage}
        />
        <EsgPercentageBar
          label={portfolio}
          percentage={calculateEsgKpiPercentage(data.kpi, data.benchmark)}
          value={data.kpi.value}
          unit={tonnage}
        />
      </ComparisonContainer>
    </Grid>
  );
};

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ComparisonContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
`;
