import React, { FC } from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks';

interface Props {
  value: number;
  description: string;
  unit?: string;
}

export const EsgValueListItem: FC<Props> = ({ value, description, unit }) => {
  const locale = useUserLocale();

  return (
    <Container>
      <Description>{description}</Description>
      <TextShort>{`${value.toLocaleString(locale)}${unit}`}</TextShort>
    </Container>
  );
};

const Container = styled.li`
  display: flex;
  border-bottom: 1px solid var(--color-border);
  padding: 8px 0;
`;

const Description = styled(TextShort)`
  flex: 1;
`;
