import React from 'react';

import { TotalRedemptionAmountCell } from '../components/TotalRedemptionAmountCell';
import { RedemptionFundRow } from '../types/RedemptionFundRow';

export const createRedemptionSummaryRow = (): RedemptionFundRow => {
  return {
    fund: {
      displayContent: <div />,
      sortContent: '',
    },
    sellAll: {
      displayContent: null,
      sortContent: '',
    },
    holdings: {
      displayContent: null,
      sortContent: '',
    },
    amount: {
      displayContent: <TotalRedemptionAmountCell />,
      sortContent: '',
    },
  };
};
