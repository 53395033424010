import { FMSTexts } from '@/types/fms';

export const getScreenTitle = (texts: FMSTexts, screenId: string): string => {
  switch (screenId) {
    case 'cover-screen':
      return texts.pages.presentation.screens.cover.title;
    case 'portfolio-holdings-screen':
      return texts.pages.presentation.screens.portfolioHoldings.title;
    case 'asset-allocation-screen':
      return texts.pages.presentation.screens.assetAllocationTable.title;
    case 'asset-allocation-donut-screen':
      return texts.pages.presentation.screens.assetAllocationDonut.title;
    case 'portfolio-performance-screen':
      return texts.pages.presentation.screens.portfolioPerformance.title;
    case 'asset-return-screen':
      return texts.pages.presentation.screens.assetReturn.title;
    case 'esg-overview-screen':
      return texts.pages.presentation.screens.esg.overview;
    case 'esg-score-and-carbon-footprint-screen':
      return texts.pages.presentation.screens.esg.scoreAndCarbonFootprint;
    case 'esg-solutions-screen':
      return texts.pages.presentation.screens.esg.solutions;
    case 'esg-coverage-screen':
      return texts.pages.presentation.screens.esg.coverage;
    case 'esg-excluded-companies-screen':
      return texts.pages.presentation.screens.esg.excludedCompanies;
    default:
      throw new Error(`Unable to find FMS text for screenId [${screenId}]`);
  }
};
