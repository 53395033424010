import Input from '@storeblocks/input';
import type { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import { Field, FieldProps } from 'formik';
import React, { FunctionComponent } from 'react';

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  name: string;
  label: string;
  hint?: string;
  cleaveOptions?: CleaveOptions;
  error?: string;
  fluid?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  dataTest?: string;

  // Legacy props that are not supported any more.
  // Leaving them as-is since this component will be replaced when
  // the rewrite of the order pages is done.
  sign?: string;
  textarea?: boolean;
}

export const FormInput: FunctionComponent<Props> = ({
  name,
  label,
  hint,
  cleaveOptions,
  error,
  fluid = false,
  dataTest,
  small,
  medium,
  large,
  ...props
}): JSX.Element => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        inputProps={{
          // This will cause errors in the console, and it is expected.
          // This component will be replaced when the rewrite of the order pages is done.
          // The error will be of the type:
          // "Warning: Received `false` for a non-boolean attribute `fluid`."
          // Where the attribute can differ.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          as: cleaveOptions ? Cleave : undefined,
          options: cleaveOptions,
        }}
        fluid={fluid}
        error={error}
        label={label}
        hint={hint}
        small={small}
        medium={medium}
        large={large}
        data-test={dataTest}
      />
    )}
  </Field>
);
