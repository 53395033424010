import Input from '@storeblocks/input/lib';
import React, { FC } from 'react';
import styled from 'styled-components';

import { NumericFormatProps } from '../types/NumericFormatProps';

export const FundBaseInput: FC<NumericFormatProps> = (props) => {
  return (
    <StyledInput
      id={props.id}
      value=""
      suffix={props.customSuffix}
      name={props.name}
      disabled={props.disabled}
      onChange={props.onChange}
      onBlur={props.onBlur}
      error={props.error}
      inputProps={{ ...props }}
      fluid
    />
  );
};

const StyledInput = styled(Input)`
  & input {
    text-align: right;
  }

  min-width: 184px;
`;
