import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiError } from '@/api/types/ApiError';

import { UnknownApiError } from '../types/UnknownApiError';
import { axiosRequest } from './axiosRequest';

interface ErrorResponse {
  error: ApiError | UnknownApiError;
}

const request = async <T>(
  config: AxiosRequestConfig,
): Promise<AxiosResponse<T> | ErrorResponse> => {
  const query = await axiosRequest<T>(config);

  if (query.error) {
    return {
      error: query.error,
    };
  }

  return query.response;
};

type Options = Omit<AxiosRequestConfig, 'url' | 'method' | 'data'>;

/**
 * Create a request that does not go through the RTK Query.
 */
export const axiosQuery = {
  get: <T>(
    url: string,
    options?: Options,
  ): Promise<AxiosResponse<T> | ErrorResponse> => {
    return request<T>({ method: 'get', url, ...options });
  },

  post: <T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    options?: Options,
  ): Promise<AxiosResponse<T> | ErrorResponse> => {
    return request<T>({ method: 'post', url, data, ...options });
  },

  patch: <T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    options?: Options,
  ): Promise<AxiosResponse<T> | ErrorResponse> => {
    return request<T>({ method: 'patch', url, data, ...options });
  },

  put: <T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    options?: Options,
  ): Promise<AxiosResponse<T> | ErrorResponse> => {
    return request<T>({ method: 'put', url, data, ...options });
  },

  delete: <T>(
    url: string,
    options?: Options,
  ): Promise<AxiosResponse<T> | ErrorResponse> => {
    return request<T>({ method: 'delete', url, ...options });
  },
} as const;

export function isErrorResponse<T>(
  response: AxiosResponse<T> | ErrorResponse,
): response is ErrorResponse {
  return !!(response as ErrorResponse).error;
}
