import React, { FC } from 'react';

import { Column, Row } from '@/components/Grid';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { NewsLinkCard } from './NewsLinkCard';
import { NewsLink } from './types';

export const NewsLinks: FC = () => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const newsLinks: Array<NewsLink> = Object.values(texts.news.links);

  return (
    <Row>
      {newsLinks.map((newsLink) => (
        <Column $span={4} key={newsLink.title}>
          <NewsLinkCard
            newsLink={newsLink}
            trackId={getTrackId(
              `go-to-${newsLink.title
                .trim()
                .toLowerCase()
                .replace(/\s/g, '-')}`,
              TrackingElement.Link,
            )}
          />
        </Column>
      ))}
    </Row>
  );
};
