import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { DateFilterOption } from '../types/DateFilterOption';
import { DateFilterType } from '../types/DateFilterType';

export const useDateFilterOptions = (
  /**
   * The data source of the date filter.
   * This is used to track what data the filter is used on.
   */
  dataSource: string,
): DateFilterOption[] => {
  const texts = useFms();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-date-filter-type',
    TrackingElement.FilterChip,
  );

  return [
    {
      id: DateFilterType.ThisMonth,
      value: DateFilterType.ThisMonth,
      label: texts.dateFilter.thisMonth,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.ThisMonth,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.LastMonth,
      value: DateFilterType.LastMonth,
      label: texts.dateFilter.lastMonth,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.LastMonth,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.LastThreeMonths,
      value: DateFilterType.LastThreeMonths,
      label: texts.dateFilter.lastThreeMonths,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.LastThreeMonths,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.ThisYear,
      value: DateFilterType.ThisYear,
      label: texts.dateFilter.thisYear,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.ThisYear,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.OneMonth,
      value: DateFilterType.OneMonth,
      label: texts.dateFilter.small.oneMonth,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.OneMonth,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.ThreeMonths,
      value: DateFilterType.ThreeMonths,
      label: texts.dateFilter.small.threeMonths,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.ThreeMonths,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.SixMonths,
      value: DateFilterType.SixMonths,
      label: texts.dateFilter.small.sixMonths,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.SixMonths,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.OneYear,
      value: DateFilterType.OneYear,
      label: texts.dateFilter.small.oneYear,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.OneYear,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.ThreeYears,
      value: DateFilterType.ThreeYears,
      label: texts.dateFilter.small.threeYears,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.ThreeYears,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
    {
      id: DateFilterType.SinceInception,
      value: DateFilterType.SinceInception,
      label: texts.dateFilter.sinceInception,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': DateFilterType.SinceInception,
        'data-trackid-type': 'filter-chips',
        'data-trackid-source': dataSource,
      },
    },
  ];
};
