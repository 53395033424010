import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from 'formik';

import { FundState } from '@/pages/Order/steps/OrderDetails/types/FundState';

/**
 * Find the specified fund in the specified array in the
 * current Formik form context.
 */
export function useArrayFieldByIsin<T>(
  arrayName: string,
  isin: string,
  fieldName?: string,
): [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>] {
  const [fundField] = useField<FundState[]>(arrayName);

  const index = fundField.value.findIndex((f) => f.isin === isin);

  if (index === -1) {
    throw new Error(
      `Could not find fund with isin [${isin}] in the array [${arrayName}].`,
    );
  }

  const fieldSelector = fieldName
    ? `${arrayName}[${index}].${fieldName}`
    : `${arrayName}[${index}]`;

  return useField<T>(fieldSelector);
}
