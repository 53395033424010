import Select from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { styled } from 'styled-components';

import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { OrderType } from '@/api/order/models/OrderType';
import { useFms } from '@/hooks/useFms';
import { CurrencyCode } from '@/types/CurrencyCode';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { SubscriptionOrderFormValues } from '../../../types/SubscriptionOrderFormValues';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';

interface Props {
  paymentIndex: number;
  accountHolder: PaymentAccountHolder;
  currency: CurrencyCode;
}

export const SubscriptionPortfolioBankAccount: React.FC<Props> = ({
  paymentIndex,
  accountHolder,
  currency,
}) => {
  const texts = useFms();
  const formContext = useFormikContext<SubscriptionOrderFormValues>();
  const portfolios = useFundPortfolios(OrderType.Subscription);

  const [field, meta] = useField<string>(
    `payments[${paymentIndex}].portfolioBankAccountNumber`,
  );

  const portfolioBankAccounts = getPortfolioBankAccounts(
    formContext.values.toPortfolioShortName,
    portfolios,
  );

  const hasMultiplePortfolioBankAccounts = portfolioBankAccounts.length > 1;

  if (hasMultiplePortfolioBankAccounts) {
    // When there are multiple portfolio bank accounts, we show a select input
    // to let the user choose the account where the payment will be placed.

    const options = portfolioBankAccounts.map((account) => ({
      label: `${account.accountNumber} - ${account.bankName} - ${account.currency}`,
      value: account.accountNumber,
    }));

    return (
      <Select
        {...field}
        id={`portfolioBankAccount-select-${accountHolder}-${currency}`}
        label={texts.orders.form.payment.options.portfolioSelect.title}
        placeholder={
          texts.orders.form.payment.options.portfolioSelect.placeholder
        }
        options={options}
        error={meta.touched && meta.error}
        value={field.value || ''}
        fluid
      />
    );
  }

  // When there is only one portfolio bank account, we show the account
  // details for that account.

  const account = portfolioBankAccounts[0];

  return (
    <div data-test="one-portfolio-account">
      <PaymentTitle>
        {texts.orders.form.payment.options.subscription.portfolioAccountShort}
      </PaymentTitle>

      <PaymentSubtitle>
        {`${account.accountNumber} - ${account.bankName} - ${account.currency}`}
      </PaymentSubtitle>
    </div>
  );
};

const PaymentTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 16px;
`;

const PaymentSubtitle = styled.div`
  line-height: 24px;
  word-wrap: break-word;
`;
