import React from 'react';
import styled from 'styled-components';

import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import coverImage from '@/assets/images/rob1179-5642.jpg';
import { Column, Row } from '@/components/Grid';
import { H1, Ingress } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { usePresentationContext } from '../PresentationContext';

export const CoverScreen: React.FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();
  const { organizationName } = useOrganizationName(organizationCmId);

  const { globalFilter } = usePresentationContext();

  return (
    <A4WidthForPrint>
      <Row>
        <LeftColumn $span={4}>
          <WithGap gap="96">
            <div>
              <StyledH1>{prettifyOrganizationName(organizationName)}</StyledH1>
              {globalFilter.portfolioGroups.map((portfolioGroup) => (
                <StyledIngress key={portfolioGroup.id}>
                  {texts.pages.presentation.screens.cover.portfolio}{' '}
                  {portfolioGroup.id} - {portfolioGroup.name}
                </StyledIngress>
              ))}
            </div>
            <LogoWrapper>
              <StyledStorebrandLogo />
            </LogoWrapper>
          </WithGap>
        </LeftColumn>
        <RightColumn $span={8}>
          <div>
            <Image src={coverImage} />
          </div>
        </RightColumn>
      </Row>
    </A4WidthForPrint>
  );
};

const StyledH1 = styled(H1)`
  margin: 24px 0;
`;

const StyledIngress = styled(Ingress)`
  display: block;
  margin-bottom: 8px;
`;

const LogoWrapper = styled.div`
  height: 24px;
`;

const LeftColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RightColumn = styled(Column)`
  display: flex;
  justify-content: end;
`;

const Image = styled.img`
  max-width: 100%;
  // Allows the image to be resized, but keep the same square aspect ratio.
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
`;
