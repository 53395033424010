import styled, { css } from 'styled-components';

export const h3Style = css`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-on-background);
`;

export const H3 = styled.h3`
  ${h3Style}
`;
