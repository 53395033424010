import React from 'react';

import { NoFundsAddedInfo } from '../components/NoFundsAddedInfo';
import { SubscriptionFundRow } from '../types/SubscriptionFundRow';

export const createNoFundsAddedRow = (): SubscriptionFundRow => {
  return {
    reactKey: 'no-funds-added-row',
    fund: {
      displayContent: <NoFundsAddedInfo />,
      sortContent: '',
    },
    holdings: {
      displayContent: null,
      sortContent: '',
    },
    amount: {
      displayContent: null,
      sortContent: '',
    },
  };
};
