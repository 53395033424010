import React, {
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Report } from '../types/ReportModel';

interface ReportsContextType {
  selectedReports: ReadonlyMap<string, Report>;
  set: (report: Report) => void;
  delete: (reportId: string) => void;
}

const ReportsContext = React.createContext<ReportsContextType | null>(null);

export const ReportsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [reports, setReports] = useState<ReadonlyMap<string, Report>>(
    new Map(),
  );

  const contextData = useMemo<ReportsContextType>(
    () => ({
      selectedReports: reports,
      set: (report: Report) => {
        setReports(new Map([...reports, [report.id, report]]));
      },
      delete: (reportId: string) => {
        const map = new Map([...reports]);
        map.delete(reportId);

        setReports(map);
      },
    }),
    [reports, setReports],
  );

  return (
    <ReportsContext.Provider value={contextData}>
      {children}
    </ReportsContext.Provider>
  );
};

export const useReportsContext = (): ReportsContextType => {
  const context = useContext(ReportsContext);

  if (!context) {
    throw new Error(
      'useReportsContext must be called within a ReportsProvider.',
    );
  }

  return context;
};
