import { ReportFile } from '@/api/reports/models/ReportFile';

import { Report, ReportSource } from '../types/ReportModel';

export const mapReportFileToReport = (reportFile: ReportFile): Report => ({
  source: ReportSource.Crossroads,
  id: reportFile.id,
  name: reportFile.name,
  size: reportFile.size,
  fileType: reportFile.fileType,
  reportDateISO: reportFile.reportDateISO,
});
