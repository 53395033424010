import Alert from '@storeblocks/alert';
import React from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';
import { media } from '@/styles/variables';

interface Props {
  contentType: 'content' | 'overview';
  fluid?: boolean;

  /**
   * To be used by Styled Components.
   */
  className?: string;
}

export const FundAvailabilityAlert: React.FC<Props> = ({
  contentType,
  fluid,
  className,
}) => {
  const texts = useFms();

  switch (contentType) {
    case 'content':
      return (
        <StyledAlert
          variant="warning"
          title={texts.alert.fundAvailability.newsContent.title}
          description={texts.alert.fundAvailability.newsContent.description}
          fluid={fluid}
          className={className}
        />
      );

    case 'overview':
      return (
        <StyledAlert
          variant="warning"
          title={texts.alert.fundAvailability.overviewContent.title}
          description={texts.alert.fundAvailability.overviewContent.description}
          fluid={fluid}
          className={className}
        />
      );

    default:
      throw new Error(`Missing JSX for alert type [${contentType}].`);
  }
};

const StyledAlert = styled(Alert)`
  margin: 0;

  @media ${media.tabletAndBelow} {
    min-width: unset;
  }
`;
