import Accordion, { Row } from '@storeblocks/accordion';
import StoreblocksModal, { Content, Footer, Heading } from '@storeblocks/modal';
import { useFormikContext } from 'formik';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import React from 'react';

import { useFundListQuery } from '@/api/funds/fundsApi';
import { FundList } from '@/api/funds/models';
import { WithGap } from '@/components/WithGap';
import { isCypress } from '@/config/utils';
import { useFms } from '@/hooks/useFms';
import { nameof } from '@/util/nameof';

import { SubscriptionOrderFormValues } from '../../types/SubscriptionOrderFormValues';
import { SwitchOrderFormValues } from '../../types/SwitchOrderFormValues';
import {
  AddFundsModalProvider,
  useAddFundsModalContext,
} from './AddFundsModalContext';
import { AddFundsModalContent } from './components/AddFundsModalContent';
import { CloseButton } from './components/CloseButton';
import { SubmitButton } from './components/SubmitButton';
import { mapFundToFundState } from './utils/mapFundToFundState';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddFundsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  // Wrap the modal in React Context provider.
  return (
    <AddFundsModalProvider>
      <Modal isOpen={isOpen} onClose={onClose} />
    </AddFundsModalProvider>
  );
};

const Modal: React.FC<Props> = ({ isOpen, onClose }) => {
  const texts = useFms();

  const formContext = useFormikContext<
    SubscriptionOrderFormValues | SwitchOrderFormValues
  >();

  const { selectedIsins, resetContextData } = useAddFundsModalContext();

  const { data: fundList } = useFundListQuery();

  const { currency } = formContext.values as SwitchOrderFormValues;

  const fundsOpenForSubscription = fundList.funds.filter(
    (fund) => !fund.closedForSubscription,
  );

  const fundsOpenForSubscriptionByIsin = pickBy(
    fundList.byIsin,
    (fund) => !fund.closedForSubscription,
  );

  // Get all the isins of the funds that the user has holdings in.
  const fundsWithHoldings = uniq(
    formContext.values.toFunds
      .filter((fund) => fund.marketValue > 0)
      .map((fund) => fund.isin),
  );

  // Filter funds to only display the funds with the same base currency as the selected currency if any.
  const fundListToDisplay: FundList = {
    funds: currency
      ? fundsOpenForSubscription.filter(
          (fund) => fund.baseCurrency === currency,
        )
      : fundsOpenForSubscription,
    byIsin: currency
      ? pickBy(
          fundsOpenForSubscriptionByIsin,
          (fund) => fund.baseCurrency === currency,
        )
      : fundsOpenForSubscriptionByIsin,
  };

  const handleClose = (): void => {
    resetContextData();
    onClose();
  };

  const handleAddFunds = (): void => {
    const fundsToAdd = fundList.funds
      .filter((fund) => selectedIsins.has(fund.isin))
      .filter(
        // Don't add funds that are already in the form.
        (fund) => !formContext.values.toFunds.some((f) => f.isin === fund.isin),
      );

    const fundStates = fundsToAdd.map((fund) =>
      mapFundToFundState(fund, formContext.values.toValueType),
    );

    formContext
      .getFieldHelpers(nameof<SubscriptionOrderFormValues>('toFunds'))
      .setValue([...formContext.values.toFunds, ...fundStates]);

    handleClose();
  };

  return (
    <StoreblocksModal
      trigger={undefined}
      open={isOpen}
      onClose={handleClose}
      disableAnimation={isCypress()}
    >
      <Heading>{texts.orders.form.addFundModal.header.title}</Heading>
      <Content data-test="add-funds-modal">
        <AddFundsModalContent
          fundList={fundListToDisplay}
          fundsWithHoldings={fundsWithHoldings}
        />
      </Content>

      <Footer>
        <WithGap>
          <Accordion>
            <Row
              id="minimum-subscription-explanation"
              header={
                texts.orders.form.addFundModal.minimumSubscriptionAmount.title
              }
            >
              {
                texts.orders.form.addFundModal.minimumSubscriptionAmount
                  .description
              }
            </Row>
          </Accordion>
          <div>
            <SubmitButton key="confirm-button" onClick={handleAddFunds} />
            <CloseButton key="cancel-button" onClick={handleClose} />
          </div>
        </WithGap>
      </Footer>
    </StoreblocksModal>
  );
};
