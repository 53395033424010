import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { EsgCurrencyCategory } from '@/api/esg/models/EsgCurrencyCategory';
import { Center } from '@/components/Center';
import { Gauge } from '@/components/Charts';
import { Column, Row } from '@/components/Grid';
import { useFms } from '@/hooks/useFms';

import { EsgOverviewValueList } from './EsgOverviewValueList';

interface Props {
  esgScore: EsgCategory;
  solutionCompanies: EsgCategory;
  euTaxonomyAlignment: EsgCategory;
  euTaxonomyEligibility: EsgCategory;
  carbonIntensities: EsgCurrencyCategory[];
}

export const EsgOverview: React.FC<Props> = ({
  esgScore,
  solutionCompanies,
  euTaxonomyAlignment,
  euTaxonomyEligibility,
  carbonIntensities,
}) => {
  const fms = useFms();

  const texts = fms.pages.esg.overview;

  return (
    <Row>
      <Column $span={4}>
        <Center horizontal vertical>
          <Gauge
            percent={solutionCompanies.kpi.value}
            description={texts.sustainableInvestments}
          />
        </Center>
      </Column>

      <Column $span={8}>
        <EsgOverviewValueList
          esgScore={esgScore}
          solutionCompanies={solutionCompanies}
          euTaxonomyAlignment={euTaxonomyAlignment}
          euTaxonomyEligibility={euTaxonomyEligibility}
          carbonIntensities={carbonIntensities}
        />
      </Column>
    </Row>
  );
};
