import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Highstocks from 'highcharts/highstock';
import Accessibility from 'highcharts/modules/accessibility';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import SeriesLabel from 'highcharts/modules/series-label';
import SolidGauge from 'highcharts/modules/solid-gauge';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import { useGlobalOptions } from './hooks/useGlobalOptions';
import { useModules } from './hooks/useModules';

interface HighchartsState {
  highcharts: typeof Highcharts;
  highstocks: typeof Highstocks;
}

const HighchartsContext = createContext<HighchartsState | undefined>(undefined);

/** Provides a global Highcharts instance for use with `highcharts-react-official` */
export const HighchartsProvider: FC<PropsWithChildren> = ({ children }) => {
  const highchartsState = useMemo(
    () => ({
      highcharts: Highcharts,
      highstocks: Highstocks,
    }),
    [],
  );

  // Set the global highcharts options.
  useGlobalOptions(highchartsState.highcharts);
  useGlobalOptions(highchartsState.highstocks);

  // Activate additional modules for the highcharts instances.
  useModules(highchartsState.highcharts, [
    Accessibility,
    NoDataToDisplay,
    HighchartsMore,
    SolidGauge,
    SeriesLabel,
  ]);
  useModules(highchartsState.highstocks, [Accessibility, NoDataToDisplay]);

  return (
    <HighchartsContext.Provider value={highchartsState}>
      {children}
    </HighchartsContext.Provider>
  );
};

/**
 * Use the global Highcharts instance for synchronized modules and configuration.
 * @returns {typeof Highcharts} The global highcharts instance.
 */
export const useHighcharts = (): typeof Highcharts => {
  const context = useContext(HighchartsContext);

  if (!context) {
    throw new Error('useHighcharts must be used within a HighchartsProvider');
  }

  return context.highcharts;
};

/**
 * Use the global Highstocks instance for synchronized modules and configuration.
 * @returns {typeof Highstocks} The global highcharts instance.
 */
export const useHighstocks = (): typeof Highcharts => {
  const context = useContext(HighchartsContext);

  if (!context) {
    throw new Error('useHighstocks must be used within a HighchartsProvider');
  }

  return context.highstocks;
};
