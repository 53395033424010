import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { CurrencyCode } from '@/types/CurrencyCode';
import { FMSTexts } from '@/types/fms';

import { getPaymentTitleFromAccountHolder } from './getPaymentTitleFromAccountHolder';

interface Options {
  displayCurrencyInTitle: boolean;
}

export const getSubscriptionPaymentTitle = (
  accountHolder: PaymentAccountHolder,
  currency: CurrencyCode,
  texts: FMSTexts,
  options?: Options,
): string => {
  if (options?.displayCurrencyInTitle) {
    return fmsWithTemplate(
      texts.orders.form.payment.subscription.settlementAccount.info.title
        .withCurrency,
      {
        name: getPaymentTitleFromAccountHolder(accountHolder, texts),
        currency: currency,
      },
    );
  }

  return fmsWithTemplate(
    texts.orders.form.payment.subscription.settlementAccount.info.title
      .withoutCurrency,
    {
      name: getPaymentTitleFromAccountHolder(accountHolder, texts),
    },
  );
};
