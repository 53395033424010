import React, { PropsWithChildren } from 'react';

import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { isInternalUser } from '@/config/utils';
import { isOnboardingUserInProgress } from '@/util/isOnNewUserPage';

import { NoAccessExternalUser } from './NoAccessExternalUser';
import { NoAccessInternalUser } from './NoAccessInternalUser';

/**
 * Check if the user has access to the Connect platform. This includes
 * both internal and external users.
 *
 * It will display a "no access" page if they don't have access.
 *
 * All users can sign in to Connect with BankID/SSO successfully. It is the
 * HTTP call to fetch organizations that determine if the user has access to
 * the platform. If the HTTP call returns an error code, then the user does
 * not have access. A successful HTTP call will return the organizations the
 * user has access to.
 */
export const HasUserAccessToConnect: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const organizationsQuery = useOrganizationsQuery();

  if (isOnboardingUserInProgress()) {
    // Should render the new user pages even if the user doesn't have access yet.
    return <>{children}</>;
  }

  if (organizationsQuery.error) {
    return isInternalUser() ? (
      <NoAccessInternalUser />
    ) : (
      <NoAccessExternalUser />
    );
  }

  return <>{children}</>;
};
