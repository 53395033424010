import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  progressInPercent: number;
}

export const ProgressBar: FC<Props> = ({ progressInPercent }: Props) => (
  <Bar style={{ width: `${progressInPercent}%` }}>
    <PercentNumber>{Math.round(progressInPercent)} %</PercentNumber>
  </Bar>
);

const Bar = styled.div`
  background: #475718;
  border-radius: 5px;
`;

const PercentNumber = styled.span`
  white-space: nowrap;
  color: white;
`;
