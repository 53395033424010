import React from 'react';
import styled from 'styled-components';

import { Show } from '@/components/Show';
import { TextShortBold } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks/useUserLocale';
import { formatNumber } from '@/util/formatNumber';

interface Props {
  amount: number;
  suffix: string;
}

export const TotalAmountCell: React.FC<Props> = ({ amount, suffix }) => {
  const locale = useUserLocale();

  const formattedTotal = formatNumber(amount, locale, suffix);

  return (
    <Show when={amount > 0}>
      <Container>
        <TextShortBold>Total:</TextShortBold>
        <TextShortBold id={`total-order-value-${suffix}`}>
          {formattedTotal}
        </TextShortBold>
      </Container>
    </Show>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  gap: 8px;
  margin-right: 8px;
`;
