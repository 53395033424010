import round from 'lodash/round';
import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { DonutChart, DonutChartData } from '@/components/Charts';
import { useFms } from '@/hooks/useFms';
import { Unit } from '@/types/Unit';

interface Props {
  solutionCompaniesEqualOpportunities: EsgCategory | undefined;
  solutionCompaniesRenewableEnergy: EsgCategory | undefined;
  solutionCompaniesCircularEconomy: EsgCategory | undefined;
  solutionCompaniesCrossCutting: EsgCategory | undefined;
  solutionCompaniesSmartCities: EsgCategory | undefined;
}

export const EsgSolutionsGraph: React.FC<Props> = ({
  solutionCompaniesEqualOpportunities,
  solutionCompaniesRenewableEnergy,
  solutionCompaniesCircularEconomy,
  solutionCompaniesCrossCutting,
  solutionCompaniesSmartCities,
}) => {
  const texts = useFms();

  const donutChartData: DonutChartData = [];
  let total = 0;

  if (solutionCompaniesCircularEconomy) {
    total += solutionCompaniesCircularEconomy.kpi.value;

    donutChartData.push([
      texts.pages.fundEsg.solutions.companyCircularEconomy,
      solutionCompaniesCircularEconomy.kpi.value,
    ]);
  }

  if (solutionCompaniesSmartCities) {
    total += solutionCompaniesSmartCities.kpi.value;

    donutChartData.push([
      texts.pages.fundEsg.solutions.companySmartCities,
      solutionCompaniesSmartCities.kpi.value,
    ]);
  }

  if (solutionCompaniesEqualOpportunities) {
    total += solutionCompaniesEqualOpportunities.kpi.value;

    donutChartData.push([
      texts.pages.fundEsg.solutions.companyEqualOpportunities,
      solutionCompaniesEqualOpportunities.kpi.value,
    ]);
  }

  if (solutionCompaniesRenewableEnergy) {
    total += solutionCompaniesRenewableEnergy.kpi.value;

    donutChartData.push([
      texts.pages.fundEsg.solutions.companyRenewableEnergy,
      solutionCompaniesRenewableEnergy.kpi.value,
    ]);
  }

  if (solutionCompaniesCrossCutting) {
    total += solutionCompaniesCrossCutting.kpi.value;

    donutChartData.push([
      texts.pages.fundEsg.solutions.companyCrossCutting,
      solutionCompaniesCrossCutting.kpi.value,
    ]);
  }

  donutChartData.push([
    texts.pages.fundEsg.solutions.chart.legend.other,
    round(100 - total, 2),
  ]);

  return (
    <DonutChart
      data={donutChartData}
      size={200}
      unit={Unit.Percent}
      positionLegendBottom
    />
  );
};
