import { IHubDirectoriesReply } from '../dtos/IHubDirectoriesReply';
import { IHubDirectory } from '../models/IHubDirectory';
import { getFriendlyName } from './getFriendlyName';

export const transformIHubDirectoriesReply = (
  reply: IHubDirectoriesReply,
): Array<IHubDirectory> => {
  return reply.itemList.file.map((file) => ({
    id: file.id,
    name: file.name,
    friendlyName: getFriendlyName(file.name),
  }));
};
