import { AnyAction, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { adminApi } from './api/admin/adminApi';
import { assignableRolesApi } from './api/assignableRoles/assignableRolesApi';
import { contactUsFormApi } from './api/contactUsForm/contactUsFormApi';
import { countriesApi } from './api/countries/countriesApi';
import { documentArchiveApi } from './api/documentArchive/documentArchiveApi';
import { esgApi } from './api/esg/esgApi';
import { fmsApi } from './api/fms/fmsApi';
import { fundHoldingsApi } from './api/fundHoldings/fundHoldingsApi';
import { fundsApi } from './api/funds/fundsApi';
import { newsArticlesApi } from './api/newsArticles/newsArticlesApi';
import { orderApi } from './api/order/orderApi';
import { orderListApi } from './api/orderList/orderListApi';
import { organizationsApi } from './api/organizations/organizationsApi';
import { organizationUsersApi } from './api/organizationUsers/organizationUsersApi';
import { paymentOptionsApi } from './api/paymentOptions/paymentOptionsApi';
import { multiPortfolioReturnsApi } from './api/performanceData/multiPortfolioReturnsApi';
import { performanceDataApi } from './api/performanceData/performanceDataApi';
import { permissionsApi } from './api/permissions/permissionsApi';
import { multiPortfolioGroupHoldingsApi } from './api/portfolioGroupHoldings/multiPortfolioGroupHoldingsApi';
import { portfoliosApi } from './api/portfolios';
import { reportsApi } from './api/reports/reportsApi';
import { signingRightApi } from './api/signing-right/signingRightApi';
import { transactionsApi } from './api/transactions/transactionsApi';
import { usersApi } from './api/users/usersApi';
import { userSettingsApi } from './api/userSettings/userSettingsApi';
import { logApiErrorListener } from './logging/middleware/logApiErrorListener';
import { logTypeErrorListener } from './logging/middleware/logTypeErrorListener';
import { postLogEventToConnectListener } from './logging/middleware/postLogEventToConnectListener';
import { printLogEventListener } from './logging/middleware/printLogEventListener';
import { rootReducer } from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    const transformedAction: AnyAction = {
      ...action,

      // Remove as this can be quite big and isn't needed for debugging
      // in Sentry.
      payload: 'REMOVED',
    };

    return transformedAction;
  },

  stateTransformer: () => {
    // Don't send the redux state as it is very large and for the most time not
    // needed for debugging. And there is sensitive information in there (PII).
    return {};
  },
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    // serializableCheck is disabled only because we have a release tomorrow. We should see if it can be enabled again when we have time.
    getDefaultMiddleware({ serializableCheck: false })
      .concat(
        esgApi.middleware,
        adminApi.middleware,
        orderApi.middleware,
        fundsApi.middleware,
        usersApi.middleware,
        countriesApi.middleware,
        orderListApi.middleware,
        portfoliosApi.middleware,
        permissionsApi.middleware,
        userSettingsApi.middleware,
        fundHoldingsApi.middleware,
        signingRightApi.middleware,
        newsArticlesApi.middleware,
        transactionsApi.middleware,
        contactUsFormApi.middleware,
        paymentOptionsApi.middleware,
        performanceDataApi.middleware,
        multiPortfolioReturnsApi.middleware,
        multiPortfolioGroupHoldingsApi.middleware,
        organizationsApi.middleware,
        assignableRolesApi.middleware,
        organizationUsersApi.middleware,
        documentArchiveApi.middleware,
        fmsApi.middleware,
        reportsApi.middleware,
      )
      // NOTE: Since listeners can receive actions with functions inside,
      // it should go before the serializability check middleware
      .prepend(
        printLogEventListener.middleware,
        postLogEventToConnectListener.middleware,
        logApiErrorListener.middleware,
        logTypeErrorListener.middleware,
      ),
});

// Infer the `GlobalState` and `AppDispatch` types from the store itself.
export type GlobalState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
