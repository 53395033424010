import React, { FC } from 'react';

import { Column, Row, WithRowGap } from '@/components/Grid';
import { Paragraph } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

import { EsgExclusionsList } from './EsgExclusionsList';

export const EsgExclusions: FC = () => {
  const texts = useFms();
  const { description, storebrandStandard } = texts.pages.esg.exclusions;

  return (
    <Row>
      <Column $span={6}>
        <WithRowGap>
          <Paragraph>{storebrandStandard}</Paragraph>
          <Paragraph>{description}</Paragraph>
        </WithRowGap>
      </Column>

      <Column $span={6}>
        <EsgExclusionsList />
      </Column>
    </Row>
  );
};
