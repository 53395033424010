import React from 'react';
import styled from 'styled-components';

import { TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

interface Props {
  fundPercent: number;
  benchmarkPercent: number;
}

/**
 * Display two bars with a gray background and a blue bar on top.
 * They gray background is always 100% wide while the blue bar
 * will fill it up from the specified input value (0% to 100%).
 *
 * @example
 * Fund: 37%
 * ████████████░░░░░░░░░░░░░
 *
 * Benchmark: 26%
 * █████████░░░░░░░░░░░░░░░░
 */
export const ScienceBasedTargetsChart: React.FC<Props> = ({
  fundPercent: fundValue,
  benchmarkPercent: benchmarkValue,
}) => {
  const texts = useFms();

  return (
    <WithGapStyled gap="24">
      <WithGap gap="16" direction="row">
        <ValueText>{fundValue} %</ValueText>

        <WithGapStyled gap="8">
          <TextShortBold>{texts.pages.fundEsg.fund.title}</TextShortBold>

          <BarContainer>
            <WithGrayBackground />
            <SingleBar value={fundValue} color="var(--color-blue-50)" />
          </BarContainer>
        </WithGapStyled>
      </WithGap>

      {benchmarkValue && (
        <WithGap gap="16" direction="row">
          <ValueText>{benchmarkValue} %</ValueText>

          <WithGapStyled gap="8">
            <TextShortBold>{texts.pages.fundEsg.benchmark.title}</TextShortBold>

            <BarContainer>
              <WithGrayBackground />
              <SingleBar value={benchmarkValue} color="var(--color-blue-20)" />
            </BarContainer>
          </WithGapStyled>
        </WithGap>
      )}
    </WithGapStyled>
  );
};

const ValueText = styled(TextShortBold)`
  /**
   * Make sure that both the fund and benchmark values have the same
   * width, so that the bars are properly vertically aligned.
   * This width should be good enough for up to four digits.
   */
  min-width: 52px;

  text-align: right;
  margin-bottom: 4px;
  align-self: flex-end;
`;

const WithGapStyled = styled(WithGap)`
  flex: 1;
  width: 100%;
`;

const BarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
`;

interface SingleBarProps {
  value: number;
  color: string;
}

const SingleBar = styled.div<SingleBarProps>`
  width: ${(props) => props.value}%;
  height: 32px;
  background-color: ${(props) => props.color};
  border: 1px solid var(--color-on-background);

  grid-column: 1;
  grid-row: 1;
`;

const WithGrayBackground = styled.div`
  width: 100%;
  height: 32px;
  background-color: var(--color-graph-00);
  border: 1px solid var(--color-on-background);

  grid-column: 1;
  grid-row: 1;
`;
