import Button from '@storeblocks/button';
import Checkbox, { Option } from '@storeblocks/checkbox';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Permission } from '@/api/permissions/models/Permission';
import {
  permissionsApi,
  usePermissionsQuery,
} from '@/api/permissions/permissionsApi';
import { AppDispatch } from '@/store';

import { Paragraph } from '../Typography';

interface Props {
  show: boolean;
  onClose: () => void;
}

export const RoleManager: FC<Props> = ({ show, onClose }: Props) => {
  const { data: permissions } = usePermissionsQuery();
  const dispatch = useDispatch<AppDispatch>();

  const updatePermissions = (permissions: Permission[]): void => {
    const updatePermissionsAction = permissionsApi.util.upsertQueryData(
      'permissions',
      undefined,
      permissions,
    );

    dispatch(updatePermissionsAction);
  };

  const options: Option[] = Object.values(Permission).map((permission) => ({
    id: permission,
    value: permission,
    label: permission,
  }));

  return (
    <Modal open={show} onClose={onClose} trigger={undefined}>
      <Heading>Role manager</Heading>
      <Content data-test="role-manager-modal">
        <Paragraph>
          Note: the components will not rerender automatically when changing the
          permissions. Navigate to a new page in the application to make the
          components rerender.
        </Paragraph>

        <Checkbox
          id="development-tools-role-manager"
          name="development-tools-role-manager"
          options={options}
          value={permissions ?? []}
          onChange={(_, value) => {
            updatePermissions(value as Permission[]);
          }}
        />
      </Content>
      <Footer>
        <Button onClick={onClose} key="close-button">
          Close
        </Button>
      </Footer>
    </Modal>
  );
};
