import { OrderStatus } from '@/api/orderList/models/Order';
import { FMSTexts } from '@/types/fms';

export const orderStatusToFmsText = (
  orderStatus: OrderStatus,
  texts: FMSTexts,
): string => {
  switch (orderStatus) {
    case OrderStatus.PendingSigning:
      return texts.pages.orderList.orderState.pendingSigning;

    case OrderStatus.PendingApproval:
      return texts.pages.orderList.orderState.pendingApproval;

    case OrderStatus.PendingCash:
      return texts.pages.orderList.orderState.pendingCash;

    case OrderStatus.PendingSettlement:
      return texts.pages.orderList.orderState.pendingSettlement;

    case OrderStatus.Settled:
      return texts.pages.orderList.orderState.settled;

    default:
      throw new Error(`Missing translation for order status [${orderStatus}].`);
  }
};
