import * as Yup from 'yup';

import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';

import { baseSubscriptionFundStateValidationSchema } from './baseSubscriptionFundStateValidationSchema';

interface ErrorMessages {
  maximumPercentMessage: string;
}

export const subscriptionInPercentFundStateValidationSchema = ({
  maximumPercentMessage,
}: ErrorMessages): Yup.SchemaOf<SubscriptionFundState> =>
  baseSubscriptionFundStateValidationSchema.concat(
    Yup.object({
      value: Yup.number()
        .test(function validateSubscriptionAmount(value) {
          // Don't show validation message when there isn't any amount.
          if (!value) {
            return true;
          }

          // Show validation message if the value is negative.
          if (value < 0) {
            return this.createError({
              message: 'Value cannot be negative',
            });
          }

          // Show validation message if the percentage exceeds 100%.
          if (value > 100) {
            return this.createError({
              message: maximumPercentMessage,
            });
          }
          return true;
        })
        .required(),
    }),
  );
