import { SumByCurrency } from '../models/SumByCurrency';

/**
 * Merge the given sum by currency maps to a summarized map by currency.
 *
 * @example:
 * {
 *   "NOK": 82736.40,
 *   "SEK": 988873.74
 * }
 */
export const mergeSumsByCurrency = (
  sums: Array<SumByCurrency>,
): SumByCurrency => {
  return sums.reduce((mergedSumByCurrency, sumByCurrency) => {
    Object.keys(sumByCurrency).forEach((currency) => {
      if (mergedSumByCurrency[currency]) {
        mergedSumByCurrency[currency] += sumByCurrency[currency];
      } else {
        mergedSumByCurrency[currency] = sumByCurrency[currency];
      }
    });

    return mergedSumByCurrency;
  }, {});
};
