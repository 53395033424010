import * as Yup from 'yup';

import { Organization } from '@/api/organizations/models/Organization';
import { FMSTexts } from '@/types/fms';

export interface AddOrganizationFormValues {
  organizationCmId: string;
}

export const getInitialValues = (): AddOrganizationFormValues => ({
  organizationCmId: '',
});

export const getValidationSchema = (
  texts: FMSTexts,
  organizations: Organization[],
): Yup.SchemaOf<AddOrganizationFormValues> =>
  Yup.object({
    organizationCmId: Yup.string()
      .min(4, texts.orgadmin.form.orgIdLength)
      .max(12, texts.orgadmin.form.orgIdLength)
      .test({
        message: texts.adminModal.addOrganization.duplicateOrg,
        test: (organizationCmId) =>
          !organizations.some(
            (organization) => organization.organizationId === organizationCmId,
          ),
      })
      .required(texts.form.emptyMessage),
  });
