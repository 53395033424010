import React, { FC } from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks';

interface Props {
  percentage: number;
}

/** Renders a styled localized text for displaying a percentage.
 * @param {number} percentage - The percentage to display.
 */
export const EsgPercentageText: FC<Props> = ({ percentage }) => {
  const locale = useUserLocale();

  return (
    <TextShortStyled>{`${percentage.toLocaleString(locale)}%`}</TextShortStyled>
  );
};

const TextShortStyled = styled(TextShort)`
  color: var(--color-blue-50);
  font-size: 32px;
  font-weight: 600;
  margin: 24px 0;
`;
