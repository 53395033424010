import { createListenerMiddleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { axiosQuery, isErrorResponse } from '@/api/axios/axiosQuery';
import { isDemoOrganization } from '@/demoMode/isDemoOrganization';
import { isIntraDomain } from '@/logging/isIntraDomain';
import { getSessionId } from '@/logging/sessionId';
import type { LogEvent } from '@/logging/types/LogEvent';

import { addLogEvent } from '../logSlice';
import { PostLogError } from '../types/PostLogError';

export const postLogEventToConnectListener = createListenerMiddleware();

/**
 * Post log events to the Connect backend.
 */
postLogEventToConnectListener.startListening({
  predicate: (action) =>
    addLogEvent.match(action) &&
    // Check the config to determine if the POST request should be done.
    window.config.postLogEvents &&
    // When trying to POST log events, internal users on the root page will
    // always get an HTTP 403 Forbidden error. It is due to internal users
    // missing the behalf_of value in the JWT token when they have yet to
    // select an organization. The backend authorization (Jettywar) requires
    // a behalf_of value and throws an HTTP 403 Forbidden error when it is missing.
    // Once the internal user has chosen an organization, it works
    // as expected (they then get behalf_of with the selected organization).
    // Since this scenario is known, we can safely omit posting to the log endpoint to avoid an HTTP 403 Forbidden error.
    !isIntraUserOnRootPage() &&
    // Do not post log events when in demo mode.
    // The log events is not useful in this scenario and will only clutter the log data.
    !isDemoOrganization(),

  effect: async (action) => {
    const url = `${window.config.connectBaseApiUrl}/api/log.json`;

    // We duplicate the condition from the predicate
    // to get the action to be properly typed.
    if (addLogEvent.match(action)) {
      // Do POST with the log event.
      const query = await axiosQuery.post(url, action.payload);

      if (isErrorResponse(query)) {
        // Log events may fail to POST to the Connect backend due to the
        // contents of the log event. The Azure application gateway blocks
        // requests that have contents it deems to be "dangerous". This can
        // be a script tag, an funny looking URL, or something else that we
        // don't know about.
        // Therefore we log the error to Sentry to notify us that there is
        // a problem with the log event.
        const eventId = Sentry.captureException(
          new PostLogError(
            {
              sessionId: getSessionId(),
              pageUrl: action.payload.url,
            },
            query.error,
          ),
        );

        // Create a more simple log event to POST to Connect.
        // Hopefully this will not fail. In any case we have the
        // Sentry error to investigate what went wrong.
        const logEvent: LogEvent = {
          type: 'http',
          level: 'error',
          description: 'Failed to POST log event to Connect',
          sessionId: getSessionId(),
          data: {
            // We add the Sentry event ID to the log event so that we can
            // find the Sentry error in the Sentry UI since it have more
            // info than this log event.
            sentryEventId: eventId,

            // Keeping it light to avoid it being blocked by the Azure application gateway.
          },
          timestamp: Date.now(),
          url: action.payload.url,
        };

        await axiosQuery.post(url, logEvent);
      }
    }
  },
});

function isIntraUserOnRootPage(): boolean {
  return isIntraDomain() && window.location.pathname === '/';
}
