import React from 'react';
import styled from 'styled-components';

import { InternalLink } from '@/components/InternalLink';
import { useFms } from '@/hooks/useFms';

import { ShowOnDesktop } from '../../Responsive/ShowOnDesktop';
import { ShowOnMobile } from '../../Responsive/ShowOnMobile';
import { StorebrandLogoChain } from './StorebrandLogoChain';
import { StorebrandLogoFull } from './StorebrandLogoFull';

interface Props {
  to: string;
}

export const StorebrandLogoWithMediaQuery: React.FC<Props> = ({ to }) => {
  const texts = useFms();

  return (
    <>
      <ShowOnDesktop>
        <StyledLink to={to} aria-label={texts.topmenu.logo.ariaLabel}>
          <StorebrandLogoFull />
        </StyledLink>
      </ShowOnDesktop>

      <ShowOnMobile>
        <StyledLink to={to} aria-label={texts.topmenu.logo.ariaLabel}>
          <StorebrandLogoChain />
        </StyledLink>
      </ShowOnMobile>
    </>
  );
};

const StyledLink = styled(InternalLink)`
  padding: 15px 0;
`;
