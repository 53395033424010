import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { FetchDocumentFilesParams } from '@/api/documentArchive/documentArchiveApi';
import { DocumentFile } from '@/api/documentArchive/models/DocumentFile';

interface DocumentsArchiveContextState {
  /** Array of checked document files by fileId */
  checkedDocumentFiles: Set<string>;
  uncheckAllDocumentFiles: () => void;
  checkDocumentFile: (fileId: string) => void;
  uncheckDocumentFile: (fileId: string) => void;
  showUploadFileModal: boolean;
  setShowUploadFileModal: (show: boolean) => void;
  documentFileToDelete: DocumentFile | null;
  setDocumentFileToDelete: (documentFile: DocumentFile | null) => void;
  documentFileToEdit: DocumentFile | null;
  setDocumentFileToEdit: (documentFile: DocumentFile | null) => void;
  documentFilesParams: FetchDocumentFilesParams;
  setDocumentFilesParams: (
    documentFilesParams: FetchDocumentFilesParams,
  ) => void;
}

const DocumentsArchiveContext =
  React.createContext<DocumentsArchiveContextState | null>(null);

export const DocumentsArchiveProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [checkedDocumentFiles, setCheckedDocumentFiles] = useState<Set<string>>(
    new Set<string>(),
  );

  const [showUploadFileModal, setShowUploadFileModal] =
    useState<boolean>(false);

  const [documentFileToDelete, setDocumentFileToDelete] =
    useState<DocumentFile | null>(null);

  const [documentFileToEdit, setDocumentFileToEdit] =
    useState<DocumentFile | null>(null);

  const uncheckAllDocumentFiles = (): void => {
    setCheckedDocumentFiles(new Set<string>());
  };

  const [documentFileArgs, setDocumentFileArgs] =
    useState<FetchDocumentFilesParams>({});

  const checkDocumentFile = useCallback(
    (fileId: string) => {
      checkedDocumentFiles.add(fileId);
      setCheckedDocumentFiles(new Set<string>(checkedDocumentFiles));
    },
    [checkedDocumentFiles],
  );

  const uncheckDocumentFile = useCallback(
    (fileId: string) => {
      checkedDocumentFiles.delete(fileId);
      setCheckedDocumentFiles(new Set<string>(checkedDocumentFiles));
    },
    [checkedDocumentFiles],
  );

  const contextData = useMemo<DocumentsArchiveContextState>(
    () => ({
      checkedDocumentFiles,
      uncheckAllDocumentFiles,
      checkDocumentFile,
      uncheckDocumentFile,
      showUploadFileModal,
      setShowUploadFileModal,
      documentFileToDelete,
      setDocumentFileToDelete,
      documentFileToEdit,
      setDocumentFileToEdit,
      documentFilesParams: documentFileArgs,
      setDocumentFilesParams: setDocumentFileArgs,
    }),
    [
      checkedDocumentFiles,
      uncheckAllDocumentFiles,
      checkDocumentFile,
      uncheckDocumentFile,
      showUploadFileModal,
      setShowUploadFileModal,
      documentFileToDelete,
      setDocumentFileToDelete,
      documentFileToEdit,
      setDocumentFileToEdit,
      documentFileArgs,
      setDocumentFileArgs,
    ],
  );

  return (
    <DocumentsArchiveContext.Provider value={contextData}>
      {children}
    </DocumentsArchiveContext.Provider>
  );
};

export const useDocumentsArchiveContext = (): DocumentsArchiveContextState => {
  const context = useContext(DocumentsArchiveContext);

  if (!context) {
    throw new Error(
      `${useDocumentsArchiveContext.name} must be called within a DocumentsArchiveProvider.`,
    );
  }

  return context;
};
