import Table from '@storeblocks/table';
import React, { useMemo } from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { HoldingsCalculationType } from '@/api/portfolioGroupHoldings/models/HoldingsCalculationType';
import { useMultiPortfolioGroupHoldingsQuery } from '@/api/portfolioGroupHoldings/multiPortfolioGroupHoldingsApi';
import { isNotReady } from '@/api/utils/isNotReady';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';

import { usePortfolioHoldingsColumns } from './hooks/usePortfolioHoldingsColumns';
import { HoldingsRow } from './types/HoldingsRow';
import { mapToPortfolioGroupHoldingsRow } from './utils/mapToPortfolioGroupHoldingsRow';
import { mapToPortfolioGroupHoldingsSummaryRow } from './utils/mapToPortfolioGroupHoldingsSummaryRow';

interface Props {
  portfolioGroupIds: Array<string>;
  calculationType: HoldingsCalculationType;
  expanded?: boolean;
  displayVerticalScrollbar?: boolean;
}

export const PortfolioHoldingsTable: FC<Props> = ({
  portfolioGroupIds,
  calculationType,
  expanded,
  displayVerticalScrollbar,
}) => {
  const texts = useFms();

  const locale = useUserLocale();

  const multiPortfolioGroupHoldingsQuery = useMultiPortfolioGroupHoldingsQuery({
    portfolioGroupIds,
    calculationType,
  });

  const columns = usePortfolioHoldingsColumns();

  const rows = useMemo<Array<HoldingsRow>>(() => {
    const { data } = multiPortfolioGroupHoldingsQuery;

    if (!data || data.holdings.length === 0) {
      return [];
    }

    const holdingsRows = data.holdings.map((portfolioGroupHoldings) =>
      mapToPortfolioGroupHoldingsRow(portfolioGroupHoldings, locale),
    );

    const summaryRow = mapToPortfolioGroupHoldingsSummaryRow(data, texts);

    holdingsRows.push(summaryRow);

    return holdingsRows;
  }, [locale, texts, multiPortfolioGroupHoldingsQuery.data]);

  return (
    <>
      {isNotReady(multiPortfolioGroupHoldingsQuery) ? (
        <QueryState query={multiPortfolioGroupHoldingsQuery} />
      ) : (
        <StyledTable
          columns={columns}
          data={rows}
          expanded={expanded}
          expandable
          displayVerticalScrollbar={displayVerticalScrollbar}
        />
      )}
    </>
  );
};

interface TableProps {
  displayVerticalScrollbar?: boolean;
}

const StyledTable = styled(Table)<TableProps>`
  /*
    Set max height to allow vertical scrolling of the table
    if it grows larger than the defined max height.
  */
  max-height: ${(props) => (props.displayVerticalScrollbar ? '800px' : 'none')};

  & table {
    border-collapse: separate;
    table-layout: auto;
    font-size: 16px;
    min-width: unset;
  }

  /*
    Disable wrapping.
  */
  th,
  td {
    white-space: nowrap;
  }

  /*
    Set cell padding to be a scalable unit for print resizing.
  */
  td {
    padding: 1em;
  }

  /*
    Right align all columns with amount (columns 4-9)
  */
  td:nth-child(n + 4):nth-child(-n + 9) {
    text-align: right;
  }

  /*
    Set the max width of the first column excluding the chevrons (96px)
  */
  th:first-child,
  td:first-child {
    max-width: 240px;
  }

  /*
    Set the max width of the second column
  */
  th:nth-child(2),
  td:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 96px;
  }

  /*
    Set the max width of the third column
  */
  th:nth-child(3),
  td:nth-child(3) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 128px;
  }

  /*
    Set all header columns sticky except the first column.
  */
  & th:not(:first-child) {
    position: sticky;
    top: 0;
  }

  /*
    Set the first header column sticky.
  */
  & th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    border-right: 1px solid var(--color-border);
    z-index: 1;
  }

  /*
    Make the first column sticky excluding the last row (summary).
  */
  & tr:not(:last-child) > td:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--color-background);
    border-right: 1px solid var(--color-border);
    position: sticky;
    left: 0;
  }

  /*
    Make the first column of the last row sticky.
  */
  & tr:last-child > td:first-child {
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: var(--color-background);
    border-top: 1px solid var(--color-border);
    border-right: 1px solid var(--color-border);
    z-index: 1;
  }

  /*
    Make the last row (summary) sticky excluding the first column.
  */
  & tr:last-child > td:not(:first-child) {
    position: sticky;
    bottom: 0;
    background-color: var(--color-background);
    border-top: 1px solid var(--color-border);
  }

  /*
    Make the last row bold.
  */
  & tr:last-child {
    font-weight: 600;
  }

  /*
    Hide the numbers on the parent row when the row is expanded
    to make it easier to view the data in the expanded rows.
  */
  & [aria-expanded='true'] {
    td:not(:first-of-type) {
      color: transparent;
    }
  }

  /*
    Scale down font size when printing to avoid horizontal scroll when printing.
  */
  @media print {
    table {
      font-size: 12px;
    }

    // Unset the max height to avoid vertical scroll when printing.
    max-height: unset;
  }
`;
