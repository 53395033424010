import sortBy from 'lodash/sortBy';

import { UserDto } from '../dtos/UserDto';
import { UsersReply } from '../dtos/UsersReply';
import { User } from '../models/User';

export const transformUsersReply = (dto: UsersReply): Array<User> => {
  return sortBy(
    dto.connectUserDtos.map(mapUserDtoToModel),
    (d) => d.familyName,
  );
};

const mapUserDtoToModel = (dto: UserDto): User => ({
  userId: dto.userId,
  givenName: dto.givenName,
  familyName: dto.familyName,
  isActiveUser: dto.isActiveUser,
  email: dto.email,
  allowedOrganizationsByName: dto.allowedOrganizations,
});
