import { startOfYear, subMonths, subYears } from 'date-fns';

import { FilterPeriod } from '../types/FilterPeriod';

interface FilterPeriodDateRange {
  start: Date;
  end: Date;
}

/**
 * Resolves a given filter period to a date range based on the provided `endDate`.
 * The period reflects the past period up until the `endDate`.
 * This is useful for fetching a snapshot of how a past period looked at any given date.
 * @param period
 * @param endDate
 * @returns The filter period date range.
 */
export const getDateRange = (
  period: FilterPeriod,
  endDate: Date,
): FilterPeriodDateRange => {
  switch (period) {
    case FilterPeriod.OneMonth: {
      return {
        start: subMonths(endDate, 1),
        end: endDate,
      };
    }
    case FilterPeriod.ThreeMonths: {
      return {
        start: subMonths(endDate, 3),
        end: endDate,
      };
    }
    case FilterPeriod.SixMonths: {
      return {
        start: subMonths(endDate, 6),
        end: endDate,
      };
    }
    case FilterPeriod.OneYear: {
      return {
        start: subYears(endDate, 1),
        end: endDate,
      };
    }
    case FilterPeriod.ThreeYears: {
      return {
        start: subYears(endDate, 3),
        end: endDate,
      };
    }
    case FilterPeriod.YTD: {
      return {
        start: startOfYear(endDate),
        end: endDate,
      };
    }
    default: {
      throw new Error(
        `Unable to get the date range for filter period [${period}]`,
      );
    }
  }
};
