import Select, { Option } from '@storeblocks/select';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  options: Option[];
  onChange: (query: string) => void;
  id?: string;
}

export const SelectSearch: React.FC<Props> = ({
  label,
  options,
  onChange,
  id = 'search-by-option',
}: Props) => {
  const [selectedValue, setSelectedValue] = useState('');

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <StyledSelect
      name="search"
      id={id}
      label={label}
      value={selectedValue}
      options={options}
      onChange={onSelectChange}
      fluid
    />
  );
};

const StyledSelect = styled(Select)`
  width: 252px;
`;
