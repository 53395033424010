import { UserType } from '@/config/types';
import { isInternalUser } from '@/config/utils';

import { getAuth } from '../Auth';
import { UserInfo } from '../types/UserInfo';

export const useCurrentUserInfo = (): UserInfo => {
  const userType = isInternalUser() ? UserType.Internal : UserType.External;
  const token = getAuth().getTokenParsed();

  return {
    userCmId: token.cmId,
    name: token.name,
    currentOrgCmId: token.behalfOf?.cmId,
    userType,
  };
};
