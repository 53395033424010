import Breadcrumbs from '@storeblocks/breadcrumbs';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { ContractNotesTableWithToolbar } from '@/components/ContractNotes';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

export const ContractNotesPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useFms();

  return (
    <CenteredPageContent data-test="reports-contract-notes">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/reports`}>
          {texts.menu.links.reports.reports}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/reports/contract-notes`}>
          {texts.menu.links.contractNotes}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.contractNotes} />

        <ContractNotesTableWithToolbar />
      </MainContent>
    </CenteredPageContent>
  );
};
