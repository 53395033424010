import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { SaveSigningRightsRequestDto } from '@/api/signing-right/dtos/SaveSigningRightsRequestDto';
import { useStoreSigningRightMutation } from '@/api/signing-right/signingRightApi';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { WithGap } from '@/components/WithGap';
import { isCypress } from '@/config/utils';
import { useFms } from '@/hooks/useFms';
import { nameof } from '@/util/nameof';

import { AddDualCitizenshipButton } from './components/AddDualCitizenshipButton';
import { CitizenshipRadio } from './components/CitizenshipRadio';
import { ConfirmAuthorizedToSign } from './components/ConfirmAuthorizedToSign';
import { DualCitizenship } from './components/DualCitizenship';
import { InfoBanner } from './components/InfoBanner';
import { Pep } from './components/Pep';
import { PepReason } from './components/PepReason';
import { PostErrorAlert } from './components/PostErrorAlert';
import { RemoveDualCitizenshipButton } from './components/RemoveDualCitizenshipButton';
import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';
import { SubmitButton } from './components/SubmitButton';
import { FormValues } from './types/FormValues';
import { PoliticallyExposedPerson } from './types/PoliticallyExposedPerson';
import { ifEmptyReturnNull } from './utils/ifEmptyReturnNull';
import { validationSchema } from './validation/validationSchema';

const initialFormValues: FormValues = {
  pep: PoliticallyExposedPerson.Unknown,
  pepReason: '',
  citizenship: '',
  dualCitizenship: '',
  confirmed: false,
};

export const SigningRightPage: React.FC = () => {
  const texts = useFms();
  const navigate = useNavigate();
  const [hasDualCitizenship, setHasDualCitizenship] = useState(false);

  const [postData] = useStoreSigningRightMutation();

  const handleOnSubmitted = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const isPep = values.pep === PoliticallyExposedPerson.Yes;

    const requestDto: SaveSigningRightsRequestDto = {
      pep: isPep,
      pepReason: isPep ? values.pepReason : null,
      citizenship: values.citizenship,
      dualCitizenship: ifEmptyReturnNull(values.dualCitizenship),
      confirmed: values.confirmed,
    };

    try {
      const payload = await postData(requestDto).unwrap();

      if (isCypress()) {
        // Don't redirect to signing when we are on Cypress tests due to
        // window.location.href redirects the Cypress Chrome instance and not
        // the page under test.
        navigate(payload.signingUrl);
        return;
      }

      // Redirect to Signicat for signing.
      window.location.href = payload.signingUrl;
    } catch {
      // The error is already handled and logged by the RTK.
      // Thus only need to display an error message to the user.
      helpers.setStatus({ postError: true });
    }
  };

  return (
    <>
      <SigningRightBreadcrumb />

      <MainContent data-test="signing-right-started">
        <InfoBanner />

        <Stepper activeStep={1} />

        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema(texts)}
          onSubmit={handleOnSubmitted}
        >
          {({ values, setFieldValue, isSubmitting, status }) => (
            <Form>
              <WithGap>
                <Row>
                  <Column $span={9}>
                    <ContactForm source={Source.SigningRight} />
                  </Column>
                </Row>
                <Pep />

                {values.pep === PoliticallyExposedPerson.Yes && <PepReason />}

                <CitizenshipRadio />

                {!hasDualCitizenship && (
                  <AddDualCitizenshipButton
                    onClick={() => setHasDualCitizenship(true)}
                  />
                )}

                {hasDualCitizenship && (
                  <>
                    <DualCitizenship />
                    <RemoveDualCitizenshipButton
                      onClick={() => {
                        setHasDualCitizenship(false);
                        setFieldValue(
                          nameof<FormValues>('dualCitizenship'),
                          '',
                        );
                      }}
                    />
                  </>
                )}

                <ConfirmAuthorizedToSign />

                {status?.postError && <PostErrorAlert />}

                <SubmitButton loading={isSubmitting} />
              </WithGap>
            </Form>
          )}
        </Formik>
      </MainContent>
    </>
  );
};
