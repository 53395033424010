import round from 'lodash/round';

import { AssetPerformanceData } from '@/api/performanceData/models';
import { Unit } from '@/types/Unit';
import { roundHalfEven } from '@/util/roundHalfEven';

import { AssetNode, AssetReturnPeriod } from '../types';

export const getAssetNodeData = (node: AssetPerformanceData): AssetNode => {
  return {
    name: node.name,
    data: {
      [Unit.Percent]: {
        [AssetReturnPeriod.MTD]: {
          return: round(node.monthToDatePercent, 2),
          benchmark: round(node.benchmarkMonthToDatePercent, 2),
          excessReturn: round(node.monthToDateExcessReturnPercent, 2),
        },
        [AssetReturnPeriod.QTD]: {
          return: round(node.quarterToDatePercent, 2),
          benchmark: round(node.benchmarkQuarterToDatePercent, 2),
          excessReturn: round(node.quarterToDateExcessReturnPercent, 2),
        },
        [AssetReturnPeriod.YTD]: {
          return: round(node.yearToDatePercent, 2),
          benchmark: round(node.benchmarkYearToDatePercent, 2),
          excessReturn: round(node.yearToDateExcessReturnPercent, 2),
        },
      },
      [Unit.Amount]: {
        [AssetReturnPeriod.MTD]: {
          return: roundHalfEven(node.monthToDateAmount),
          benchmark: roundHalfEven(node.benchmarkMonthToDateAmount),
          excessReturn: roundHalfEven(node.monthToDateExcessReturnAmount),
        },
        [AssetReturnPeriod.QTD]: {
          return: roundHalfEven(node.quarterToDateAmount),
          benchmark: roundHalfEven(node.benchmarkQuarterToDateAmount),
          excessReturn: roundHalfEven(node.quarterToDateExcessReturnAmount),
        },
        [AssetReturnPeriod.YTD]: {
          return: roundHalfEven(node.yearToDateAmount),
          benchmark: roundHalfEven(node.benchmarkYearToDateAmount),
          excessReturn: roundHalfEven(node.yearToDateExcessReturnAmount),
        },
      },
    },
  };
};
