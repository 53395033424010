import React from 'react';
import styled from 'styled-components';

import { TableRowHeader } from './TableRowHeader';

interface Props {
  title: string;
}

export const MainHeaderRow: React.FC<Props> = ({ title }) => {
  return (
    <tr>
      <MainHeader colSpan={3}>{title}</MainHeader>
    </tr>
  );
};

const MainHeader = styled(TableRowHeader)`
  font-size: 18px;
  font-weight: bold;
`;
