import styled, { css } from 'styled-components';

export const h4Style = css`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-on-background);
`;

export const H4 = styled.h4`
  ${h4Style}
`;
