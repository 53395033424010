export function hasData(data: unknown): boolean {
  if (!data) {
    // There is no data.
    return false;
  }

  if (Array.isArray(data)) {
    // Data is an array, check if it has any entries.
    return data.length !== 0;
  }

  // There is data.
  return !!data;
}
