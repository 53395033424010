import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderPlacementType } from '@/api/order/models/OrderPlacementType';

import { FundState } from '../types/FundState';
import { SubscriptionPayment } from '../types/Payment';

export const mapSubscriptionFundStatesToOrderPlacements = (
  funds: Array<FundState>,
  payments: Array<SubscriptionPayment>,
): Array<OrderPlacement> => {
  return funds
    .filter((fund) => fund.value > 0)
    .map((fund) => {
      // When in the context of switch, there will be no payments.
      const payment = payments.find((payment) =>
        payment.forIsins.includes(fund.isin),
      );

      return {
        accountId: fund.accountId,
        isin: fund.isin,
        fundName: fund.name,
        currency: fund.currency,
        brand: fund.brand,

        payment: payment?.paymentPlacement,

        // The external account number should only be filled when the user has selected
        // to pay with a portfolio bank account. The external account is then the bank
        // account number of the portfolio account.
        externalAccountNumber:
          payment?.paymentPlacement === OrderPaymentType.Portfolio
            ? payment.portfolioBankAccountNumber
            : undefined,

        placementType: OrderPlacementType.Buy,

        // When the user has selected to sell all their holdings then the
        // sell value must be in units.
        value: fund.value,
        valueType: fund.valueType,
      };
    });
};
