import { SystemIcon } from '@storeblocks/icon';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  direction: 'up' | 'down';
}

export const YieldIcon: FC<Props> = ({ direction }) => {
  return direction === 'up' ? (
    <StyledSystemIcon name="ascend" />
  ) : (
    <StyledSystemIcon name="descend" />
  );
};

const StyledSystemIcon = styled(SystemIcon)`
  font-size: 24px;
  line-height: 24px;
`;
