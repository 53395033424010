import { useMemo } from 'react';
import * as Yup from 'yup';

import { useCurrentUserInfo } from '@/auth/hooks';
import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';

import { SwitchOrderFormValues } from '../../types/SwitchOrderFormValues';
import { createSwitchValidationSchema } from '../createSwitchValidationSchema';

export const useCreateSwitchValidationSchema =
  (): Yup.SchemaOf<SwitchOrderFormValues> => {
    const texts = useFms();
    const errorTexts = texts.orders.form.errors;

    const locale = useUserLocale();

    const { userType } = useCurrentUserInfo();

    return useMemo(
      () =>
        createSwitchValidationSchema({
          locale,
          userType,
          errorMessages: {
            portfolioRequired: errorTexts.portfolioRequired,
            redemptionMustHaveAtLeastOneFund:
              errorTexts.redemption.atLeastOneFundRequired,
            subscriptionMustHaveAtLeastOneFund:
              errorTexts.subscription.atLeastOneFundRequired,
            holdingsNotAbove95percent:
              errorTexts.valueIsOver95percentOfHoldings,
            minimumRedemptionAmount: errorTexts.redemption.minimumAmount,
            minimumSubscriptionAmount: errorTexts.subscription.minimumAmount,
            signatoryOptionRequired: errorTexts.signatoryOptionRequired,
            signerRequired: errorTexts.signerRequired,
            cosignerRequired: errorTexts.cosignerRequired,
            signingProviderRequired: errorTexts.signingProviderRequired,
            redemptionAndSubscriptionAmountIsNotEqual:
              errorTexts.switch.redemptionAndSubscriptionAmountIsNotEqual,
            maximumPercent: errorTexts.maximumPercent,
            redemptionMaximumUnits: errorTexts.redemption.maximumUnits,
            toPercentDoesNotAddUpTo100:
              errorTexts.switch.toPercentDoesNotAddUpTo100,
            fromToSameFund: errorTexts.switch.fromToSameFund,
          },
        }),
      [errorTexts, locale],
    );
  };
