import styled from 'styled-components';

import StorebrandChain from '@/assets/logo/storebrand-only-chain.svg';

export const StorebrandLogoChain = styled(StorebrandChain)`
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;
