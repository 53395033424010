import Button from '@storeblocks/button';
import Surface from '@storeblocks/surface';
import React from 'react';
import { generatePath } from 'react-router';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { H1, Paragraph, textShortBoldStyle } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { CenteredPageContent } from './components/CenteredPageContent';
import { PortfolioGroupCheckboxes } from './PortfolioGroupCheckboxes';
import { usePresentationContext } from './PresentationContext';
import { ScreenCheckboxes } from './ScreenCheckboxes';

export const PresentationMenuPage: React.FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();

  const { selectedScreens, globalFilter } = usePresentationContext();

  const firstScreenPath =
    selectedScreens[0] &&
    generatePath(selectedScreens[0].path, { organizationCmId });

  const noPortfoliosSelected = globalFilter.portfolioGroups.length === 0;

  return (
    <CenteredPageContent data-test="presentation-menu">
      <MainContent>
        <div>
          <H1>{texts.pages.presentation.title}</H1>
          <Paragraph>
            {texts.pages.presentation.setup.selectScreens.description}
          </Paragraph>
        </div>
        <Row>
          <Column $span={3}>
            <ScreenCheckboxes />
          </Column>

          <Column $span={3}>
            <PortfolioGroupCheckboxes />
          </Column>

          <Column $span={1}>
            {/* Empty to have space between the link list and the tips column. */}
          </Column>

          <Column $span={5}>
            <div>
              <StyledSurface>
                <Title>{texts.pages.presentation.setup.info.title}</Title>
                <div>{texts.pages.presentation.setup.info.description}</div>
              </StyledSurface>
              <StyledSurface>
                <Title>{texts.pages.presentation.setup.tip.title}</Title>
                <div>
                  {texts.pages.presentation.setup.tip.description1} <Key>N</Key>{' '}
                  {texts.pages.presentation.setup.tip.description2} <Key>M</Key>{' '}
                  {texts.pages.presentation.setup.tip.description3}
                </div>
              </StyledSurface>
            </div>
          </Column>
        </Row>
        <ButtonContainer>
          <Button
            as={InternalLink}
            to={`/${organizationCmId}`}
            iconLeft="arrow-left"
            variant="text"
          >
            {texts.pages.presentation.backToConnect}
          </Button>
          <WithGap direction="row">
            <Button
              as={InternalLink}
              to={firstScreenPath}
              disabled={!firstScreenPath || noPortfoliosSelected}
              data-test="start-presentation-button"
            >
              {texts.pages.presentation.startPresentation}
            </Button>
            <Button
              as={InternalLink}
              to={`/${organizationCmId}/reports/presentation/print`}
              variant="text"
              iconRight="arrow-right"
              disabled={selectedScreens.length === 0 || noPortfoliosSelected}
              data-test="go-to-print-button"
            >
              {texts.pages.presentation.createReport}
            </Button>
          </WithGap>
        </ButtonContainer>
      </MainContent>
    </CenteredPageContent>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledSurface = styled(Surface)`
  line-height: 24px;
  padding: 24px;
  height: 100%;
  margin-bottom: 24px;
`;

const Title = styled.div`
  ${textShortBoldStyle}
  margin-bottom: 8px;
`;

const Key = styled.kbd`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 2px;
  box-shadow: var(--elevation-overlay);
  background-color: white;
  line-height: normal;
`;
