import { Step } from '@storeblocks/stepper';
import React, { FC } from 'react';

import { Permission } from '@/api/permissions/models/Permission';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useFms } from '@/hooks/useFms';

import { useHasCosigners } from '../hooks/useHasCosigners';
import { useIsCurrentUserOwner } from '../hooks/useIsCurrentUserOwner';
import { Completed } from '../steps/Completed';
import { Confirm } from '../steps/Confirm';
import { OrderDetails } from '../steps/OrderDetails';
import { Signing } from '../steps/Signing';
import { OrderStepsProvider } from './OrderStepsProvider';

export const OrderSteps: FC = () => {
  const texts = useFms();

  const hasPermission = useHasPermission();
  const isOwner = useIsCurrentUserOwner();
  const hasCosigners = useHasCosigners();

  return (
    <OrderStepsProvider>
      {/* Only show the order details page if the current user is the owner. */}
      {isOwner && (
        <Step
          title={texts.orders.form.steps.orderDetails.stepperTitle}
          legendProps={{
            'data-test': 'order-step-details',
          }}
        >
          <OrderDetails />
        </Step>
      )}

      <Step
        title={texts.orders.form.steps.confirm.stepperTitle}
        legendProps={{
          'data-test': 'order-step-confirm',
        }}
      >
        <Confirm />
      </Step>

      {/* Only show the signing step if the user has orders sign permission. */}
      {hasPermission(Permission.OrdersSign) && (
        <Step
          title={texts.orders.form.steps.signing.stepperTitle}
          legendProps={{
            'data-test': 'order-step-signing',
          }}
        >
          <Signing />
        </Step>
      )}

      <Step
        title={texts.orders.form.steps.finished.stepperTitle}
        legendProps={{
          'data-test': 'order-step-completed',
        }}
      >
        <Completed isOwner={isOwner} hasCosigners={hasCosigners} />
      </Step>
    </OrderStepsProvider>
  );
};
