import Button from '@storeblocks/button';
import { Icon } from '@storeblocks/icon';
import Surface from '@storeblocks/surface';
import React from 'react';
import styled from 'styled-components';

import { Hint, TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

/**
 * Temporary information box about the new cut-off time.
 *
 * "Fra 21. mai 2024 innfører vi nye frister for å imøtekomme kortere oppgjørsperiode
 * i amerikanske verdipapirer: For handel i NO-ISIN verdipapirfond som
 * Storebrand Asset Management AS er forvaltningsselskap for vil
 * fristen (cut off) endres fra kl. 15.00 til kl. 13.00."
 */
export const NewCutOffTimeInformation: React.FC = () => {
  const texts = useFms();

  const getTrackId = useGetTrackId();

  // Hide the component if the texts are missing.
  if (!texts.info.newcutofftime.title) {
    return null;
  }

  return (
    <StyledSurface variant="border" data-test="cutoff-information-alert">
      <IconContainer>
        <Icon name="delay" width="96px" height="96px" />
      </IconContainer>

      <Container>
        <WithGap gap="16">
          <TextShortBold>{texts.info.newcutofftime.title}</TextShortBold>
          <Hint>{texts.info.newcutofftime.description}</Hint>

          <ButtonContainer>
            <Button
              as={'a'}
              href={texts.info.newcutofftime.link.url}
              target="_blank"
              variant="text"
              iconRight="arrow-right"
              data-test="announcement-info-link"
              data-trackid={getTrackId(
                'announcement-info',
                TrackingElement.Link,
              )}
              data-trackid-source="announcement-info"
            >
              {texts.info.newcutofftime.link.title}
            </Button>
          </ButtonContainer>
        </WithGap>
      </Container>
    </StyledSurface>
  );
};

const StyledSurface = styled(Surface)`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 16px;
`;

const Container = styled.div`
  padding: 16px;
`;

const ButtonContainer = styled.div`
  /* Align with the text above the button. */
  margin-left: -24px;
`;
