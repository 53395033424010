import { Icon } from '@storeblocks/icon';
import React, { FC } from 'react';
import styled from 'styled-components';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { Grid } from '@/components/Grid';
import { TextShort } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

import { EsgCoverageText } from '../../components/EsgCoverageText';
import { EsgInsufficientDataAlert } from '../../components/EsgInsufficientDataAlert';
import { EsgValueText } from '../../components/EsgValueText';

interface Props {
  data: EsgCategory | undefined;
}

export const EsgCarbonFootprintTonnage: FC<Props> = ({ data }) => {
  const texts = useFms();
  const { emissions, tonnage } = texts.pages.esg.carbonFootprint;

  if (!data) {
    return <EsgInsufficientDataAlert />;
  }

  return (
    <GridStyled>
      <OverviewContainer>
        <TextShort>{emissions}</TextShort>
        <EsgValueText value={data.kpi.value} unit={tonnage} />
        <EsgCoverageText percentage={data.kpi.coverageInPercent} />
      </OverviewContainer>
      <IconContainer>
        <IconStyled name="industry" />
      </IconContainer>
    </GridStyled>
  );
};

const GridStyled = styled(Grid)`
  flex: 1;
`;

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;

const IconStyled = styled(Icon)`
  & > svg {
    width: 128px;
    height: 128px;
  }
`;
