import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { axiosBaseQuery, RtkBaseQueryResponse } from '../axios/axiosBaseQuery';
import { RequestConfig } from '../axios/axiosRequest';
import { AssetPerformanceDto, PortfolioPerformanceDto } from './dtos';
import { AssetPerformance, PortfolioPerformance } from './models';
import {
  transformAssetPerformanceResponse,
  transformPortfolioPerformanceResponse,
} from './utils';

interface PortfolioPerformanceArguments {
  portfolioGroup: string;
  from: string;
  to: string;
}

interface AssetPerformanceArguments {
  portfolioGroup: string;
  to: string;
}

export const formatDateArgument = (date: Date): string =>
  format(date, 'yyyy-MM-dd');

export const performanceDataApi = createApi({
  reducerPath: 'performanceDataApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fetchPortfolioPerformance: builder.query<
      PortfolioPerformance | null,
      PortfolioPerformanceArguments
    >({
      async queryFn(
        arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ: (
          args: RequestConfig,
        ) => Promise<RtkBaseQueryResponse<Array<PortfolioPerformanceDto>>>,
      ) {
        const response = await fetchWithBQ({
          url: `${window.config.connectBaseApiUrl}/api/performancedata/portfolio/search/${arg.portfolioGroup}/${arg.from}/${arg.to}`,
        });

        if (response?.error) {
          return {
            error: response.error,
          };
        }

        return {
          data: isEmpty(response.data)
            ? null
            : transformPortfolioPerformanceResponse(response.data),
        };
      },
    }),
    fetchAssetPerformance: builder.query<
      AssetPerformance | null,
      AssetPerformanceArguments
    >({
      async queryFn(
        arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ: (
          args: RequestConfig,
        ) => Promise<RtkBaseQueryResponse<AssetPerformanceDto>>,
      ) {
        const response = await fetchWithBQ({
          url: `${window.config.connectBaseApiUrl}/api/performancedata/asset/search/${arg.portfolioGroup}/${arg.to}`,
        });

        if (response.error) {
          return {
            error: response.error,
          };
        }

        return {
          data: isEmpty(response.data)
            ? null
            : transformAssetPerformanceResponse(response.data),
        };
      },
    }),
  }),
});

export const {
  useFetchAssetPerformanceQuery,
  useFetchPortfolioPerformanceQuery,
} = performanceDataApi;
