import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Pending } from '@/components/Pending';

import { useExistingOrder } from './hooks/useExistingOrder';

/**
 * The page the user is redirected to when they cancel the signing of an order.
 * This component will redirect to the correct trading page based on the order type.
 */
export const SigningCancelledPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const { existingOrder } = useExistingOrder();
  const navigate = useNavigate();

  useEffect(() => {
    if (!existingOrder) {
      return;
    }

    switch (existingOrder.orderType) {
      case OrderType.Subscription:
        navigate(
          `/${organizationCmId}/trading/subscription?orderId=${existingOrder.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Redemption:
        navigate(
          `/${organizationCmId}/trading/redemption?orderId=${existingOrder.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Switch:
        navigate(
          `/${organizationCmId}/trading/switch?orderId=${existingOrder.id}`,
          {
            replace: true,
          },
        );
        break;

      default:
        throw new Error(
          'Unknown order type received when returning from cancelling signing order.',
        );
    }
  }, [existingOrder]);

  return <Pending center />;
};
