import Button from '@storeblocks/button';
import cookies from 'js-cookie';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Show } from '@/components/Show';
import { UserType } from '@/config/types';
import { isInternalUser, isLocalhost } from '@/config/utils';
import { setUserTypeToCookie } from '@/config/utils/setUserTypeToCookie';
import { shouldFetchConfigFromBackend } from '@/config/utils/shouldFetchConfigFromBackend';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';
import { removeBetaFeaturesCookie } from '@/util/removeBetaFeaturesCookie';
import { setBetaFeaturesCookieOn } from '@/util/setBetaFeaturesCookieOn';

import { InternalLink } from '../InternalLink';
import { H3 } from '../Typography';
import { RoleManager } from './RoleManager';

export const DevelopmentTools: FC = () => {
  const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);

  const organizationCmId = useOrganizationCmId();

  const toggleDevMode = (): void => {
    if (isBetaFeaturesOn()) {
      removeBetaFeaturesCookie();
    } else {
      setBetaFeaturesCookieOn();
    }

    document.location.reload();
  };

  const toggleUserType = (): void => {
    setUserTypeToCookie(
      isInternalUser() ? UserType.External : UserType.Internal,
    );

    document.location.reload();
  };

  const toggleConfigSource = (): void => {
    if (shouldFetchConfigFromBackend()) {
      cookies.remove('development-fetch-config-from-backend');
    } else {
      cookies.set('development-fetch-config-from-backend', 'true');
    }

    document.location.reload();
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Wrapper data-test="developer-tools">
        <Header>
          <H3>Developer Tools</H3>

          <Button
            variant="icon"
            iconLeft="close"
            onClick={() => setShow(false)}
          />
        </Header>

        <Button onClick={toggleDevMode} variant="outlined">
          Show beta content: {isBetaFeaturesOn() ? 'ON' : 'OFF'}
        </Button>

        <Show when={isLocalhost()}>
          <Button
            // Cannot toggle user type when config is fetched from backend.
            disabled={shouldFetchConfigFromBackend()}
            title={
              shouldFetchConfigFromBackend()
                ? 'Cannot toggle user type when config is fetched from backend.'
                : undefined
            }
            onClick={toggleUserType}
            variant="outlined"
          >
            {`User type: ${isInternalUser() ? 'INTERNAL' : 'EXTERNAL'}`}
          </Button>
        </Show>

        <Show when={isLocalhost()}>
          <Button onClick={toggleConfigSource} variant="outlined">
            {`Config source: ${
              shouldFetchConfigFromBackend() ? 'BACKEND' : 'LOCALHOST'
            }`}
          </Button>
        </Show>

        {organizationCmId && (
          <>
            <Button
              onClick={() => setShowRoleModal(true)}
              variant="outlined"
              data-test="role-manager-button"
            >
              Role manager
            </Button>
            <Button
              as={InternalLink}
              to={`${organizationCmId}/debug`}
              iconRight="arrow-right"
              variant="text"
            >
              Debug page
            </Button>
          </>
        )}
      </Wrapper>

      {organizationCmId && (
        <RoleManager
          show={showRoleModal}
          onClose={() => setShowRoleModal(false)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  text-align: center;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  padding: 20px;
  background: #fee6db;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
