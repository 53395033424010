import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { OrganizationReply } from './dtos/OrganizationReply';
import { Organization } from './models/Organization';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    organization: builder.query<Organization, { organizationCmId: string }>({
      query: ({ organizationCmId }) => ({
        url: `${window.config.connectBaseApiUrl}/api/admin/${organizationCmId}`,
      }),
      transformResponse: (dto: OrganizationReply) => {
        return {
          organizationName: dto.organizationName,
        };
      },
    }),
  }),
});

export const { useLazyOrganizationQuery } = adminApi;
