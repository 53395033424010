import { Option } from '@storeblocks/select';
import { format, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { DocumentCategory } from '@/api/documentArchive/models/DocumentCategory';
import { DocumentFile } from '@/api/documentArchive/models/DocumentFile';
import { FileOperationsColumn } from '@/pages/Archives/DocumentsArchive/FileOperationsColumn';
import { NameColumn } from '@/pages/Archives/DocumentsArchive/NameColumn';
import { FMSTexts } from '@/types/fms';

import { getDocumentCategoryTextFromEnum } from './getDocumentCategoryTextFromEnum';
import { PreparedDocumentFile } from './PreparedDocumentFile';

export function prepareDocumentsForDisplay(
  documents: DocumentFile[],
  texts: FMSTexts,
  dateFormat: string,
): PreparedDocumentFile[] {
  return documents.map((document) => {
    const dateInCorrectFormat =
      document.reportDate && format(parseISO(document.reportDate), dateFormat);

    const categoryText = document.category
      ? getDocumentCategoryTextFromEnum(document.category, texts)
      : '';

    return {
      reportDate: {
        sortContent: `${dateInCorrectFormat} ${document.name}`,
        displayContent: <Center>{dateInCorrectFormat}</Center>,
      },
      name: {
        sortContent: `${document.name}-${document.id}`,
        displayContent: <NameColumn document={document} />,
      },
      category: categoryText,
      toolbar: {
        sortContent: `toolbar-${document.id}`,
        displayContent: <FileOperationsColumn document={document} />,
      },
    };
  });
}

interface ConvertDocumentCategoriesToOptionsConfig {
  includeAllCategoriesCategory?: boolean;
}

export function convertDocumentCategoriesToOptions(
  categories: Array<DocumentCategory>,
  texts: FMSTexts,
  config: ConvertDocumentCategoriesToOptionsConfig = {},
): Option[] {
  if (!categories) {
    return [];
  }

  const convertedCategories: Option[] = categories.map((category) => {
    return {
      value: category,
      label: getDocumentCategoryTextFromEnum(category, texts),
      key: category,
    };
  });

  convertedCategories.sort((category1, category2) =>
    category1.label.localeCompare(category2.label),
  );

  if (config.includeAllCategoriesCategory) {
    convertedCategories.unshift({
      label: texts.categories.allCategories,
      value: 'ALL_CATEGORIES',
      key: 'ALL_CATEGORIES',
    });
  }

  return convertedCategories;
}

const Center = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
