import { MediumCard } from '@storeblocks/card';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const TradingLinks: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <Row>
      <Column $span={3}>
        <MediumCard
          as={InternalLink}
          to={`/${organizationCmId}/trading/subscription`}
          icon="shopping-cart"
          title={texts.dashboard.tradingLinks.buyFund}
          data-trackid={getTrackId('go-to-subscription', TrackingElement.Link)}
          data-test="subscription-order-link"
        />
      </Column>
      <Column $span={3}>
        <MediumCard
          as={InternalLink}
          to={`/${organizationCmId}/trading/redemption`}
          icon="pig"
          title={texts.dashboard.tradingLinks.sellFund}
          data-trackid={getTrackId('go-to-redemption', TrackingElement.Link)}
          data-test="redemption-order-link"
        />
      </Column>
      <Column $span={3}>
        <MediumCard
          as={InternalLink}
          to={`/${organizationCmId}/trading/switch`}
          icon="arrows"
          title={texts.dashboard.tradingLinks.switchFund}
          data-trackid={getTrackId('go-to-switch', TrackingElement.Link)}
          data-test="switch-order-link"
        />
      </Column>
      <Column $span={3}>
        <MediumCard
          as={InternalLink}
          to={`/${organizationCmId}/trading/orders`}
          icon="application-approved"
          title={texts.dashboard.tradingLinks.pendingOrders}
          data-trackid={getTrackId('go-to-pending-order', TrackingElement.Link)}
          data-test="pending-order-link"
        />
      </Column>
    </Row>
  );
};
