import { Option } from '@storeblocks/checkbox';

import { PortfolioGroup } from '@/api/portfolios';

export const selectAllOptionValue = 'select-all-portfolio-groups';

export const usePortfolioGroupOptions = (
  portfolioGroups: Array<PortfolioGroup>,
): Array<Option> => {
  return portfolioGroups.map((portfolioGroup) => ({
    id: portfolioGroup.id,
    value: portfolioGroup.id,
    label: `${portfolioGroup.id} - ${portfolioGroup.name}`,
  }));
};
