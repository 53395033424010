import { Query } from '../types/Query';
import { hasData } from './hasData';
import { toArray } from './toArray';

interface Options {
  skipNoData?: boolean;
  skipError?: boolean;
}

export function isNotReady(query: Query | Query[], options?: Options): boolean {
  const queries: Query[] = toArray(query);

  const isError = queries.some((response) => response.isError);
  if (!options?.skipError && isError) {
    return true;
  }

  if (options?.skipError && isError) {
    // We are in an error state, nothing more to do.
    return false;
  }

  if (queries.some((request) => request.isLoading || request.isFetching)) {
    return true;
  }

  if (
    !options?.skipNoData &&
    queries.some((response) => !hasData(response.data))
  ) {
    return true;
  }

  return false;
}
