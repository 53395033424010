import { HoldingsCalculationType } from '@/api/portfolioGroupHoldings/models/HoldingsCalculationType';
import { FMSTexts } from '@/types/fms';

export const getHoldingsCalculationText = (
  type: HoldingsCalculationType,
  texts: FMSTexts,
): string => {
  switch (type) {
    case HoldingsCalculationType.EOD:
      return texts.pages.presentation.filters.holdingsCalculationType
        .filterTexts.eod;
    case HoldingsCalculationType.EOM:
      return texts.pages.presentation.filters.holdingsCalculationType
        .filterTexts.eom;
    default:
      throw new Error(
        `Unable to find filter text for holdings calculation type [${type}]`,
      );
  }
};
