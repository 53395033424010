import Checkboxes, { Option } from '@storeblocks/checkbox';
import { useField } from 'formik';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useFms } from '@/hooks/useFms';

interface Props {
  orderType: OrderType;
}

export const ConfirmInformationIsCorrectCheckbox: React.FC<Props> = ({
  orderType,
}) => {
  const { orders } = useFms();
  const [field, meta] = useField<boolean>('confirmInformationIsCorrect');

  const title = {
    [OrderType.Subscription]:
      orders.form.information.subscription.confirm.title,
    [OrderType.Redemption]: orders.form.information.redemption.confirm.title,
    [OrderType.Switch]: orders.form.information.switch.confirm.title,
  };

  const message = {
    [OrderType.Subscription]:
      orders.form.information.subscription.confirm.message,
    [OrderType.Redemption]: orders.form.information.redemption.confirm.message,
    [OrderType.Switch]: orders.form.information.switch.confirm.message,
  };

  const options: Option[] = [
    {
      label: title[orderType],
      hint: message[orderType],
      value: 'confirm',
      id: 'confirm',
    },
  ];

  return (
    <Checkboxes
      id="confirmInformationIsCorrect"
      options={options}
      error={meta.error}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
    />
  );
};
