import { Permission } from '@/api/permissions/models/Permission';
import { HasPermission } from '@/components/Permissions/useHasPermission';

import { SignatoryOption } from '../types/SignatoryOption';

/* Get the signatory option from a list of existing signatories. */
export const getSignatoryOptionFromSignatories = (
  signatories: Array<string>,
  currentUserCmId: string,
  hasPermission: HasPermission,
): SignatoryOption => {
  // Set the default signatory option to Self if the user has sign permission
  // and the user is the only signatory.
  if (
    hasPermission(Permission.OrdersSign) &&
    signatories.length === 1 &&
    signatories.includes(currentUserCmId)
  ) {
    return SignatoryOption.Self;
  }

  // Set the default signatory option to Cosigner if the user has sign permission
  // and there are more than one signatory and the user is one of the signatories.
  if (
    hasPermission(Permission.OrdersSign) &&
    signatories.length > 1 &&
    signatories.includes(currentUserCmId)
  ) {
    return SignatoryOption.Cosigner;
  }

  // Set the default signatory option to other if there are any existing signatories
  // or if the current user does not have sign permission.
  if (!hasPermission(Permission.OrdersSign) || signatories.length > 0) {
    return SignatoryOption.Other;
  }

  // Default to unknown to allow the user to select the signatory option.
  return SignatoryOption.Unknown;
};
