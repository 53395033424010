import styled from 'styled-components';

/**
 * @deprecated Use `WithGap` instead.
 */
export const WithRowGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
