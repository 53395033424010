import Bars from '@storeblocks/bars';
import Button from '@storeblocks/button';
import React from 'react';
import { styled } from 'styled-components';

import { Esg } from '@/api/esg/models/Esg';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { H2, H3 } from '@/components/Typography';
import { SurfaceWidget } from '@/components/Widget';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { EsgFundList } from '@/pages/Esg/widgets/EsgFundList/EsgFundList';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { CurrencyCode } from '@/types/CurrencyCode';

interface Props {
  esgData: Esg;
  portfolioGroupId?: string;
}

export const EsgOverview: React.FC<Props> = ({ esgData, portfolioGroupId }) => {
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();
  const texts = useFms();

  const carbonFootprintData = esgData.carbonIntensities.find(
    (score) => score.currency === CurrencyCode.NOK,
  );

  return (
    <WithGap>
      <H2>{texts.dashboard.widgets.esgOverview.title}</H2>
      <Row data-test="esg-overview-widget">
        <Column $span={6}>
          <SurfaceWidget variant="border-alt">
            <WithGap>
              <WithGap>
                <H3>{texts.pages.esg.overview.esgScore}</H3>
                <Bars
                  value={esgData.esgScore.kpi.value}
                  variant="sustainability"
                />
                {`${esgData.esgScore.kpi.value}/10`}
              </WithGap>
              <WithGap>
                <H3>{texts.pages.esg.overview.carbonFootprint}</H3>
                {`${carbonFootprintData?.kpi.value} ${texts.pages.esg.carbonFootprint.tonnage}`}
              </WithGap>
              <WithGap>
                <H3>{texts.pages.esg.overview.sustainableInvestments}</H3>
                {esgData.solutionCompanies.kpi.value}%
              </WithGap>
            </WithGap>
            <AlignBottom>
              <Button
                as={InternalLink}
                variant="text"
                iconRight="arrow-right"
                to={`/${organizationCmId}/sustainability/esg${
                  portfolioGroupId ? `/${portfolioGroupId}` : ''
                }`}
                data-trackid={getTrackId('go-to-fund', TrackingElement.Link)}
                data-trackid-source="dashboard-page"
              >
                {texts.pages.esg.overview.goToEsgDashboard}
              </Button>
            </AlignBottom>
          </SurfaceWidget>
        </Column>

        <Column $span={6}>
          <EsgFundList portfolioGroupId={esgData.portfolioGroupId} />
        </Column>
      </Row>
    </WithGap>
  );
};

const AlignBottom = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;
