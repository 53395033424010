import React, { FC } from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';

import { RedemptionOrderForm } from './RedemptionOrderForm';
import { SubscriptionOrderForm } from './SubscriptionOrderForm';
import { SwitchOrderForm } from './SwitchOrderForm';

export const OrderForm: FC = () => {
  const { orderType } = useOrderPageContext();

  switch (orderType) {
    case OrderType.Subscription:
      return <SubscriptionOrderForm />;

    case OrderType.Redemption:
      return <RedemptionOrderForm />;

    case OrderType.Switch:
      return <SwitchOrderForm />;

    default:
      throw new Error(`Could not find form for order type [${orderType}].`);
  }
};
