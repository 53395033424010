import styled from 'styled-components';

export const LinkButton = styled.a`
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;

  display: inline-block;
  align-items: center;
  s & * {
    margin: 0;
  }
`;
