import InlineAlert from '@storeblocks/inline-alert';
import { LoadingIndicator } from '@storeblocks/loading-indicator';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useOrganizationUsersQuery } from '@/api/organizationUsers/organizationUsersApi';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { padding } from '@/styles/variables';

import { AddOrganizationUsersModal } from './AddAndEditUserModal/AddOrganizationUsers';
import { OrganizationActiveUsersTable } from './OrganizationActiveUsersTable';
import { OrganizationInvitedUsersTable } from './OrganizationInvitedUsersTable';
import { Toolbar } from './Toolbar';
import { useUserSearch } from './useUserSearch';

export const OrganizationUserAdmin: FC = () => {
  const texts = useFms();

  const [searchValue, setSearchValue] = useState<string | undefined>();

  const organizationUsersQuery = useOrganizationUsersQuery();

  const { activeUsers, inactiveUsers } = useUserSearch(
    searchValue,
    organizationUsersQuery.data,
  );

  if (organizationUsersQuery.isError) {
    return (
      <Wrapper>
        <InlineAlert variant="error" title={texts.admin.user.loadError} />
      </Wrapper>
    );
  }

  if (organizationUsersQuery.isLoading) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Toolbar
        onSearchParamsChange={(params) => setSearchValue(params.query)}
        modal={AddOrganizationUsersModal}
      />

      <Show when={inactiveUsers.length > 0}>
        <Wrapper>
          <Label>{texts.admin.user.inactiveUsers}</Label>
          <OrganizationInvitedUsersTable organizationUsers={inactiveUsers} />
        </Wrapper>
      </Show>

      <Show when={activeUsers.length > 0}>
        <Wrapper>
          {/* Only show label if the table above is visible. */}
          <Show when={inactiveUsers.length > 0}>
            <Label>{texts.admin.user.activeUsers}</Label>
          </Show>

          <OrganizationActiveUsersTable organizationUsers={activeUsers} />
        </Wrapper>
      </Show>

      <Show when={activeUsers.length === 0 && inactiveUsers.length === 0}>
        <InlineAlert title={texts.admin.users.none} />
      </Show>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 32px;
`;

const Label = styled.div`
  margin-top: 64px;
  margin-bottom: ${padding.medium}px;
  font-weight: bold;
`;
