// Load styles for react datepicker
import 'react-datepicker/dist/react-datepicker.css';

import React, { FC } from 'react';
import { useRoutes } from 'react-router';

import { connectRoutes } from '@/routes/connectRoutes';

import { ResetFocus } from './ResetFocus';

export const App: FC = () => {
  const routesElement = useRoutes(connectRoutes());

  return (
    <>
      <ResetFocus />
      {routesElement}
    </>
  );
};
