import { Locale } from '@/i18n/locale';
import { CurrencyCode } from '@/types/CurrencyCode';
import { unsafeConvertStringToEnumWithWarning } from '@/util/unsafeConvertStringToEnumWithWarning';

import { FundDocumentDto } from '../dtos/FundDocumentDto';
import { FundDocument } from '../models/FundDocument';
import { FundDocumentType } from '../models/FundDocumentType';

export const transformFundDocumentsResponse = (
  document: FundDocumentDto,
): FundDocument => ({
  fileName: document.fileName,
  url: document.url,

  // The fund list API can return a host of different enums for the types
  // below. Since we don't know all of them at runtime we log a warning to
  // add them as they appear.
  currencyCode: unsafeConvertStringToEnumWithWarning(
    document.currencyCode,
    CurrencyCode,
  ),

  documentType: unsafeConvertStringToEnumWithWarning(
    document.documentType,
    FundDocumentType,
  ),

  languageCode: unsafeConvertStringToEnumWithWarning(
    document.languageCode,
    Locale,
  ),
});
