import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { useAddOrganizationUserMutation } from '@/api/organizationUsers/organizationUsersApi';
import { useFms } from '@/hooks/useFms';
import { FMSTexts } from '@/types/fms';

import { RolesSelection } from '../RolesSelection';
import { AddUserModalProps } from './AddUserModalProps';
import { UserAdminForm } from './UserAdminForm';
import {
  getValidationSchemaAddOrganizationUsers,
  OrganizationUserFormValues,
} from './UserFormSchema';

const emptyInitialValues: OrganizationUserFormValues = {
  givenName: '',
  familyName: '',
  email: '',
  roles: [],
};

type Props = AddUserModalProps;

export const AddOrganizationUsersModal: FC<Props> = ({ show, onClose }) => {
  const texts: FMSTexts = useFms();

  const [addOrganizationUser, addOrganizationUserRequest] =
    useAddOrganizationUserMutation();

  const handleSubmit = async (
    formUser: OrganizationUserFormValues,
  ): Promise<void> => {
    await addOrganizationUser({
      firstName: formUser.givenName,
      surname: formUser.familyName,
      email: formUser.email,
      roles: formUser.roles,
    });
  };

  const formik = useFormik({
    initialValues: emptyInitialValues,
    onSubmit: handleSubmit,
    validationSchema: getValidationSchemaAddOrganizationUsers(texts),
    enableReinitialize: true,
  });

  const handleOnClose = (): void => {
    // Reset state as this component is a singleton triggered by the `show` boolean.
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (addOrganizationUserRequest.isSuccess) {
      handleOnClose();
    }
  }, [addOrganizationUserRequest.isSuccess]);

  return (
    <FormikProvider value={formik}>
      <UserAdminForm
        show={show}
        onClose={handleOnClose}
        header={texts.adminModal.addUserButton}
        isSubmitting={formik.isSubmitting}
        submitFailed={addOrganizationUserRequest.isError}
        currentMode="addOrganization"
        touched={formik.touched}
        errors={formik.errors}
        dirty={formik.dirty}
        trackingModal="add-organization-user"
      >
        <RolesSelection
          error={formik.submitCount > 0 && (formik.errors.roles as string)}
        />
      </UserAdminForm>
    </FormikProvider>
  );
};
