import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';

import { OrganizationUser } from '@/api/organizationUsers/models/OrganizationUser';
import { User } from '@/api/users/models/User';

interface SearchResult<T> {
  activeUsers: T[];
  inactiveUsers: T[];
}

export const useUserSearch = <T extends User | OrganizationUser>(
  searchValue: string | undefined,
  users: T[] | undefined,
): SearchResult<T> => {
  const [activeUsers, setActiveUsers] = useState<T[]>([]);
  const [inactiveUsers, setInactiveUsers] = useState<T[]>([]);

  useEffect(() => {
    if (!users) {
      return;
    }

    let usersToDisplay = [...users];

    if (searchValue) {
      const searchOptions = {
        keys: ['givenName', 'familyName', 'userId'],
        threshold: 0.2,
      };

      const fuse = new Fuse(usersToDisplay, searchOptions);
      usersToDisplay = fuse.search(searchValue).map((res) => res.item);
    }

    const activeUsersToDisplay = usersToDisplay.filter(
      (user) => user.isActiveUser,
    );
    const inactiveUsersToDisplay = usersToDisplay.filter(
      (user) => !user.isActiveUser,
    );

    setActiveUsers(activeUsersToDisplay);
    setInactiveUsers(inactiveUsersToDisplay);
  }, [users, searchValue]);

  return { activeUsers, inactiveUsers };
};
