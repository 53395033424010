import { OrderValueType } from '@/api/order/models/OrderValueType';

export const getValidToValueTypes = (
  fromValueType: OrderValueType,
): Array<OrderValueType> => {
  switch (fromValueType) {
    case OrderValueType.Amount:
      return [OrderValueType.Amount, OrderValueType.Percent];
    case OrderValueType.Percent:
      return [OrderValueType.Percent];
    case OrderValueType.Units:
      return [OrderValueType.Percent];
    default:
      throw new Error(`Invalid order value type [${fromValueType}]`);
  }
};
