import { createApi } from '@reduxjs/toolkit/query/react';

import { SaveSigningRightsRequestDto } from '@/api/signing-right/dtos/SaveSigningRightsRequestDto';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { OrganizationSignatoriesDto } from './dtos/OrganizationSignatoriesDto';
import { SaveSigningRightsResponseDto } from './dtos/SaveSigningRightsResponseDto';
import { SigningRightDto } from './dtos/SigningRightDto';
import { Signatory } from './models/Signatory';
import { SigningRight } from './models/SigningRight';
import { mapSigningRightDtoToModel } from './utils/mapSigningRightDtoToModel';

export const signingRightApi = createApi({
  reducerPath: 'signingRightApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    /**
     * Fetch users that can sign on behalf of the current organization.
     * The organization CMID is extracted from the JWT token on the backend.
     */
    signatoriesForOrganization: builder.query<Signatory[], void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/signing-right/organization`,
      }),
      transformResponse: (response: OrganizationSignatoriesDto) => {
        return response.signatories;
      },
    }),

    /**
     * Fetch the signing right for the current user.
     */
    signingRightForUser: builder.query<SigningRight, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/signing-right`,
      }),
      transformResponse: (response: SigningRightDto) => {
        return mapSigningRightDtoToModel(response);
      },
    }),

    /**
     * Post the signing right for the current user.
     */
    storeSigningRight: builder.mutation<
      SaveSigningRightsResponseDto,
      SaveSigningRightsRequestDto
    >({
      query: (dto) => ({
        url: `${window.config.connectBaseApiUrl}/api/signing-right`,
        method: 'post',
        data: dto,
      }),
    }),

    /**
     * Cancel the current signing of the document.
     */
    cancelSigningRight: builder.mutation<void, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/signing-right/cancel`,
        method: 'post',
      }),
    }),

    /**
     * Notify the backend that the user has signed the document.
     */
    signingRightSigned: builder.mutation<void, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/signing-right/signed`,
        method: 'post',
      }),
    }),
  }),
});

export const {
  useSignatoriesForOrganizationQuery,
  useSigningRightForUserQuery,
  useStoreSigningRightMutation,
  useCancelSigningRightMutation,
  useSigningRightSignedMutation,
} = signingRightApi;
