import {
  AnyAction,
  createListenerMiddleware,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { ApiError } from '@/api/types/ApiError';
import { UnknownApiError } from '@/api/types/UnknownApiError';
import { createLogEvent } from '@/logging/createLogEvent';

import { addLogEvent } from '../logSlice';
import { tryGetPathnameFromUrl } from '../utils/tryGetPathnameFromUrl';

/**
 * Log errors originating in rejected thunks from RTK.
 */
export const logApiErrorListener = createListenerMiddleware();

logApiErrorListener.startListening({
  predicate: (action) =>
    isRejectedWithValue(action) &&
    (isApiError(action) || isUnknownApiError(action)),

  effect: (action, { dispatch }) => {
    // We duplicate the condition from the predicate
    // to get the action to be properly typed.
    if (isApiError(action) || isUnknownApiError(action)) {
      sendToSentry(action.payload);

      // Get the pathname from the URL since a full URL triggers the
      // Azure application gateway to block the request.
      const parsedUrl = tryGetPathnameFromUrl(action.payload.url);

      if (isApiError(action)) {
        dispatch(
          addLogEvent(
            createLogEvent({
              level: 'error',
              type: 'http',
              data: {
                message: action.payload.originalMessage,
                method: action.payload.method,
                status: action.payload.status,
                code: action.payload.code,
                url: parsedUrl,

                // Gives additional debugging info.
                // E.g.: "signingRightApi/executeQuery/rejected".
                actionType: action.type,
              },
            }),
          ),
        );
      }
    }

    if (isUnknownApiError(action)) {
      dispatch(
        addLogEvent(
          createLogEvent({
            level: 'error',
            type: 'http',
            data: {
              message: action.payload.originalMessage,
              url: action.payload.url,
              errorObject: action.payload.stringifiedErrorObject,

              // Gives additional debugging info.
              // E.g.: "signingRightApi/executeQuery/rejected".
              actionType: action.type,
            },
          }),
        ),
      );
    }
  },
});

/**
 * Send API error to Sentry.
 *
 * This function is a wrapper for `Sentry.captureException` to make
 * it type safe to ensure we are passing in the actual error object.
 * This will give Sentry more info, such as a stack trace.
 */
function sendToSentry(error: ApiError | UnknownApiError): void {
  Sentry.captureException(error);
}

function isApiError(action: AnyAction): action is PayloadAction<ApiError> {
  return action.payload instanceof ApiError;
}

function isUnknownApiError(
  action: AnyAction,
): action is PayloadAction<UnknownApiError> {
  return action.payload instanceof UnknownApiError;
}
