import { CurrencyCode } from '@/types/CurrencyCode';
import { unsafeConvertStringToEnumWithWarning } from '@/util/unsafeConvertStringToEnumWithWarning';

import { PortfolioGroupDto } from '../dtos/PortfolioReply';
import { PortfolioGroup } from '../models/PortfolioGroup';

export const transformPortfolioGroupDtoToModel = (
  portfolioGroupDto: PortfolioGroupDto,
): PortfolioGroup => ({
  id: portfolioGroupDto.id,
  name: portfolioGroupDto.name,

  portfolios: portfolioGroupDto.portfolios.map((portfolioDto) => ({
    id: portfolioDto.id,

    name: portfolioDto.name,

    currency:
      unsafeConvertStringToEnumWithWarning(
        portfolioDto.currency,
        CurrencyCode,
      ) ?? (portfolioDto.currency as CurrencyCode),

    isFundPortfolio: portfolioDto.isFundPortfolio,

    accounts: portfolioDto.accounts.map((accountDto) => ({
      accountId: accountDto.accountId,
    })),

    portfolioBankAccounts: portfolioDto.portfolioBankAccounts.map(
      (accountDto) => ({
        bank: accountDto.bank,
        bankName: accountDto.bankName,
        accountNumber: accountDto.accountNo,
        currency:
          unsafeConvertStringToEnumWithWarning(
            accountDto.currency,
            CurrencyCode,
          ) ?? (accountDto.currency as CurrencyCode),
      }),
    ),
  })),
});
