import {
  AnyAction,
  createListenerMiddleware,
  isRejected,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { createLogEvent } from '@/logging/createLogEvent';

import { addLogEvent } from '../logSlice';

/**
 * Log type errors originating in rejected thunks from RTK.
 */
export const logTypeErrorListener = createListenerMiddleware();

logTypeErrorListener.startListening({
  predicate: (action) => isRejected(action) && isTypeError(action),

  effect: (action, { dispatch }) => {
    // We duplicate the condition from the predicate
    // to get the action to be properly typed.
    if (isTypeError(action)) {
      Sentry.captureException(
        // Make the value be an exception.
        // This will give Sentry more info, such as a stack trace.
        new TypeError(
          `Type error, message: [${action.error.message}], action type: [${action.type}]`,
          { cause: action.error },
        ),
      );

      dispatch(
        addLogEvent(
          createLogEvent({
            level: 'error',
            type: 'type error',
            data: {
              message: action.error.message,

              // Gives additional debugging info.
              // E.g.: "signingRightApi/executeQuery/rejected".
              actionType: action.type,
            },
          }),
        ),
      );
    }
  },
});

type ActionWithTypeError = AnyAction & {
  error: {
    name: 'TypeError';
    stack: string;
    message: string;
  };
};

function isTypeError(action: AnyAction): action is ActionWithTypeError {
  return (
    action.error instanceof TypeError || action.error?.name === TypeError.name
  );
}
