import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { CountriesDto } from './CountriesDto';
import { Country } from './Country';

export const countriesApi = createApi({
  reducerPath: 'countryApi',
  baseQuery: axiosBaseQuery<CountriesDto>(),
  endpoints: (builder) => ({
    fetchCountries: builder.query<Country[], void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/countries`,
      }),
      transformResponse: (dto: CountriesDto) => {
        return dto.countries;
      },
    }),
  }),
});

export const { useFetchCountriesQuery } = countriesApi;
