import { useEffect, useRef } from 'react';

/**
 * @returns Returns `true` if it is the first render, otherwise `false`.
 */
export const useFirstRender = (): boolean => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};
