/**
 * Find the name of the corresponding system icon for the specified file extension.
 *
 * @returns System icon name for the specified file extension.
 */
export function fileExtensionToIconName(fileExtension: string): string {
  const extension = fileExtension.toLowerCase();

  if (shouldHavePdfIcon(extension)) {
    return 'pdf';
  }

  if (shouldHaveDocIcon(extension)) {
    return 'doc';
  }

  if (shouldHaveXlsIcon(extension)) {
    return 'xls';
  }

  if (shouldHavePptIcon(extension)) {
    return 'ppt';
  }

  if (shouldHaveTxtIcon(extension)) {
    return 'txt';
  }

  if (shouldHaveXmlIcon(extension)) {
    return 'xml';
  }

  if (shouldHaveZipIcon(extension)) {
    return 'zip';
  }

  return 'file';
}

const shouldHavePdfIcon = (extension: string): boolean => extension === 'pdf';

const shouldHaveDocIcon = (extension: string): boolean =>
  extension.startsWith('do') || extension === 'odt';

const shouldHaveXlsIcon = (extension: string): boolean =>
  extension.startsWith('xl') || extension === 'ods';

const shouldHavePptIcon = (extension: string): boolean =>
  extension.startsWith('pp') || extension === 'pot' || extension === 'odp';

const shouldHaveTxtIcon = (extension: string): boolean => extension === 'txt';

const shouldHaveXmlIcon = (extension: string): boolean =>
  ['xml', 'xsl', 'xsd', 'rng'].some((type) => type === extension);

const shouldHaveZipIcon = (extension: string): boolean =>
  extension === 'zip' || extension === '7z';
