import { HoldingsSummary } from '../models/HoldingsSummary';
import { PortfolioHoldings } from '../models/PortfolioHoldings';
import { mergeSumsByCurrency } from './mergeSumsByCurrency';

/**
 * Summarize the given portfolio group holdings.
 * The summary contains summarized amounts mapped by currency.
 */
export const getPortfolioGroupHoldingsSummary = (
  portfolioHoldings: Array<PortfolioHoldings>,
): HoldingsSummary => {
  return {
    cleanValue: mergeSumsByCurrency(
      portfolioHoldings.map((holdings) => holdings.summary.cleanValue),
    ),
    dirtyValue: mergeSumsByCurrency(
      portfolioHoldings.map((holdings) => holdings.summary.dirtyValue),
    ),
    dirtyValueInclPayRec: mergeSumsByCurrency(
      portfolioHoldings.map(
        (holdings) => holdings.summary.dirtyValueInclPayRec,
      ),
    ),
    bookValue: mergeSumsByCurrency(
      portfolioHoldings.map((holdings) => holdings.summary.bookValue),
    ),
    accruedInterest: mergeSumsByCurrency(
      portfolioHoldings.map((holdings) => holdings.summary.accruedInterest),
    ),
    unrealizedPLValue: mergeSumsByCurrency(
      portfolioHoldings.map((holdings) => holdings.summary.unrealizedPLValue),
    ),
  };
};
