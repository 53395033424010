import { Money } from '../models/Money';
import { SumByCurrency } from '../models/SumByCurrency';

/**
 * Sum the given money amounts to a map by currency.
 *
 * @example:
 * {
 *   "NOK": 82736.40,
 *   "SEK": 988873.74
 * }
 */
export const getSumByCurrency = (moneys: Array<Money>): SumByCurrency => {
  return moneys
    .filter((money) => !!money)
    .reduce((sumByCurrency, money) => {
      if (sumByCurrency[money.currency]) {
        sumByCurrency[money.currency] += money.amount ?? 0;
      } else {
        sumByCurrency[money.currency] = money.amount ?? 0;
      }

      return sumByCurrency;
    }, {});
};
