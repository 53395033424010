import { useFormikContext } from 'formik';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { Show } from '@/components/Show';

import { SwitchOrderFormValues } from '../../../types/SwitchOrderFormValues';
import { TotalAmountCell } from './TotalAmountCell';

export const TotalSwitchRedemptionPercentCell: React.FC = () => {
  const { values } = useFormikContext<SwitchOrderFormValues>();

  const totalToPercent = sumBy(values.toFunds, (fund) => fund.value);

  const hasSellAll = values.fromFunds.some((fund) => fund.sellAll);

  // Show the total cell only if the total to percent is more than 0
  // and there are no from funds with sellAll.
  const showTotalCell = totalToPercent > 0 && !hasSellAll;

  return (
    <Show when={showTotalCell}>
      <TotalAmountCell amount={totalToPercent} suffix="%" />
    </Show>
  );
};
