import { RedemptionPayment } from '@/pages/Order/steps/OrderDetails/types/Payment';
import { CurrencyCode } from '@/types/CurrencyCode';

export const findPaymentForRedemptionFund = (
  currency: CurrencyCode,
  payments: RedemptionPayment[],
): RedemptionPayment => {
  const payment = payments.find((p) => p.currency === currency);

  if (payment) {
    return payment;
  }

  throw new Error(`Could not find payment info for currency [${currency}].`);
};
