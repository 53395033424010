import { Holdings } from '../models/Holdings';
import { HoldingsSummary } from '../models/HoldingsSummary';
import { getSumByCurrency } from './getSumByCurrency';

/**
 * Summarize the given portfilio holdings.
 * The summary contains summarized amounts mapped by currency.
 */
export const getPortfolioHoldingsSummary = (
  portfolioHoldings: Array<Holdings>,
): HoldingsSummary => {
  return {
    cleanValue: getSumByCurrency(
      portfolioHoldings.map((holdings) => holdings.cleanValue),
    ),
    dirtyValue: getSumByCurrency(
      portfolioHoldings.map((holdings) => holdings.dirtyValue),
    ),
    dirtyValueInclPayRec: getSumByCurrency(
      portfolioHoldings.map((holdings) => holdings.dirtyValueInclPayRec),
    ),
    bookValue: getSumByCurrency(
      portfolioHoldings.map((holdings) => holdings.bookValue),
    ),
    accruedInterest: getSumByCurrency(
      portfolioHoldings.map((holdings) => holdings.accruedInterest),
    ),
    unrealizedPLValue: getSumByCurrency(
      portfolioHoldings.map((holdings) => holdings.unrealizedPLValue),
    ),
  };
};
