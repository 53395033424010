import * as Yup from 'yup';

import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { FundState } from '@/pages/Order/steps/OrderDetails/types/FundState';
import { SubscriptionPayment } from '@/pages/Order/steps/OrderDetails/types/Payment';
import { CurrencyCode } from '@/types/CurrencyCode';
import { nameof } from '@/util/nameof';

import { SubscriptionOrderFormValues } from '../../types/SubscriptionOrderFormValues';

interface ErrorMessages {
  paymentPlacementRequired: string;
  portfolioBankAccountNumberRequired: string;
}

const validationSchema = (
  errorMessages: ErrorMessages,
): Yup.SchemaOf<SubscriptionPayment> =>
  Yup.object({
    type: Yup.mixed().oneOf(['SUBSCRIPTION_PAYMENT']),

    paymentPlacement: Yup.mixed<OrderPaymentType>()
      .oneOf(
        [OrderPaymentType.BankAccount, OrderPaymentType.Portfolio],
        errorMessages.paymentPlacementRequired,
      )
      .required(errorMessages.paymentPlacementRequired),

    portfolioBankAccountNumber: Yup.string().when(
      nameof<SubscriptionPayment>('paymentPlacement'),
      {
        is: OrderPaymentType.Portfolio,

        // This field is required when the payment type is set to "portfolio".
        // Since the portfolio can have more than one portfolio bank account.
        // Thus, the user must choose which portfolio bank account to use.
        then: Yup.string().required(
          errorMessages.portfolioBankAccountNumberRequired,
        ),

        // The payment placement is not portfolio, so this field is not required.
        otherwise: Yup.string().optional(),
      },
    ),

    fundPaymentInformation: Yup.object({
      accountHolder: Yup.mixed<PaymentAccountHolder>()
        .oneOf(Object.values(PaymentAccountHolder))
        .required(),
      currency: Yup.mixed<CurrencyCode>()
        .oneOf(Object.values(CurrencyCode))
        .required(),
      bankAccountNumber: Yup.string().required(),
      iban: Yup.string().required(),
      swift: Yup.string().required(),
    }).required(),

    forIsins: Yup.array().of(Yup.string()).required(),
  }).required();

export const subscriptionPaymentValidationSchema = (
  errorMessages: ErrorMessages,
): Yup.ArraySchema<Yup.SchemaOf<SubscriptionPayment>> =>
  Yup.array()
    .of(validationSchema(errorMessages))
    // Only display validation errors if some funds are selected for subscription.
    .when(nameof<SubscriptionOrderFormValues>('toFunds'), {
      is: (funds: FundState[]) => funds.some((fund) => fund.value),
      then: (schema) => schema.min(1),
    })
    .required();
