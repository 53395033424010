import Button from '@storeblocks/button';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { FundState } from '@/pages/Order/steps/OrderDetails/types/FundState';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  isin: string;
  fundFieldName: string;
}

export const RemoveFundButton: React.FC<Props> = ({ isin, fundFieldName }) => {
  const getTrackId = useGetTrackId();

  const [fundField, , helpers] = useField<FundState[]>(fundFieldName);

  const handleClick = (): void => {
    const fundsToKeep = fundField.value.filter((fund) => fund.isin !== isin);
    helpers.setValue(fundsToKeep);
  };

  return (
    <ButtonStyled
      variant="icon"
      iconLeft="close"
      onClick={handleClick}
      data-test={`remove-${isin}`}
      data-trackid={getTrackId('remove-selected-fund', TrackingElement.Button)}
    />
  );
};

const ButtonStyled = styled(Button)`
  margin-left: 16px;
`;
