import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { RedemptionFundState } from '../../types/RedemptionFundState';
import { SubscriptionFundState } from '../../types/SubscriptionFundState';

interface ErrorMessages {
  fromToSameFund: string;
}

export const testSwitchFromToFunds = (
  context: Yup.TestContext<AnyObject>,
  errorMessages: ErrorMessages,
  toFunds: Array<SubscriptionFundState>,
): true | Yup.ValidationError => {
  const toFundsWithValue = toFunds.filter((fund) => fund.value > 0);

  const fromFundsWithValue = context.parent.fromFunds.filter(
    (fund: RedemptionFundState) => fund.value > 0,
  );

  const toFundsContainsFromFunds = toFundsWithValue.some(
    (toFund: SubscriptionFundState) =>
      fromFundsWithValue.some(
        (fromFund: RedemptionFundState) => fromFund.isin === toFund.isin,
      ),
  );

  if (toFundsContainsFromFunds) {
    return context.createError({
      message: errorMessages.fromToSameFund,
    });
  }

  return true;
};
