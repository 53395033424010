import max from 'date-fns/max';

import { AssetPerformance } from '../models';
import { MultiAssetPerformance } from '../models/MultiAssetPerformance';

export const mapMultiAssetPerformance = (
  assetPerformances: Array<AssetPerformance>,
): MultiAssetPerformance => {
  const dates = assetPerformances.map(
    (assetPerformance) => assetPerformance.date,
  );

  const latestDate = max(dates);

  return {
    date: latestDate,
    assetPerformances,
  };
};
