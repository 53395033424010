import React from 'react';

import { EsgCurrencyCategory } from '@/api/esg/models/EsgCurrencyCategory';
import { Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { CurrencyCode } from '@/types/CurrencyCode';

import { EsgCarbonFootprintComparison } from './EsgCarbonFootprintComparison';
import { EsgCarbonFootprintTonnage } from './EsgCarbonFootprintTonnage';

interface Props {
  esgCarbonIntensities: EsgCurrencyCategory[];
}

export const EsgCarbonFootprint: React.FC<Props> = ({
  esgCarbonIntensities,
}) => {
  const texts = useFms();

  const carbonFootprintData = esgCarbonIntensities.find(
    (score) => score.currency === CurrencyCode.NOK,
  );

  return (
    <WithGap>
      <Paragraph>{texts.pages.esg.carbonFootprint.description}</Paragraph>

      {carbonFootprintData?.benchmark ? (
        <EsgCarbonFootprintComparison data={carbonFootprintData} />
      ) : (
        <EsgCarbonFootprintTonnage data={carbonFootprintData} />
      )}
    </WithGap>
  );
};
