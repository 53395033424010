import mime from 'mime';

import { DailyTradeReportDto } from '../dtos/DailyTradeReportDto';
import { DailyTradeReportsReply } from '../dtos/DailyTradeReportsReply';
import { DailyTradeReport } from '../models/DailyTradeReports';
import { sortReportsByDate } from './sortReportsByDate';

export const transformDailyTradeReportsReply = (
  reply: DailyTradeReportsReply,
): Array<DailyTradeReport> =>
  sortReportsByDate(reply.documents.map(mapDailyTradeReportDtoToModel));

export const mapDailyTradeReportDtoToModel = (
  dto: DailyTradeReportDto,
): DailyTradeReport => ({
  displayName: dto.displayName,
  filename: dto.filename,
  id: dto.uuid,
  fileType: mime.getExtension(dto.mimeType) ?? 'unknown',
  documentType: dto.documentType,
  reportType: dto.reportType,
  // Removing timezone to make sure we have the same date everywhere
  reportDateISO: dto.createdDate.split('T')[0],
});
