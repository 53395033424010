import { apiClientCreator } from '@/api/axios/apiClientCreator';
import { IHubSource, ReportSource } from '@/pages/Reports/types/ReportModel';

import { fileDownload } from './FileDownload';

export const downloadFilesFromIhub = async (
  fileIds: Array<string>,
  source: IHubSource,
): Promise<void> => {
  if (fileIds.length === 0) {
    return Promise.resolve();
  }

  const params = {
    fileIds,
  };

  const url = getUrlForSource(source);

  const response = await apiClientCreator({
    useKeycloakToken: true,

    // 300 000 milliseconds = 5 minutes.
    customTimeout: 300_000,
  }).post(url, params, { responseType: 'blob' });

  return fileDownload(response.data, response.headers['file-name']);
};

const getUrlForSource = (source: IHubSource): string => {
  switch (source) {
    case ReportSource.IHubShared:
      return `${window.config.connectBaseApiUrl}/api/reports/ihub/files/shared/download`;

    case ReportSource.IHubOrganization:
      return `${window.config.connectBaseApiUrl}/api/reports/ihub/files/organization/download`;

    default:
      throw new Error(`Could not find download URL for source [${source}].`);
  }
};
