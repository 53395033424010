import Button from '@storeblocks/button';
import React from 'react';

import { H3, Ingress } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  onClose: () => void;
  onReset: () => void;
}

/**
 * Display a message for a successfully submitted contact us form.
 */
export const ContactFormSuccess: React.FC<Props> = ({ onClose, onReset }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <WithGap gap="16" data-test="contact-us-form-success-message">
      <H3>{texts.pages.contactUs.success.title}</H3>

      <Ingress>{texts.pages.contactUs.success.description}</Ingress>

      <WithGap gap="8" direction="row">
        <Button
          type="button"
          onClick={() => onClose()}
          data-test="close-contact-us-form"
          data-trackid={getTrackId(
            'close-contact-us-form',
            TrackingElement.Button,
          )}
        >
          {texts.pages.contactUs.close}
        </Button>

        <Button
          type="button"
          variant="outlined"
          onClick={() => onReset()}
          data-test="reset-contact-us-form"
          data-trackid={getTrackId(
            'reset-contact-us-form',
            TrackingElement.Button,
          )}
        >
          {texts.pages.contactUs.createNewMessage}
        </Button>
      </WithGap>
    </WithGap>
  );
};
