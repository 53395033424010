import React from 'react';

/**
 * The em dash is a punctuation mark that can be used
 * in place of a comma, parentheses, or a colon — usually for
 * the purposes of improved readability.
 */
export const EmDash: React.FC = () => {
  return <>&mdash;</>;
};
