import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useMultipleAssetPerformanceQuery } from '@/api/performanceData/multiPortfolioReturnsApi';
import { formatDateArgument } from '@/api/performanceData/performanceDataApi';
import { isNotReady } from '@/api/utils/isNotReady';
import { H1 } from '@/components/Typography';
import { AssetAllocationViewMode } from '@/components/Widgets/AssetAllocation';
import { MultiAssetAllocationTable } from '@/components/Widgets/AssetAllocation/AssetAllocationTable/MultiAssetAllocationTable';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { CollapsibleFilterContainer } from '../filters/CollapsibleFilterContainer';
import { DateFilter } from '../filters/DateFilter';
import { useDateFilterText } from '../filters/hooks/useDateFilterText';
import { usePresentationContext } from '../PresentationContext';

export const AssetAllocationScreen: React.FC = () => {
  const texts = useFms();
  const { globalFilter, isPrintPreviewPage } = usePresentationContext();

  const assetPerformanceQuery = useMultipleAssetPerformanceQuery({
    portfolioGroups: globalFilter.portfolioGroups.map(
      (portfolioGroup) => portfolioGroup.id,
    ),
    to: formatDateArgument(globalFilter.date),
  });

  const dateFilterText = useDateFilterText();

  return (
    <A4WidthForPrint>
      <WithGap data-test="asset-allocation-screen">
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.assetAllocationTable.title}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>
        <CollapsibleFilterContainer selectedFilterTexts={[dateFilterText]}>
          <WithGap>
            <WithGap direction="row">
              <DateFilter />
            </WithGap>
          </WithGap>
        </CollapsibleFilterContainer>

        {isNotReady(assetPerformanceQuery) ? (
          <QueryState
            query={assetPerformanceQuery}
            customTexts={{
              noData: {
                title: texts.pages.presentation.filters.noData.title,
                description: texts.pages.presentation.filters.noData.message,
              },
            }}
          />
        ) : (
          <>
            <MultiAssetAllocationTable
              multiAssetPerformance={assetPerformanceQuery.data}
              expanded
              viewMode={AssetAllocationViewMode.All}
              displayVerticalScrollbar={!isPrintPreviewPage}
            />
          </>
        )}
      </WithGap>
    </A4WidthForPrint>
  );
};
