import * as Yup from 'yup';

import { Organization } from '@/api/organizations/models/Organization';
import { FMSTexts } from '@/types/fms';

export interface OrganizationUserFormValues {
  email: string;
  givenName: string;
  familyName: string;
  organizations?: Organization[];
  roles: string[];
}

const organizationSchema = (): Yup.SchemaOf<Organization> =>
  Yup.object({
    organizationId: Yup.string(),
    organizationName: Yup.string(),
    numberOfUsers: Yup.number(),
  });

export const getValidationSchemaEditAllUsers = (
  texts: FMSTexts,
): Yup.SchemaOf<OrganizationUserFormValues> =>
  Yup.object({
    givenName: Yup.string().required(texts.form.emptyMessage),
    familyName: Yup.string().required(texts.form.emptyMessage),
    email: Yup.string().email(texts.form.invalidEmail),
    organizations: Yup.array().of(organizationSchema()),
    roles: Yup.array().of(Yup.string()),
  });

export const getValidationSchemaAddAllUsers = (
  texts: FMSTexts,
): Yup.SchemaOf<OrganizationUserFormValues> =>
  Yup.object({
    givenName: Yup.string().required(texts.form.emptyMessage),
    familyName: Yup.string().required(texts.form.emptyMessage),
    email: Yup.string()
      .required(texts.form.emptyMessage)
      .email(texts.form.invalidEmail),
    organizations: Yup.array()
      .of(organizationSchema())
      .required(texts.form.emptyMessage),
    roles: Yup.array().of(Yup.string()).min(1, texts.form.emptyMessage),
  });

export const getValidationSchemaAddOrganizationUsers = (
  texts: FMSTexts,
): Yup.SchemaOf<OrganizationUserFormValues> =>
  Yup.object({
    givenName: Yup.string().required(texts.form.emptyMessage),
    familyName: Yup.string().required(texts.form.emptyMessage),
    email: Yup.string()
      .required(texts.form.emptyMessage)
      .email(texts.form.invalidEmail),
    organizations: Yup.array().of(organizationSchema()),
    roles: Yup.array().of(Yup.string()).min(1, texts.form.emptyMessage),
  });

export const getValidationSchemaEditOrganizationUsers = (
  texts: FMSTexts,
): Yup.SchemaOf<OrganizationUserFormValues> =>
  Yup.object({
    givenName: Yup.string().required(texts.form.emptyMessage),
    familyName: Yup.string().required(texts.form.emptyMessage),
    email: Yup.string().email(texts.form.invalidEmail),
    organizations: Yup.array().of(organizationSchema()),
    roles: Yup.array().of(Yup.string()).min(1, texts.form.emptyMessage),
  });

interface TextInputSetting {
  field: string;
  disabledRules: {
    editOrganization: boolean;
    editAll: boolean;
    addOrganization: boolean;
    addAll: boolean;
  };
}

export const textInputSettings: TextInputSetting[] = [
  {
    field: 'givenName',
    disabledRules: {
      editOrganization: false,
      editAll: true,
      addOrganization: false,
      addAll: false,
    },
  },
  {
    field: 'familyName',
    disabledRules: {
      editOrganization: false,
      editAll: true,
      addOrganization: false,
      addAll: false,
    },
  },
  {
    field: 'email',
    disabledRules: {
      editOrganization: true,
      editAll: true,
      addOrganization: false,
      addAll: false,
    },
  },
];
