import React, { FC } from 'react';
import styled from 'styled-components';

import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  email: string;
}

export const EmailLink: FC<Props> = ({ email }) => {
  const getTrackId = useGetTrackId();

  return (
    <Link
      href={`mailto:${email}`}
      data-trackid={getTrackId('mail-to', TrackingElement.Link)}
      data-trackid-mail-address={email}
    >
      {email}
    </Link>
  );
};

const Link = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-on-surface);
  line-break: anywhere;
`;
