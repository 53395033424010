import sortBy from 'lodash/sortBy';

import { PortfolioFundHoldings } from '@/api/fundHoldings/models';
import { FundList } from '@/api/funds/models';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderValueType } from '@/api/order/models/OrderValueType';

import { RedemptionFundState } from '../../../types/RedemptionFundState';
import { mapHoldingsToRedemptionFundState } from '../../../utils/mapHoldingsToRedemptionFundState';
import { mapOrderPlacementToRedemptionFundState } from './mapOrderPlacementToRedemptionFundState';

/**
 * Create redemption fund states based on the funds that have holdings and
 * order placements that has state (i.e.: value).
 */
export const createRedemptionFundStates = (
  orderPlacements: OrderPlacement[],
  portfolioHoldings: PortfolioFundHoldings,
  fundList: FundList,
  valueType: OrderValueType,
): RedemptionFundState[] => {
  // Map all funds in the portfolio to fund state.
  const holdingsFundStates = mapHoldingsToRedemptionFundState(
    fundList.byIsin,
    portfolioHoldings.holdings,
    valueType,
  );

  // Map all order placements to fund state.
  const orderPlacementFundStates = mapOrderPlacementToRedemptionFundState(
    orderPlacements,
    portfolioHoldings.byFund,
    fundList.byIsin,
  );

  // Replace the original portfolio fund state with the one from order
  // placement since it has more state.
  const fundStates = holdingsFundStates.map((holdingsFundState) => {
    const fundState = orderPlacementFundStates.find(
      (orderPlacementFundState) =>
        orderPlacementFundState.isin === holdingsFundState.isin,
    );

    if (fundState) {
      return fundState;
    }

    return holdingsFundState;
  });

  return sortBy(fundStates, (fund) => fund.name);
};
