import * as Sentry from '@sentry/react';

import { AuthorizationTokenError } from '@/api/axios/interceptors/AuthorizationTokenError';
import { ApiError } from '@/api/types/ApiError';
import { UnknownApiError } from '@/api/types/UnknownApiError';
import { isIntraDomain } from '@/logging/isIntraDomain';
import { getSessionId } from '@/logging/sessionId';
import { PostLogError } from '@/logging/types/PostLogError';

export const initializeSentry = (): void => {
  Sentry.init({
    dsn: 'https://5b21c4f650464aacbf52a4709a0ab67d@o7778.ingest.sentry.io/1288863',
    integrations: [
      Sentry.captureConsoleIntegration({
        // Array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }),
    ],

    // This sets the sample rate for the session replay.
    // A sample rate of 0.1 is 10% of sessions.
    // We have this disabled for now since we don't need it thus far.
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // We have set this to 100% since we want to capture session replays for all errors.
    replaysOnErrorSampleRate: 1.0,

    environment: window.config.sentryEnvironment,

    ignoreErrors: [
      // An asyncThunk was not executed due to a condition function returning false.
      'Aborted due to condition callback returning false.',
    ],

    enabled: window.config.enableSentry,
  });

  /**
   * Enrich Sentry events with additional data.
   */
  Sentry.addEventProcessor(function (event, hint) {
    // Add anything to the event here
    // returning `null` will drop the event.

    if (hint.originalException instanceof PostLogError) {
      const { pageUrl } = hint.originalException;

      // Sentry usually uses the stacktrace (amongst other things) to group
      // errors together. Still, since the stacktrace is about the same for
      // all POST Log errors, we use the fingerprint instead to make the grouping
      // more granular and valuable. The fingerprint will group all
      // POST Log errors with the same URL, regardless of the stacktrace.
      event.fingerprint = [
        'http',
        'postLogError',
        pageUrl,
        isIntraDomain() ? 'internal' : 'external',
      ];
    }

    if (hint.originalException instanceof ApiError) {
      const { method, status, url } = hint.originalException;
      // Sentry usually uses the stacktrace (amongst other things) to group
      // errors together. Still, since the stacktrace is about the same for
      // all API errors, we use the fingerprint instead to make the grouping
      // more granular and valuable. The fingerprint will group all
      // API errors with the same method, status, and URL, regardless of the stacktrace.
      event.fingerprint = ['http', 'apiError', method, status.toString(), url];

      event.tags = {
        ...event.tags,

        // Add tags to make it easier to search HTTP errors.
        // With this we can search for all HTTP errors with a specific status code.
        httpMethod: method,
        httpStatusCode: status,
      };
    }

    if (hint.originalException instanceof UnknownApiError) {
      const { url } = hint.originalException;
      // Sentry usually uses the stacktrace (amongst other things) to group
      // errors together. Still, since the stacktrace is about the same for
      // all Unknown API errors, we use the fingerprint instead to make the grouping
      // more granular and valuable. The fingerprint will group all
      // Unknown API errors with the same URL, regardless of the stacktrace.
      event.fingerprint = ['http', 'unknownApiError', url];
    }

    if (hint.originalException instanceof AuthorizationTokenError) {
      // Sentry usually uses the stacktrace (amongst other things) to group
      // errors together. We want to group error of this type together regardless
      // of stacktrace. Thus use the fingerprint instead to make the grouping
      // more granular and valuable. The fingerprint will group all of
      // this type of errors together.
      event.fingerprint = ['http', 'auth', 'authorizationTokenError'];
    }

    return {
      ...event,

      // Tags are key/value string pairs that are both indexed and searchable in Sentry.
      tags: {
        ...event.tags,

        // Add the session ID to the event.
        connectFrontendSessionId: getSessionId(),

        // Wether the user is on the internal or external domain.
        isIntraDomain: isIntraDomain() ? 'yes' : 'no',
      },
    };
  });
};
