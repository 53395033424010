import { format } from 'date-fns';

import { useFms } from '@/hooks/useFms';
import { useUserDateFnsLocale, useUserDateFormat } from '@/i18n/hooks';
import { TableColumn } from '@/types/tables';

import { AssetAllocationViewMode } from '../../types/AssetAllocationViewMode';
import { AssetAllocationRow } from '../types/AssetAllocationRow';

export const useAssetAllocationColumns = (
  date: Date,
  viewMode: AssetAllocationViewMode,
): TableColumn<AssetAllocationRow>[] => {
  const texts = useFms();

  const dateFnsLocale = useUserDateFnsLocale();
  const dateFormat = useUserDateFormat('long');

  return [
    {
      name: `${
        texts.widgets.assetAllocation.holdingsTable.columns.investment
      } ${format(date, dateFormat, {
        locale: dateFnsLocale,
      })}`,
      key: 'name',
      dataType: 'text',
    },

    ...(viewMode === AssetAllocationViewMode.Holdings ||
    viewMode === AssetAllocationViewMode.All
      ? [
          {
            name: texts.widgets.assetAllocation.holdingsTable.columns
              .marketValue,
            key: 'marketValue',
            dataType: 'text',
          },
          {
            name: texts.widgets.assetAllocation.holdingsTable.columns
              .yearToDate,
            key: 'yearToDate',
            dataType: 'text',
          },
          {
            name: texts.widgets.assetAllocation.holdingsTable.columns.weight,
            key: 'weight',
            dataType: 'text',
          },
        ]
      : []),

    ...(viewMode === AssetAllocationViewMode.Performance ||
    viewMode === AssetAllocationViewMode.All
      ? [
          {
            // The number 1 is the reference for the table footnotes, which will
            // be made into superscript by the css style.
            name: `${texts.widgets.assetAllocation.table.columns.monthToDate}1`,
            key: 'monthToDatePercent',
            dataType: 'text',
          },
          {
            name: `${texts.widgets.assetAllocation.table.columns.monthToDateBenchmark}`,
            key: 'monthToDateBenchmark',
            dataType: 'text',
          },
          {
            // The number 2 is the reference for the table footnotes, which will
            // be made into superscript by the css style.
            name: `${texts.widgets.assetAllocation.table.columns.yearToDate}2`,
            key: 'yearToDatePercent',
            dataType: 'text',
          },
          {
            name: `${texts.widgets.assetAllocation.table.columns.yearToDateBenchmark}`,
            key: 'yearToDateBenchmark',
            dataType: 'text',
          },
        ]
      : []),
  ] as TableColumn<AssetAllocationRow>[];
};
