import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { FundHoldingsDto } from './dtos';
import { Holdings } from './models/Holdings';
import { transformFundHoldingsResponse } from './utils/transformFundHoldingsResponse';

export const fundHoldingsApi = createApi({
  reducerPath: 'fundHoldingsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fetchFundHoldings: builder.query<Holdings, void>({
      query: () => {
        return {
          url: `${window.config.connectBaseApiUrl}/api/holdings`,
        };
      },
      transformResponse: (dto: Array<FundHoldingsDto>) => {
        return transformFundHoldingsResponse(dto);
      },
    }),
  }),
});

export const { useFetchFundHoldingsQuery } = fundHoldingsApi;
