import React, { FC, useMemo } from 'react';

import { QueryState } from '@/api/components/QueryState';
import { NewsArticle } from '@/api/newsArticles/models/newsArticlesModels';
import { useFetchNewsArticlesQuery } from '@/api/newsArticles/newsArticlesApi';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';
import { isNotReady } from '@/api/utils/isNotReady';
import { Language } from '@/i18n/Language';

import { Column, Row } from '../Grid';
import { WithGap } from '../WithGap';
import { NewsArticleCard } from './NewsArticleCard';
import { NewsArticleCardSmall } from './NewsArticleCardSmall';

interface Props {
  limit?: number;
}

export const NewsArticles: FC<Props> = ({ limit }) => {
  const { language } = useUserSettings();

  const newsArticlesQuery = useFetchNewsArticlesQuery(language);

  const articlesToDisplay = useMemo<Array<NewsArticle>>(() => {
    if (!newsArticlesQuery.data) {
      return [];
    }

    if (limit) {
      return newsArticlesQuery.data.slice(0, limit);
    }

    return newsArticlesQuery.data;
  }, [newsArticlesQuery.data, limit]);

  return (
    <>
      {isNotReady(newsArticlesQuery) ? (
        <QueryState query={newsArticlesQuery} />
      ) : (
        <>
          {
            // The Swedish news articles have multi-line headers and descriptions.
            // Therefore, we use a different (vertical) layout for these articles.
            language === Language.Swedish ? (
              <WithGap id="news-articles-grid">
                {articlesToDisplay.map((newsArticle) => (
                  <NewsArticleCard
                    key={newsArticle.header}
                    newsArticle={newsArticle}
                  />
                ))}
              </WithGap>
            ) : (
              // The Norwegian and English news articles have shorter headers and descriptions.
              // Therefore, we use a different (grid) layout for these articles.
              <Row id="news-articles-grid">
                {articlesToDisplay.map((newsArticle) => (
                  <Column $span={4} key={newsArticle.link}>
                    <NewsArticleCardSmall
                      key={newsArticle.header}
                      newsArticle={newsArticle}
                    />
                  </Column>
                ))}
              </Row>
            )
          }
        </>
      )}
    </>
  );
};
