import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useMultiplePortfolioPerformanceQuery } from '@/api/performanceData/multiPortfolioReturnsApi';
import { formatDateArgument } from '@/api/performanceData/performanceDataApi';
import { isNotReady } from '@/api/utils/isNotReady';
import { FixedHeight } from '@/components/FixedHeight';
import { PortfolioReturnsGraph } from '@/components/PortfolioReturnsGraph/PortfolioReturnsGraph';
import { H1 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { CollapsibleFilterContainer } from '../filters/CollapsibleFilterContainer';
import { DateFilter } from '../filters/DateFilter';
import { useDateFilterText } from '../filters/hooks/useDateFilterText';
import { usePeriodFilterText } from '../filters/hooks/usePeriodFilterText';
import { useUnitFilterText } from '../filters/hooks/useUnitFilterText';
import { PeriodFilter } from '../filters/PeriodFilter';
import { UnitFilter } from '../filters/UnitFilter';
import { getDateRange } from '../filters/utils/getDateRange';
import { usePresentationContext } from '../PresentationContext';

export const PortfolioPerformanceScreen: React.FC = () => {
  const texts = useFms();

  const { globalFilter } = usePresentationContext();

  const dates = getDateRange(globalFilter.period, globalFilter.date);

  const multiplePortfolioReturnsQuery = useMultiplePortfolioPerformanceQuery({
    portfolioGroups: globalFilter.portfolioGroups.map(
      (portfolioGroup) => portfolioGroup.id,
    ),
    from: formatDateArgument(dates.start),
    to: formatDateArgument(dates.end),
  });

  const dateFilterText = useDateFilterText();
  const periodFilterText = usePeriodFilterText();
  const unitFilterText = useUnitFilterText();

  const selectedFilterTexts = [
    periodFilterText,
    dateFilterText,
    unitFilterText,
  ];

  return (
    <A4WidthForPrint>
      <WithGap data-test="portfolio-performance-screen">
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.portfolioPerformance.title}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>
        <CollapsibleFilterContainer selectedFilterTexts={selectedFilterTexts}>
          <WithGap>
            <WithGap direction="row">
              <PeriodFilter />
              <DateFilter />
              <UnitFilter />
            </WithGap>
          </WithGap>
        </CollapsibleFilterContainer>

        <FixedHeight height={500}>
          {isNotReady(multiplePortfolioReturnsQuery) ? (
            <QueryState
              query={multiplePortfolioReturnsQuery}
              customTexts={{
                noData: {
                  title: texts.pages.presentation.filters.noData.title,
                  description: texts.pages.presentation.filters.noData.message,
                },
              }}
            />
          ) : (
            <PortfolioReturnsGraph
              data={multiplePortfolioReturnsQuery.data.returns}
              unit={globalFilter.unit}
              height={500}
            />
          )}
        </FixedHeight>
      </WithGap>
    </A4WidthForPrint>
  );
};
