import { SystemIcon } from '@storeblocks/icon';
import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';

import { menuItemStyle } from '../styles/menuItemStyle';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  id: string;
  title: string;
  active?: boolean;
  onClick: () => void;
}

export const ParentMenuItem: FC<Props> = ({
  id,
  title,
  active,
  onClick,
  ...rest
}) => {
  return (
    <li>
      <StyledButton
        onClick={onClick}
        className={active && 'active'}
        data-test={`menu-item-${id}`}
        aria-expanded={active}
        {...rest}
      >
        <TextShort>{title}</TextShort>
        <StyledSystemIcon name={active ? 'minus' : 'add'} aria-hidden="true" />
      </StyledButton>
    </li>
  );
};

const StyledSystemIcon = styled(SystemIcon)`
  font-size: 18px;
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  width: 100%;
  ${menuItemStyle}
`;
