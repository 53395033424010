import { MediumCard } from '@storeblocks/card';
import React, { FC } from 'react';

import { NewsLink } from './types';

interface Props {
  newsLink: NewsLink;
  trackId: string;
}

export const NewsLinkCard: FC<Props> = ({ newsLink, trackId }) => {
  return (
    <MediumCard
      title={newsLink.title}
      icon={newsLink.icon}
      id={newsLink.link}
      href={newsLink.link}
      as="a"
      target="_blank"
      data-trackid={trackId}
    />
  );
};
