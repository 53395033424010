import React from 'react';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useFms } from '@/hooks/useFms';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { nameof } from '@/util/nameof';

import { SubscriptionOrderFormValues } from '../../../types/SubscriptionOrderFormValues';
import { useArrayFieldByIsin } from '../hooks/useArrayFieldByIsin';
import { FundInput } from './FundInput';

interface Props {
  fund: SubscriptionFundState;
}

export const SubscriptionInput: React.FC<Props> = ({ fund }) => {
  const texts = useFms();

  const fundFieldName = nameof<SubscriptionOrderFormValues>('toFunds');

  const [valueField, valueMeta, valueHelpers] = useArrayFieldByIsin<number>(
    fundFieldName,
    fund.isin,
    nameof<SubscriptionFundState>('value'),
  );

  const handleOnChange = (value: number): void => {
    valueHelpers.setValue(value);
  };

  const handleOnBur = (event: React.FocusEvent<HTMLDivElement>): void => {
    valueField.onBlur(event);
  };

  const id = `input-subscription-fund-${fund.isin}`;

  switch (fund.valueType) {
    case OrderValueType.Amount:
      return (
        <FundInput
          id={id}
          name={valueField.name}
          value={valueMeta.value}
          suffix={fund.currency}
          disabled={fund.isClosed}
          error={valueMeta.touched && !!valueMeta.error}
          onChange={handleOnChange}
          onBlur={handleOnBur}
        />
      );
    case OrderValueType.Percent:
      return (
        <FundInput
          id={id}
          name={valueField.name}
          value={valueField.value}
          decimalScale={2}
          suffix="%"
          disabled={fund.isClosed}
          error={valueMeta.touched && !!valueMeta.error}
          onChange={handleOnChange}
          onBlur={handleOnBur}
        />
      );
    case OrderValueType.Units:
      return (
        <FundInput
          id={id}
          name={valueField.name}
          value={valueField.value}
          decimalScale={4}
          suffix={texts.orders.form.valueType.units.toLowerCase()}
          disabled={fund.isClosed}
          error={valueMeta.touched && !!valueMeta.error}
          onChange={handleOnChange}
          onBlur={handleOnBur}
        />
      );
    default:
      throw new Error(`Invalid order value type [${fund.valueType}]`);
  }
};
