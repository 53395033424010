import Accordion, { Row as AccordionRow } from '@storeblocks/accordion';
import Button from '@storeblocks/button';
import Surface from '@storeblocks/surface';
import sortBy from 'lodash/sortBy';
import React from 'react';
import styled from 'styled-components';

import { Fund } from '@/api/funds/models';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { LinearGauge } from '@/components/Charts';
import { Documents } from '@/components/FundDocuments/Documents';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { FundPerformance } from './FundPerformance';
import { toUrlFriendlySlug } from './utils/toUrlFriendlySlug';

interface Props {
  funds: Fund[];
  'data-test': string;

  /**
   * Wether or not the organization has holdings in the specified funds.
   */
  hasHoldingsInFunds: boolean;
}

export const FundListAccordion: React.FC<Props> = ({
  funds,
  'data-test': dataTest,
  hasHoldingsInFunds,
}) => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();
  const sortedFunds = sortBy(funds, (fund) => fund.name.toLowerCase());
  const getTrackId = useGetTrackId();

  return (
    <Accordion data-test={dataTest}>
      {sortedFunds.map((fund) => (
        <AccordionRow
          key={fund.isin}
          id={fund.isin}
          header={fund.name}
          data-test={`fund-information-${fund.isin}`}
          headerProps={{
            'data-trackid': `${getTrackId(
              'toggle-fund-information',
              TrackingElement.Button,
            )}`,
            'data-trackid-isin': fund.isin,
            'data-trackid-fund-name': fund.name,
            'data-trackid-has-holdings-in-fund': hasHoldingsInFunds.toString(),
          }}
        >
          <Row>
            {/* Left column. */}
            <Column $span={6}>
              <DocumentsWrapper variant="alt">
                <Documents isin={fund.isin} />
              </DocumentsWrapper>
            </Column>

            {/* Right column. */}
            <Column $span={6}>
              <WithGap>
                <FundPerformance
                  fundStartDate={fund.startDate}
                  performanceNumbers={fund.performanceNumbers}
                />

                <StyledSurface variant="border-alt">
                  <div>
                    <TextShortBold>
                      {texts.pages.fundList.sustainability}
                    </TextShortBold>
                    <LinearGauge
                      value={fund.sustainabilityScore}
                      max={10}
                      color={{
                        color: 'var(--color-green-50)',
                        background: 'var(--color-green-10)',
                      }}
                      decimals={1}
                      minimize
                    />
                  </div>

                  <Button
                    as={InternalLink}
                    to={`/${organizationCmId}/sustainability/esg/fund/${
                      fund.isin
                    }/${toUrlFriendlySlug(fund.name)}`}
                    iconRight="arrow-right"
                    variant="text"
                    data-trackid={`${getTrackId(
                      'go-to-fund-esg-page',
                      TrackingElement.Link,
                    )}`}
                    data-trackid-isin={fund.isin}
                    data-trackid-fund-name={fund.name}
                    data-trackid-source="fund-list-page"
                  >
                    {texts.pages.fundEsg.linkToPage.title}
                  </Button>
                </StyledSurface>
              </WithGap>
            </Column>
          </Row>
        </AccordionRow>
      ))}
    </Accordion>
  );
};

const DocumentsWrapper = styled(Surface)`
  /* Make the background fill its parent container. */
  height: 100%;
  padding: 16px;
`;

const StyledSurface = styled(Surface)`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
`;
