import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { OrganizationSelector } from '@/components/OrganizationSelector';
import { useFms } from '@/hooks/useFms';

import { Dropdown } from './Dropdown';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onToggle: (isOpen: boolean) => void;
}

export const ChangeOrganizationDropdown: FC<Props> = ({
  isOpen,
  setIsOpen,
  onToggle,
}) => {
  const texts = useFms();

  const toggleIsOpen = (): void => {
    setIsOpen(!isOpen);
  };

  const onClickOutside = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    onToggle(isOpen);
  }, [isOpen]);

  return (
    <Dropdown
      title={texts.application.changeCompany}
      onClickOutside={onClickOutside}
      toggleIsOpen={toggleIsOpen}
      isOpen={isOpen}
      testId="change-organization"
    >
      <Container>
        <OrganizationSelector
          initialCollapseSize={5}
          excludeCurrentOrganization
          smallSearchField
        />
      </Container>
    </Dropdown>
  );
};

const Container = styled.div`
  background-color: var(--color-surface);
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
