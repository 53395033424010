import Checkbox from '@storeblocks/checkbox';
import React from 'react';
import { styled } from 'styled-components';

import { useReportsContext } from '@/pages/Reports/context/ReportsProvider';
import { Report } from '@/pages/Reports/types/ReportModel';

interface Props {
  report: Report;
}

export const FileDownloadCheckboxColumn: React.FC<Props> = ({ report }) => {
  const context = useReportsContext();

  const handleChecked = (): void => {
    if (context.selectedReports.has(report.id)) {
      context.delete(report.id);
    } else {
      context.set(report);
    }
  };

  const checkboxOption = {
    id: `file-${report.id}`,
    value: report.id,
    label: '',
  };

  return (
    <Align>
      <Checkbox
        id={`file-download-checkbox-${report.id}`}
        name={`file-download-checkbox-${report.id}`}
        value={context.selectedReports.has(report.id)}
        options={[checkboxOption]}
        onChange={() => handleChecked()}
      />
    </Align>
  );
};

const Align = styled.div`
  display: flex;
  justify-content: center;

  /* Make the center of the checkbox line up with the center of the report date and filename. */
  padding-top: 4px;
`;
