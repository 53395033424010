import { AxiosInstance } from 'axios';

import { getSessionId } from '@/logging/sessionId';
import { findOrganizationCmIdFromUrl } from '@/util/findOrganizationCmIdFromUrl';

export const addXRequestIdInterceptor = (
  axiosInstance: AxiosInstance,
): void => {
  axiosInstance.interceptors.request.use((config) => {
    if (!config.url) {
      throw new Error('URL is missing from the axios request.');
    }

    try {
      const isUrlRelative = config.url.startsWith('/');

      const pathname = isUrlRelative
        ? config.url
        : new URL(config.url).pathname;

      const httpMethod = config.method?.toLowerCase();
      const sessionId = getSessionId();
      const organizationCmId = findOrganizationCmIdFromUrl();

      const orgCmidPart = organizationCmId
        ? `[orgCmid:${organizationCmId}]`
        : '';

      const requestId = `${httpMethod}:${pathname}${orgCmidPart}[sessionId:${sessionId}]`;

      config.headers.set('X-Request-ID', requestId);

      return config;
    } catch (error) {
      const { message } = error as Error;

      throw new Error(
        `Failed to add X-Request-ID header to URL [${config.url}].\n Error message: [${message}]`,
      );
    }
  });
};
