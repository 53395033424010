import Button from '@storeblocks/button';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useDocumentCategoriesQuery } from '@/api/documentArchive/documentArchiveApi';
import { DocumentCategory } from '@/api/documentArchive/models/DocumentCategory';
import { Show } from '@/components/Show';
import { DateSearch } from '@/components/Toolbar/DateSearch';
import { DownloadButton } from '@/components/Toolbar/DownloadButton';
import { DownloadErrorAlert } from '@/components/Toolbar/DownloadErrorAlert';
import { useSearchParams } from '@/components/Toolbar/hooks/useSearchParams';
import { SelectSearch } from '@/components/Toolbar/SelectSearch';
import { TextSearch } from '@/components/Toolbar/TextSearch';
import { useFms } from '@/hooks/useFms';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import {
  documentsDownloadUrl,
  downloadFiles,
} from '@/pages/Archives/commonLogic';
import { media } from '@/styles/variables';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { useDocumentsArchiveContext } from './DocumentsArchiveContext';
import { convertDocumentCategoriesToOptions } from './documentsArchiveLogic';

interface SearchParams {
  query?: string;
  date?: string;
  category?: string;
}

export const Toolbar: FC = () => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const { setDocumentFilesParams: setDocumentFileArgs } =
    useDocumentsArchiveContext();

  const { checkedDocumentFiles, setShowUploadFileModal } =
    useDocumentsArchiveContext();

  const documentCategoriesQuery = useDocumentCategoriesQuery();

  const documentCategoryOptions = useMemo(
    () =>
      convertDocumentCategoriesToOptions(documentCategoriesQuery.data, texts, {
        includeAllCategoriesCategory: true,
      }),
    [documentCategoriesQuery.data, texts],
  );

  const onSearchParamsChange = useCallback(
    (searchParams: SearchParams) => {
      setDocumentFileArgs({
        category:
          searchParams.category === 'ALL_CATEGORIES'
            ? undefined
            : convertStringToEnum(searchParams.category, DocumentCategory),
        startDate: searchParams.date,
        endDate: searchParams.date,
        name: searchParams.query,
      });
    },
    [setDocumentFileArgs],
  );

  const [handleDownloadClick, , downloadError] = useHandleAsyncOperation(
    async () => {
      await downloadFiles(documentsDownloadUrl(), [...checkedDocumentFiles]);
    },
  );
  const searchParams = useSearchParams<SearchParams>(onSearchParamsChange);

  const updateTextSearch = (query: string): void => {
    searchParams.update((state) => {
      state.query = query;
    });
  };

  const updateCategorySearch = (category: string): void => {
    searchParams.update((state) => {
      state.category = category;
    });
  };

  const updateDateSearch = (date: Date | undefined): void => {
    const dateString = date ? format(date, 'yyyy-MM-dd') : '';

    searchParams.update((state) => {
      state.date = dateString;
    });
  };

  return (
    <>
      <Wrapper>
        <InputWrapper>
          <TextSearch
            onChange={updateTextSearch}
            label={texts.documentsArchivePage.searchForDocuments}
          />
          <SelectSearch
            id="search-by-category"
            onChange={updateCategorySearch}
            label={texts.category.text}
            options={documentCategoryOptions}
          />
          <DateSearch
            onChange={updateDateSearch}
            label={texts.documentsArchivePage.datePublished}
          />
        </InputWrapper>

        <ButtonWrapper>
          <Button
            onClick={() => setShowUploadFileModal(true)}
            id="upload-file-button"
            data-trackid={getTrackId(
              'open-upload-file-modal',
              TrackingElement.Button,
            )}
          >
            {texts.documentsArchivePage.upload}
          </Button>

          <DownloadButton
            onClick={handleDownloadClick}
            numberOfFilesToDownload={checkedDocumentFiles.size}
          />
        </ButtonWrapper>
      </Wrapper>

      <Show when={downloadError}>
        <DownloadErrorAlert />
      </Show>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;

  @media ${media.maxContentWidthAndBelow} {
    display: block;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  height: 136px;

  & > div + div {
    margin-left: 24px;
  }

  @media ${media.tabletAndBelow} {
    flex-flow: column;
    align-items: flex-start;

    & > div + div {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 40px;

  & > button + button {
    margin-left: 16px;
  }

  @media ${media.maxContentWidthAndBelow} {
    margin-top: 32px;
  }

  @media ${media.tabletAndBelow} {
    display: block;
  }
`;
