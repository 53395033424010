import isEmpty from 'lodash/isEmpty';

import { Fund } from '@/api/funds/models';
import { Brand } from '@/types/Brand';

export const filterFundsByBrands = (
  brands: Brand[],
  funds: Fund[],
): string[] => {
  if (isEmpty(brands)) {
    return funds.map((fund) => fund.isin);
  }

  return funds
    .filter((fund) => brands.includes(fund.brand))
    .map((fund) => fund.isin);
};
