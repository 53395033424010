import { useMemo } from 'react';

import { AssetPerformanceData } from '@/api/performanceData/models';
import { ColumnChartData } from '@/components/Charts';
import { useFms } from '@/hooks/useFms';
import { Unit } from '@/types/Unit';

import { AssetReturnPeriod } from '../types';
import { getVisibleAssetNodes } from '../utils';

export const useAssetReturnChartData = (
  assets: Array<AssetPerformanceData>,
  period: AssetReturnPeriod,
  unit: Unit,
): Array<ColumnChartData> => {
  const texts = useFms();

  return useMemo<Array<ColumnChartData>>(() => {
    const visibleNodes = getVisibleAssetNodes(assets, texts);

    return [
      {
        title: texts.charts.assetReturn.returnLabel,
        dataPoints: visibleNodes.map((node) => ({
          name: node.name,
          y: node.data[unit][period].return,
        })),
      },
      {
        title: texts.charts.assetReturn.benchmarkLabel,
        dataPoints: visibleNodes.map((node) => ({
          name: node.name,
          y: node.data[unit][period].benchmark,
        })),
      },
      {
        title: texts.charts.assetReturn.excessReturnLabel,
        dataPoints: visibleNodes.map((node) => {
          const nodeData = node.data[unit][period];

          return {
            name: node.name,
            y: nodeData.benchmark !== 0 ? nodeData.excessReturn : 0,
          };
        }),
      },
    ];
  }, [assets, period, unit, texts]);
};
