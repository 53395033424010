import groupBy from 'lodash/groupBy';

import { FundList } from '@/api/funds/models';
import { Order } from '@/api/order/models/Order';
import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderPlacementType } from '@/api/order/models/OrderPlacementType';
import { OrderType } from '@/api/order/models/OrderType';
import { FundOrder } from '@/pages/Order/types/FundOrder';

import {
  RedemptionPayment,
  SubscriptionPayment,
} from '../steps/OrderDetails/types/Payment';

export const getFundOrderFromOrder = (
  order: Order,
  fundList: FundList,
): FundOrder => {
  const subscriptionOrderPlacements = order.orderPlacements.filter(
    (orderPlacement) => orderPlacement.placementType === OrderPlacementType.Buy,
  );

  const subscriptionPayments = createSubscriptionPayments(
    subscriptionOrderPlacements,
    fundList,
  );

  const redemptionOrderPlacements = order.orderPlacements.filter(
    (orderPlacement) =>
      orderPlacement.placementType === OrderPlacementType.Sell,
  );

  const redemptionPayments = createRedemptionPayments(
    redemptionOrderPlacements,
  );

  const payments: Array<SubscriptionPayment | RedemptionPayment> = [
    ...subscriptionPayments,
    ...redemptionPayments,
  ];

  const transferToPortfolioNumber =
    // Currently the backend does not support transfers. Thus the destination
    // portfolio will always be the same as the initial portfolio.
    order.orderType === OrderType.Switch ? order.portfolioNumber : undefined;

  return {
    userId: order.userId,
    transferToPortfolioNumber,
    portfolioNumber: order.portfolioNumber,
    orderPlacements: order.orderPlacements,
    signatories: order.signatories,
    signingProvider: order.signingProvider,
    payments,
  };
};

const createSubscriptionPayments = (
  orderPlacements: OrderPlacement[],
  fundList: FundList,
): SubscriptionPayment[] => {
  const placementByCurrency = groupBy(
    orderPlacements,
    (placement) => `${placement.currency}-${placement.externalAccountNumber}`,
  );

  return Object.entries(placementByCurrency).map<SubscriptionPayment>(
    ([, orderPlacements]) => {
      // The payment information is the same for all placements.
      const orderPlacement = orderPlacements[0];
      const fund = fundList.byIsin[orderPlacement.isin];

      return {
        type: 'SUBSCRIPTION_PAYMENT',

        paymentPlacement: orderPlacement.payment,

        portfolioBankAccountNumber: orderPlacement.externalAccountNumber,

        forIsins: orderPlacements.map((placement) => placement.isin),

        fundPaymentInformation: {
          currency: fund.paymentInformation.currency,
          bankAccountNumber: fund.paymentInformation.bankAccountNumber,
          accountHolder: fund.paymentInformation.accountHolder,
          iban: fund.paymentInformation.iban,
          swift: fund.paymentInformation.swift,
        },
      };
    },
  );
};

const createRedemptionPayments = (
  orderPlacements: OrderPlacement[],
): RedemptionPayment[] => {
  const placementByCurrency = groupBy(
    orderPlacements,
    (placement) => placement.currency,
  );

  return Object.entries(placementByCurrency).map<RedemptionPayment>(
    ([, placements]) => {
      // The payment information is the same for all placements.
      const placement = placements[0];

      return {
        type: 'REDEMPTION_PAYMENT',

        paymentPlacement: placement.payment,

        bankAccountNumber:
          placement.payment === OrderPaymentType.BankAccount
            ? placement.externalAccountNumber
            : '',

        portfolioBankAccountNumber:
          placement.payment === OrderPaymentType.Portfolio
            ? placement.externalAccountNumber
            : '',

        currency: placement.currency,
      };
    },
  );
};
