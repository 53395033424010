import { OrderWithSignUrlDto } from '../dtos/OrderWithSignUrlDto';
import { OrderWithSignUrl } from '../models/OrderWithSignUrl';
import { mapOrderDtoToModel } from './mapOrderDtoToModel';

export const mapOrderWithSignUrlDtoToModel = (
  dto: OrderWithSignUrlDto,
): OrderWithSignUrl => {
  return {
    order: mapOrderDtoToModel(dto.order),
    signUrl: dto.signUrl,
  };
};
