import { SystemIcon } from '@storeblocks/icon';
import React from 'react';

import { fileExtensionToIconName } from './fileExtensionToIconName';

interface Props {
  fileExtension: string;
}

/**
 * Display a file icon for the specified file extension.
 */
export const FileTypeIcon: React.FC<Props> = ({ fileExtension }) => {
  const iconName = fileExtensionToIconName(fileExtension);

  return <SystemIcon name={iconName} />;
};
