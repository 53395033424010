import Alert from '@storeblocks/alert';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useFms } from '@/hooks/useFms';

interface Props {
  orderType: OrderType;
}

export const MultipleCurrenciesAlert: React.FC<Props> = ({ orderType }) => {
  const texts = useFms();

  const textMap = {
    [OrderType.Subscription]: {
      title: texts.orders.form.table.subscription.summary.title,
      text: texts.orders.form.table.subscription.summary.text,
    },
    [OrderType.Redemption]: {
      title: texts.orders.form.table.redemption.summary.title,
      text: texts.orders.form.table.redemption.summary.text,
    },
  };

  return (
    <Alert
      title={textMap[orderType].title}
      description={textMap[orderType].text}
      fluid
    />
  );
};
