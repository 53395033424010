import Button from '@storeblocks/button';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import React, { FC } from 'react';

import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import {
  useDeleteOrganizationMutation,
  useOrganizationsQuery,
} from '@/api/organizations/organizationsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useFms } from '@/hooks/useFms';
import { getBasename } from '@/routes/utils/getBasename';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { FMSTexts } from '@/types/fms';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

interface Props {
  organizationId: string;
  onClose: () => void;
}

export const DeleteOrganizationModal: FC<Props> = ({
  organizationId,
  onClose,
}) => {
  const texts: FMSTexts = useFms();
  const getTrackId = useGetTrackId();

  const currentOrgCmId = useOrganizationCmId();
  const { organizationName } = useOrganizationName(organizationId);

  const organizationsQuery = useOrganizationsQuery();

  const [deleteOrganization] = useDeleteOrganizationMutation();

  const handleDeleteOrg = (): void => {
    deleteOrganization(organizationId).then(async () => {
      // Redirect to home page if the current organization has been deleted.
      if (currentOrgCmId === organizationId) {
        // Using non-resolving promise to avoid code running when the browser is in the process to redirect.
        await new Promise(() => {
          const redirectUri = `${window.location.origin}${getBasename()}/`;

          window.location.replace(redirectUri);
        });
      } else {
        // Refresh the list of organisations if deleting the organization is successful.
        organizationsQuery.refetch();
      }

      onClose();
    });
  };

  return (
    <Modal trigger={undefined} open onClose={onClose}>
      <Heading>
        {`${texts.admin.delete.areYouSure} ${prettifyOrganizationName(
          organizationName,
        )}?`}
      </Heading>
      <Content data-test="delete-organization-modal">
        {texts.admin.delete.cannotBeReverted}
      </Content>
      <Footer>
        <Button
          onClick={handleDeleteOrg}
          key="delete-button"
          data-trackid={getTrackId(
            'delete:confirm-delete-organization-modal',
            TrackingElement.Button,
          )}
          mr="8px"
          data-test="delete-organization-button"
        >
          {texts.admin.organization.delete}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          key="cancel-button"
          data-trackid={getTrackId(
            'cancel:delete-organization-modal',
            TrackingElement.Button,
          )}
        >
          {texts.admin.cancel}
        </Button>
      </Footer>
    </Modal>
  );
};
