import { DashStyleValue, dateFormat, Options } from 'highcharts';

import { useUserLocale } from '@/i18n/hooks';
import { Unit } from '@/types/Unit';

import { getUnitNumberFormat } from '../../utils/getUnitNumberFormat';
import { LineChartData } from '../types/LineChartData';

export const useLineChartOptions = (
  data: LineChartData,
  unit: Unit,
  height: number | undefined,
): Options => {
  const locale = useUserLocale();

  const dashStyle: Array<DashStyleValue> = ['Solid', 'ShortDash', 'LongDash'];
  return {
    series: data.map((entry, i) => ({
      type: 'line',
      dashStyle: dashStyle[i % 3],
      lineWidth: 2,
      ...entry,
    })),
    colors: [
      'var(--color-blue-30)',
      'var(--color-green-70)',
      'var(--color-beige-70)',
      'var(--color-blue-70)',
      'var(--color-green-10)',
      'var(--color-beige-60)',
      'var(--color-blue-20)',
      'var(--color-green-50)',
      'var(--color-beige-90)',
      'var(--color-blue-50)',
      'var(--color-green-30)',
      'var(--color-beige-30)',
    ],
    xAxis: {
      type: 'datetime',
      ordinal: false,
      units: [
        ['week', [1, 2]],
        ['month', [1, 2]],
        ['year', [1, 2]],
      ],
      labels: {
        style: {
          color: 'var(--color-on-background)',
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          color: 'var(--color-on-background)',
          fontSize: '14px',
          fontWeight: '400',
        },
      },
      opposite: false,
    },
    tooltip: {
      useHTML: true,
      shared: true,
      split: false,
      // Override hardcoded styling.
      padding: 0,
      borderWidth: 2,
      backgroundColor: 'var(--color-on-background)',
      borderColor: 'var(--color-border)',
      // Highcharts formats tooltip text by using a default format for given unitNames (minute/hour/day/year/month/year).
      // This leads to tooltip having strange text, for example "Week from Mandag..." which was neither translated nor properly spelled.
      // Therefore, by defining a formatter function, we override how we format the contents of the tooltip, thus making it consistent over different unit names.
      formatter: function () {
        const formattedDate = dateFormat('%A %e. %b %Y', Number(this.x), true);

        const tooltip = this.points.reduce((tooltipContent, point) => {
          return (
            tooltipContent +
            `<div style="color: var(--color-on-primary);">${point.series.name}: <b>${getUnitNumberFormat(point.y, unit, locale)}</b></div>`
          );
        }, `<div style="color: var(--color-on-primary);">${formattedDate}</div>`);

        return tooltip;
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      enabled: true,
      useHTML: true,
      labelFormat: legendTemplate,
    },
    // Disable unused features.
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    // Override spacing to let us space the graph as we desire
    // with Styled Components.
    chart: {
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingTop: 0,
      height,
      zooming: {
        // Mouse wheel zooming is enabled in Highcharts Stock by default since v11.1
        mouseWheel: false,
      },
      numberFormatter(value) {
        return getUnitNumberFormat(value, unit, locale);
      },
    },
  };
};

const legendTemplate = `
<div class="highcharts-legend-entry">
  {name}
</div>
`;
