import styled from 'styled-components';

interface ColumnProps {
  $span: number;
}

export const Column = styled.div<ColumnProps>`
  grid-column: span ${({ $span }) => $span};

  @media (max-width: 768px) {
    grid-column: span 12;
  }
`;
