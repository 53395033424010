import Breadcrumbs from '@storeblocks/breadcrumbs';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

import { CopyBearerToken } from './CopyBearerToken';
import { ThrowError } from './ThrowError';

export const DebugPage: React.FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();
  const rootPath = organizationCmId ? `/${organizationCmId}` : '/';

  return (
    <CenteredPageContent data-test="debug-page">
      <AnnouncementWarning />

      <Breadcrumbs>
        <InternalLink to={rootPath}>{texts.menu.links.home}</InternalLink>
        <InternalLink to={`${rootPath}/debug`}>Debug</InternalLink>
      </Breadcrumbs>

      <PageHeading header="Debug" />

      <CopyBearerToken />

      <ThrowError />
    </CenteredPageContent>
  );
};
