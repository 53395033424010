import React, { FC } from 'react';

import { AssetPerformanceData } from '@/api/performanceData/models';
import { ColumnChart } from '@/components/Charts';
import { Unit } from '@/types/Unit';

import { useAssetReturnChartData } from './hooks';
import { AssetReturnPeriod } from './types';

interface Props {
  assets: AssetPerformanceData[];
  period: AssetReturnPeriod;
  unit: Unit;
}

export const AssetReturnChart: FC<Props> = ({ assets, period, unit }) => {
  const chartData = useAssetReturnChartData(assets, period, unit);

  return <ColumnChart data={chartData} unit={unit} showLegend />;
};
