import * as Yup from 'yup';

interface ErrorMessages {
  signerRequired: string;
}

/**
 * Validate that the signer is set.
 */
export const signerFieldValidationSchema = (
  errorMessages: ErrorMessages,
): Yup.StringSchema =>
  Yup.string().nullable().required(errorMessages.signerRequired);
