import { useFormikContext } from 'formik';
import { uniq } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { RedemptionPayment } from '@/pages/Order/steps/OrderDetails/types/Payment';
import { RedemptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/RedemptionOrderFormValues';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';

/**
 * Add payment options to the redemption form based on the funds that
 * has an amount.
 */
export const useRedemptionPaymentOptions = (): void => {
  const formContext = useFormikContext<RedemptionOrderFormValues>();

  const portfolios = useFundPortfolios(OrderType.Redemption);

  const hasPortfolioBankAccounts =
    getPortfolioBankAccounts(
      formContext.values.fromPortfolioShortName,
      portfolios,
    ).length > 0;

  const defaultPlacement = hasPortfolioBankAccounts
    ? // Let the user choose where the withdrawal should be placed.
      // To the portfolio bank account (porteføljekonto) or to an
      // bank account of their specification.
      OrderPaymentType.None
    : // The user has only the option to specify their own bank account.
      OrderPaymentType.BankAccount;

  const fundsWithValue = formContext.values.fromFunds.filter(
    (fund) => fund.value > 0,
  );

  const currencies = fundsWithValue.map((fund) => fund.currency);
  const uniqueCurrencies = uniq(currencies);

  useDeepCompareEffect(() => {
    // There should be a payment option for each currency.
    const paymentOptions: RedemptionPayment[] =
      uniqueCurrencies.map<RedemptionPayment>((currency) => {
        // Payment is based on the currency of the selected fund.
        const existingPayment = formContext.values.payments.find(
          (payment) => payment.currency === currency,
        );

        // Use the existing payment option if it exists to preserve its state.
        if (existingPayment) {
          return existingPayment;
        }

        return {
          type: 'REDEMPTION_PAYMENT',

          currency,
          bankAccountNumber: '',
          portfolioBankAccountNumber: '',
          paymentPlacement: defaultPlacement,
        };
      });

    formContext.setFieldValue('payments', paymentOptions);
  }, [uniqueCurrencies]);
};
