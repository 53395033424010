import React from 'react';

import { H1 } from '@/components/Typography';
import { Widget } from '@/components/Widget';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { EsgExclusions } from '@/pages/Esg/widgets/EsgExclusions';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';

export const EsgExcludedCompanies: React.FC = () => {
  const texts = useFms();

  return (
    <A4WidthForPrint>
      <WithGap data-test="esg-excluded-companies-screen">
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.esg.excludedCompanies}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>

        <Widget data-test="esg-exclusions-widget">
          <EsgExclusions />
        </Widget>
      </WithGap>
    </A4WidthForPrint>
  );
};
