import {
  PendingOrderDto,
  PendingOrdersReply,
} from '../dtos/PendingOrdersReply';
import { PendingOrder } from '../models/PendingOrder';
import { mapOrderTypeDtoToEnum } from './mapOrderTypeDtoToEnum';

export const transformPendingOrdersReply = (
  reply: PendingOrdersReply,
): Array<PendingOrder> => {
  return reply.pendingOrders.map(mapPendingOrderDtoToModel);
};

const mapPendingOrderDtoToModel = (dto: PendingOrderDto): PendingOrder => {
  return {
    orderId: dto.order.id,
    orderType: mapOrderTypeDtoToEnum(dto.order.orderType),
    signUrl: dto.signUrl,
    createdBy: dto.order.userName,
    createdDate: dto.order.created,
    portfolioNumber: dto.order.portfolioNumber,
  };
};
