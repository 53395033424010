import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { appConfig } from '@/constants/config';
import { useClickOutsideRef } from '@/hooks/useClickOutsideRef';
import { timeout } from '@/util/timeout';

import { NavbarButton } from '../NavbarButton';

interface Props extends PropsWithChildren {
  title: string;
  testId?: string;
  isOpen: boolean;
  onClickOutside: () => void;
  toggleIsOpen: () => void;
}

/** Animation duration in seconds. */
const animationDuration = 0.3;

export const Dropdown: FC<Props> = ({
  title,
  testId,
  isOpen,
  onClickOutside,
  toggleIsOpen,
  children,
}) => {
  const clickOutsideRef = useClickOutsideRef<HTMLDivElement>(onClickOutside);

  /** Ensure that the open animation has finished before setting the focus trap. */
  const checkCanFocusTrap = async (): Promise<void> => {
    return await timeout(animationDuration * 1000);
  };

  return (
    <>
      <NavbarButton
        onClick={toggleIsOpen}
        title={title}
        active={isOpen}
        dataTest={`${testId}-button`}
        aria-expanded={isOpen}
        aria-haspopup="true"
      />
      <AnimatePresence>
        {isOpen && (
          <FocusTrap
            active={isOpen}
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              // We don't want the trap to deactivate when the sub menu is closed
              // with escape. The trap is deactivated when the menu is closed.
              escapeDeactivates: false,
              delayInitialFocus: true,
              preventScroll: true,
              checkCanFocusTrap,
            }}
          >
            <Container data-test={`${testId}-dropdown`}>
              <Card
                key="dropdown"
                transition={{ ease: 'easeInOut', duration: animationDuration }}
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                ref={clickOutsideRef}
              >
                {children}
              </Card>
            </Container>
          </FocusTrap>
        )}
      </AnimatePresence>
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 72px;
  left: 0;
  z-index: 3;
  width: 100%;
  pointer-events: none;
`;

const Card = styled(motion.div)`
  pointer-events: all;
  max-width: ${appConfig.maxPageWidth};
  margin: 16px auto;
  background-color: var(--color-background);
  width: calc(100% - 32px);
  box-sizing: border-box;
  overflow: hidden;
`;
