import { useState } from 'react';

import { usePortfoliosQuery } from '@/api/portfolios';
import { Unit } from '@/types/Unit';

import { FilterPeriod } from '../filters/types/FilterPeriod';
import { GlobalFilter } from '../types/GlobalFilter';

const dateOfToday = new Date();

export const useGlobalFilter = (): [
  globalFilter: GlobalFilter,
  setGlobalFilter: (globalFilter: GlobalFilter) => void,
] => {
  const portfoliosQuery = usePortfoliosQuery();

  const [globalFilters, setGlobalFilters] = useState<GlobalFilter>({
    portfolioGroups: portfoliosQuery.data.portfolioGroups,
    date: dateOfToday,
    period: FilterPeriod.YTD,
    unit: Unit.Percent,
  });

  return [globalFilters, setGlobalFilters];
};
