import round from 'lodash/round';

import { Locale } from '@/i18n/locale';
import { Unit } from '@/types/Unit';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

export const getUnitNumberFormat = (
  value: number,
  unit: Unit,
  locale: Locale,
): string => {
  switch (unit) {
    case Unit.Amount:
      return formatNumber(roundHalfEven(value, 0), locale);
    case Unit.Percent:
      return formatNumber(round(value, 1), locale, '%');
    default:
      throw new Error(`Invalid unit [${unit}]`);
  }
};
