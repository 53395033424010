export const groupByBoolean = <T>(
  list: T[],
  predicate: (item: T) => boolean,
): {
  truthy: T[];
  falsy: T[];
} => {
  const truthy = list.filter(predicate);
  const falsy = list.filter((item) => !predicate(item));

  return {
    truthy,
    falsy,
  };
};
