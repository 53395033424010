import Alert from '@storeblocks/alert';
import React from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';

/**
 * Display a message notifying the user that the
 * files could not be downloaded.
 */
export const DownloadErrorAlert: React.FC = () => {
  const texts = useFms();

  return (
    <StyledAlert
      variant="error"
      title={texts.reportsArchivePage.cannotDownloadFiles}
    />
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 24px;
  margin-bottom: 24px;
`;
