import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { Paragraph } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

import { EsgSolutionsChart } from './EsgSolutionsChart';

interface Props {
  greenBond: EsgCategory;
  solutionCompanies: EsgCategory;
  solutionCompaniesEqualOpportunities: EsgCategory | undefined;
  solutionCompaniesRenewableEnergy: EsgCategory | undefined;
  solutionCompaniesCircularEconomy: EsgCategory | undefined;
  solutionCompaniesSmartCities: EsgCategory | undefined;
  positionLegendBottom?: boolean;
}

export const EsgSolutions: React.FC<Props> = ({
  greenBond,
  solutionCompanies,
  solutionCompaniesEqualOpportunities,
  solutionCompaniesRenewableEnergy,
  solutionCompaniesCircularEconomy,
  solutionCompaniesSmartCities,
  positionLegendBottom,
}) => {
  const texts = useFms();

  return (
    <>
      <Paragraph>{texts.pages.esg.solutions.description}</Paragraph>
      <EsgSolutionsChart
        greenBond={greenBond}
        solutionCompanies={solutionCompanies}
        solutionCompaniesEqualOpportunities={
          solutionCompaniesEqualOpportunities
        }
        solutionCompaniesRenewableEnergy={solutionCompaniesRenewableEnergy}
        solutionCompaniesCircularEconomy={solutionCompaniesCircularEconomy}
        solutionCompaniesSmartCities={solutionCompaniesSmartCities}
        positionLegendBottom={positionLegendBottom}
      ></EsgSolutionsChart>
    </>
  );
};
