import Select, { Option } from '@storeblocks/select';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AddSingleItemContext } from './context';
import { Item } from './Item';
import { RemoveButton } from './RemoveButton';

interface Props {
  placeholderText: string;

  /**
   * @param previousItemId
   * The value is `undefined` for the initial selected item.
   */
  onChange: (newItem: Item, previousItemId: string | undefined) => void;

  /**
   * @param selectedItem
   * The value is `undefined` if an item has not been selected.
   */
  onRemoveClick: (selectedItemId: string | undefined) => void;
}

export const SelectListRow: React.FC<Props> = ({
  placeholderText,
  onChange: onSelectChange,
  onRemoveClick,
}) => {
  const [selectedItem, setSelectedItem] = useState<{
    option: Option;
    index: number;
  }>();
  const [options, setOptions] = useState<Option[]>([]);
  const [htmlId] = useState(nanoid());
  const context = useContext(AddSingleItemContext);

  useEffect(() => {
    // Create options for the select list.
    const selectListItems: Option[] = context.selectableItems.map((item) => ({
      label: item.label,
      value: item.id,
    }));

    // The selectable items list from the context does not contain the selected item
    // since it is not supposed to be selected by the other select lists.
    // Thus we have to add it back to the current select list.
    if (selectedItem) {
      selectListItems.splice(selectedItem.index, 0, selectedItem.option);
    }

    setOptions(selectListItems);
  }, [context.selectableItems]);

  const onChange = (option: Option): void => {
    const index = context.selectableItems.findIndex(
      (item) => item.id === option.value,
    );
    const newItem = context.selectableItems[index];

    onSelectChange(newItem, selectedItem?.option.value);

    setSelectedItem({
      option,
      index,
    });
  };

  return (
    <Wrapper>
      <Select
        id={htmlId}
        value={selectedItem?.option.value || ''}
        name={`select-item-${htmlId}`}
        placeholder={placeholderText}
        options={options}
        onChange={(_, option) => onChange(option)}
        fluid
      />
      <RemoveButton onClick={() => onRemoveClick(selectedItem?.option.value)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;
