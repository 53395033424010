import * as Yup from 'yup';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { UserType } from '@/config/types';
import { Locale } from '@/i18n/locale';
import { CurrencyCode } from '@/types/CurrencyCode';
import { nameof } from '@/util/nameof';

import { SwitchOrderFormValues } from '../types/SwitchOrderFormValues';
import { baseRedemptionFundStateValidationSchema } from './rules/baseRedemptionFundStateValidationSchema';
import { baseSubscriptionFundStateValidationSchema } from './rules/baseSubscriptionFundStateValidationSchema';
import { cosignerFieldValidationSchema } from './rules/cosignerFieldValidationSchema';
import { portfolioNameFieldValidationSchema } from './rules/portfolioNameFieldValidationSchema';
import { redemptionInAmountFundStateValidationSchema } from './rules/redemptionInAmountFundStateValidationSchema';
import { redemptionInPercentFundStateValidationSchema } from './rules/redemptionInPercentFundStateValidationSchema';
import { redemptionInUnitsFundStateValidationSchema } from './rules/redemptionInUnitsFundStateValidationSchema';
import { signatoryOptionValidationSchema } from './rules/signatoryOptionValidationSchema';
import { signerFieldValidationSchema } from './rules/signerFieldValidationSchema';
import { signingProviderFieldValidationSchema } from './rules/signingProviderFieldValidationSchema';
import { subscriptionInAmountFundStateValidationSchema } from './rules/subscriptionInAmountFundStateValidationSchema';
import { subscriptionInPercentFundStateValidationSchema } from './rules/subscriptionInPercentFundStateValidationSchema';
import { testSwitchFromAmountToAmountTotal } from './rules/testSwitchFromAmountToAmountTotal';
import { testSwitchFromAmountToPercentTotal } from './rules/testSwitchFromAmountToPercentTotal';
import { testSwitchFromToFunds } from './rules/testSwitchFromToFunds';
import { testSwitchToTotalPercent } from './rules/testSwitchToTotalPercent';

interface ValidationSchema {
  errorMessages: ErrorMessages;
  locale: Locale;
  userType: UserType;
}

interface ErrorMessages {
  portfolioRequired: string;
  redemptionMustHaveAtLeastOneFund: string;
  subscriptionMustHaveAtLeastOneFund: string;
  signatoryOptionRequired: string;
  signerRequired: string;
  cosignerRequired: string;
  signingProviderRequired: string;
  maximumPercent: string;
  minimumRedemptionAmount: string;
  minimumSubscriptionAmount: string;
  holdingsNotAbove95percent: string;
  redemptionAndSubscriptionAmountIsNotEqual: string;
  redemptionMaximumUnits: string;
  toPercentDoesNotAddUpTo100: string;
  fromToSameFund: string;
}

export const createSwitchValidationSchema = ({
  errorMessages,
  locale,
  userType,
}: ValidationSchema): Yup.SchemaOf<SwitchOrderFormValues> => {
  return Yup.object({
    fromPortfolioShortName: portfolioNameFieldValidationSchema({
      portfolioRequired: errorMessages.portfolioRequired,
    }),

    toPortfolioShortName: portfolioNameFieldValidationSchema({
      portfolioRequired: errorMessages.portfolioRequired,
    }),

    currency: Yup.mixed<CurrencyCode>()
      .oneOf(Object.values(CurrencyCode))
      .required(),

    fromValueType: Yup.mixed<OrderValueType>()
      .oneOf(Object.values(OrderValueType))
      .required(),

    toValueType: Yup.mixed<OrderValueType>()
      .oneOf(Object.values(OrderValueType))
      .required(),

    fromFunds: Yup.array()
      .of(baseRedemptionFundStateValidationSchema)
      .min(1, errorMessages.redemptionMustHaveAtLeastOneFund)
      .test({
        // Ensure that the user has input an amount to sell.
        message: errorMessages.redemptionMustHaveAtLeastOneFund,
        test: (funds) => funds.some((fund) => fund.value),
      })
      // Validate from funds when the value type is Amount.
      .when(nameof<SwitchOrderFormValues>('fromValueType'), {
        is: OrderValueType.Amount,
        then: Yup.array().of(
          redemptionInAmountFundStateValidationSchema(locale, {
            minimumRedemptionAmountMessage:
              errorMessages.minimumRedemptionAmount,
            marketValueNotAbove95percentMessage:
              errorMessages.holdingsNotAbove95percent,
          }),
        ),
      })
      // Validate from funds when the value type is Percent.
      .when(nameof<SwitchOrderFormValues>('fromValueType'), {
        is: OrderValueType.Percent,
        then: Yup.array().of(
          redemptionInPercentFundStateValidationSchema({
            maximumPercentMessage: errorMessages.maximumPercent,
          }),
        ),
      })
      // Validate from funds when the value type is Amount.
      .when(nameof<SwitchOrderFormValues>('fromValueType'), {
        is: OrderValueType.Units,
        then: Yup.array().of(
          redemptionInUnitsFundStateValidationSchema({
            maximumUnitsMessage: errorMessages.redemptionMaximumUnits,
          }),
        ),
      })
      .required(),

    toFunds: Yup.array()
      .of(baseSubscriptionFundStateValidationSchema)
      .min(1, errorMessages.subscriptionMustHaveAtLeastOneFund)
      .test({
        // Ensure that the user has input an amount to sell.
        message: errorMessages.subscriptionMustHaveAtLeastOneFund,
        test: (funds) => funds.some((fund) => fund.value),
      })
      // Validate to funds when the value type is Amount.
      .when(nameof<SwitchOrderFormValues>('toValueType'), {
        is: OrderValueType.Amount,
        then: Yup.array().of(
          subscriptionInAmountFundStateValidationSchema(locale, {
            minimumSubscriptionAmountMessage:
              errorMessages.minimumSubscriptionAmount,
          }),
        ),
      })
      // Validate to funds when the value type is Percent.
      .when(nameof<SwitchOrderFormValues>('toValueType'), {
        is: OrderValueType.Percent,
        then: Yup.array().of(
          subscriptionInPercentFundStateValidationSchema({
            maximumPercentMessage: errorMessages.maximumPercent,
          }),
        ),
      })
      // Validate to funds when the from value type is Amount
      // and the to value type is Amount.
      .when(nameof<SwitchOrderFormValues>('fromValueType'), {
        is: OrderValueType.Amount,
        then: Yup.array().when(nameof<SwitchOrderFormValues>('toValueType'), {
          is: OrderValueType.Amount,
          then: Yup.array().test(function test(toFunds) {
            return testSwitchFromAmountToAmountTotal(
              this,
              {
                minimumSubscriptionAmount:
                  errorMessages.minimumRedemptionAmount,
                redemptionAndSubscriptionAmountIsNotEqual:
                  errorMessages.redemptionAndSubscriptionAmountIsNotEqual,
              },
              toFunds,
            );
          }),
        }),
      })
      // Validate to funds when the from value type is Amount
      // and the to value type is Percent.
      .when(nameof<SwitchOrderFormValues>('fromValueType'), {
        is: OrderValueType.Amount,
        then: Yup.array().when(nameof<SwitchOrderFormValues>('toValueType'), {
          is: OrderValueType.Percent,
          then: Yup.array().test(function test(toFunds) {
            return testSwitchFromAmountToPercentTotal(
              this,
              locale,
              {
                minimumSubscriptionAmount:
                  errorMessages.minimumRedemptionAmount,
                redemptionAndSubscriptionAmountIsNotEqual:
                  errorMessages.redemptionAndSubscriptionAmountIsNotEqual,
              },
              toFunds,
            );
          }),
        }),
      })
      // Validate to funds when the from value type is Percent or Units.
      .when(nameof<SwitchOrderFormValues>('fromValueType'), {
        is: (value) =>
          value === OrderValueType.Percent || value === OrderValueType.Units,
        then: Yup.array().when(nameof<SwitchOrderFormValues>('toValueType'), {
          is: OrderValueType.Percent,
          then: Yup.array().test(function test(toFunds) {
            return testSwitchToTotalPercent(
              this,
              {
                toPercentDoesNotAddUpTo100:
                  errorMessages.toPercentDoesNotAddUpTo100,
              },
              toFunds,
            );
          }),
        }),
      })
      // Validate that it is allowed to switch between
      // the selected funds.
      .test(function test(toFunds) {
        return testSwitchFromToFunds(
          this,
          {
            fromToSameFund: errorMessages.fromToSameFund,
          },
          toFunds,
        );
      })
      .required(),

    signatoryOption: signatoryOptionValidationSchema({
      signatoryOptionRequired: errorMessages.signatoryOptionRequired,
    }),

    signer: signerFieldValidationSchema({
      signerRequired: errorMessages.signerRequired,
    }),

    cosigner: cosignerFieldValidationSchema({
      cosignerRequired: errorMessages.cosignerRequired,
    }),

    signingProvider: signingProviderFieldValidationSchema(userType, {
      signingProviderRequired: errorMessages.signingProviderRequired,
    }),
  });
};
