import { createApi } from '@reduxjs/toolkit/query/react';

import { authorizationApiUrl } from '@/api/utils/authorizationApiUrl';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { AssignableRolesReply } from './dtos/AssignableRolesReply';
import { AssignableRoles } from './models/AssignableRoles';
import { transformAssignableRolesReply } from './utils/transformAssignableRolesReply';

export const assignableRolesApi = createApi({
  reducerPath: 'assignableRolesApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    assignableRoles: builder.query<AssignableRoles, void>({
      query: () => ({
        url: `${authorizationApiUrl()}/organizations/assignable-roles/v2`,
      }),
      transformResponse: (dto: AssignableRolesReply) => {
        return transformAssignableRolesReply(dto);
      },
    }),
  }),
});

export const { useAssignableRolesQuery, util } = assignableRolesApi;
