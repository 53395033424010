import React, { FC } from 'react';
import styled from 'styled-components';

import { MenuItem } from '../types/MenuItem';
import { MenuItemLink } from './MenuItemLink';
import { ParentMenuItem } from './ParentMenuItem';

interface Props {
  menuItems: Array<MenuItem>;
  selectedMenuItem?: MenuItem;
  deactivateTabFocus?: boolean;
  onSelected?: (menuItem: MenuItem) => void;
  toggleIsOpen: () => void;
}

export const MenuItemList: FC<Props> = ({
  menuItems,
  selectedMenuItem,
  deactivateTabFocus,
  onSelected,
  toggleIsOpen,
}) => {
  return (
    <Container>
      {menuItems.map((menuItem) =>
        menuItem.children ? (
          <ParentMenuItem
            key={menuItem.id}
            id={menuItem.id}
            title={menuItem.title}
            onClick={() => onSelected && onSelected(menuItem)}
            active={menuItem.id === selectedMenuItem?.id}
            tabIndex={deactivateTabFocus ? -1 : 0}
          />
        ) : (
          <MenuItemLink
            key={menuItem.id}
            routeId={menuItem.id}
            text={menuItem.title}
            to={menuItem.to}
            onClick={toggleIsOpen}
            tabIndex={deactivateTabFocus ? -1 : 0}
          />
        ),
      )}
    </Container>
  );
};

const Container = styled.ul`
  flex: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  padding: 8px 0;
`;
