import Table, { IColumn } from '@storeblocks/table';
import { format, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { DailyTradeReport } from '@/api/reports/models/DailyTradeReports';
import { useFms } from '@/hooks/useFms';
import { useUserDateFormat } from '@/i18n/hooks';
import { downloadFile } from '@/util/downloadFile';

import { FileNameColumn } from '../Table/FileNameColumn';

interface Props {
  contractNotes: DailyTradeReport[];
}

export const ContractNotesTable: React.FC<Props> = ({ contractNotes }) => {
  const texts = useFms();
  const dateFormat = useUserDateFormat();

  const downloadSingleFile = (fileId: string): Promise<void> => {
    const url = `${window.config.connectBaseApiUrl}/api/reports/wolf/${fileId}`;
    return downloadFile(url);
  };

  const columns: IColumn[] = [
    {
      key: 'date',
      name: texts.orders.history.table.orderDate,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
    {
      key: 'name',
      name: texts.orders.history.table.name,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
  ];

  const rows = contractNotes.map((contractNote) => ({
    date: {
      displayContent: (
        <DateCell>
          {format(parseISO(contractNote.reportDateISO), dateFormat)}
        </DateCell>
      ),
      sortContent: contractNote.reportDateISO,
    },
    name: {
      displayContent: (
        <FileNameColumn
          filename={contractNote.displayName}
          fileId={contractNote.id}
          fileExtension={contractNote.fileType}
          onDownload={downloadSingleFile}
        />
      ),
      sortContent: contractNote.displayName,
    },
  }));

  return (
    <StyledTable
      columns={columns}
      data={rows}
      data-test="contract-notes-table"
    />
  );
};

const StyledTable = styled(Table)`
  table {
    table-layout: unset;
    white-space: nowrap;
  }

  & tr td:nth-of-type(2) {
    width: 100%;
  }
`;

const DateCell = styled.div`
  padding: 16px;
  line-height: 24px;
`;
