import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { OrderType } from '@/api/order/models/OrderType';
import { Show } from '@/components/Show';
import { RedemptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/RedemptionOrderFormValues';

import { FundsTable } from './components/FundsTable';
import { MultipleCurrenciesAlert } from './components/MultipleCurrenciesAlert';
import { useCreateRedemptionFundTableColumns } from './hooks/useCreateRedemptionFundTableColumns';
import { createRedemptionSummaryRow } from './utils/createRedemptionSummaryRow';
import { hasFundsMultipleCurrencies } from './utils/hasFundsMultipleCurrencies';
import { mapFundStateToRedemptionFundRow } from './utils/mapFundStateToRedemptionFundRow';

export const RedemptionFundsTable: React.FC = () => {
  const { values } = useFormikContext<RedemptionOrderFormValues>();

  const columns = useCreateRedemptionFundTableColumns(values.fromValueType);

  const hasMultipleCurrencies = hasFundsMultipleCurrencies(values.fromFunds);

  const summaryRow = createRedemptionSummaryRow();

  let fundRows = values.fromFunds.map(mapFundStateToRedemptionFundRow);

  fundRows = fundRows.concat(summaryRow);

  return (
    <Container data-test="redemption-input-table">
      <FundsTable columns={columns} rows={fundRows} />
      <Show when={hasMultipleCurrencies} animate>
        <MultipleCurrenciesAlert orderType={OrderType.Subscription} />
      </Show>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
