import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useOrganizationEsgQuery } from '@/api/esg/esgApi';
import { Esg } from '@/api/esg/models/Esg';
import { isNotReady } from '@/api/utils/isNotReady';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

interface Props {
  render: (data: Esg) => React.ReactNode;
}

export const WithOrganizationEsgData: React.FC<Props> = ({ render }) => {
  const esgQuery = useOrganizationEsgQuery();
  const texts = useFms();

  if (isNotReady(esgQuery)) {
    return (
      <WithGap>
        <H2>{texts.dashboard.widgets.esgOverview.title}</H2>
        <QueryState
          query={esgQuery}
          customTexts={{ noData: { title: texts.pages.esg.noData } }}
        />
      </WithGap>
    );
  }

  return <>{render(esgQuery.data)}</>;
};
