import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { Transaction } from './domain/Transaction';
import { TransactionsReply } from './dtos/TransactionsReplyDto';
import { transformTransactionsReply } from './transformTransactionsReply';

interface TransactionsArguments {
  portfolioId: string;
  fromDate: Date;
  toDate: Date;
}

export const transactionsApi = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: axiosBaseQuery<TransactionsReply>(),
  endpoints: (builder) => ({
    transactions: builder.query<Array<Transaction>, TransactionsArguments>({
      query: ({ portfolioId, fromDate, toDate }) => {
        return {
          url: `${window.config.connectBaseApiUrl}/api/transactions/${portfolioId}`,
          params: {
            fromDate: format(fromDate, 'yyyy-MM-dd'),
            toDate: format(toDate, 'yyyy-MM-dd'),
          },
        };
      },
      transformResponse: (dto) => {
        return transformTransactionsReply(dto);
      },
    }),
  }),
});

export const { useTransactionsQuery } = transactionsApi;
