import * as Yup from 'yup';

import { SignatoryOption } from '../../types/SignatoryOption';

interface ErrorMessages {
  signatoryOptionRequired: string;
}

/**
 * Validate that a signatory option is set.
 */
export const signatoryOptionValidationSchema = (
  errorMessages: ErrorMessages,
): Yup.BaseSchema =>
  Yup.mixed()
    .oneOf(
      [SignatoryOption.Self, SignatoryOption.Cosigner, SignatoryOption.Other],
      errorMessages.signatoryOptionRequired,
    )
    .required(errorMessages.signatoryOptionRequired);
