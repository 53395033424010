interface ErrorArgs {
  message: string;
  url: string;
  errorObject: unknown;
}

/**
 * HTTP errors from the APIs we use in the frontend.
 * The `unknown` part is due to this not being a HTTP error, but an
 * other type of error that we don't know. We still define this as
 * an API error since it is an error originating from the API pipeline.
 */
export class UnknownApiError extends Error {
  public url: string;
  public originalMessage: string;
  public stringifiedErrorObject: string;

  constructor(error: ErrorArgs, cause?: Error | unknown) {
    /**
     * JavaScript does not always play nicely when printing objects.
     * Thus to avoid the objects being printed as `[object Object]` in
     * the logs (console/dbo.log/Sentry), we stringify the object.
     */
    super(
      `${error.message}, URL: [${
        error.url
      }]. Error object stringified: [${stringifyErrorObject(
        error.errorObject,
      )}].`,
      { cause },
    );

    this.originalMessage = error.message;
    this.url = error.url;
    this.stringifiedErrorObject = stringifyErrorObject(error.errorObject);

    // Maintains proper stack trace for where our error was thrown (only available on V8).
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Error.captureStackTrace) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Error.captureStackTrace(this, UnknownApiError);
    }

    this.name = 'Unknown API Error';
  }
}

function stringifyErrorObject(errorObject: unknown): string {
  /**
   * Use try/catch to avoid throwing an error in the error handler.
   */
  try {
    return JSON.stringify(errorObject);
  } catch (error) {
    return `Error stringifying error object: ${error}`;
  }
}
