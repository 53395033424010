import { createApi } from '@reduxjs/toolkit/query/react';

import { Language } from '@/i18n/Language';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { NewsArticle } from './models/newsArticlesModels';
import { convertToArticles as parseHtmlToArticles } from './utils/convertToArticles';

export const newsArticlesApi = createApi({
  reducerPath: 'newsArticlesApi',
  baseQuery: axiosBaseQuery<string>(),
  endpoints: (builder) => ({
    fetchNewsArticles: builder.query<NewsArticle[], Language>({
      query: (language) => ({
        url: getUrl(language),
      }),
      transformResponse: (html: string) => parseHtmlToArticles(html),
    }),
  }),
});

export const { useFetchNewsArticlesQuery } = newsArticlesApi;

/**
 * Get the correct URL to fetch news articles for the given language
 *
 * The `headless=true` query parameter is used to fetch the news
 * articles with only the HTML content that is defined in the CMS.
 * Otherwise, the returned HTML would include the entire HTML
 * structure of the page, including scripts and styles.
 */
const getUrl = (language: Language): string => {
  const url = 'https://www.storebrand.no/infosider/connect';

  switch (language) {
    case Language.English:
      return `${url}/news-english?headless=true`;

    case Language.Norwegian:
      return `${url}/news-norwegian?headless=true`;

    case Language.Swedish:
      return `${url}/news-swedish?headless=true`;

    default:
      throw new Error(
        `Unknown language [${language}] to fetch news articles for.`,
      );
  }
};
