import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';

import {
  isRedemptionPayment,
  isSubscriptionPayment,
  RedemptionPayment,
  SubscriptionPayment,
} from '../../OrderDetails/types/Payment';
import { isLastRow } from '../utils/isLastRow';
import { PaymentRowCell } from './PaymentRowCell';
import { PaymentRowHeadingCell } from './PaymentRowHeadingCell';
import { RedemptionPaymentRowHeadingCell } from './RedemptionPaymentRowHeadingCell';

interface Props {
  payments: Array<SubscriptionPayment | RedemptionPayment>;
}

export const PaymentRows: React.FC<Props> = ({ payments }) => {
  const orderContext = useOrderPageContext();

  const currencies = payments.map((payment) => {
    if (isSubscriptionPayment(payment)) {
      return payment.fundPaymentInformation.currency;
    }

    if (isRedemptionPayment(payment)) {
      return payment.currency;
    }
  });

  const hasMultipleCurrencies = new Set(currencies).size > 1;

  if (orderContext.orderType === OrderType.Redemption) {
    const redemptionPayments = payments.filter(isRedemptionPayment);

    return (
      <>
        {redemptionPayments.map((payment, index, array) => (
          <tr key={payment.currency}>
            <RedemptionPaymentRowHeadingCell
              currency={payment.currency}
              noBorder={!isLastRow(index, array)}
              displayCurrency={hasMultipleCurrencies}
            />

            <PaymentRowCell
              noBorder={!isLastRow(index, array)}
              orderPaymentType={payment.paymentPlacement}
              bankAccountNumber={payment.bankAccountNumber}
              portfolioBankAccountNumber={payment.portfolioBankAccountNumber}
            />
          </tr>
        ))}
      </>
    );
  }

  const subscriptionPayments = payments.filter(isSubscriptionPayment);

  return (
    <>
      {subscriptionPayments.map((payment, index, array) => (
        <tr key={payment.fundPaymentInformation.bankAccountNumber}>
          <PaymentRowHeadingCell
            accountHolder={payment.fundPaymentInformation.accountHolder}
            currency={payment.fundPaymentInformation.currency}
            noBorder={!isLastRow(index, array)}
            displayCurrency={hasMultipleCurrencies}
          />

          <PaymentRowCell
            noBorder={!isLastRow(index, array)}
            orderPaymentType={payment.paymentPlacement}
            bankAccountNumber={payment.fundPaymentInformation.bankAccountNumber}
            portfolioBankAccountNumber={payment.portfolioBankAccountNumber}
          />
        </tr>
      ))}
    </>
  );
};
