import React from 'react';
import styled from 'styled-components';

import { FundDocumentType } from '@/api/funds/models';
import { getBaseApiUrl } from '@/config/utils';
import { useFms } from '@/hooks/useFms';
import { Locale } from '@/i18n/locale';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { Divider } from '../Divider';
import { TextShortBold } from '../Typography';
import { DownloadLink } from './DownloadLink';
import { useFindDocumentByType } from './hooks/useFindDocumentByType';

interface Props {
  isin: string;
}

export const Documents: React.FC<Props> = ({ isin }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const kiid = useFindDocumentByType(isin, FundDocumentType.Kiid);
  const prospectus = useFindDocumentByType(isin, FundDocumentType.Prospectus);
  const monthlyReport = useFindDocumentByType(
    isin,
    FundDocumentType.MonthlyReport,
  );

  const fundDataUrl = `${getBaseApiUrl()}/open/savings/funds`;

  const getLanguageAsText = (languageCode: Locale): string => {
    switch (languageCode) {
      case Locale.Norwegian: {
        return texts.language.noLabel.toLowerCase();
      }
      case Locale.Swedish: {
        return texts.language.svLabel.toLowerCase();
      }
      case Locale.English: {
        return texts.language.enLabel.toLowerCase();
      }
      default:
        throw new Error(`Invalid locale enum [${languageCode}]`);
    }
  };

  return (
    <DocumentsContainer data-test="fund-documents">
      <TextShortBold>{texts.pages.fundList.documents.title}</TextShortBold>

      <StyledDivider margin="16" />

      {monthlyReport && (
        <DownloadLink
          url={`${fundDataUrl}${monthlyReport.url}`}
          title={`${
            texts.pages.fundList.documents.monthlyReport
          } (${getLanguageAsText(monthlyReport.languageCode)})`}
          data-test="monthly-report"
          data-trackid={getTrackId(
            `download-monthly-report-for-${isin}`,
            TrackingElement.Link,
          )}
        />
      )}

      {kiid && (
        <DownloadLink
          url={`${fundDataUrl}${kiid.url}`}
          title={`${texts.pages.fundList.documents.kiid} (${getLanguageAsText(
            kiid.languageCode,
          )})`}
          data-test="kiid"
          data-trackid={getTrackId(
            `download-kiid-for-${isin}`,
            TrackingElement.Link,
          )}
        />
      )}

      {prospectus && (
        <DownloadLink
          url={`${fundDataUrl}${prospectus.url}`}
          title={`${
            texts.pages.fundList.documents.prospectus
          } (${getLanguageAsText(prospectus.languageCode)})`}
          data-test="prospectus"
          data-trackid={getTrackId(
            `download-prospectus-for-${isin}`,
            TrackingElement.Link,
          )}
        />
      )}
    </DocumentsContainer>
  );
};

const DocumentsContainer = styled.div`
  /* Make the container take all the space it can. */
  flex: 1;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0;
`;
