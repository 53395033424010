import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { EsgCurrencyCategory } from '@/api/esg/models/EsgCurrencyCategory';
import { ColumnChart, ColumnChartData } from '@/components/Charts';
import { useFms } from '@/hooks/useFms';
import { CurrencyCode } from '@/types/CurrencyCode';
import { Unit } from '@/types/Unit';

interface Props {
  carbonIntensities: EsgCurrencyCategory[];
  esgScore: EsgCategory;
}

export const EsgCoverageChart: React.FC<Props> = ({
  carbonIntensities,
  esgScore,
}) => {
  const texts = useFms();

  const carbonIntensityData = carbonIntensities.find(
    (score) => score.currency === CurrencyCode.NOK,
  );

  const columnChartData: Array<ColumnChartData> = [];

  if (carbonIntensityData) {
    columnChartData.push({
      title: texts.pages.esg.carbonFootprint.title,
      dataPoints: [
        {
          name: texts.pages.esg.carbonFootprint.title,
          y: carbonIntensityData.kpi.coverageInPercent,
        },
      ],
    });
  }

  if (esgScore) {
    columnChartData.push({
      title: texts.pages.esg.esgScore.title,
      dataPoints: [
        {
          name: texts.pages.esg.esgScore.title,
          y: esgScore.kpi.coverageInPercent,
        },
      ],
    });
  }

  return (
    <ColumnChart data={columnChartData} unit={Unit.Percent} min={0} max={100} />
  );
};
