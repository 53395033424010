import { convertStringToEnum } from '@/util/convertStringToEnum';

import { DocumentCategoriesReply } from '../dtos/DocumentCategoriesReply';
import { DocumentCategory } from '../models/DocumentCategory';

export const transformDocumentCategoriesReply = (
  dto: DocumentCategoriesReply,
): Array<DocumentCategory> => {
  return dto.map((documentCategory) =>
    convertStringToEnum(documentCategory, DocumentCategory),
  );
};
