import React, { FC } from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks';

interface Props {
  value: number;
  unit?: string;
}

/** Renders a styled text for displaying a value.
 * @param {number} value - The value to display.
 * @param {string} [unit] - The value unit.
 */
export const EsgValueText: FC<Props> = ({ value, unit }) => {
  const locale = useUserLocale();

  return (
    <TextShortStyled>{`${value.toLocaleString(
      locale,
    )} ${unit}`}</TextShortStyled>
  );
};

const TextShortStyled = styled(TextShort)`
  color: var(--color-blue-50);
  font-size: 32px;
  font-weight: 600;
  margin: 24px 0;
`;
