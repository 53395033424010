import { format, parseISO } from 'date-fns';
import React, { FC } from 'react';

import { Esg } from '@/api/esg/models/Esg';
import { Center } from '@/components/Center';
import { Gauge } from '@/components/Charts';
import { Column, Row } from '@/components/Grid';
import { Helper } from '@/components/Helper';
import { Show } from '@/components/Show';
import { H2, H3, Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';

import { Line } from './components/Line';
import { EsgCarbonFootprint } from './widgets/EsgCarbonFootprint/EsgCarbonFootprint';
import { EsgCoverage } from './widgets/EsgCoverage/EsgCoverage';
import { EsgExclusions } from './widgets/EsgExclusions/EsgExclusions';
import { EsgFundList } from './widgets/EsgFundList/EsgFundList';
import { EsgOverviewValueList } from './widgets/EsgOverview/EsgOverviewValueList';
import { EsgScore } from './widgets/ESGScore/EsgScore';
import { EsgSolutions } from './widgets/EsgSolutions/EsgSolutions';
import { EsgTaxonomyWidget } from './widgets/EsgTaxonomy';

interface Props {
  data: Esg;
}

export const EsgDashboard: FC<Props> = ({ data }) => {
  const texts = useFms();
  const dateFormat = useUserDateFormat();

  return (
    <WithGap data-test="esg-dashboard">
      <Hint>
        {fmsWithTemplate(texts.common.lastUpdated, {
          date: format(parseISO(data.updatedDate), dateFormat),
        })}
      </Hint>

      <H2>{texts.pages.esg.title}</H2>

      <Row>
        <Column $span={6}>
          <WithGap data-test="esg-overview-widget">
            <H3>{texts.pages.esg.overview.title}</H3>

            <Center horizontal vertical>
              <Gauge
                percent={data.solutionCompanies.kpi.value}
                description={texts.pages.esg.overview.sustainableInvestments}
                size={300}
              />
            </Center>

            <EsgOverviewValueList
              esgScore={data.esgScore}
              solutionCompanies={data.solutionCompanies}
              euTaxonomyAlignment={data.euTaxonomyAlignment}
              euTaxonomyEligibility={data.euTaxonomyEligibility}
              carbonIntensities={data.carbonIntensities}
            />
          </WithGap>
        </Column>

        <Column $span={6}>
          <EsgFundList portfolioGroupId={data.portfolioGroupId} />
        </Column>
      </Row>

      <Line />

      <Row>
        <Column $span={6}>
          <WithGap>
            <H2>{texts.pages.esg.esgScore.title}</H2>

            <EsgScore esgScore={data.esgScore} />
          </WithGap>
        </Column>

        <Column $span={6}>
          <WithGap>
            <H2>{texts.pages.esg.carbonFootprint.title}</H2>

            <EsgCarbonFootprint esgCarbonIntensities={data.carbonIntensities} />
          </WithGap>
        </Column>
      </Row>

      {/** @todo: Temporary taxonomy data need to be hidden, see SAMIK-1799 */}
      <Show when={false}>
        <Line />

        <EsgTaxonomyWidget
          euTaxonomyAlignment={data.euTaxonomyAlignment}
          euTaxonomyEligibility={data.euTaxonomyEligibility}
        />
      </Show>

      <Line />

      <Row>
        <Column $span={6}>
          <WithGap>
            <WithGap direction="row" gap="16">
              <H2>{texts.pages.esg.solutions.title}</H2>
              <Helper
                id="esg-solutions"
                title={texts.pages.esg.solutions.helpTitle}
                description={texts.pages.esg.solutions.helpDescription}
              />
            </WithGap>

            <EsgSolutions
              greenBond={data.greenBond}
              solutionCompanies={data.solutionCompanies}
              solutionCompaniesEqualOpportunities={
                data.solutionCompaniesEqualOpportunities
              }
              solutionCompaniesRenewableEnergy={
                data.solutionCompaniesRenewableEnergy
              }
              solutionCompaniesCircularEconomy={
                data.solutionCompaniesCircularEconomy
              }
              solutionCompaniesSmartCities={data.solutionCompaniesSmartCities}
              positionLegendBottom
            />
          </WithGap>
        </Column>

        <Column $span={6}>
          <WithGap>
            <H2>{texts.pages.esg.coverage.title}</H2>

            <EsgCoverage
              carbonIntensities={data.carbonIntensities}
              esgScore={data.esgScore}
            />
          </WithGap>
        </Column>
      </Row>

      <Line />

      <WithGap>
        <H2>{texts.pages.esg.exclusions.title}</H2>

        <EsgExclusions />
      </WithGap>
    </WithGap>
  );
};
