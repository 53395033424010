import * as Yup from 'yup';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { FundState } from '@/pages/Order/steps/OrderDetails/types/FundState';
import { RedemptionPayment } from '@/pages/Order/steps/OrderDetails/types/Payment';
import { CurrencyCode } from '@/types/CurrencyCode';
import { nameof } from '@/util/nameof';

import { RedemptionOrderFormValues } from '../../types/RedemptionOrderFormValues';

interface ErrorMessages {
  paymentPlacementRequired: string;
  bankAccountNumberRequired: string;
  portfolioBankAccountNumberRequired: string;
}

const validationSchema = (
  errorMessages: ErrorMessages,
): Yup.SchemaOf<RedemptionPayment> =>
  Yup.object({
    type: Yup.mixed().oneOf(['REDEMPTION_PAYMENT']),

    paymentPlacement: Yup.mixed<OrderPaymentType>()
      .oneOf(
        [OrderPaymentType.BankAccount, OrderPaymentType.Portfolio],
        errorMessages.paymentPlacementRequired,
      )
      .required(errorMessages.paymentPlacementRequired),

    bankAccountNumber: Yup.string().when(
      nameof<RedemptionPayment>('paymentPlacement'),
      {
        is: OrderPaymentType.BankAccount,
        then: Yup.string().required(errorMessages.bankAccountNumberRequired),
        otherwise: Yup.string().optional(),
      },
    ),

    portfolioBankAccountNumber: Yup.string().when(
      nameof<RedemptionPayment>('paymentPlacement'),
      {
        is: OrderPaymentType.Portfolio,
        then: Yup.string().required(
          errorMessages.portfolioBankAccountNumberRequired,
        ),
        otherwise: Yup.string().optional(),
      },
    ),

    currency: Yup.mixed<CurrencyCode>()
      .oneOf(Object.values(CurrencyCode))
      .required(),
  }).required();

export const redemptionPaymentValidationSchema = (
  errorMessages: ErrorMessages,
): Yup.ArraySchema<Yup.SchemaOf<RedemptionPayment>> =>
  Yup.array()
    .of(validationSchema(errorMessages))
    // Only display validation errors if some funds are selected for redemption.
    .when(nameof<RedemptionOrderFormValues>('fromFunds'), {
      is: (funds: FundState[]) => funds.some((fund) => fund.value),
      then: (schema) => schema.min(1),
    })
    .required();
