import React, { FC } from 'react';
import { styled } from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import { usePortfoliosQuery } from '@/api/portfolios';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { MainContent } from '@/components/MainContent';
import { NewCutOffTimeInformation } from '@/components/NewCutOffTimeInformation';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

import { AnnouncementInfo } from './components/AnnouncementInfo';
import { NoPortfoliosDashboard } from './components/NoPortfoliosDashboard';
import { PortfolioDashboard } from './components/PortfolioDashboard';

export const DashboardPage: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const { organizationName } = useOrganizationName(organizationCmId);

  const portfoliosQuery = usePortfoliosQuery();

  return (
    <CenteredPageContent data-test="dashboard-page">
      <AnnouncementWarning />
      <OrderToSignAlert />
      <AnnouncementInfo />

      <NewCutOffTimeInformation />

      <MainContent>
        <MarginTop>
          <PageHeading header={prettifyOrganizationName(organizationName)} />
        </MarginTop>

        {isNotReady(portfoliosQuery) ? (
          <QueryState query={portfoliosQuery} />
        ) : portfoliosQuery.data.portfolioGroups.length === 0 ? (
          <NoPortfoliosDashboard />
        ) : (
          <PortfolioDashboard />
        )}
      </MainContent>
    </CenteredPageContent>
  );
};

const MarginTop = styled.div`
  margin-top: 32px;
`;
