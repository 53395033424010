import React, { PropsWithChildren } from 'react';

import { getAuth } from './Auth';
import { NotAuthenticatedPage } from './NotAuthenticatedPage';

/** Only render the children if the user is authenticated and show an information page if not. */
export const Authenticated: React.FC<PropsWithChildren> = ({ children }) => {
  // If the user is authenticated, render the app.
  if (getAuth().isAuthenticated()) {
    return <>{children}</>;
  }

  // If the user is not authenticated, render the not authenticated page.
  return <NotAuthenticatedPage />;
};
