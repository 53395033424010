import Surface from '@storeblocks/surface';
import styled from 'styled-components';

export const SurfaceWidget = styled(Surface)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  height: 100%;
`;
