import { useUserDateFnsLocale } from '@/i18n/hooks';

/**
 * Get weekdays for current locale.
 * @returns List of weekdays formatted to the current locale.
 */
export const useLocaleWeekdays = (): string[] => {
  const dateFnsLocale = useUserDateFnsLocale();

  if (!dateFnsLocale.localize) {
    throw new Error(
      `Unsupported locale for translating weekdays, [${dateFnsLocale.code}]`,
    );
  }

  const weekdays = [];
  for (let i = 0; i < 7; i += 1) {
    weekdays.push(dateFnsLocale.localize.day(i));
  }

  return weekdays;
};
