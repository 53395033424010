import React from 'react';

import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { OrderType } from '@/api/order/models/OrderType';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { getSubscriptionPaymentTitle } from '@/pages/Order/utils/getSubscriptionPaymentTitle';
import { CurrencyCode } from '@/types/CurrencyCode';

import { TableRowHeader } from './TableRowHeader';

interface Props {
  accountHolder: PaymentAccountHolder;
  currency: CurrencyCode;
  displayCurrency: boolean;
  noBorder: boolean;
}

export const PaymentRowHeadingCell: React.FC<Props> = ({
  accountHolder,
  currency,
  displayCurrency,
  noBorder,
}) => {
  const texts = useFms();

  const { orderType } = useOrderPageContext();

  let title: string;

  if (orderType === OrderType.Subscription) {
    title = getSubscriptionPaymentTitle(accountHolder, currency, texts, {
      displayCurrencyInTitle: displayCurrency,
    });
  } else {
    title = displayCurrency
      ? fmsWithTemplate(
          texts.orders.form.table.redemption.paymentWithCurrency,
          { currency },
        )
      : texts.orders.form.table.redemption.payment;
  }

  return (
    <TableRowHeader colSpan={2} noBorder={noBorder}>
      {title}
    </TableRowHeader>
  );
};
