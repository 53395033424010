import { AssetPerformanceData } from '@/api/performanceData/models';

export const getAssetProductGroups = (
  node: AssetPerformanceData,
  parentNode?: AssetPerformanceData,
): Array<AssetPerformanceData> => {
  if (
    isAssetProductGroup(
      node.nodeLevel,
      node.nodeDepth,
      parentNode?.nodeDepth ?? 0,
    )
  ) {
    return [node];
  }

  return node.assets.flatMap((childNode) =>
    getAssetProductGroups(childNode, node),
  );
};

const isAssetProductGroup = (
  nodeLevel: number,
  nodeDepth: number,
  parentNodeDepth: number,
): boolean => {
  // Check if the node is a top level product group.
  if (nodeDepth === 0 && nodeLevel === 1) {
    return true;
  }

  // Check if the node is a product group with no children.
  if (nodeDepth === 0 && parentNodeDepth > 1) {
    return true;
  }

  // Check if the node is a category group with children.
  return nodeDepth === 1;
};
