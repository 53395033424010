import { createApi } from '@reduxjs/toolkit/query/react';

import { OrderType } from '@/api/order/models/OrderType';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { PendingOrdersReply } from './dtos/PendingOrdersReply';
import { PostOrderDto } from './dtos/PostOrderDto';
import { OrderWithSignUrl } from './models/OrderWithSignUrl';
import { PendingOrder } from './models/PendingOrder';
import { mapOrderWithSignUrlDtoToModel } from './utils/mapOrderWithSignUrlDtoToModel';
import { transformPendingOrdersReply } from './utils/transformPendingOrdersReply';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getOrderById: builder.query<OrderWithSignUrl, { orderId: string }>({
      query: ({ orderId }) => {
        return {
          url: `${window.config.connectBaseApiUrl}/api/orders/order/${orderId}`,
        };
      },
      transformResponse: mapOrderWithSignUrlDtoToModel,
    }),

    pendingOrders: builder.query<PendingOrder[], void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/orders/v2/pending`,
      }),
      transformResponse: (reply: PendingOrdersReply) => {
        return transformPendingOrdersReply(reply);
      },
    }),

    postOrder: builder.mutation<
      OrderWithSignUrl,
      { orderType: OrderType; order: PostOrderDto }
    >({
      query: ({ orderType, order }) => {
        let urlMap = {
          [OrderType.Subscription]: `${window.config.connectBaseApiUrl}/api/orders/v2/buy/fund`,
          [OrderType.Redemption]: `${window.config.connectBaseApiUrl}/api/orders/v2/sell/fund`,
          [OrderType.Switch]: `${window.config.connectBaseApiUrl}/api/orders/v2/switch/fund`,
        };

        if (isBetaFeaturesOn()) {
          // Use the new order endpoints.
          urlMap = {
            [OrderType.Subscription]: `${window.config.connectBaseApiUrl}/api/orders/v3/buy/fund`,
            [OrderType.Redemption]: `${window.config.connectBaseApiUrl}/api/orders/v3/sell/fund`,
            [OrderType.Switch]: `${window.config.connectBaseApiUrl}/api/orders/v3/switch/fund`,
          };
        }

        return {
          url: urlMap[orderType],
          method: 'post',
          data: order,
        };
      },
      transformResponse: mapOrderWithSignUrlDtoToModel,
    }),

    /**
     * Mark the order as signed by the logged in user.
     *
     * We don't know who or when signatories has signed the order until ESS sends an APEX message that
     * all parties have signed the order. Thus, to track when individual users signs the order, we
     * do a call to update the signing order status for the current user.
     */
    confirmOrderSigned: builder.mutation<void, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `${window.config.connectBaseApiUrl}/api/orders/signed/${orderId}`,
        method: 'put',
      }),
    }),
  }),
});

export const {
  useGetOrderByIdQuery,
  usePendingOrdersQuery,
  usePostOrderMutation,
  useConfirmOrderSignedMutation,
} = orderApi;
