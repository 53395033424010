import { convertStringToEnum } from '@/util/convertStringToEnum';

import { SigningRightDto } from '../dtos/SigningRightDto';
import { SigningRight, SigningRightStatus } from '../models/SigningRight';

export const mapSigningRightDtoToModel = (
  dto: SigningRightDto,
): SigningRight => ({
  status: convertStringToEnum(dto.status, SigningRightStatus),
  signingUrl: dto.signingUrl,
});
