import React from 'react';
import styled from 'styled-components';

import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';

import {
  RedemptionPayment,
  SubscriptionPayment,
} from '../../OrderDetails/types/Payment';
import { FundRow } from './FundRow';
import { MainHeaderRow } from './MainHeaderRow';
import { PaymentRows } from './PaymentRows';
import { PortfolioRow } from './PortfolioRow';
import { TotalAmountRows } from './TotalAmountRows';

interface Props {
  portfolioShortName: string;
  orderPlacements: Array<OrderPlacement>;
  payments: Array<SubscriptionPayment | RedemptionPayment>;
}

export const OrderDetailsTable: React.FC<Props> = ({
  portfolioShortName,
  orderPlacements,
  payments,
}) => {
  const { orders } = useFms();

  const showTotal = orderPlacements.every(
    (orderPlacement) => orderPlacement.valueType === OrderValueType.Amount,
  );

  return (
    <StyledTable cellSpacing="0" cellPadding="0">
      <thead>
        <MainHeaderRow title={orders.form.table.orderDetails} />
      </thead>
      <tbody>
        <PortfolioRow portfolioShortName={portfolioShortName} />
        <FundRow
          portfolioShortName={portfolioShortName}
          orderPlacements={orderPlacements}
        />
        <Show when={showTotal}>
          <TotalAmountRows orderPlacements={orderPlacements} />
        </Show>
        <PaymentRows payments={payments} />
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  line-height: 16px;
  width: 100%;
`;
