import React from 'react';

import { TextShortBold } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

interface Props {
  amount: number;
}

export const ReturnInAmountText: React.FC<Props> = ({ amount }) => {
  const locale = useUserLocale();

  return (
    <TextShortBold>{`${formatNumber(
      roundHalfEven(amount, 0),
      locale,
    )}`}</TextShortBold>
  );
};
