import { SystemIcon } from '@storeblocks/icon';
import { format } from 'date-fns';
import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Transaction } from '@/api/transactions/domain/Transaction';
import { useUserDateFnsLocale, useUserDateFormat } from '@/i18n/hooks';

interface Props {
  transaction: Transaction;
  open: boolean;
  onClick: MouseEventHandler<HTMLTableRowElement>;
}

/**
 * The expandable row in the transaction table containing the most important transaction information.
 */
export const TransactionMainRow: FC<Props> = ({
  transaction,
  open,
  onClick,
}) => {
  const dateFnsLocale = useUserDateFnsLocale();
  const dateFormat = useUserDateFormat('short');

  return (
    <StyledRow onClick={onClick}>
      <StyledTableCell data-test="transaction-table-toggle-details-view">
        <SystemIcon name={open ? 'chevron-up' : 'chevron-down'} size={16} />
      </StyledTableCell>
      <StyledTableCell data-test="transaction-table-created-date">
        {format(transaction.createdDate, dateFormat, {
          locale: dateFnsLocale,
        })}
      </StyledTableCell>
      <StyledTableCell data-test="transaction-table-fund-name">
        {transaction.securityName}
      </StyledTableCell>
      <StyledTableCell data-test="transaction-table-type">
        {transaction.transactionType}
      </StyledTableCell>
      <StyledTableCell alignRight data-test="transaction-table-nominal">
        {transaction.nominal}
      </StyledTableCell>
      <StyledTableCell alignRight data-test="transaction-table-price">
        {transaction.nominal}
      </StyledTableCell>
    </StyledRow>
  );
};

const StyledRow = styled.tr`
  cursor: pointer;
`;

const StyledTableCell = styled.td<{ alignRight?: boolean }>`
  line-height: 24px;
  padding: 11px 16px;
  min-height: 48px;
  ${({ alignRight }) => alignRight && 'text-align: right;'}
`;
