import { useFundListQuery } from '@/api/funds/fundsApi';
import { FundDocument, FundDocumentType } from '@/api/funds/models';
import { useUserLocale } from '@/i18n/hooks';
import { Locale } from '@/i18n/locale';
import { Brand } from '@/types/Brand';

export const useFindDocumentByType = (
  isin: string,
  type: FundDocumentType,
): FundDocument | undefined => {
  const locale = useUserLocale();
  const fundListQuery = useFundListQuery();

  const fund = fundListQuery.data.byIsin[isin];

  if (!fund) {
    throw new Error(
      `Trying to find document for the specified fund, but the fund with isin ${isin} was not found in the fund list.`,
    );
  }

  // Try to find the relevant document in the language set by the user.
  let fundDocument = fund.documents.find(
    (document) =>
      document.documentType === type && document.languageCode === locale,
  );

  // If the document is not available in the users chosen language, try to
  // find the document in a language based on the funds legal entity.
  if (!fundDocument) {
    const language =
      fund.brand === Brand.SPP ? Locale.Swedish : Locale.Norwegian;

    fundDocument = fund.documents.find(
      (document) =>
        document.documentType === type && document.languageCode === language,
    );
  }

  // If the document is not found in any of the wanted languages, try to find
  // any document of the right type.
  if (!fundDocument) {
    fundDocument = fund.documents.find(
      (document) => document.documentType === type,
    );
  }

  return fundDocument;
};
