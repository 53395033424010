/**
 * Get the organization CMID from the URL.
 *
 * @returns The organization CMID from the URL. If not present, an error is thrown.
 */
export const getOrganizationCmIdFromUrl = (): string => {
  const organizationCmId = window.location.pathname.split('/')[1];

  const isValidCmId = new RegExp('^[0-9]+$').test(organizationCmId);

  if (!isValidCmId) {
    throw new Error(
      `Failed to get CMID from the URL. Parsed value: [${organizationCmId}].`,
    );
  }

  return organizationCmId;
};
