/**
 * Try to parse the URL and return the pathname.
 * Returns the string as-is if the URL is invalid (e.g. if it is a relative path).
 */
export const tryGetPathnameFromUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url);

    return parsedUrl.pathname;
  } catch (error) {
    // The URL constructor throws TypeError if the URL is invalid.
    // E.g.: it throws when the URL is relative.
    return url;
  }
};
