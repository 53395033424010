import Select, { Option } from '@storeblocks/select';
import React, { useState } from 'react';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFms } from '@/hooks/useFms';
import { CurrencyCode } from '@/types/CurrencyCode';

interface Props {
  initialCurrency: CurrencyCode | undefined;
  portfolioShortName: string;
  onChange: (currency: string) => void;
}

export const CurrencySelector: React.FC<Props> = ({
  initialCurrency,
  portfolioShortName,
  onChange,
}) => {
  const { orders } = useFms();
  const { data: fundHoldings } = useFetchFundHoldingsQuery();

  const currencies = Object.keys(
    fundHoldings.byPortfolioId[portfolioShortName].byCurrency,
  );

  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    initialCurrency || '',
  );

  const options = currencies.map<Option>((currency) => ({
    value: currency,
    label: currency,
  }));

  const handleOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSelectedCurrency(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Select
      name="selectedCurrency"
      id="currency-selector"
      label={orders.form.currency.title}
      hint={orders.form.currency.message}
      placeholder={orders.form.currency.placeholder}
      options={options}
      value={selectedCurrency}
      large
      onChange={handleOnChange}
    />
  );
};
