import { combineReducers } from 'redux';

import { adminApi } from './api/admin/adminApi';
import { assignableRolesApi } from './api/assignableRoles/assignableRolesApi';
import { contactUsFormApi } from './api/contactUsForm/contactUsFormApi';
import { countriesApi } from './api/countries/countriesApi';
import { documentArchiveApi } from './api/documentArchive/documentArchiveApi';
import { esgApi } from './api/esg/esgApi';
import { fmsApi } from './api/fms/fmsApi';
import { fundHoldingsApi } from './api/fundHoldings/fundHoldingsApi';
import { fundsApi } from './api/funds/fundsApi';
import { newsArticlesApi } from './api/newsArticles/newsArticlesApi';
import { orderApi } from './api/order/orderApi';
import { orderListApi } from './api/orderList/orderListApi';
import { organizationsApi } from './api/organizations/organizationsApi';
import { organizationUsersApi } from './api/organizationUsers/organizationUsersApi';
import { paymentOptionsApi } from './api/paymentOptions/paymentOptionsApi';
import { multiPortfolioReturnsApi } from './api/performanceData/multiPortfolioReturnsApi';
import { performanceDataApi } from './api/performanceData/performanceDataApi';
import { permissionsApi } from './api/permissions/permissionsApi';
import { multiPortfolioGroupHoldingsApi } from './api/portfolioGroupHoldings/multiPortfolioGroupHoldingsApi';
import { portfoliosApi } from './api/portfolios';
import { reportsApi } from './api/reports/reportsApi';
import { signingRightApi } from './api/signing-right/signingRightApi';
import { transactionsApi } from './api/transactions/transactionsApi';
import { usersApi } from './api/users/usersApi';
import { userSettingsApi } from './api/userSettings/userSettingsApi';
import { logReducer } from './logging/logSlice';

export const rootReducer = combineReducers({
  log: logReducer,
  [esgApi.reducerPath]: esgApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [fundsApi.reducerPath]: fundsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [orderListApi.reducerPath]: orderListApi.reducer,
  [portfoliosApi.reducerPath]: portfoliosApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [userSettingsApi.reducerPath]: userSettingsApi.reducer,
  [fundHoldingsApi.reducerPath]: fundHoldingsApi.reducer,
  [signingRightApi.reducerPath]: signingRightApi.reducer,
  [newsArticlesApi.reducerPath]: newsArticlesApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [contactUsFormApi.reducerPath]: contactUsFormApi.reducer,
  [paymentOptionsApi.reducerPath]: paymentOptionsApi.reducer,
  [performanceDataApi.reducerPath]: performanceDataApi.reducer,
  [multiPortfolioReturnsApi.reducerPath]: multiPortfolioReturnsApi.reducer,
  [multiPortfolioGroupHoldingsApi.reducerPath]:
    multiPortfolioGroupHoldingsApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [assignableRolesApi.reducerPath]: assignableRolesApi.reducer,
  [organizationUsersApi.reducerPath]: organizationUsersApi.reducer,
  [documentArchiveApi.reducerPath]: documentArchiveApi.reducer,
  [fmsApi.reducerPath]: fmsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
});
