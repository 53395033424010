import Button from '@storeblocks/button';
import Input from '@storeblocks/input/lib';
import React, { useState } from 'react';
import styled from 'styled-components';

import { DebugSurface } from './DebugSurface';

export const ThrowError: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  return (
    <DebugSurface>
      <p>Throw an error and the global error boundary will catch it.</p>
      <Container>
        <Input
          id="throw-error-input-message"
          name="throw-error-input-message"
          label="Error message"
          value={errorMessage}
          onChange={(e) => {
            setErrorMessage(e.target.value);
            setSuccessMessage(undefined);
          }}
          success={successMessage}
          large
        />
        <div>
          <ThrowButton
            onClick={() => {
              setSuccessMessage('Done, check console');

              throw new Error(errorMessage);
            }}
            disabled={errorMessage === ''}
          >
            Throw
          </ThrowButton>
        </div>
      </Container>
    </DebugSurface>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ThrowButton = styled(Button)`
  margin-left: 16px;
  margin-top: 40px;
`;
