import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { CurrencyCode } from '@/types/CurrencyCode';

/**
 * Where to place the withdrawal for a given redemption order line.
 */
export interface RedemptionPayment {
  /**
   * A type guard field.
   * It allows us to distinguish between different payment types
   * when we have a list of mixed payment types.
   */
  type: 'REDEMPTION_PAYMENT';

  paymentPlacement: OrderPaymentType;

  currency: CurrencyCode;

  /**
   * The bank account number that the user specifies during the order process.
   * This is only applicable if the selected `paymentPlacement` is bank account.
   */
  bankAccountNumber: string | undefined;

  /**
   * The portfolio bank account number the user wants to do payment from.
   * This is only applicable if the selected `paymentPlacement` is portfolio.
   */
  portfolioBankAccountNumber: string | undefined;
}

/**
 * Where to do the payment for a given subscription order line.
 */
export interface SubscriptionPayment {
  /**
   * A type guard field.
   * It allows us to distinguish between different payment types
   * when we have a list of mixed payment types.
   */
  type: 'SUBSCRIPTION_PAYMENT';

  paymentPlacement: OrderPaymentType;

  /**
   * The bank account number for the portfolio the user wants to do payment from.
   * This is only applicable if the selected `paymentPlacement` is portfolio.
   */
  portfolioBankAccountNumber: string | undefined;

  /**
   * Payment information for the given funds in the `forIsins` list.
   * This information comes from the fund list.
   */
  fundPaymentInformation: {
    accountHolder: PaymentAccountHolder;
    currency: CurrencyCode;
    bankAccountNumber: string;
    iban: string;
    swift: string;
  };

  /**
   * ISINs for the funds the payment is applicable for.
   *
   * Create a two-way relationship between the payment and the order line.
   * It makes it easier to find the payment for a given order line.
   */
  forIsins: string[];
}

/**
 * Type guard for `SubscriptionPayment`.
 */
export const isSubscriptionPayment = (
  payment: SubscriptionPayment | RedemptionPayment,
): payment is SubscriptionPayment => payment.type === 'SUBSCRIPTION_PAYMENT';

/**
 * Type guard for `RedemptionPayment`.
 */
export const isRedemptionPayment = (
  payment: SubscriptionPayment | RedemptionPayment,
): payment is RedemptionPayment => payment.type === 'REDEMPTION_PAYMENT';
