import React, { useState } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import {
  formatDateArgument,
  useFetchAssetPerformanceQuery,
} from '@/api/performanceData/performanceDataApi';
import { isNotReady } from '@/api/utils/isNotReady';
import { Helper } from '@/components/Helper';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { AssetAllocationViewModeChips } from '../components/AssetAllocationViewModeChips';
import { AssetAllocationViewMode } from '../types/AssetAllocationViewMode';
import { AssetAllocationTable } from './AssetAllocationTable';

interface Props {
  portfolioGroupShortName: string;
  date?: Date;
  defaultViewMode?: AssetAllocationViewMode;
}

const today = new Date();

export const AssetAllocationTableWidget: React.FC<Props> = ({
  portfolioGroupShortName,
  date,
  defaultViewMode,
}) => {
  const texts = useFms();

  const assetPerformanceQuery = useFetchAssetPerformanceQuery({
    portfolioGroup: portfolioGroupShortName,
    to: formatDateArgument(date ?? today),
  });

  const [selectedViewMode, setSelectedViewMode] =
    useState<AssetAllocationViewMode>(
      defaultViewMode ?? AssetAllocationViewMode.Holdings,
    );

  return (
    <WithGap
      data-test={`widget-asset-allocation-table-${portfolioGroupShortName}`}
    >
      <WithGap gap="8" direction="row">
        <H2>{texts.widgets.assetAllocation.table.title}</H2>

        <Helper
          id="asset-allocation-table"
          title={texts.widgets.assetAllocation.table.help.title}
          description={texts.widgets.assetAllocation.table.help.description}
        />
      </WithGap>

      <FilterContainer>
        <AssetAllocationViewModeChips
          checked={selectedViewMode}
          onChange={setSelectedViewMode}
        ></AssetAllocationViewModeChips>
      </FilterContainer>
      {isNotReady(assetPerformanceQuery) ? (
        <QueryState query={assetPerformanceQuery} />
      ) : (
        <AssetAllocationTable
          viewMode={selectedViewMode}
          assetPerformance={assetPerformanceQuery.data}
        />
      )}
    </WithGap>
  );
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: end;
`;
