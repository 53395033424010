import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import React, { FC, useEffect } from 'react';

import { OrganizationUser } from '@/api/organizationUsers/models/OrganizationUser';
import {
  useDeleteOrganizationUserMutation,
  useOrganizationUsersQuery,
} from '@/api/organizationUsers/organizationUsersApi';
import { User } from '@/api/users/models/User';
import { useDeleteUserMutation } from '@/api/users/usersApi';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { FMSTexts } from '@/types/fms';

interface PropsModal {
  show: boolean;
  name: string;
  isError: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteModal: FC<PropsModal> = ({
  show,
  name,
  isError,
  onClose,
  onDelete,
}) => {
  const texts: FMSTexts = useFms();
  const getTrackId = useGetTrackId();

  return (
    <Modal trigger={undefined} open={show} onClose={onClose}>
      <Heading>{`${texts.admin.delete.areYouSure} ${name}?`}</Heading>
      <Content>{texts.admin.delete.cannotBeReverted}</Content>
      <Footer>
        <Button
          onClick={onDelete}
          key="delete-button"
          data-trackid={getTrackId(
            'delete-user:confirm-delete-user-modal',
            TrackingElement.Button,
          )}
          mr="8px"
        >
          {texts.admin.user.delete}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          key="cancel-button"
          data-trackid={getTrackId(
            'cancel:confirm-delete-user-modal',
            TrackingElement.Button,
          )}
        >
          {texts.admin.user.cancel}
        </Button>
      </Footer>
      {isError && (
        <Alert
          fluid
          variant="error"
          title={texts.admin.user.deleteError}
          data-test="add-organization-error-alert"
        />
      )}
    </Modal>
  );
};

interface PropsIndividualUser {
  show: boolean;
  user: User;
  onClose: () => void;
}

export const DeleteIndividualUserModal: FC<PropsIndividualUser> = ({
  show,
  user,
  onClose,
}) => {
  const [deleteUser, deleteUserRequest] = useDeleteUserMutation();

  const handleDelete = (): void => {
    deleteUser(user.userId);
  };

  useEffect(() => {
    if (deleteUserRequest.isSuccess) {
      onClose();
    }
  }, [deleteUserRequest.isSuccess]);

  return (
    <DeleteModal
      show={show}
      name={`${user.givenName} ${user.familyName}`}
      isError={deleteUserRequest.isError}
      onDelete={handleDelete}
      onClose={onClose}
    />
  );
};

interface PropsOrganizationUser {
  show: boolean;
  user: OrganizationUser;
  onClose: () => void;
}

export const DeleteOrganizationUserModal: FC<PropsOrganizationUser> = ({
  show,
  user,
  onClose,
}) => {
  const [deleteOrganizationUser, deleteOrganizationUserRequest] =
    useDeleteOrganizationUserMutation();

  const organizationUsersQuery = useOrganizationUsersQuery();

  const handleDelete = (): void => {
    deleteOrganizationUser(user.userId);
  };

  useEffect(() => {
    if (deleteOrganizationUserRequest.isSuccess) {
      organizationUsersQuery.refetch();
      onClose();
    }
  }, [deleteOrganizationUserRequest.isSuccess]);

  return (
    <DeleteModal
      show={show}
      name={`${user.givenName} ${user.familyName}`}
      isError={deleteOrganizationUserRequest.isError}
      onDelete={handleDelete}
      onClose={onClose}
    />
  );
};
