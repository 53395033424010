import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { Column, Row } from '@/components/Grid';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { getSubscriptionPaymentTitle } from '@/pages/Order/utils/getSubscriptionPaymentTitle';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { SubscriptionOrderFormValues } from '../../../types/SubscriptionOrderFormValues';
import { createCompositeKey } from '../../../utils/createCompositeKey';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';
import { useSubscriptionPaymentOptions } from '../hooks/useSubscriptionPaymentOptions';
import { SubscriptionPaymentInformation } from './SubscriptionPaymentInformation';
import { SubscriptionPaymentRadio } from './SubscriptionPaymentRadio';
import { SubscriptionPortfolioBankAccount } from './SubscriptionPortfolioBankAccount';

export const SubscriptionPaymentPlacement: React.FC = () => {
  const texts = useFms();
  const { values } = useFormikContext<SubscriptionOrderFormValues>();
  const portfolios = useFundPortfolios(OrderType.Subscription);

  const hasPortfolioBankAccounts =
    getPortfolioBankAccounts(values.toPortfolioShortName, portfolios).length >
    0;

  useSubscriptionPaymentOptions();

  if (values.payments.length === 0) {
    return null;
  }

  return (
    <Container>
      {values.payments.map((payment, paymentIndex) => {
        return (
          <div
            key={`${payment.fundPaymentInformation.bankAccountNumber}-${payment.fundPaymentInformation.currency}`}
            data-test={`subscription-payment-placement-${createCompositeKey(
              payment.fundPaymentInformation.accountHolder,
              payment.fundPaymentInformation.currency,
            )}`}
          >
            {
              // When the user has portfolio bank accounts, let the user choose
              // how to pay. Through the portfolio bank account (porteføljekonto)
              // or with a bank transfer.
              hasPortfolioBankAccounts ? (
                <WithGap>
                  <SubscriptionPaymentRadio
                    currency={payment.fundPaymentInformation.currency}
                    accountHolder={payment.fundPaymentInformation.accountHolder}
                    paymentIndex={paymentIndex}
                    // Display the currency in the title if there are two or more
                    // currencies in play.
                    displayCurrencyInTitle={values.payments.length > 1}
                  />

                  {/*
                   * Only display the portfolio info when the portfolio option is
                   * selected in the radio input.
                   */}
                  {payment.paymentPlacement === OrderPaymentType.Portfolio && (
                    <Row>
                      <Column $span={5}>
                        <SubscriptionPortfolioBankAccount
                          currency={payment.fundPaymentInformation.currency}
                          accountHolder={
                            payment.fundPaymentInformation.accountHolder
                          }
                          paymentIndex={paymentIndex}
                        />
                      </Column>
                    </Row>
                  )}

                  {/*
                   * Only display the bank payment info when the bank option is
                   * selected in the radio input.
                   */}
                  {payment.paymentPlacement ===
                    OrderPaymentType.BankAccount && (
                    <div>
                      <PaymentTitle>
                        {
                          texts.orders.form.payment.options.subscription
                            .settlementAccountShort
                        }
                      </PaymentTitle>
                      <SubscriptionPaymentInformation payment={payment} />
                    </div>
                  )}
                </WithGap>
              ) : (
                // The user has only the option to use bank transfer.
                <>
                  <PaymentTitle>
                    {getSubscriptionPaymentTitle(
                      payment.fundPaymentInformation.accountHolder,
                      payment.fundPaymentInformation.currency,
                      texts,
                      {
                        displayCurrencyInTitle: values.payments.length > 1,
                      },
                    )}
                  </PaymentTitle>

                  <PaymentSubtitle>
                    {
                      texts.orders.form.payment.options.subscription
                        .settlementAccount
                    }
                  </PaymentSubtitle>

                  <SubscriptionPaymentInformation payment={payment} />
                </>
              )
            }
          </div>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const PaymentTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 16px;
`;

const PaymentSubtitle = styled.div`
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 8px;
`;
