import axios from 'axios';

import { isDemoOrganization } from '@/demoMode/isDemoOrganization';
import { getBasename } from '@/routes/utils/getBasename';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { UserType } from './types';
import { ConfigDto } from './types/ConfigDto';
import { isCypress, isLocalhost } from './utils';
import { getUserTypeFromCookie } from './utils/getUserTypeFromCookie';
import { shouldFetchConfigFromBackend } from './utils/shouldFetchConfigFromBackend';

export const initializeServerConfig = async (): Promise<void> => {
  if (isLocalhost() && !shouldFetchConfigFromBackend() && !isCypress()) {
    const userType = getUserTypeFromCookie() ?? UserType.External;
    const isInternal = userType === UserType.Internal;

    window.config = {
      userType,
      storebrandApiUrl: 'https://api-t.storebrand.no',
      storeblocksAssetsUrl: 'https://assets-t.storebrand.no/css/index.css',
      fmsUrl:
        'https://api.storebrand.no/open/content/texts/storkunde?version=pending',
      keycloakUrl: isInternal
        ? 'https://id-t.intra.storebrand.no/auth'
        : 'https://id-t.storebrand.no/auth',
      keycloakRealm: isInternal ? 'corporate' : 'storebrand',
      keycloakClientId: 'storebrand.sam-connect',
      enableSentry: false,
      sentryEnvironment: 'localhost',
      postLogEvents: false,
      printLogEvents: true,
      enableDevTools: true,

      /**
       * The URL to the Connect backend API.
       *
       * Goes to the backend in staging when we are on localhost.
       * We don't wan't to fire up a local backend via IntelliJ
       * since it's cumbersome and
       * not needed for 99% of our frontend development work.
       */
      connectBaseApiUrl: 'https://connect-t.storebrand.no',
    };
  } else {
    const { data } = await axios.get<ConfigDto>(`${getBasename()}/config`);

    window.config = {
      userType: convertStringToEnum(data.userType, UserType),
      storebrandApiUrl: data.storebrandApiUrl,
      storeblocksAssetsUrl: data.storeblocksAssetsUrl,
      fmsUrl: data.fmsUrl,
      keycloakUrl: data.keycloakUrl,
      keycloakRealm: data.keycloakRealm,
      keycloakClientId: data.keycloakClientId,
      sentryEnvironment: data.sentryEnvironment,
      printLogEvents: data.printLogEvents,
      enableDevTools: data.enableDevTools,

      /**
       * Don't POST log events to Connect if we are in demo mode.
       */
      postLogEvents: data.postLogEvents && !isDemoOrganization(),

      /**
       * Don't log to Sentry if we are in demo mode.
       */
      enableSentry: data.enableSentry && !isDemoOrganization(),

      /**
       * The URL to the Connect backend API.
       *
       * The backend and frontend are on the same domain.
       * Thus no need for a base URL as we can use relative paths
       * when doing HTTP calls.
       */
      connectBaseApiUrl: '',
    };
  }
};
