import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { ApiMeta } from '../types/ApiMeta';
import { EsgReply } from './dtos/EsgReply';
import { FundEsgReply } from './dtos/FundEsgReply';
import { Esg } from './models/Esg';
import { FundEsg } from './models/FundEsg';
import { transformFundEsgReply } from './utils/transformFundEsgReply';
import { transformOrganizationEsgReply } from './utils/transformOrganizationEsgReply';

export const esgApi = createApi({
  reducerPath: 'esgApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    /**
     * Get the latest ESG sustainability data for the organization.
     */
    organizationEsg: builder.query<Esg | null, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/esg/organization`,
      }),
      transformResponse: (dto: EsgReply, meta: ApiMeta) => {
        if (meta?.statusCode === 204) {
          // Some organizations don't have ESG data.
          // This endpoint will return HTTP 204 for those.
          return null;
        }

        return transformOrganizationEsgReply(dto);
      },
    }),

    /**
     * Get the latest ESG sustainability data for the specified portfolio group.
     */
    portfolioGroupEsg: builder.query<Esg | null, { portfolioGroupId: string }>({
      query: ({ portfolioGroupId }) => ({
        url: `${window.config.connectBaseApiUrl}/api/esg/portfolio-group/${portfolioGroupId}`,
      }),
      transformResponse: (dto: EsgReply, meta: ApiMeta, arg) => {
        if (meta?.statusCode === 204) {
          // Some portfolio groups don't have ESG data.
          // This endpoint will return HTTP 204 for those.
          return null;
        }

        const model = transformOrganizationEsgReply(dto);
        model.portfolioGroupId = arg.portfolioGroupId;

        return model;
      },
    }),

    /**
     * Get the latest ESG sustainability data for the specified fund.
     */
    fundEsg: builder.query<FundEsg | null, { isin: string }>({
      query: ({ isin }) => ({
        url: `${window.config.connectBaseApiUrl}/api/esg/fund/${isin}`,
      }),
      transformResponse: (dto: FundEsgReply | null, meta: ApiMeta) => {
        if (meta?.statusCode === 204) {
          // Some funds don't have ESG data.
          // This endpoint will return HTTP 204 for those.
          return null;
        }

        return transformFundEsgReply(dto);
      },
    }),
  }),
});

export const {
  useOrganizationEsgQuery,
  usePortfolioGroupEsgQuery,
  useFundEsgQuery,
} = esgApi;
