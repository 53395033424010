import styled, { css } from 'styled-components';

export const h1Style = css`
  font-family: 'SignaWeb-ColumnBook';
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: var(--color-on-background);
`;

export const H1 = styled.h1`
  ${h1Style}
`;
