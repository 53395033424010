// Only initialize the cookie consent popup once.
let isInitialized = false;

/**
 * Initialize the cookie consent popup.
 *
 * The cookie consent popup is displayed when these
 * two conditions are met:
 *
 * - The lang attribute is set on the HTML element. Since the popup
 *   needs to be displayed in the correct language and it uses the
 *   lang attribute to determine what language to display.
 *
 * - The user has not previously accepted the cookie consent. This
 *   is determined if the "_sfid_cc_cookie" cookie exists or not.
 *
 * The cookie consent popup is only initialized once.
 *
 * The repository for the cookie consent popup is located at:
 * https://github.com/storebrand-digital/enonic-cloud/blob/main/assets.storebrand.no/cookieconsent-no.js
 */
export function initializeCookieConsent(): void {
  // Create a observer to watch for changes to the lang attribute.
  const observer = new MutationObserver(() => {
    const isLangAttributeEmpty = !document.documentElement.lang;

    if (isLangAttributeEmpty || isInitialized) {
      // Do nothing when the lang attribute is empty, or the cookie consent has already
      // been initialized.
      return;
    }
    // We have the lang attribute.
    // Add scripts to the head of the document to fetch the cookie consent script.

    // CSS for the cookie consent popup.
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href =
      'https://assets.storebrand.no/elements/scripts/cookieconsent.css';
    document.head.appendChild(link);

    // JavaScript for the cookie consent popup.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://assets.storebrand.no/elements/scripts/cookieconsent-no.js';
    document.head.appendChild(script);

    // Make sure we only initialize the cookie consent popup once.
    isInitialized = true;
  });

  // Begin observing.
  observer.observe(document.documentElement, {
    attributes: true,
    // Only watch for changes to the lang attribute.
    attributeFilter: ['lang'],
  });
}
