import Button from '@storeblocks/button';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { Order } from '@/api/orderList/models/Order';
import { H3, Hint, Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { defaultDateFormat } from '@/i18n/hooks/useUserDateFormat';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { orderExpiryDate } from '@/util/orderExpiryDate';

import { CheckIcon } from './CheckIcon';
import { WaitingIcon } from './WaitingIcon';

interface Props {
  order: Order;
}

export const SignatoriesTable: React.FC<Props> = ({ order }) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();

  const allSigned = order.signatories.every(
    (signatories) => signatories.signedDate,
  );

  return (
    <WithGap gap="16">
      <H3>{texts.pages.orderList.signatures.title}</H3>

      {/* Only display the expiry date when there is a missing signature. */}
      {!allSigned && (
        <Hint>
          {fmsWithTemplate(texts.pages.orderList.expiryDate, {
            dateTime: format(
              orderExpiryDate(order.createdDate),
              `${defaultDateFormat} HH:mm`,
            ),
          })}
        </Hint>
      )}

      <Table>
        <tbody>
          {order.signatories.map((signatory) => (
            <tr key={signatory.name}>
              <td>
                <Paragraph>{signatory.name}</Paragraph>
              </td>
              <td>
                <StyledWithGap direction="row" gap="8">
                  {signatory.signedDate ? (
                    <>
                      <Paragraph>
                        {texts.pages.orderList.signatures.signed}
                      </Paragraph>
                      <CheckIcon />
                    </>
                  ) : (
                    <>
                      <Paragraph>
                        {texts.pages.orderList.signatures.notSigned}
                      </Paragraph>
                      <WaitingIcon />
                    </>
                  )}
                </StyledWithGap>
              </td>
              <td>
                {signatory.signedDate && (
                  <Paragraph>
                    {format(signatory.signedDate, `${defaultDateFormat} HH:mm`)}
                  </Paragraph>
                )}

                {!signatory.signedDate && signatory.signUrl && (
                  <Button
                    variant="contained"
                    iconRight="edit"
                    as="a"
                    href={signatory.signUrl}
                    data-trackid={getTrackId(
                      'go-to-signicat',
                      TrackingElement.Link,
                    )}
                  >
                    {texts.pages.orderList.signatures.signOrder}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </WithGap>
  );
};

const Table = styled.table`
  border-spacing: 0 8px;

  tr td:last-of-type {
    text-align: right;
  }
`;

const StyledWithGap = styled(WithGap)`
  align-items: baseline;
`;
