import { useEffect, useState } from 'react';

export const useConsumeQueryParameter = (name: string): string | undefined => {
  const url = new URL(window.location.href);
  const queryParam = url.searchParams.get(name);

  // Since we set the value as the default value it will not change on
  // rerenders even when the `queryParam` above changes.
  const [param] = useState<string | undefined>(queryParam ?? undefined);

  useEffect(() => {
    // Remove the query parameter from the URL.
    url.searchParams.delete(name);
    window.history.replaceState({}, '', url);
  }, [name]);

  return param;
};
