import React from 'react';
import styled from 'styled-components';

import { Show } from './Show';
import { H1, Hint, Ingress } from './Typography';

interface PageHeadingProps {
  header: string;
  ingress?: React.ReactNode;
  hint?: React.ReactNode;
}

export const PageHeading: React.FC<PageHeadingProps> = ({
  header,
  ingress,
  hint,
}) => (
  <Show when={!!header}>
    <Wrapper>
      <H1>{header}</H1>
      <Show when={!!ingress}>
        <StyledIngress>{ingress}</StyledIngress>
      </Show>
      <Show when={!!hint}>
        <StyledHint hasIngress={!!ingress}>{hint}</StyledHint>
      </Show>
    </Wrapper>
  </Show>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIngress = styled(Ingress)`
  margin-top: 16px;
`;

interface HintProps {
  hasIngress?: boolean;
}

const StyledHint = styled(Hint)<HintProps>`
  margin-top: ${({ hasIngress }) => (hasIngress ? '24px' : '16px')};
`;
