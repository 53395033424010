import { Illustration } from '@storeblocks/icon';
import React, { FC } from 'react';
import styled from 'styled-components';

import { useCurrentUserInfo } from '@/auth/hooks';
import { Column, Row } from '@/components/Grid';
import { HideOnMobile } from '@/components/HideOnMobile';
import { OrganizationSelector } from '@/components/OrganizationSelector';
import { H1 } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

export const HeaderSection: FC = () => {
  const texts = useFms();

  const { name } = useCurrentUserInfo();

  const title = name ? `${texts.home.title} ${name}!` : `${texts.home.title}!`;

  return (
    <>
      <H1>{title}</H1>
      <StyledRow>
        <Column $span={7}>
          <OrganizationSelector initialCollapseSize={5} />
        </Column>
        <Column $span={5}>
          <HideOnMobile>
            <IllustrationWrapper>
              <Illustration name="profitable-sustainability" />
            </IllustrationWrapper>
          </HideOnMobile>
        </Column>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  padding: 0 24px;
`;

const IllustrationWrapper = styled.div`
  padding: 96px;
  max-height: 296px;
`;
