import React from 'react';
import { styled } from 'styled-components';

import { useUserLocale } from '@/i18n/hooks';
import { CurrencyCode } from '@/types/CurrencyCode';
import { formatNumber } from '@/util/formatNumber';

interface Props {
  amount: number;
  currency: CurrencyCode;
}

export const MinimumSubscriptionAmountCell: React.FC<Props> = ({
  amount,
  currency,
}) => {
  const locale = useUserLocale();

  return (
    <Container data-test="minimum-subscription-amount">
      {formatNumber(amount, locale, currency)}
    </Container>
  );
};

const Container = styled.div`
  line-height: 24px;
`;
