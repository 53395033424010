import { createApi } from '@reduxjs/toolkit/query/react';

import { authorizationApiUrl } from '@/api/utils/authorizationApiUrl';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { AddOrganizationDto } from './dtos/AddOrganizationDto';
import { EditOrganizationDto } from './dtos/EditOrganizationDto';
import { OrganizationsReply } from './dtos/OrganizationsReply';
import { Organization } from './models/Organization';
import { transformOrganizationsReply } from './utils/transformOrganizationsReply';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    organizations: builder.query<Array<Organization>, void>({
      query: () => ({
        url: `${authorizationApiUrl()}/organizations/allowed/v2`,
      }),
      transformResponse: (dto: OrganizationsReply) => {
        return transformOrganizationsReply(dto);
      },
    }),

    addOrganization: builder.mutation<void, AddOrganizationDto>({
      query: (organization) => ({
        url: `${authorizationApiUrl()}/organizations`,
        method: 'PUT',
        data: organization,
      }),
    }),

    editOrganization: builder.mutation<void, EditOrganizationDto>({
      query: (organization) => ({
        url: `${authorizationApiUrl()}/organizations/${
          organization.organizationId
        }/name`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/text' },
        data: organization.organizationName,
      }),
    }),

    deleteOrganization: builder.mutation<void, string>({
      query: (organizationId) => ({
        url: `${authorizationApiUrl()}/organizations/${organizationId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useOrganizationsQuery,
  useAddOrganizationMutation,
  useEditOrganizationMutation,
  useDeleteOrganizationMutation,
  util,
} = organizationsApi;
