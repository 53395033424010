import { createApi } from '@reduxjs/toolkit/query/react';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { PaymentOptionReply } from './dtos/PaymentOptionReply';
import { PaymentOptions } from './models/PaymentOption';

export const paymentOptionsApi = createApi({
  reducerPath: 'paymentOptionsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    paymentOptions: builder.query<PaymentOptions, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/paymentoptions`,
      }),
      transformResponse: (dto: PaymentOptionReply) => {
        const paymentOptions = dto.map((paymentOption) => ({
          accountNumber: paymentOption.accountNumber,
          denominationCurrency: convertStringToEnum(
            paymentOption.denominationCurrency,
            CurrencyCode,
          ),
        }));

        return {
          paymentOptions,
        };
      },
    }),
  }),
});

export const { usePaymentOptionsQuery } = paymentOptionsApi;
