import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { FundListDto } from './dtos/FundListDto';
import { FundList } from './models/FundList';
import { transformFundListResponse } from './utils/transformFundListResponse';

export const fundsApi = createApi({
  reducerPath: 'fundsApi',
  baseQuery: axiosBaseQuery<FundListDto>(),
  endpoints: (builder) => ({
    fundList: builder.query<FundList, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/funds`,
      }),
      transformResponse: (dto: FundListDto) => {
        return transformFundListResponse(dto);
      },
    }),
  }),
});

export const { useFundListQuery } = fundsApi;
