import ChipGroup, { Option } from '@storeblocks/chip/lib/group';
import React from 'react';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  id: string;
  dataSource: string;
  valueType: OrderValueType;
  validValueTypes: Array<OrderValueType>;
  onChange: (valueType: string) => void;
}

export const ValueTypeSelector: React.FC<Props> = ({
  id,
  dataSource,
  valueType,
  validValueTypes,
  onChange,
}) => {
  const { orders } = useFms();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId('select-value-type', TrackingElement.FilterChip);

  const textMap = {
    [OrderValueType.Amount]: orders.form.valueType.amount,
    [OrderValueType.Percent]: orders.form.valueType.percent,
    [OrderValueType.Units]: orders.form.valueType.units,
  };

  const options: Array<Option> = validValueTypes.map((validValueType) => ({
    id: `${id}-${validValueType}`,
    label: textMap[validValueType],
    value: validValueType,
    inputProps: {
      'data-trackid': trackId,
      'data-trackid-selected-filter': validValueType,
      'data-trackid-source': dataSource,
      'data-trackid-type': 'filter-chips',
    },
  }));

  const handleOnChange = (checked: Array<string>): void => {
    onChange(checked[0]);
  };

  return (
    <ChipGroup
      type="choice"
      id={id}
      checked={[valueType]}
      options={options}
      onChange={handleOnChange}
    />
  );
};
