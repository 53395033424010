import Stepper from '@storeblocks/stepper';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useIsCurrentUserOwner } from '../hooks/useIsCurrentUserOwner';
import { useOrderPageContext } from '../OrderPageContext';

interface OrderStepsState {
  activeStep: number;
  setActiveStep: (step: number) => void;
  completeCurrentStep: () => void;
  goBack: () => void;
}

const OrderStepsContext = React.createContext<OrderStepsState | null>(null);

interface Props extends PropsWithChildren {
  initialStep?: number;
}

export const OrderStepsProvider: FC<Props> = ({ initialStep, children }) => {
  const [activeStep, setActiveStep] = useState<number>(initialStep ?? 0);

  const isOwner = useIsCurrentUserOwner();

  const { orderType, currentOrder } = useOrderPageContext();

  const goBack = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  const completeCurrentStep = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    setActiveStep(0);
  }, [orderType]);

  useEffect(() => {
    if (currentOrder) {
      if (isOwner) {
        setActiveStep(1);
      } else {
        setActiveStep(0);
      }
    }
  }, [currentOrder, isOwner]);

  const contextData = useMemo<OrderStepsState>(() => {
    return {
      activeStep,
      setActiveStep,
      completeCurrentStep,
      goBack,
    };
  }, [activeStep, completeCurrentStep, goBack]);

  return (
    <OrderStepsContext.Provider value={contextData}>
      <Stepper active={activeStep} orientation="horizontal">
        {children}
      </Stepper>
    </OrderStepsContext.Provider>
  );
};

export const useOrderSteps = (): OrderStepsState => {
  const context = useContext(OrderStepsContext);

  if (!context) {
    throw new Error(
      `${useOrderSteps.name} must be called within a ${OrderStepsProvider.name}.`,
    );
  }

  return context;
};
