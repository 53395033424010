import HighchartsReact from 'highcharts-react-official';
import React, { FC, memo } from 'react';

import { Unit } from '@/types/Unit';

import { useHighcharts } from '../highcharts';
import { ColumnChartData } from './types/ColumnChartData';
import { useColumnChartOptions } from './useColumnChartOptions';

interface Props {
  data: Array<ColumnChartData>;
  min?: number;
  max?: number;
  unit?: Unit;
  yAxisSuffix?: string;
  showLegend?: boolean;
  /** Used by Google Tag Manager to identify hover event. */
  trackId?: string;
}

/**
 * Highcharts Column Chart.
 * @param {ColumnChartData} data - Data to be rendered in the chart.
 * @param {number} [min=0] - The minimum `y` value of the chart.
 * @param {number} [max=100] - The maximum `y` value of the chart.
 * @param {Unit} [unit=Unit.Amount] - Used for number formatting. Defaults to `Amount`.
 * @param {string} [trackId] - GTM id for interaction tracking.
 */
export const ColumnChart: FC<Props> = memo(
  ({ data, min, max, unit = Unit.Amount, showLegend, trackId }: Props) => {
    const highcharts = useHighcharts();

    const options = useColumnChartOptions(data, min, max, unit, showLegend);

    return (
      <HighchartsReact
        highcharts={highcharts}
        options={options}
        containerProps={{ 'data-chartid': trackId }}
        immutable
      />
    );
  },
);

ColumnChart.displayName = ColumnChart.name;
