import styled from 'styled-components';

interface Props {
  /** Defaults to 24px */
  gap?:
    | '8'
    | '16'
    | '24'
    | '32'
    | '40'
    | '48'
    | '56'
    | '64'
    | '72'
    | '80'
    | '88'
    | '96';

  direction?: 'row' | 'column';
}

export const WithGap = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => (props.direction === 'row' ? 'row' : 'column')};
  gap: ${(props) => (props.gap ? `${props.gap}px` : '24px')};
`;
