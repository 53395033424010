import { DateFilter } from '../types/DateFilter';
import { DateFilterType } from '../types/DateFilterType';
import { getDateIntervals } from './getFilterDateIntervals';

export const getDateFilter = (type: DateFilterType): DateFilter => {
  const interval = getDateIntervals().get(type);

  if (!interval) {
    throw new Error(`Missing handler for filter type ${type}.`);
  }

  return {
    type,
    ...interval,
  };
};
