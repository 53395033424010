import mime from 'mime';
import React from 'react';

import { DocumentFile } from '@/api/documentArchive/models/DocumentFile';
import { FileNameColumnWithCheckbox } from '@/components/Table/FileNameColumnWithCheckbox';
import {
  documentsDownloadUrl,
  downloadFiles,
} from '@/pages/Archives/commonLogic';
import { getFilenameWithoutPathAndExtension } from '@/util/FileHelpers';

import { useDocumentsArchiveContext } from './DocumentsArchiveContext';

interface Props {
  document: DocumentFile;
}

export function NameColumn({ document }: Props): JSX.Element {
  const { checkedDocumentFiles, checkDocumentFile, uncheckDocumentFile } =
    useDocumentsArchiveContext();

  const isChecked = (): boolean => checkedDocumentFiles.has(document.id);

  const fileExtension = mime.getExtension(document.fileType) ?? 'unknown';

  return (
    <FileNameColumnWithCheckbox
      filename={getFilenameWithoutPathAndExtension(document.name)}
      fileId={document.id}
      fileExtension={fileExtension}
      checked={isChecked()}
      onCheck={(_, checked) => {
        if (checked) {
          checkDocumentFile(document.id);
        } else {
          uncheckDocumentFile(document.id);
        }
      }}
      onDownload={() => downloadFiles(documentsDownloadUrl(), [document.id])}
    />
  );
}
