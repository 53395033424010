import { AxiosError } from 'axios';

export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).isAxiosError ||
  error instanceof AxiosError ||
  // When Axios does not receive a response from the server, it will throw
  // an AxiosError but the error will, for some odd reason, not have
  // the isAxiosError property. The error object is still an AxiosError with all the
  // associated properties.
  (error as Error).name === 'AxiosError';
