import { unflatten } from 'flat';

import { fmsApi } from '@/api/fms/fmsApi';
import fmsBackupTextsNo from '@/assets/fmsBackupTexts_no.json';
import { Language } from '@/i18n/Language';
import { AppStore } from '@/store';

/**
 * Initialize the store with the Norwegian backup texts
 * for FMS.
 */
export const initializeFmsWithBackupTexts = (store: AppStore): void => {
  store.dispatch(
    fmsApi.util.upsertQueryData(
      'fmsTexts',
      Language.Norwegian,
      unflatten(fmsBackupTextsNo),
    ),
  );
};
