import * as Yup from 'yup';

import { RedemptionFundState } from '../../types/RedemptionFundState';
import { baseFundStateValidationSchema } from './baseFundStateValidationSchema';

export const baseRedemptionFundStateValidationSchema: Yup.SchemaOf<RedemptionFundState> =
  baseFundStateValidationSchema.concat(
    Yup.object({
      sellAll: Yup.boolean().required(),
      marketValue95percent: Yup.number().required(),
      minimumRedemptionAmount: Yup.number().required(),
    }),
  );
