import React from 'react';

import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';

import { HoldingsCell } from '../components/HoldingsCell';
import { RedemptionFundCell } from '../components/RedemptionFundCell';
import { RedemptionInput } from '../components/RedemptionInput';
import { SellAllCheckbox } from '../components/SellAllCheckbox';
import { RedemptionFundRow } from '../types/RedemptionFundRow';

export const mapFundStateToRedemptionFundRow = (
  fund: RedemptionFundState,
): RedemptionFundRow => {
  return {
    fund: {
      displayContent: <RedemptionFundCell isin={fund.isin} />,
      sortContent: fund.isin,
    },
    holdings: {
      displayContent: <HoldingsCell fund={fund} />,
      sortContent: fund.isin,
    },
    sellAll: {
      displayContent: <SellAllCheckbox isin={fund.isin} />,
      sortContent: fund.isin,
    },
    amount: {
      displayContent: <RedemptionInput fund={fund} />,
      sortContent: fund.isin,
    },
  };
};
