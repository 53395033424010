import round from 'lodash/round';

import { DonutChartData } from '@/components/Charts';

export const aggregateDonutChartData = (
  data: DonutChartData,
  aggregatedLength: number,
  aggregatedLegend: string,
): DonutChartData => {
  const dataToAggregate = data.splice(aggregatedLength - 1);
  const aggregated = dataToAggregate.reduce((acc, value) => acc + value[1], 0);

  return [...data, [aggregatedLegend, round(aggregated, 2)]];
};
