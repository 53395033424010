import Fuse from 'fuse.js';

import { Fund } from '@/api/funds/models';

export const fuzzySearchFunds = (query: string, funds: Fund[]): Fund[] => {
  if (query === '') {
    return funds;
  }

  const fuse = new Fuse(funds, {
    keys: ['name', 'isin'],

    // Make the most relevant result be on top.
    shouldSort: true,

    // Make the search more restrictive as not to show results that is not
    // relevant.
    threshold: 0.3,
  });

  // Use fuzzy search.
  return fuse.search(query).map((result) => result.item);
};
