import React from 'react';

import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { nameof } from '@/util/nameof';

import { SubscriptionOrderFormValues } from '../../../types/SubscriptionOrderFormValues';
import { CellWrapper } from './CellWrapper';
import { RemoveFundButton } from './RemoveFundButton';
import { SubscriptionInput } from './SubscriptionInput';

interface Props {
  fund: SubscriptionFundState;
}

export const SubscriptionInputCell: React.FC<Props> = ({ fund }) => {
  const hasHoldings = !!fund.marketValue;

  const fundFieldName = nameof<SubscriptionOrderFormValues>('toFunds');

  return (
    <CellWrapper>
      <SubscriptionInput fund={fund} />

      {/* Only funds without holdings can be removed from the table. */}
      {!hasHoldings && (
        <RemoveFundButton isin={fund.isin} fundFieldName={fundFieldName} />
      )}
    </CellWrapper>
  );
};
