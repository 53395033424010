interface ErrorArgs {
  exceptionMessage: string;
}

/**
 * Error for when we fail to get the JWT bearer token for the authorization header.
 */
export class AuthorizationTokenError extends Error {
  public originalMessage: string;

  constructor(error: ErrorArgs, cause?: unknown) {
    super(
      `Failed to get the token for the authorization header. Message: [${error.exceptionMessage}].`,
      { cause },
    );

    this.originalMessage = error.exceptionMessage;

    // Maintains proper stack trace for where our error was thrown (only available on V8).
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Error.captureStackTrace) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Error.captureStackTrace(this, AuthorizationTokenError);
    }

    this.name = 'Authorization Token Error';
  }
}
