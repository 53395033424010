import React from 'react';
import styled from 'styled-components';

import { TextShort } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

export const NoFundsAddedInfo: React.FC = () => {
  const texts = useFms();

  return (
    <StyledText>{texts.orders.form.table.subscription.noFundsAdded}</StyledText>
  );
};

const StyledText = styled(TextShort)`
  color: var(--color-scrim);
`;
