import orderBy from 'lodash/orderBy';

import { FundHoldings } from '@/api/fundHoldings/models';
import { Fund } from '@/api/funds/models';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';

export const mapHoldingsToSubscriptionFundState = (
  fundsByIsin: Record<string, Fund | undefined>,
  fundHoldings: Array<FundHoldings>,
  valueType: OrderValueType,
): SubscriptionFundState[] => {
  // Map funds that has holdings data.
  const fundStates = fundHoldings.map((holdings) => {
    const fund = fundsByIsin[holdings.isin];

    if (!fund) {
      throw new Error(
        `Customer has holdings in [${holdings.isin}], but the fund is not present in the fund list.`,
      );
    }

    const isClosed = fund.closedForSubscription;

    const fundState: SubscriptionFundState = {
      accountId: holdings.accountId,
      isin: holdings.isin,
      name: holdings.name,
      currency: holdings.currency,
      brand: fund.brand,
      isClosed,

      units: holdings.units,
      nav: holdings.nav,
      // Remove decimals from the market value as they are not needed in the UI.
      marketValue: Math.trunc(holdings.marketValue),

      value: 0,
      valueType,

      minimumSubscriptionAmount: fund.minimumSubsequentSubscriptionAmount,
    };

    return fundState;
  });

  return orderBy(
    fundStates,
    [(fund) => !!fund.marketValue, (fund) => fund.name],
    ['desc', 'asc'],
  );
};
