import type { Locale as DatefnsLocale } from 'date-fns';
import { enGB, nb, sv } from 'date-fns/locale';

import { Language } from '../Language';

export const getDateFnsLocaleFromLanguage = (
  language: Language,
): DatefnsLocale => {
  switch (language) {
    case Language.English:
      return enGB;
    case Language.Norwegian:
      return nb;
    case Language.Swedish:
      return sv;

    default:
      throw new Error(
        `Missing conversion between language [${language}] and date-fns locale.`,
      );
  }
};
