import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';

/**
 * Get the expiry date of an order.
 * The expiry date is the end of the day after the order was created.
 *
 * @example
 * Order was created 22.08.2023, then the expiry date is 23.08.2023 23:59.
 */
export const orderExpiryDate = (orderCreatedDate: Date): Date => {
  return endOfDay(addDays(orderCreatedDate, 1));
};
