import { AssetPerformanceData } from '@/api/performanceData/models';
import { FMSTexts } from '@/types/fms';

import { AssetNode } from '../types';
import { getAssetNodeData } from './getAssetNodeData';
import { getContainerNodes } from './getContainerNodes';

/**
 * Get the asset nodes that should be visible from the asset performance node tree.
 * Only nodes with a node depth of 1 should be visible alongside one top level node.
 *
 * @param assets The asset performance data.
 * @param texts FMS texts
 * @returns The visible node data mapped by unit and period.
 */
export const getVisibleAssetNodes = (
  assets: Array<AssetPerformanceData>,
  texts: FMSTexts,
): Array<AssetNode> => {
  if (assets.length === 0) {
    return [];
  }

  // Get the container nodes from the asset performance node tree.
  // A container node is a node with a depth of 1.
  const containerNodes: Array<AssetPerformanceData> = assets.reduce(
    (nodes, node: AssetPerformanceData) => {
      const containersForNode = getContainerNodes(node);

      containersForNode.forEach((containerNode) => {
        nodes.push(containerNode);
      });

      return nodes;
    },
    [],
  );

  const topLevelNode = assets[0];

  // Prepend the top level node to the start of the node array.
  const assetNodes = [
    containerNodes.length > 1 && {
      ...topLevelNode,
      name: texts.charts.assetReturn.totalLabel,
    },
    ...containerNodes,
  ].filter(Boolean);

  // Construct the node data from the visible nodes by unit and period.
  return assetNodes.map(getAssetNodeData);
};
