import { SystemIcon } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  url: string;
  'data-test': string;
  'data-trackid': string;
}

export const DownloadLink: React.FC<Props> = ({
  title,
  url,
  'data-test': dataTest,
  'data-trackid': dataTrackid,
}) => {
  return (
    <Link
      href={url}
      target="_blank"
      data-test={dataTest}
      data-trackid={dataTrackid}
    >
      <Container>
        <SystemIcon name="pdf" />
        <Text>{title}</Text>
      </Container>

      <SystemIcon
        name="download"
        /*
         * Make the icons to have the same color as the link text.
         * This goes also for the hover state.
         */
        color="inherit"
      />
    </Link>
  );
};

const Link = styled.a`
  color: var(--color-on-background);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-border);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
`;
