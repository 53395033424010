import orderBy from 'lodash/orderBy';

import { PortfolioFundHoldings } from '@/api/fundHoldings/models';
import { FundList } from '@/api/funds/models';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderValueType } from '@/api/order/models/OrderValueType';

import { SubscriptionFundState } from '../../../types/SubscriptionFundState';
import { mapHoldingsToSubscriptionFundState } from '../../../utils/mapHoldingsToSubscriptionFundState';
import { mapOrderPlacementToSubscriptionFundStates } from './mapOrderPlacementToSubscriptionFundStates';

/**
 * Create subscription fund states based on the funds that have holdings and
 * order placements that has state (i.e.: value).
 */
export const createSubscriptionFundStates = (
  orderPlacements: OrderPlacement[],
  portfolioHoldings: PortfolioFundHoldings,
  fundList: FundList,
  valueType: OrderValueType,
): SubscriptionFundState[] => {
  // Map all funds in the portfolio to fund state.
  const holdingsFundStates = mapHoldingsToSubscriptionFundState(
    fundList.byIsin,
    portfolioHoldings.holdings,
    valueType,
  );

  // Map all order placements to fund state.
  const orderPlacementFundStates = mapOrderPlacementToSubscriptionFundStates(
    orderPlacements,
    portfolioHoldings.byFund,
    fundList.byIsin,
  );

  // Replace the original portfolio fund state with the one from order
  // placement since it has more state.
  const subscriptionFundStates = holdingsFundStates.map((holdingsFundState) => {
    const fundState = orderPlacementFundStates.withHoldings.find(
      (orderPlacementFundState) =>
        orderPlacementFundState.isin === holdingsFundState.isin,
    );

    if (fundState) {
      return fundState;
    }

    return holdingsFundState;
  });

  // Merge the fund states that has holdings with those that were added
  // by the use of the funds modal.
  const fundStates = [
    ...subscriptionFundStates,
    ...orderPlacementFundStates.withoutHoldings,
  ];

  return orderBy(
    fundStates,
    [(fund) => !!fund.marketValue, (fund) => fund.name],
    ['desc', 'asc'],
  );
};
