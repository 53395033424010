import React, { useState } from 'react';

import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';

import { DatePicker } from '../DatePicker';

interface Props {
  label: string;
  onChange: (date: Date) => void;
}

export const DateSearch: React.FC<Props> = ({ label, onChange }: Props) => {
  const texts = useFms();
  const locale = useUserLocale();

  const [searchDate, setSearchDate] = useState<Date>();
  const [errorMessage, setErrorMessage] = useState('');

  const handleDateOnChange = (_, selectedDate: Date): void => {
    // Check if the selected date is not empty, but an invalid date
    // and set the error message accordingly.
    if (selectedDate && isNaN(selectedDate.getTime())) {
      setErrorMessage(texts.reportsArchivePage.dateSearch.error);
    } else {
      setSearchDate(selectedDate);
      onChange(selectedDate);
      setErrorMessage('');
    }
  };

  return (
    <DatePicker
      id="search-by-date"
      name="search-by-date"
      label={label}
      locale={locale}
      error={errorMessage}
      data-trackid-source="presentation-filter"
      data-trackid-type="date-picker"
      value={searchDate}
      onChange={handleDateOnChange}
      clearable
      clearLabel={texts.reportsArchivePage.dateSearch.clearLabel}
    />
  );
};
