import { Language } from '@/i18n/Language';

export enum Locale {
  Norwegian = 'nb-NO',
  Swedish = 'sv-SE',
  English = 'en-GB',
  Danish = 'da-DK',
  German = 'de-DE',
  French = 'fr-FR',
}

export const fromLanguageToLocale = (language: Language): Locale => {
  switch (language) {
    case Language.English:
      return Locale.English;
    case Language.Norwegian:
      return Locale.Norwegian;
    case Language.Swedish:
      return Locale.Swedish;

    default:
      throw new Error(
        `Missing conversion between language [${language}] and locale.`,
      );
  }
};
