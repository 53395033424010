import uniqBy from 'lodash/uniqBy';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderPlacementType } from '@/api/order/models/OrderPlacementType';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { SwitchOrderFormValues } from '../../../types/SwitchOrderFormValues';
import { getDefaultSignatoryOption } from '../../../utils/getDefaultSignatoryOption';
import { getSignatoryOptionFromSignatories } from '../../../utils/getSignatoryOptionFromSignatories';
import { getValueTypeFromOrderPlacements } from '../../../utils/getValueTypeFromOrderPlacements';
import { mapHoldingsToRedemptionFundState } from '../../../utils/mapHoldingsToRedemptionFundState';
import { mapHoldingsToSubscriptionFundState } from '../../../utils/mapHoldingsToSubscriptionFundState';
import { createRedemptionFundStates } from '../../RedemptionOrderForm/utils/createRedemptionFundStates';
import { createSubscriptionFundStates } from '../../SubscriptionOrderForm/utils/createSubscriptionFundStates';
import { createInitialSwitchValues } from '../utils/createInitialSwitchValues';

export const useInitialSwitchFormValues = (): SwitchOrderFormValues => {
  const { data: fundList } = useFundListQuery();
  const { data: fundHoldings } = useFetchFundHoldingsQuery();
  const { currentOrder } = useOrderPageContext();
  const portfolios = useFundPortfolios(OrderType.Redemption);
  const { userCmId: currentUserCmId } = useCurrentUserInfo();
  const hasPermission = useHasPermission();

  // Convert an existing fundOrder to order form values if it exists in the state.
  if (currentOrder) {
    const portfolioHoldings =
      fundHoldings.byPortfolioId[currentOrder.portfolioNumber];

    const fromOrderPlacements = currentOrder.orderPlacements.filter(
      (orderPlacement) =>
        orderPlacement.placementType === OrderPlacementType.Sell,
    );

    const toOrderPlacements = currentOrder.orderPlacements.filter(
      (orderPlacement) =>
        orderPlacement.placementType === OrderPlacementType.Buy,
    );

    const fromValueType = getValueTypeFromOrderPlacements(fromOrderPlacements);

    const toValueType = getValueTypeFromOrderPlacements(toOrderPlacements);

    const currencies = uniqBy(
      [...fromOrderPlacements, ...toOrderPlacements],
      (holdings) => holdings.currency,
    ).map((holdings) => holdings.currency);

    if (currencies.length > 1) {
      throw new Error(
        'An order of type switch cannot have multiple currencies.',
      );
    }

    const redemptionFundStates = createRedemptionFundStates(
      fromOrderPlacements,
      portfolioHoldings,
      fundList,
      fromValueType,
    );

    const subscriptionFundStates = createSubscriptionFundStates(
      toOrderPlacements,
      portfolioHoldings,
      fundList,
      toValueType,
    );

    return {
      currency: currencies[0],

      fromPortfolioShortName: currentOrder.portfolioNumber,
      toPortfolioShortName: currentOrder.transferToPortfolioNumber,

      fromValueType,
      toValueType,

      fromFunds: redemptionFundStates,
      toFunds: subscriptionFundStates,

      signer: currentOrder.signatories[0] ?? null,
      cosigner: currentOrder.signatories[1],
      signatoryOption: getSignatoryOptionFromSignatories(
        currentOrder.signatories,
        currentUserCmId,
        hasPermission,
      ),
      signingProvider: currentOrder.signingProvider,
    };
  }

  const portfolioShortName = portfolios.length === 1 ? portfolios[0].id : '';

  const currencies = portfolioShortName
    ? Object.keys(fundHoldings.byPortfolioId[portfolioShortName].byCurrency)
    : [];

  const currency =
    currencies.length === 1 && convertStringToEnum(currencies[0], CurrencyCode);

  const fromFundStates =
    portfolioShortName && currency
      ? mapHoldingsToRedemptionFundState(
          fundList.byIsin,
          fundHoldings.byPortfolioId[portfolioShortName].holdings,
          OrderValueType.Amount,
        )
      : [];

  const toFundStates =
    portfolioShortName && currency
      ? mapHoldingsToSubscriptionFundState(
          fundList.byIsin,
          fundHoldings.byPortfolioId[portfolioShortName].holdings,
          OrderValueType.Percent,
        )
      : [];

  // Make it easier for the customer if they only have one portfolio.
  // Set it to that so they don't have to choose it.
  return createInitialSwitchValues(
    currency,
    portfolioShortName,
    portfolioShortName,
    OrderValueType.Amount,
    OrderValueType.Percent,
    fromFundStates,
    toFundStates,
    getDefaultSignatoryOption(hasPermission),
  );
};
