import Table, { IColumn, IRow } from '@storeblocks/table';
import React from 'react';
import styled from 'styled-components';

interface Props {
  columns: Array<IColumn>;
  rows: Array<IRow>;
}

export const FundsTable: React.FC<Props> = ({ columns, rows }) => {
  return <TableStyled data-test="funds-table" columns={columns} data={rows} />;
};

const TableStyled = styled(Table)`
  & table {
    table-layout: auto;
  }

  /*
    Make the first column take all the remaining space
    to make the other columns only be as wide as its content.
 */
  & th:nth-of-type(1) {
    width: 100%;
  }

  & td {
    vertical-align: top;
  }

  & tr:last-of-type {
    background-color: var(--color-surface);
    font-weight: bold;

    &:hover {
      color: unset;
    }
  }

  & tr:last-of-type {
    height: 48px;

    td {
      padding: 0 16px;
      vertical-align: middle;
    }
  }
`;
