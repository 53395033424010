import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useFms } from '@/hooks/useFms';
import { TableColumn } from '@/types/tables';

import { useOrderValueTypeText } from '../../../hooks/useOrderValueTypeText';
import { RedemptionFundRow } from '../types/RedemptionFundRow';

export const useCreateRedemptionFundTableColumns = (
  valueType: OrderValueType,
): Array<TableColumn<RedemptionFundRow>> => {
  const texts = useFms();

  const valueTypeText = useOrderValueTypeText(valueType);

  return [
    {
      name: texts.orders.form.table.fund,
      key: 'fund',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: null,
      key: 'sellAll',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.orders.form.table.holdings,
      key: 'holdings',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: valueTypeText,
      key: 'amount',
      renderType: 'jsx',
      dataType: 'other',
    },
  ];
};
