import ChipGroup from '@storeblocks/chip/lib/group';
import styled from 'styled-components';

export const FilterChipGroup = styled(ChipGroup)`
  display: flex;
  flex-wrap: wrap;

  label {
    // Some chip values are very long and we don't want the chips
    // to get too wide when there are several chips on a line.
    // E.g. on the portfolio group selector we have values like
    // "1103 - NUF, Bank Norwegian, en filial av Nordax Bank AB".
    // 344px is about 1/3 of the full page width on desktop.
    max-width: 344px;
    min-width: 0;

    span {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
