import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { Show } from '@/components/Show';
import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';
import { RedemptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/RedemptionOrderFormValues';
import { nameof } from '@/util/nameof';

import { FundName } from '../../FundName';
import { Isin } from '../../Isin';
import { useArrayFieldByIsin } from '../hooks/useArrayFieldByIsin';
import { AmountError } from './AmountError';
import { ClosedForTradeInfo } from './ClosedForTradeInfo';
import { SellAllInfo } from './SellAllInfo';

interface Props {
  isin: string;
}

export const RedemptionFundCell: React.FC<Props> = ({ isin }) => {
  // Get the fund object.
  const [{ value: fund }] = useArrayFieldByIsin<RedemptionFundState>(
    nameof<RedemptionOrderFormValues>('fromFunds'),
    isin,
  );

  // Get the amount form field.
  const [, { touched, error }] = useArrayFieldByIsin<number>(
    nameof<RedemptionOrderFormValues>('fromFunds'),
    isin,
    nameof<RedemptionFundState>('value'),
  );

  const hasErrorInAmount = touched && !!error;
  const isSellingAllHoldings = fund.sellAll;

  return (
    <>
      <FundName data-test="fund-name">{fund.name}</FundName>
      <Isin>{isin}</Isin>

      <Show when={hasErrorInAmount} animate>
        <AmountError errorMessage={error} isin={isin} />
      </Show>

      <Show when={isSellingAllHoldings} animate>
        <SellAllInfo isin={isin} />
      </Show>

      <Show when={fund.isClosed} animate>
        <ClosedForTradeInfo isin={isin} orderType={OrderType.Redemption} />
      </Show>
    </>
  );
};
