import { createApi } from '@reduxjs/toolkit/query/react';
import { unflatten } from 'flat';
import { isEmpty } from 'lodash';

import { Language } from '@/i18n/Language';
import { FMSTexts } from '@/types/fms';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { getBackupFmsTexts } from './utils/getBackupFmsTexts';
import { getFmsLanguage } from './utils/getFmsLanguage';
import { getFmsUrl } from './utils/getFmsUrl';

export const fmsApi = createApi({
  reducerPath: 'fmsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fmsTexts: builder.query<FMSTexts, Language>({
      query: (language) => {
        const fmsLanguage = getFmsLanguage(language);

        return {
          url: getFmsUrl(fmsLanguage),
          disableKeycloakToken: true, // No authentication is required for this endpoint.
          timeout: 5000,
        };
      },
      transformResponse: (data: JSON, _, language) => {
        if (isEmpty(data)) {
          // Use backup texts if the backend did not serve us data.
          return getBackupFmsTexts(language);
        }

        return unflatten(data);
      },
      async onQueryStarted(language, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch {
          // Use backup texts if the request fails.
          const backupTexts = await getBackupFmsTexts(language);
          dispatch(
            fmsApi.util.upsertQueryData('fmsTexts', language, backupTexts),
          );
        }
      },
    }),
  }),
});

export const { useFmsTextsQuery } = fmsApi;
