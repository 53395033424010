/**
 * Internet Explorer has its own functionality in
 * the Navigator namespace.
 */
interface NavigatorInIE extends Navigator {
  msSaveBlob(blob: Blob, filename: string);
}

export const fileDownload = (data: ArrayBuffer, filename: string): void => {
  const blob = new Blob([data]);
  if (typeof (window.navigator as NavigatorInIE).msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    (window.navigator as NavigatorInIE).msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    tempLink.setAttribute('target', '_blank');
    document.body.append(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};
