import React, { FC, PropsWithChildren } from 'react';

import { QueryState } from '@/api/components/QueryState';
import { usePortfoliosQuery } from '@/api/portfolios';
import { isNotReady } from '@/api/utils/isNotReady';
import { H1 } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';

import { CenteredPageContent } from './CenteredPageContent';

export const WaitForPortfolioDetails: FC<PropsWithChildren> = ({
  children,
}) => {
  const texts = useFms();
  const portfoliosQuery = usePortfoliosQuery();

  if (isNotReady(portfoliosQuery)) {
    return (
      <CenteredPageContent>
        <H1>{texts.pages.presentation.title}</H1>
        <QueryState
          query={portfoliosQuery}
          customTexts={{
            noData: {
              title:
                texts.pages.presentation.screens.portfolioPerformance
                  .noPortfolioGroup,
            },
          }}
        />
      </CenteredPageContent>
    );
  }

  return <>{children}</>;
};
