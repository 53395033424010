import { AssetPerformanceData } from '@/api/performanceData/models';

import { AllocationType } from '../types/AllocationType';
import { getAssetProductGroups } from './getAssetProductGroups';
import { getAssetProducts } from './getAssetProducts';

export const getAssetsByAllocationType = (
  assetRoot: AssetPerformanceData,
  allocationType: AllocationType,
): Array<AssetPerformanceData> => {
  switch (allocationType) {
    case AllocationType.Product:
      return getAssetProducts(assetRoot);

    case AllocationType.ProductGroup: {
      return getAssetProductGroups(assetRoot);
    }

    case AllocationType.MainDistribution:
      return assetRoot.assets;

    default:
      throw new Error(`Invalid allocation type [${allocationType}]`);
  }
};
