import Alert from '@storeblocks/alert';
import React, { FC } from 'react';

import { useFms } from '@/hooks/useFms';

export const EsgInsufficientDataAlert: FC = () => {
  const texts = useFms();

  return <Alert title={texts.pages.esg.insufficientData} />;
};
