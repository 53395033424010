import { unsafeConvertStringToEnumWithWarning } from '@/util/unsafeConvertStringToEnumWithWarning';

import { PermissionDto } from '../dto/PermissionsReply';
import { Permission } from '../models/Permission';

export const mapToPermission = (
  permission: PermissionDto,
): Permission | undefined =>
  unsafeConvertStringToEnumWithWarning(
    `${permission.resource}:${permission.privilege}`,
    Permission,
  );
