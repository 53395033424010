import React from 'react';

import { AssetAllocationDonutScreen } from '@/pages/Presentation/screens/AssetAllocationDonutScreen';
import { AssetAllocationScreen } from '@/pages/Presentation/screens/AssetAllocationScreen';
import { AssetReturnScreen } from '@/pages/Presentation/screens/AssetReturnScreen';
import { CoverScreen } from '@/pages/Presentation/screens/CoverScreen';
import { EsgCoverageScreen } from '@/pages/Presentation/screens/EsgCoverageScreen';
import { EsgExcludedCompanies } from '@/pages/Presentation/screens/EsgExcludedCompanies';
import { EsgOverviewScreen } from '@/pages/Presentation/screens/EsgOverviewScreen';
import { EsgScoreAndCarbonFootprintScreen } from '@/pages/Presentation/screens/EsgScoreAndCarbonFootprintScreen';
import { EsgSolutionsScreen } from '@/pages/Presentation/screens/EsgSolutionsScreen';
import { PortfolioHoldingsScreen } from '@/pages/Presentation/screens/PortfolioHoldingsScreen';
import { PortfolioPerformanceScreen } from '@/pages/Presentation/screens/PortfolioPerformanceScreen';

import { ConnectRoute } from './types/ConnectRoute';

export const presentationScreenRoutes: Array<ConnectRoute> = [
  {
    id: 'cover-screen',
    element: <CoverScreen />,
    path: '/:organizationCmId/reports/presentation/screen/cover',
  },
  {
    id: 'portfolio-holdings-screen',
    element: <PortfolioHoldingsScreen />,
    path: '/:organizationCmId/reports/presentation/screen/portfolio-holdings',
  },
  {
    id: 'asset-allocation-screen',
    element: <AssetAllocationScreen />,
    path: '/:organizationCmId/reports/presentation/screen/asset-allocation',
  },
  {
    id: 'asset-allocation-donut-screen',
    element: <AssetAllocationDonutScreen />,
    path: '/:organizationCmId/reports/presentation/screen/asset-allocation-donut',
  },
  {
    id: 'portfolio-performance-screen',
    element: <PortfolioPerformanceScreen />,
    path: '/:organizationCmId/reports/presentation/screen/portfolio-performance',
  },
  {
    id: 'asset-return-screen',
    element: <AssetReturnScreen />,
    path: '/:organizationCmId/reports/presentation/screen/asset-return',
  },
  {
    id: 'esg-overview-screen',
    element: <EsgOverviewScreen />,
    path: '/:organizationCmId/reports/presentation/screen/esg-overview',
  },
  {
    id: 'esg-score-and-carbon-footprint-screen',
    element: <EsgScoreAndCarbonFootprintScreen />,
    path: '/:organizationCmId/reports/presentation/screen/esg-score-and-carbon-footprint',
  },
  {
    id: 'esg-solutions-screen',
    element: <EsgSolutionsScreen />,
    path: '/:organizationCmId/reports/presentation/screen/esg-solutions',
  },
  {
    id: 'esg-coverage-screen',
    element: <EsgCoverageScreen />,
    path: '/:organizationCmId/reports/presentation/screen/esg-coverage',
  },
  {
    id: 'esg-excluded-companies-screen',
    element: <EsgExcludedCompanies />,
    path: '/:organizationCmId/reports/presentation/screen/esg-excluded-companies',
  },
];
