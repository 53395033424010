import { Permission } from '@/api/permissions/models/Permission';

export type ComplexPermission = {
  permissions: Permission[];
  mustHave: 'all' | 'one';
};

/**
 * Check if the user has the specified permission.
 * To be used where a hook cannot be used.
 *
 * DO NOT USE THIS FUNCTION IN COMPONENTS, USE THE HOOK INSTEAD.
 * The hook will make the components rerender when the permissions change.
 * Making the components to always have the latest permissions.
 * This function will not make things rerender.
 *
 * @returns `true` if the user has the permission, `false` otherwise.
 */
export const hasPermission = (
  permission: Permission | ComplexPermission,
  userPermissions: Permission[],
): boolean => {
  if (isComplexPermission(permission)) {
    switch (permission.mustHave) {
      case 'all':
        return permission.permissions.every((p) => userPermissions.includes(p));
      case 'one':
        return permission.permissions.some((p) => userPermissions.includes(p));

      default:
        throw new Error(
          `Unknown mustHave value in permissions: [${permission.mustHave}].`,
        );
    }
  }

  return userPermissions.includes(permission);
};

function isComplexPermission(value: unknown): value is ComplexPermission {
  return (
    value instanceof Object &&
    Object.prototype.hasOwnProperty.call(value, 'permissions') &&
    Object.prototype.hasOwnProperty.call(value, 'mustHave')
  );
}
