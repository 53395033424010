import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { FileDownloadCheckboxColumn } from '@/components/Table/FileDownloadCheckboxColumn';
import { FileNameColumn } from '@/components/Table/FileNameColumn';
import { useFms } from '@/hooks/useFms';
import { useUserDateFormat } from '@/i18n/hooks';

import { GroupedTableRow, TableRow } from '../Table/TableModels';
import { Report } from '../types/ReportModel';
import { downloadReports } from '../utils/downloadReports';
import { visuallyGroupByDate } from '../utils/visuallyGroupByDate';

export const useMapReportsToTableRows = (reports: Report[]): TableRow[] => {
  const texts = useFms();
  const dateFormat = useUserDateFormat();

  return useMemo<TableRow[]>(() => {
    const mappedRows: GroupedTableRow[] = reports.map((report) => {
      const date = format(parseISO(report.reportDateISO), dateFormat);

      return {
        groupKey: date,
        reportDate: {
          sortContent: `${date} ${report.name}`,
          displayContent: <Align>{date}</Align>,
        },
        name: {
          sortContent: `${report.name}-${report.id}`,
          displayContent: (
            <FileNameColumn
              filename={report.name}
              fileId={report.id}
              fileExtension={report.fileType}
              onDownload={() => downloadReports([report])}
            />
          ),
        },
        selectFile: {
          sortContent: null,
          displayContent: <FileDownloadCheckboxColumn report={report} />,
        },
      };
    });

    return visuallyGroupByDate(mappedRows);
  }, [reports, dateFormat, texts]);
};

const Align = styled.div`
  padding: 16px;
  line-height: 24px;
`;
