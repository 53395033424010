import { useUserDateFnsLocale } from '@/i18n/hooks';

/**
 * Get months for current locale.
 * @returns List of months formatted to the current locale.
 */
export const useLocaleShortMonths = (): string[] => {
  const dateFnsLocale = useUserDateFnsLocale();

  if (!dateFnsLocale.localize) {
    throw new Error(
      `Unsupported locale for translating shortMonths, [${dateFnsLocale.code}]`,
    );
  }

  const months = [];
  for (let i = 0; i < 12; i += 1) {
    months.push(dateFnsLocale.localize.month(i, { width: 'abbreviated' }));
  }

  return months;
};
