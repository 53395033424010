import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import { useUserSettingsQuery } from '@/api/userSettings/userSettingsApi';
import { Language } from '@/i18n/Language';
import { FMSTexts } from '@/types/fms';

/** Get FMS texts from the store in the users language.
 * If a user language is not set, the default language is Norwegian.
 */
export function useFms(): FMSTexts {
  const { data: userSettings } = useUserSettingsQuery();
  const fmsTextsQuery = useFmsTextsQuery(
    userSettings?.language ?? Language.Norwegian,
  );

  return fmsTextsQuery.data;
}
