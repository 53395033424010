import { createListenerMiddleware } from '@reduxjs/toolkit';

import { hasLogToConsoleCookie } from '@/logging/utils/hasLogToConsoleCookie';

import { addLogEvent } from '../logSlice';

export const printLogEventListener = createListenerMiddleware();

printLogEventListener.startListening({
  predicate: (action) =>
    addLogEvent.match(action) &&
    // Only print to console when the config or print-to-console cookie
    // says so.
    (window.config.printLogEvents || hasLogToConsoleCookie()),

  effect: (action) => {
    // eslint-disable-next-line no-console
    console.debug('[LOG]', action.payload);
  },
});
