import { LargeCard } from '@storeblocks/card';
import React, { FC } from 'react';
import styled from 'styled-components';

import { EmailLink } from '@/components/EmailLink';

interface Props {
  name: string;
  position: string;
  image?: string;
  email: string;
  phone?: string;
}

export const PersonCard: FC<Props> = ({
  name,
  position,
  image,
  email,
  phone,
}) => {
  return (
    <LargeCardStyled
      key={name}
      image={image}
      icon={!image ? 'contact-advisor' : undefined}
      caption={
        <Caption>
          {position}
          {phone && <Phone>{phone}</Phone>}
        </Caption>
      }
      description={email && <EmailLink email={email} />}
      title={name}
      actions={[]}
      alt={name}
    />
  );
};

const LargeCardStyled = styled(LargeCard)`
  height: 100%;
  border: none;

  & img {
    width: auto;
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: column;
`;

const Phone = styled.span`
  white-space: nowrap;
`;
