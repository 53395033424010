/**
 * Match any character that is alphabetic according to Unicode standards.
 *
 * The \p{Alphabetic} is a Unicode property escape. It matches any character with the Unicode property "Alphabetic",
 * which includes letters from various scripts and languages.
 *
 * The /u is the Unicode flag. It ensures that the regular expression is interpreted as a sequence
 * of Unicode code points rather than individual 16-bit code units. This is crucial when dealing with characters
 * outside the Basic Multilingual Plane (BMP), ensuring accurate matching and handling of Unicode characters.
 */
const alphabeticCharacterRegex = /\p{Alphabetic}/u;

/**
 * Special characters that are allowed in the message.
 *
 * Note that these characters are also present in the backend validation.
 * Any new values must be added to the backend validation as well.
 */
const allowedSpecialCharacters = [
  // Prettier and ESLint do not agree on how to format this line.
  // Thus we disable the ESLint rule for this line.
  // eslint-disable-next-line quotes
  "'",
  '"',
  '.',
  ',',
  ':',
  ';',
  '{',
  '[',
  '(',
  ')',
  ']',
  '}',
  '+',
  '\\',
  '-',
  '_',
  '@',
  '/',
  '?',
  '%',
  '=',
  '!',
];

type ValidationResult =
  | {
      isValid: false;
      invalidCharacters: string[];
    }
  | {
      isValid: true;
      invalidCharacters?: never;
    };

/**
 * Check if the specified string is a valid message.
 * It is considered valid if it contains only alphabetic characters, digits, and allowed special characters.
 *
 * @returns An object with the validation result. If the message is invalid, the object will include the invalid characters.
 *
 * @example
 * isMessageValid('Hello world!')
 * // Produces =>
 * {
 *  isValid: true,
 * }
 *
 * isMessageValid('Hello * world! <')
 * // Produces =>
 * {
 *  isValid: false,
 *  invalidCharacters: ['<', '*'],
 * }
 */
export const isMessageValid = (value: string): ValidationResult => {
  // Check each individual character in the message if it is valid.
  const invalidCharacters = Array.from(value).reduce(
    (invalidCharacters, character) => {
      const isValid =
        // Check if the character is alphabetic.
        alphabeticCharacterRegex.test(character) ||
        // Check if the character is a digit.
        !isNaN(Number(character)) ||
        // If we get here, the character is not alphabetic nor a digit.
        // Thus it is a special character.
        // Check if the special character is allowed.
        allowedSpecialCharacters.includes(character);

      if (isValid) {
        // The character is allowed.
        return invalidCharacters;
      }

      // The character is not allowed.
      // Add it to the list of invalid characters.
      return invalidCharacters.add(character);
    },
    new Set<string>(),
  );

  const isValid = invalidCharacters.size === 0;

  if (isValid) {
    return {
      isValid: true,
    };
  }

  return {
    isValid: false,
    invalidCharacters: Array.from(invalidCharacters),
  };
};
