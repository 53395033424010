import React, { FC, useState } from 'react';
import { styled } from 'styled-components';

import { Transaction } from '@/api/transactions/domain/Transaction';

import { TransactionDetailsRow } from './TransactionDetailsRow';
import { TransactionMainRow } from './TransactionMainRow';

interface Props {
  transaction: Transaction;
}

export const TransactionRow: FC<Props> = ({ transaction }) => {
  const [open, setOpen] = useState(false);

  return (
    // Wrap the main row and the expandable row in <tbody /> for
    // better accessibility. This will let accessibility tools know that they
    // are contextually together.
    <StyledTableBody data-test="transaction-row">
      <TransactionMainRow
        transaction={transaction}
        open={open}
        onClick={() => setOpen(!open)}
      />

      {open && <TransactionDetailsRow transaction={transaction} />}
    </StyledTableBody>
  );
};

const StyledTableBody = styled.tbody`
  border-bottom: 1px solid var(--color-border);
`;
