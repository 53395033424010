import React from 'react';
import styled from 'styled-components';

import { RemoveButton } from './RemoveButton';

interface Props {
  label: string;
  onRemoveClick: () => void;
  disableRemoveButton?: boolean;
}

export const ExistingItemRow: React.FC<Props> = ({
  label,
  onRemoveClick,
  disableRemoveButton,
}) => (
  <Wrapper>
    <Label>{label}</Label>
    <RemoveButton
      disabled={disableRemoveButton}
      onClick={() => onRemoveClick()}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  line-height: 24px;
  white-space: normal;
`;
