import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { PermissionsReply } from './dto/PermissionsReply';
import { Permission } from './models/Permission';
import { mapToPermission } from './utils/mapToPermission';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    /**
     * Fetch the permissions the user has in the application.
     */
    permissions: builder.query<Permission[], void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/authorization/permissions`,
      }),
      transformResponse: (dto: PermissionsReply) => {
        return (
          dto.permissions
            .map((permission) => mapToPermission(permission))
            // The 'mapToPermission' function will return undefined
            // when it fails to convert a value. Thus remove all undefined
            // values to avoid cluttering the result set.
            .filter(Boolean)
        );
      },
    }),
  }),
});

export const { usePermissionsQuery, endpoints } = permissionsApi;
