import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props extends PropsWithChildren {
  vertical?: boolean;
  horizontal?: boolean;
}

export const Center: FC<Props> = ({
  children,
  vertical = false,
  horizontal = false,
  ...props
}) => (
  <Container $vertical={vertical} $horizontal={horizontal} {...props}>
    {children}
  </Container>
);

interface ContainerProps {
  $vertical: boolean;
  $horizontal: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  align-items: ${(props) => (props.$vertical ? 'center' : 'flex-start')};
  justify-content: ${(props) => (props.$horizontal ? 'center' : 'flex-start')};
`;
