import styled from 'styled-components';

/**
 * Avoids any page break within this container when printing.
 */
export const AvoidPageBreaksInside = styled.div`
  @media print {
    break-inside: avoid-page;
  }
`;
