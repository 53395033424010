import Breadcrumbs from '@storeblocks/breadcrumbs';
import { Tab, Tabs } from '@storeblocks/tabs';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Permission } from '@/api/permissions/models/Permission';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useCurrentUserInfo } from '@/auth/hooks';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { UserType } from '@/config/types';
import { useFms } from '@/hooks/useFms';
import { FMSTexts } from '@/types/fms';

import { OrganizationAdmin } from './Organizations/OrganizationAdmin';
import { IndividualUserAdmin } from './Users/IndividualUserAdmin';
import { OrganizationUserAdmin } from './Users/OrganizationUserAdmin';

export const AdminPage: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const { userType } = useCurrentUserInfo();
  const hasPermission = useHasPermission();

  const texts: FMSTexts = useFms();

  const { tabId } = useParams();

  const navigate = useNavigate();

  const handleChange = (_, newTabId: string): void => {
    navigate(`../admin/${newTabId}`, { replace: true });
  };

  if (!tabId) {
    return null;
  }

  return (
    <CenteredPageContent data-test="admin-page">
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/admin/${tabId}`}>
          {texts.menu.links.administration}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.administration} />

        {userType === UserType.External && <OrganizationUserAdmin />}

        {userType === UserType.Internal &&
          hasPermission(Permission.CustomerAdminWrite) && (
            <div data-test="organization-admin">
              <Tabs
                activeTabId={tabId}
                description="Administration tabs"
                onChange={handleChange}
              >
                <Tab id="orgusers" label={texts.admin.users.organization.title}>
                  <OrganizationUserAdmin />
                </Tab>
                <Tab id="allusers" label={texts.admin.users.all.title}>
                  <IndividualUserAdmin />
                </Tab>
                <Tab
                  id="orgadmin"
                  label={texts.admin.organization.title}
                  data-test="admin-page-organizations"
                >
                  <OrganizationAdmin />
                </Tab>
              </Tabs>
            </div>
          )}
      </MainContent>
    </CenteredPageContent>
  );
};
