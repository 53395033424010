import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { InternalLinkList } from '@/components/InternalLinkList';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { useFms } from '@/hooks/useFms';

export const IntermediateReportsPage: FC = () => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();

  const links = [
    <InternalLink
      key={`/${organizationCmId}/reports/portfolio`}
      to={`/${organizationCmId}/reports/portfolio`}
      data-test="reports-portfolio-link"
    >
      {texts.menu.links.reports.portfolio}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/reports/funds`}
      to={`/${organizationCmId}/reports/funds`}
      data-test="reports-funds-link"
    >
      {texts.menu.links.reports.funds}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/reports/contract-notes`}
      to={`/${organizationCmId}/reports/contract-notes`}
      data-test="contract-notes-link"
    >
      {texts.menu.links.contractNotes}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/reports/presentation`}
      to={`/${organizationCmId}/reports/presentation`}
      data-test="presentation-mode-link"
    >
      {texts.menu.links.presentation}
    </InternalLink>,
  ];

  return (
    <CenteredPageContent data-test="reports-page">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/reports`}>
          {texts.menu.links.reports.reports}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.reports.reports} />
        <InternalLinkList links={links} />
      </MainContent>
    </CenteredPageContent>
  );
};
