import Input from '@storeblocks/input';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Fund } from '@/api/funds/models';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';

import { FundListAccordion } from './FundListAccordion';
import { fuzzySearchFunds } from './utils/fuzzySearchFunds';

interface Props {
  funds: Fund[];
}

export const AllConnectFunds: React.FC<Props> = ({ funds }) => {
  const texts = useFms();

  const [filter, setFilters] = useState<{
    result: Fund[];
    query: string;
  }>({
    result: funds,
    query: '',
  });

  const handleSearch = (query: string): void => {
    const filteredFunds = fuzzySearchFunds(query, funds);

    setFilters({
      result: filteredFunds,
      query: query,
    });
  };

  return (
    <div>
      <Heading>{texts.pages.fundList.allFunds.title}</Heading>
      <WithGap>
        <Wrapper>
          <Input
            id="fund-list-search-input"
            name="fund-list-search-input"
            label={texts.pages.fundList.allFunds.search}
            value={filter.query}
            onChange={(e) => handleSearch(e.target.value)}
            icon="search"
            fluid
            inputProps={{
              autoComplete: 'off',
            }}
          />
        </Wrapper>
        <FundListAccordion
          funds={filter.result}
          data-test="fund-list-all-funds"
          hasHoldingsInFunds={false}
        />
      </WithGap>
    </div>
  );
};

const Heading = styled(H2)`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 528px;
`;
