import React, { FC, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import { Divider } from '@/components/Divider';
import { Show } from '@/components/Show';
import { TextShortBold } from '@/components/Typography';

import { ExpandFilterButton } from './ExpandFilterButton';

interface Props extends PropsWithChildren {
  selectedFilterTexts: Array<string>;
}

export const CollapsibleFilterContainer: FC<Props> = ({
  selectedFilterTexts,
  children,
}) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  return (
    <div>
      <Header>
        <TextRepresentationOfFilters>
          {selectedFilterTexts.map((text) => (
            <TextShortBold key={text}>{text}</TextShortBold>
          ))}
        </TextRepresentationOfFilters>
        <ExpandFilterButton
          isExpanded={isFilterExpanded}
          onChange={() => setIsFilterExpanded((state) => !state)}
        />
      </Header>
      <Show when={isFilterExpanded} animate>
        <Content>{children}</Content>
      </Show>
      <Divider margin="16" />
    </div>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding-top: 24px;
  // We need to set the height to accomodate for displaying an error message
  // in the filter without moving the content of the page down.
  height: 136px;

  @media print {
    display: none;
  }
`;

const TextRepresentationOfFilters = styled.div`
  display: flex;
  column-gap: 24px;
  row-gap: 8px;
  flex-wrap: wrap;
`;
