import { set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';

import { Brand } from '@/types/Brand';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { FundClosedReasonDto } from '../dtos/FundClosedReasonDto';
import { FundListDto } from '../dtos/FundListDto';
import { Fund } from '../models/Fund';
import { FundClosedReason } from '../models/FundClosedReason';
import { FundList } from '../models/FundList';
import { getPaymentInformation } from './getPaymentInformation';
import { transformFundDocumentsResponse } from './transformFundDocumentsResponse';

export const transformFundListResponse = (dto: FundListDto): FundList => {
  /**
   * @todo: SAMIK-1581: There is a bug in the funds endpoint, some funds
   * are duplicated in the JSON response.
   * Remove the uniq filter when the Jira has been done.
   */
  const uniqueFunds = uniqBy(dto.funds, (fund) => fund.isin);

  const funds = uniqueFunds.map((fund) => {
    const model: Fund = {
      startDate: fund.startDate,
      name: fund.name,
      isin: fund.isin,
      brand: convertStringToEnum(fund.brand, Brand),
      baseCurrency: fund.baseCurrency,
      paymentInformation: getPaymentInformation(
        convertStringToEnum(fund.brand, Brand),
        fund.baseCurrency,
      ),

      minimumInitialSubscriptionAmount:
        fund.minimumInitialSubscriptionAmount[fund.baseCurrency],

      minimumSubsequentSubscriptionAmount:
        fund.minimumSubsequentSubscriptionAmount[fund.baseCurrency],

      minimumRedemptionAmount: fund.minimumRedemptionAmount[fund.baseCurrency],

      closedForRedemption: fund.closedForRedemption,
      closedForSubscription: fund.closedForSubscription,

      closedForRedemptionReason: mapFundClosedReason(
        fund.closedForRedemptionReason,
      ),
      closedForSubscriptionReason: mapFundClosedReason(
        fund.closedForSubscriptionReason,
      ),

      srri: fund.srri,
      sustainabilityScore: fund.sustainabilityScore,
      performanceNumbers: fund.performanceNumbers,

      documents: fund.documents.map((document) =>
        transformFundDocumentsResponse(document),
      ),

      cutOffTime: fund.cutOffTime
        ? parseCutOffTime(fund.cutOffTime)
        : undefined,
    };

    return model;
  });

  const byIsin = keyBy(funds, (fund) => fund.isin);

  return {
    funds,
    byIsin,
  };
};

const mapFundClosedReason = (
  reason: FundClosedReasonDto | undefined,
): FundClosedReason | undefined => {
  if (!reason) {
    // If there isn't a reason then there is nothing to map.
    return undefined;
  }

  switch (reason) {
    case FundClosedReasonDto.FundClosed:
      return FundClosedReason.FundClosed;

    case FundClosedReasonDto.MissingMarketingPermission:
      return FundClosedReason.MissingMarketingPermission;

    case FundClosedReasonDto.UnsupportedCurrency:
      return FundClosedReason.UnsupportedCurrency;

    case FundClosedReasonDto.UnsupportedFund:
      return FundClosedReason.UnsupportedFund;

    default:
      throw new Error(`Missing mapping for fund closed reason [${reason}].`);
  }
};

/**
 * Parse cut-off time from the format `"15:00:00"` to todays date with the specified time.
 * The incoming cut-off time is always in the Norwegian timezone.
 *
 * @returns The date of the cut-off where the date part is today and the time part is the cut-off time.
 */
const parseCutOffTime = (time: string): Date => {
  const [hours, minutes, seconds] = time.split(':').map(Number);

  // Convert time to Norwegian timezone since the specified cut-off time does not have
  // the timezone part and the cut-off is always in Norwegian time.
  const now = utcToZonedTime(new Date().toISOString(), 'Europe/Oslo');

  const todayAtTime = set(now, { hours, minutes, seconds });

  return todayAtTime;
};
