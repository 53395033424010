import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { CrossroadsReportsReply } from './dtos/CrossroadsReportsReply';
import { CrossroadsReportTypeDto } from './dtos/CrossroadsReportTypeDto';
import { DailyTradeReportsReply } from './dtos/DailyTradeReportsReply';
import { IHubDirectoriesReply } from './dtos/IHubDirectoriesReply';
import { IHubDirectoryDto } from './dtos/IHubDirectoryDto';
import { IHubReportsReply } from './dtos/IHubReportsReply';
import { AddIHubDirectoryToOrganization } from './models/AddIHubDirectoryToOrganization';
import { DailyTradeReport } from './models/DailyTradeReports';
import { DeleteIHubDirectoryFromOrganization } from './models/DeleteIHubDirectoryFromOrganization';
import { IHubDirectory } from './models/IHubDirectory';
import { IHubFilters } from './models/IHubFilters';
import { IHubReport } from './models/IHubReport';
import { ReportFile } from './models/ReportFile';
import { transformCrossroadsReportsReply } from './utils/transformCrossroadsReportsReply';
import { transformDailyTradeReportsReply } from './utils/transformDailyTradeReportsReply';
import { transformIHubDirectoriesForOrganizationReply } from './utils/transformIHubDirectoriesForOrganizationReply';
import { transformIHubDirectoriesReply } from './utils/transformIHubDirectoriesReply';
import { transformIHubReportsReply } from './utils/transformIHubReportsReply';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['IHubDirectoriesForOrganization'],
  endpoints: (builder) => ({
    iHubMonthlyReports: builder.query<Array<IHubReport>, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/ihub/files/organization`,
        timeout: 50000,
        params: {
          limit: 3,
          reportGroups: 'MONTHLY',
        },
      }),
      transformResponse: (reply: IHubReportsReply) => {
        return transformIHubReportsReply(reply);
      },
    }),

    iHubOrganizationReports: builder.query<Array<IHubReport>, IHubFilters>({
      query: ({ name, startDate, endDate, limit }) => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/ihub/files/organization`,
        timeout: 50000,
        params: {
          name,
          startDate,
          endDate,
          limit,
        },
      }),
      transformResponse: (reply: IHubReportsReply) => {
        return transformIHubReportsReply(reply);
      },
    }),

    /**
     * Fetch reports from iHub that every organization has access to.
     * These files are fund reports, such as reports about NAV, solvency
     * and detailed holdings in the funds.
     */
    iHubSharedReports: builder.query<Array<IHubReport>, IHubFilters>({
      query: (queryParams) => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/ihub/files/shared`,
        /**
         * There are over 5000 reports, use filters to only fetch
         * what is needed in the frontend.
         */
        params: {
          name: queryParams.name,
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
          limit: queryParams.limit,
        },
      }),
      transformResponse: (reply: IHubReportsReply) => {
        return transformIHubReportsReply(reply);
      },
    }),

    iHubDirectories: builder.query<Array<IHubDirectory>, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/ihub/admin/directories`,
      }),
      transformResponse: (reply: IHubDirectoriesReply) => {
        return transformIHubDirectoriesReply(reply);
      },
    }),

    iHubDirectoriesForOrganization: builder.query<Array<IHubDirectory>, string>(
      {
        query: (orgCmId) => ({
          url: `${window.config.connectBaseApiUrl}/api/reports/ihub/admin/organizations/${orgCmId}/directories`,
        }),
        transformResponse: (reply: Array<IHubDirectoryDto>) => {
          return transformIHubDirectoriesForOrganizationReply(reply);
        },
        providesTags: ['IHubDirectoriesForOrganization'],
      },
    ),

    addIHubDirectoryToOrganization: builder.mutation<
      void,
      AddIHubDirectoryToOrganization
    >({
      query: ({ organizationCmId, directoryId }) => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/ihub/admin/organizations/${organizationCmId}/directories`,
        method: 'PUT',
        data: { directoryID: directoryId },
      }),
      invalidatesTags: ['IHubDirectoriesForOrganization'],
    }),

    deleteIHubDirectoryFromOrganization: builder.mutation<
      void,
      DeleteIHubDirectoryFromOrganization
    >({
      query: ({ organizationCmId, directoryId }) => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/ihub/admin/organizations/${organizationCmId}/directories/${directoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IHubDirectoriesForOrganization'],
    }),

    yearlyReports: builder.query<Array<ReportFile>, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/wolf?reportTypes=${CrossroadsReportTypeDto.YearlyStatement}`,
      }),
      transformResponse: (reply: CrossroadsReportsReply) => {
        return transformCrossroadsReportsReply(reply);
      },
    }),

    dailyTradeReports: builder.query<Array<DailyTradeReport>, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/reports/wolf?reportTypes=${CrossroadsReportTypeDto.DailyTransactionReport}`,
      }),
      transformResponse: (reply: DailyTradeReportsReply) => {
        return transformDailyTradeReportsReply(reply);
      },
    }),
  }),
});

export const {
  useIHubMonthlyReportsQuery,
  useIHubOrganizationReportsQuery,
  useIHubSharedReportsQuery,
  useIHubDirectoriesQuery,
  useIHubDirectoriesForOrganizationQuery,
  useAddIHubDirectoryToOrganizationMutation,
  useDeleteIHubDirectoryFromOrganizationMutation,
  useYearlyReportsQuery,
  useDailyTradeReportsQuery,
  util,
} = reportsApi;
