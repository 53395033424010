import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props extends PropsWithChildren {
  when: boolean;
  animate?: boolean;
  suppressInitialAnimation?: boolean;
}

/**
 * @deprecated There are unintended side effects on the children components
 * when using this component. The code in the children is evaluated
 * even when the component is not rendered. This leads to unintended
 * side effects when using hooks. The hook will evaluate when it should not.
 * It may cause the component to throw a error if the result from the hook
 * is not what is expected and this can be hard to debug.
 *
 * Use conditional rendering instead of the Show component.
 * @example
 * ```<>{boolean && <Component />}</>```
 */
export const Show: FC<Props> = ({
  when,
  animate,
  children,
  suppressInitialAnimation,
}) => {
  if (animate) {
    return (
      <AnimatePresence initial={!suppressInitialAnimation}>
        {when && (
          <AnimateContainer
            key="animated-show"
            transition={{ ease: 'easeInOut' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            {children}
          </AnimateContainer>
        )}
      </AnimatePresence>
    );
  }
  return <>{when ? children : null}</>;
};

const AnimateContainer = styled(motion.div)`
  overflow: hidden;
`;
