import sumBy from 'lodash/sumBy';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { RedemptionFundState } from '../../types/RedemptionFundState';
import { SubscriptionFundState } from '../../types/SubscriptionFundState';

interface ErrorMessages {
  minimumSubscriptionAmount: string;
  redemptionAndSubscriptionAmountIsNotEqual: string;
}

export const testSwitchFromAmountToAmountTotal = (
  context: Yup.TestContext<AnyObject>,
  errorMessages: ErrorMessages,
  toFunds: Array<SubscriptionFundState>,
): true | Yup.ValidationError => {
  // If there are any funds with sellAll, do not validate total amount.
  if (
    context.parent.fromFunds.some((fund: RedemptionFundState) => fund.sellAll)
  ) {
    return true;
  }

  const totalFromAmount = sumBy(
    context.parent.fromFunds,
    (fund: RedemptionFundState) => fund.value,
  );

  const totalToAmount = sumBy(toFunds, (fund) => fund.value);

  if (totalFromAmount !== totalToAmount) {
    return context.createError({
      message: errorMessages.redemptionAndSubscriptionAmountIsNotEqual,
    });
  }

  return true;
};
