import React from 'react';

import { MainContent } from '@/components/MainContent';

import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';
import { WaitForSigningConfirmation } from './components/WaitForSigningConfirmation';

export const SigningRightUnconfirmedPage: React.FC = () => {
  return (
    <>
      <SigningRightBreadcrumb />

      <MainContent data-test="signing-right-unconfirmed">
        <Stepper activeStep={2} />
        {/* Wait for signing right signature confirmation when the status is unconfirmed. */}
        <WaitForSigningConfirmation />
      </MainContent>
    </>
  );
};
