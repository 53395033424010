import styled from 'styled-components';

import StorebrandLogo from '@/assets/logo/storebrand.svg';

export const StorebrandLogoFull = styled(StorebrandLogo)`
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;
