import uniqWith from 'lodash/uniqWith';

import { FundList } from '@/api/funds/models';
import { FundPaymentInformation } from '@/api/funds/models/FundPaymentInformation';
import { OrderLine } from '@/api/orderList/models/Order';

interface Result {
  orderLines: OrderLine[];
  paymentInfo: FundPaymentInformation;
  totalAmount: number;
}

export const getPaymentInfoForOrderLines = (
  orderLines: OrderLine[],
  fundList: FundList,
): Result[] => {
  const orderLineIsins = orderLines.map((orderLine) => orderLine.isin);

  // Find all funds that are included in the order lines.
  const fundsInOrderLines = fundList.funds.filter((fund) =>
    orderLineIsins.includes(fund.isin),
  );

  // Find all unique payment information for the funds in the order lines.
  const paymentsForOrderLines = uniqWith(
    fundsInOrderLines.map((fund) => fund.paymentInformation),
    (a, b) =>
      a.bankAccountNumber === b.bankAccountNumber && a.currency === b.currency,
  );

  return paymentsForOrderLines.map<Result>((paymentInfo) => {
    // Filter order lines for the current payment information.
    // We link order lines to payment information by the associated funds bank account number and currency.
    const orderLinesForPayment = orderLines.filter(
      (orderLine) =>
        fundList.byIsin[orderLine.isin].paymentInformation.bankAccountNumber ===
          paymentInfo.bankAccountNumber &&
        orderLine.currency === paymentInfo.currency,
    );

    // Sum up all order line values for the current payment information.
    const totalAmount = orderLinesForPayment.reduce(
      (total, current) => total + current.value,
      0,
    );

    return {
      orderLines: orderLinesForPayment,
      paymentInfo,
      totalAmount,
    };
  });
};
