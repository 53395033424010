import Table from '@storeblocks/table';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { OrganizationUser } from '@/api/organizationUsers/models/OrganizationUser';
import { useFms } from '@/hooks/useFms';
import { FMSTexts } from '@/types/fms';

import {
  OrganizationInvitedUserColumn,
  OrganizationInvitedUserRow,
} from './rowAndColumnTypes';

interface Props {
  organizationUsers: OrganizationUser[];
}

export const OrganizationInvitedUsersTable: FC<Props> = ({
  organizationUsers,
}) => {
  const texts: FMSTexts = useFms();

  const [tableRows, setTableRows] = useState<OrganizationInvitedUserRow[]>([]);

  useEffect(() => {
    const rows = createRows(organizationUsers);
    setTableRows(rows);
  }, [organizationUsers]);

  const createRows = (
    users: OrganizationUser[],
  ): OrganizationInvitedUserRow[] =>
    users.map((user) => ({
      name: `${user.givenName} ${user.familyName}`,
      email: user.email || '-',
      roles: texts.admin.user.inviteSent,
    }));

  const tableColumns: OrganizationInvitedUserColumn[] = [
    {
      key: 'name',
      name: texts.contact.name,
      dataType: 'text',
    },
    {
      key: 'email',
      name: texts.email,
      dataType: 'text',
    },
    {
      key: 'roles',
      name: texts.roles,
      dataType: 'text',
    },
  ];

  return <StyledTable data={tableRows} columns={tableColumns} sortable />;
};

const StyledTable = styled(Table)`
  margin-top: 32px;

  & table {
    color: #757575;
  }

  // Name.
  & th:nth-of-type(1) {
    width: 33%;
    max-width: 33%;
  }

  // Email.
  & th:nth-of-type(2) {
    width: 20%;
    max-width: 20%;
  }

  // Roles.
  & th:nth-of-type(3) {
    width: 47%;
    max-width: 47%;
  }
`;
