import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Fund, FundList as FundListType } from '@/api/funds/models';
import { Brand } from '@/types/Brand';

import { useGetBrandsOptions } from '../hooks/useGetBrandOptions';
import { filterFundsByBrands } from '../utils/filterFundsByBrands';
import { fuzzySearchFunds } from '../utils/fuzzySearchFunds';
import { BrandFilter } from './BrandFilter';
import { FundList } from './FundList';
import { SearchField } from './SearchField';

const sortFundsByName = (funds: Fund[]): Fund[] =>
  sortBy(funds, (fund) => fund.name.toLowerCase());

interface Props {
  fundList: FundListType;
  fundsWithHoldings: Array<string>;
}

export const AddFundsModalContent: React.FC<Props> = ({
  fundList,
  fundsWithHoldings,
}) => {
  const brandOptions = useGetBrandsOptions(fundList.funds);

  // Don't display the brand filter if there is only one option.
  // Note that there is an "All" option that is always present so the length
  // will always be at least 1.
  const hasBrandssToFilter = brandOptions.length > 2;

  const [filter, setFilters] = useState<{
    result: Fund[];
    query: string;
    brands: Brand[];
  }>({
    result: sortFundsByName(fundList.funds),
    query: '',
    brands: Object.values(Brand),
  });

  const filterFunds = (query: string, brands: Brand[]): void => {
    const queryIsins =
      // Is the query empty?
      query === ''
        ? // Yes -> The query is empty, we want to display all funds sorted by name.
          sortFundsByName(fundList.funds).map((fund) => fund.isin)
        : // No -> There is a query, we want to display the results of the fuzzy search.
          fuzzySearchFunds(query, fundList.funds);

    const brandIsins = filterFundsByBrands(brands, fundList.funds);

    // Use the result set from the fuzzy search as base since it is sorted
    // according to a score and we want to keep the order as to have the most
    // relevant results at the top.
    const filteredFunds = queryIsins
      .filter((isin) => brandIsins.includes(isin))
      .map((isin) => fundList.byIsin[isin]);

    setFilters({
      result: filteredFunds,
      query,
      brands,
    });
  };

  const handleBrandFilterChanged = (brands: Brand[]): void => {
    filterFunds(filter.query, brands);
  };

  const handleFundSearch = (query: string): void => {
    filterFunds(query, filter.brands);
  };

  return (
    <Container>
      {hasBrandssToFilter && (
        <BrandFilter
          options={brandOptions}
          onFilterChange={handleBrandFilterChanged}
        />
      )}

      <SearchField value={filter.query} onChange={handleFundSearch} />

      <FundList funds={filter.result} fundsWithHoldings={fundsWithHoldings} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
