import { EsgScore } from '@/api/esg/models/EsgScore';

/** Calculate the kpi percentage of a ESG category as compared to the reference. */
export const calculateEsgKpiPercentage = (
  kpi: EsgScore,
  referenceKpi: EsgScore,
): number => {
  return kpi.value < referenceKpi.value
    ? (100 * kpi.value) / referenceKpi.value
    : 100;
};
